import { IDataItem } from '@cpa/base-core/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Schemas, TypeConstants } from '@cp/base-types';
import { axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import moment from 'moment/moment';
import { DateLanguageMapping } from '@cpa/base-core/constants';
import { useTranslation } from 'react-i18next';

import ShowMore from '../../../../../../../../../components/ShowMore/ShowMore';
import HtmlContent from '../../../../../../../../../components/HtmlContent/HtmlContent';

import styles from './RelatedMessage.module.scss';

interface IRelatedMessageProps {
  relatedMessage: IDataItem;
}

const RelatedMessage: React.FC<IRelatedMessageProps> = ({ relatedMessage }) => {
  const [t, i18n] = useTranslation();
  const [userDetails, setUserDetails] = useState<Schemas.User | null>(null);
  const cancelToken = useRef<CancelTokenSource | null>(null);

  const [timeFromNow, setTimeFromNow] = useState<string>(`(${moment(relatedMessage.createdAt as string).fromNow()})`);

  useEffect(() => {
    moment.locale(DateLanguageMapping[i18n.language]);
    setTimeFromNow(`${moment(relatedMessage.createdAt as string).fromNow()}`);
  }, [i18n.language, relatedMessage.createdAt]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeFromNow(`${moment(relatedMessage.createdAt as string).fromNow()}`);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [relatedMessage.createdAt]);

  const getDetails = useCallback(async (): Promise<void> => {
    const dataServiceEndpoint = getEndpoint(axiosDictionary.appDataService);
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();
    try {
      const response = await dataServiceEndpoint.axios.get(
        `data-store/${encodeURIComponent(TypeConstants.CompanyUser)}%3FallData=true/${encodeURIComponent(relatedMessage.createdByUser as string)}`,
        {
          cancelToken: cancelToken.current.token,
        }
      );
      setUserDetails(response.value);
    } catch (e) {
      setUserDetails(null);
    }
  }, [relatedMessage]);

  useEffect(() => {
    getDetails();
    return () => {
      cancelToken.current?.cancel();
    };
  }, [cancelToken, getDetails]);

  if (!relatedMessage?.identifier) {
    return null;
  }
  return (
    <div className={styles.relatedMessageWrapper}>
      <div className={styles.user}>
        {userDetails?.name || (relatedMessage.createdByUser as string)} <strong>{timeFromNow}</strong>
      </div>
      <ShowMore maxHeight={150}>
        <HtmlContent className={styles.title} html={relatedMessage.description as string} />
      </ShowMore>
    </div>
  );
};

export default RelatedMessage;
