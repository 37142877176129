import * as _ from 'lodash';
import { IComponentWithOptions, ITableRowOption, ITableRowProps } from '@cpa/base-core/types';
import React, { NamedExoticComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { IColumn, Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '@cpa/base-core/helpers/data/formatters';
import { useMediaQuery } from 'react-responsive';

import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/ro';
import 'moment/locale/sv';
import 'moment/locale/zh-cn';

import { DateLanguageMapping } from '@cpa/base-core/constants/pages';
import { useSetInterval } from '@fluentui/react-hooks';

import HtmlContent from '../../../components/HtmlContent/HtmlContent';
import { HtmlRenderers } from '../../../mapping';

import styles from './Comment.module.scss';

const Comment: React.FC<ITableRowProps> = ({ rowId, item, onDoubleClick, darkMode, columns, page }) => {
  const [overflowActive, setOverflowActive] = useState(false);
  const [timeFromNow, setTimeFromNow] = useState<string>(`(${moment(item.createdAt as string).fromNow()})`);
  const [t, i18n] = useTranslation();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });

  const { setInterval, clearInterval } = useSetInterval();

  useEffect(() => {
    moment.locale(DateLanguageMapping[i18n.language]);
    setTimeFromNow(`(${moment(item.createdAt as string).fromNow()})`);
  }, [i18n.language, item.createdAt]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeFromNow(`(${moment(item.createdAt as string).fromNow()})`);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [clearInterval, item.createdAt, setInterval]);

  const blockRef = useRef<HTMLDivElement>(null);

  const calculateCommentHeight = useCallback(() => {
    const maxContentHeight = isMobileDevice ? 164 : 200;
    if (blockRef.current) {
      const height = blockRef.current.getBoundingClientRect().height;
      if (height === maxContentHeight) {
        setOverflowActive(true);
      }
    }
  }, [isMobileDevice]);

  useEffect(() => {
    calculateCommentHeight();
  }, [calculateCommentHeight, isMobileDevice]);

  const gradients = classNames({
    [styles.gradientLight]: !darkMode,
    [styles.gradientDark]: darkMode,
    [styles.gradient]: true,
  });

  const openButtonClasses = classNames({
    [styles.buttonLight]: !darkMode,
    [styles.buttonDark]: darkMode,
    [styles.showMore]: true,
  });

  const commentTitle = useMemo(() => {
    // TODO: Use schema
    const ticketCommentItem = item as { name: string; creator: { email: string; name: string } };
    const creator = ticketCommentItem.creator?.name ?? ticketCommentItem.creator?.email ?? undefined;
    return [creator, ticketCommentItem.name].filter(Boolean).join(': ');
  }, [item]);

  const directionIcon = useMemo(() => {
    if (!item.direction) return null;
    return <Icon className={styles.icon} iconName={item.direction === 'Incoming' ? 'ArrowDownRight8' : 'ArrowUpRightMirrored8'} />;
  }, [item?.direction]);

  const commentDate = useMemo(() => {
    return formatDateTime(item.createdAt as string, i18n.language, false);
  }, [i18n.language, item.createdAt]);

  const onOpenClick = useCallback(() => {
    onDoubleClick?.();
  }, [onDoubleClick]);

  const HtmlRendererComponent = useMemo(() => {
    const htmlColumn = columns.find((column) => column.fieldName === 'description') as IColumn & { type?: string };
    if (!htmlColumn?.type) {
      return HtmlRenderers.Default;
    }

    return HtmlRenderers[htmlColumn.type] || HtmlRenderers.Default;
  }, [columns]);

  const handleShadowRootInitialized = useCallback(() => {
    calculateCommentHeight();
  }, [calculateCommentHeight]);

  return (
    <div className={styles.rowWrapper} onDoubleClick={onOpenClick}>
      <div id={rowId}>
        <div className={styles.commentWrapper}>
          <div className={styles.leftColumn}>
            {directionIcon}
            <span className={styles.date}>{commentDate}</span>
            <span>{timeFromNow}</span>
          </div>
          <div className={styles.rightColumn} ref={blockRef}>
            <div className={styles.contentWrapper}>
              <HtmlContent className={styles.title} html={commentTitle} />
              <HtmlRendererComponent html={item.description as string} item={item} page={page} onInit={handleShadowRootInitialized} />
            </div>
            {overflowActive && <div className={gradients} />}
            {overflowActive && (
              <span className={openButtonClasses} onClick={onOpenClick}>
                {t('common.open')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CommentMemoized: NamedExoticComponent & IComponentWithOptions<ITableRowOption> = React.memo(Comment, _.isEqual);
CommentMemoized.options = {
  table: {
    hideHeader: true,
    hideSelectedItemsCount: true,
    disabledManagedColumnsWidth: true,
    rowSize: {
      itemHeight: 220,
      templateHeight: 400,
    },
  },
};
export default CommentMemoized;
