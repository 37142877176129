import initSwc, { InitOutput, Options, transform, transformSync } from '@swc/wasm-web';

let initialization: Promise<InitOutput> | null = null;
export function startSwcInitialization(): void {
  if (initialization) {
    return;
  }

  initialization = initSwc();
}

const defaultOptions: Options = {
  jsc: {
    parser: {
      syntax: 'typescript',
    },
  },
};
export async function transformCode(code: string, options: Options = defaultOptions) {
  await initialization;

  const result = await transform(code, options);
  return result.code;
}

export function transformCodeSync(code: string, options: Options = defaultOptions) {
  return transformSync(code, options);
}
