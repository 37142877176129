import { CpaPageActionExecutionContext, IFilePropertyJsonPath, IJSONSchema, OnUiActionExecutionContext, Schemas } from '@cp/base-types';
import { ODataPropsFilter } from '@cp/base-utils';
import { DialogType } from '@fluentui/react';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import urlJoin from 'url-join';

import {
  executeBackendAction,
  executeFrontendAction,
  executeUiTrigger,
  generateCpShareLink,
  getLocationForBreadcrumb,
  IMatchedRelatedLink,
  showDialog,
} from '../helpers';
import { handleExport } from '../helpers/actions';
import notification from '../helpers/toast';
import { IGlobalState, store } from '../store';
import { wsSubscribeToBatchOperationStatus } from '../store/websocket/actions';
import {
  ActionSelection,
  IAction,
  ICustomAction,
  ICustomActionUnbound,
  IDataItem,
  IGenericComponentData,
  ITemplateCustomAction,
  LoadItemsFunction,
} from '../types';

import { useDownload } from './files';
import { ActionType, usePowerUser } from './ui';

export interface IDrawerOptions {
  readonly?: boolean;
  prefill?: IDataItem;
  customAddHandler?: (
    defaultAddHandler: (item: IDataItem) => Promise<IDataItem | void>,
    item: IDataItem,
    reloadCurrentItems: () => void
  ) => Promise<unknown>;
  type: 'add' | 'edit';
  wizardOptions?: IWizardOptions;
  customSchema?: IJSONSchema;
}

export interface IWizardOptions {
  isFullScreen?: boolean;
  isWizardMode?: boolean;
  steps?: number[];
  requiredOnly?: boolean;
  fields?: string[];
}

export enum userExperiencePreference {
  Expert = 'http://platform.cosmoconsult.com/ontology/Expert',
  Simple = 'http://platform.cosmoconsult.com/ontology/Simple',
  Undefined = 'http://platform.cosmoconsult.com/ontology/Undefined',
}

interface IActionHandlerProps {
  data: IGenericComponentData;
  selectedItems: IDataItem[];
  dismissContextCallout: () => void;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  reloadCurrentItems?: () => Promise<void>;
  downloadableFilePropertyJsonPaths?: IFilePropertyJsonPath[];
  setContentOptions?: React.Dispatch<
    React.SetStateAction<{
      activeItem: IDataItem | null;
      isDrawerOpened: boolean;
      isVersionsOpened: boolean;
      isLocalizationsOpened: boolean;
      drawerOptions: {
        readonly?: boolean;
        prefill?: IDataItem;
        customAddHandler?: (defaultAddHandler: unknown, item: IDataItem, reloadCurrentItems: () => void) => Promise<unknown>;
        type: 'add' | 'edit';
        wizardOptions?: IWizardOptions;
        customSchema?: IJSONSchema;
      };
      versionsOptions?: {
        comparedVersions: [string, string];
      };
    }>
  >;
  copyDialogRef?: React.RefObject<{
    openDialog(item: IDataItem): void;
    closeDialog(): void;
  }>;
  mergeIntoDialogRef?: React.RefObject<{
    openDialog(item: IDataItem): void;
    closeDialog(): void;
    setError(error: string): void;
  }>;
  compareDialogRef?: React.RefObject<{
    openDialog(item: IDataItem): void;
    closeDialog(): void;
    setError(error: string): void;
  }>;
  changeBaseLanguageDialogRef?: React.RefObject<{
    openDialog(item: IDataItem): void;
    closeDialog(): void;
    setError(error: string): void;
  }>;
  customActionsBound?: ICustomAction[];
  setRelatedPayload?: React.Dispatch<React.SetStateAction<[Schemas.CpaPage, Record<string, string>] | null>>;
  onEdit?: (item: IDataItem, initialItem: IDataItem) => Promise<IDataItem | void>;
  onDelete?: (items: IDataItem[]) => Promise<IDataItem | void>;
  handleOnDelete?: (items: IDataItem[]) => void;
  onAddClick?: (underlyingItem?: IDataItem, customAddHandler?: unknown, customSchema?: IJSONSchema) => void;
  handleOnCopy?: (item: IDataItem) => Promise<IDataItem | undefined | void>;
  clearTableSelection?: () => void;
  loadItems?: LoadItemsFunction;
  odataFilter?: ODataPropsFilter;
  formSchemas: Record<string, IJSONSchema>;
}

const NO_ACTION_HANDLER = async () => {};

export const useActionHandler = ({
  data,
  selectedItems,
  dismissContextCallout,
  setErrors,
  reloadCurrentItems,
  downloadableFilePropertyJsonPaths,
  setContentOptions,
  copyDialogRef,
  mergeIntoDialogRef,
  compareDialogRef,
  changeBaseLanguageDialogRef,
  customActionsBound,
  setRelatedPayload,
  onEdit,
  onDelete,
  handleOnDelete,
  onAddClick,
  handleOnCopy,
  clearTableSelection,
  loadItems,
  odataFilter,
  formSchemas,
}: IActionHandlerProps): ((action: ActionType | string, payload?: unknown, event?: React.MouseEvent<HTMLDivElement>) => void) => {
  const cpaIdentifier = useSelector((store: IGlobalState) => store.app.cpa?.identifier);
  const currentLocation = useSelector((state: IGlobalState) => state.router.location);
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.identifier);

  const [t] = useTranslation();
  const handleDownload = useDownload(data.page.dataEndpoint?.identifier);
  const dispatch = useDispatch();

  const exportActionHandler = useCallback(async () => {
    const exportConfirmed = await showDialog({
      message: '',
      dialogContentProps: {
        type: DialogType.largeHeader,
        title: t('common.export'),
        subText: t('common.exported'),
      },
      primaryButtonText: 'common.confirm',
      secondaryButtonText: 'common.cancel',
      closeOnClickOutside: true,
      closeOnAction: true,
    });
    if (exportConfirmed) {
      try {
        await handleExport(loadItems, data.page, t, data.schema, odataFilter, selectedItems);
        setErrors([]);
      } catch (error) {
        console.error(error);
        setErrors([error.message]);
      }
    }
  }, [t, loadItems, data.page, data.schema, odataFilter, selectedItems, setErrors]);

  const shareActionHandler = useCallback(
    async (actionItems: IDataItem[]) => {
      if (!cpaIdentifier) {
        return;
      }
      await generateCpShareLink(cpaIdentifier, data.page, actionItems[0]);
      dismissContextCallout();
      setErrors([]);
    },
    [cpaIdentifier, data.page, dismissContextCallout, setErrors]
  );

  const downloadActionHandler = useCallback(
    (actionItems: IDataItem[]) => {
      setErrors([]);
      clearTableSelection?.();
      handleDownload(
        actionItems,
        (downloadableFilePropertyJsonPaths || []).map((path) => path.propertyJsonPath)
      );
    },
    [clearTableSelection, downloadableFilePropertyJsonPaths, handleDownload, setErrors]
  );

  const deleteActionHandler = useCallback(async () => {
    if (!selectedItems.length) {
      return;
    }

    setErrors([]);
    const deleteConfirmed = await showDialog({
      message: '',
      title: t('common.delete'),
      dialogContentProps: {
        type: DialogType.largeHeader,
        title: t('common.delete'),
        subText: t('common.deleted', { amount: selectedItems.length }),
      },
      primaryButtonText: 'common.confirm',
      secondaryButtonText: 'common.cancel',
      closeOnClickOutside: true,
      closeOnAction: true,
    });
    if (deleteConfirmed) {
      const toastId = notification.info(t('common.deletingItem'), Infinity);
      try {
        await handleOnDelete?.(selectedItems as IDataItem[]);
        notification.dismiss(toastId);
        notification.success(t('common.itemDeleted'));
      } catch (error) {
        if (toastId) {
          notification.dismiss(toastId);
        }
        setErrors([error.message]);
      } finally {
        clearTableSelection?.();
      }
    }
  }, [clearTableSelection, handleOnDelete, selectedItems, setErrors, t]);

  const viewActionHandler = useCallback(
    (actionItems: IDataItem[], keyProp: string) => {
      if (!actionItems[0]) {
        return;
      }
      setErrors([]);
      // items[0] in case where there is no identifier in item
      const openSingleItemPage = data.page.singleItemTemplate;
      if (openSingleItemPage) {
        const pathname = urlJoin(data.page.path?.replace('/:id', '') || '/', encodeURIComponent(actionItems[0][keyProp]?.toString() || '-'));
        dispatch(push({ pathname }));
      } else {
        setContentOptions?.({
          activeItem: actionItems[0],
          isDrawerOpened: true,
          isVersionsOpened: false,
          isLocalizationsOpened: false,
          drawerOptions: {
            readonly: true,
            type: 'edit',
          },
        });
      }
    },
    [data.page.path, data.page.singleItemTemplate, dispatch, setContentOptions, setErrors]
  );

  const modifyActionHandler = useCallback(
    async (actionItems: IDataItem[], form?: Extract<Schemas.CpaPage['forms'], {}>[number]) => {
      if (!actionItems[0]) {
        return;
      }
      setErrors([]);
      const drawerOptions: IDrawerOptions = {
        type: 'edit',
      };
      if (form) {
        try {
          const formSchema = formSchemas[form.cpTypeUrl];
          drawerOptions.customSchema = formSchema;
        } catch (e) {
          notification.error(e.message);
          console.error(e);
          return;
        }
      }
      // items[0] in case where there is no identifier in item
      setContentOptions?.({
        activeItem: actionItems[0],
        isDrawerOpened: true,
        isVersionsOpened: false,
        isLocalizationsOpened: false,
        drawerOptions,
      });
    },
    [formSchemas, setContentOptions, setErrors]
  );

  const copyActionHandler = useCallback(
    async (actionItems: IDataItem[]) => {
      let newItem: IDataItem | undefined | void;
      if (handleOnCopy) {
        if (!actionItems[0]) {
          return;
        }
        try {
          setErrors([]);
          const copyConfirmed = await showDialog({
            message: '',
            title: t('common.copy'),
            dialogContentProps: {
              type: DialogType.largeHeader,
              title: t('common.copy'),
              subText: t('common.cloned'),
            },
            primaryButtonText: 'common.confirm',
            secondaryButtonText: 'common.cancel',
            closeOnClickOutside: true,
            closeOnAction: true,
          });
          if (copyConfirmed) {
            newItem = await handleOnCopy?.(actionItems[0]);
          }
        } catch (e) {
          setErrors([e.message]);
        }
      }
      clearTableSelection?.();
      return newItem;
    },
    [clearTableSelection, handleOnCopy, setErrors, t]
  );

  const copyToTypeActionHandler = useCallback(
    (actionItems: IDataItem[]) => {
      if (actionItems[0]) {
        copyDialogRef?.current?.openDialog(actionItems[0]);
      }
    },
    [copyDialogRef]
  );

  const mergeIntoActionHandler = useCallback(
    (actionItems: IDataItem[]) => {
      if (actionItems[0]) {
        mergeIntoDialogRef?.current?.openDialog(actionItems[0]);
      }
    },
    [mergeIntoDialogRef]
  );

  const compareActionHandler = useCallback(
    (actionItems: IDataItem[]) => {
      if (actionItems[0]) {
        compareDialogRef?.current?.openDialog(actionItems[0]);
      }
    },
    [compareDialogRef]
  );

  const addActionHandler = useCallback(
    async (actionItems: IDataItem[], form?: Extract<Schemas.CpaPage['forms'], {}>[number]) => {
      clearTableSelection?.();
      if (form) {
        try {
          const formSchema = formSchemas[form.cpTypeUrl];
          onAddClick?.(actionItems[0], undefined, formSchema);
        } catch (e) {
          notification.error(e.message);
          console.error(e);
        }
        return;
      }
      onAddClick?.();
    },
    [clearTableSelection, formSchemas, onAddClick]
  );

  const versionsActionHandler = useCallback(
    (actionItems: IDataItem[]) => {
      if (!actionItems[0]) {
        return;
      }
      setErrors([]);
      // items[0] in case where there is no identifier in item
      setContentOptions?.({
        activeItem: actionItems[0],
        isDrawerOpened: false,
        isVersionsOpened: true,
        isLocalizationsOpened: false,
        drawerOptions: {
          type: 'add',
        },
      });
    },
    [setContentOptions, setErrors]
  );

  const localizationsActionHandler = useCallback(
    (actionItems: IDataItem[]) => {
      if (!actionItems[0]) {
        return;
      }
      setErrors([]);
      // items[0] in case where there is no identifier in item
      setContentOptions?.({
        activeItem: actionItems[0],
        isDrawerOpened: false,
        isVersionsOpened: false,
        isLocalizationsOpened: true,
        drawerOptions: {
          type: 'add',
        },
      });
    },
    [setContentOptions, setErrors]
  );

  const changeBaseLanguageHandler = useCallback(
    (actionItems: IDataItem[]) => {
      if (!actionItems[0]) {
        return;
      }
      setErrors([]);
      changeBaseLanguageDialogRef?.current?.openDialog(actionItems[0]);
    },
    [changeBaseLanguageDialogRef, setErrors]
  );

  const relatedActionHandler = useCallback(
    (actionItems: IDataItem[], relatedLink: IMatchedRelatedLink) => {
      if (relatedLink != null) {
        const page = relatedLink.matched;
        const { originalQuery, fullMatch } = relatedLink;
        const dataQuery = Object.fromEntries(new URLSearchParams(encodeURI(originalQuery || '')).entries());
        // Check page displayRelatedPageAsDrawer and display as drawer or redirect user
        if (page.displayRelatedPageAsDrawer || page.identifier === data.page.identifier) {
          // Open drawer with generic screen
          setRelatedPayload?.([page, dataQuery]);
        } else if (page.path) {
          // Redirect user
          // Path match, we need to pass odata query
          dispatch(
            push(
              page.path,
              !fullMatch
                ? {
                    externalDataQuery: dataQuery,
                    originPage: data.page,
                    originItem: actionItems[0],
                  }
                : undefined
            )
          );
        } else {
          console.warn(`Page ${page.name} doesn't have path to redirect`);
        }
      }
    },
    [data.page, dispatch, setRelatedPayload]
  );

  const currentLocationState = (currentLocation?.state || {}) as {
    originItem?: IDataItem;
    originPage?: Schemas.CpaPage;
    breadcrumbsStructure?: IDataItem[];
  };
  const breadcrumbs = currentLocationState?.breadcrumbsStructure || [];
  const updatedLocation =
    breadcrumbs.length && data.schema
      ? getLocationForBreadcrumb(data.page, data.schema, breadcrumbs, breadcrumbs.length - 1, currentLocation)
      : undefined;

  const closeActionHandler = useCallback(
    (e?: React.MouseEvent<HTMLDivElement>) => {
      e?.preventDefault();
      dispatch(
        push(
          updatedLocation || {
            pathname: data.page.path?.replace('/:id', ''),
          }
        )
      );
    },
    [data.page.path, dispatch, updatedLocation]
  );

  const customActionHandler = useCallback(
    (actionItems: IDataItem[], actionKey: string, event?: React.MouseEvent<HTMLDivElement>) => {
      const matchedCustomAction = customActionsBound?.find((customAction) => customAction.button.key === actionKey);
      matchedCustomAction?.onClick(actionItems, event);
    },
    [customActionsBound]
  );

  const templateCustomActionHandler = useCallback(
    async (actionItems: IDataItem[], tableItems: IDataItem[], templateCustomAction: ITemplateCustomAction) => {
      templateCustomAction.onClick(actionItems, {
        onEdit,
        onDelete,
        setErrors,
        clearTableSelection,
        reloadCurrentItems,
        onAddClick,
        copyActionHandler,
        schema: data.schema,
        tableItems,
        page: data.page,
        user: user,
      });
    },
    [user, setErrors, data.page, onEdit, onDelete, clearTableSelection, reloadCurrentItems, onAddClick, copyActionHandler, data.schema]
  );

  return useCallback(
    async (action: ActionType | string, payload?: unknown, event?: React.MouseEvent<HTMLDivElement>) => {
      dismissContextCallout();

      const actionItems = selectedItems.map((item) => (item?.__originalItem as IDataItem) || item).filter((item) => !!item);
      const keyProp = actionItems[0]?.identifier ? 'identifier' : '__identifier';

      switch (action) {
        case ActionType.Export:
          return exportActionHandler();
        case ActionType.Share:
          return shareActionHandler(actionItems);
        case ActionType.Download:
          return downloadActionHandler(actionItems);
        case ActionType.Delete:
          return deleteActionHandler();
        case ActionType.View:
          return viewActionHandler(actionItems, keyProp);
        case ActionType.Modify:
          return modifyActionHandler(actionItems, (payload as { form?: Extract<Schemas.CpaPage['forms'], {}>[number] } | undefined)?.form);
        case ActionType.Copy:
          return copyActionHandler(actionItems);
        case ActionType.CopyToType:
          return copyToTypeActionHandler(actionItems);
        case ActionType.MergeInto:
          return mergeIntoActionHandler(actionItems);
        case ActionType.Compare:
          return compareActionHandler(actionItems);
        case ActionType.Add:
          return addActionHandler(actionItems, (payload as { form?: Extract<Schemas.CpaPage['forms'], {}>[number] } | undefined)?.form);
        case ActionType.Versions:
          return versionsActionHandler(actionItems);
        case ActionType.Localizations:
          return localizationsActionHandler(actionItems);
        case ActionType.ChangeBaseLanguage:
          return changeBaseLanguageHandler(actionItems);
        case ActionType.Related:
          return relatedActionHandler(actionItems, payload as IMatchedRelatedLink);
        case ActionType.Close:
          return closeActionHandler(event);
        case ActionType.CustomAction:
          return customActionHandler(actionItems, payload as string, event);
        case ActionType.TemplateCustomAction:
          return templateCustomActionHandler(actionItems, selectedItems, payload as ITemplateCustomAction);
        default:
          console.warn(`Unknown action type: ${action}`);
          break;
      }
    },
    [
      dismissContextCallout,
      selectedItems,
      exportActionHandler,
      shareActionHandler,
      downloadActionHandler,
      deleteActionHandler,
      viewActionHandler,
      modifyActionHandler,
      copyActionHandler,
      copyToTypeActionHandler,
      mergeIntoActionHandler,
      compareActionHandler,
      addActionHandler,
      versionsActionHandler,
      localizationsActionHandler,
      changeBaseLanguageHandler,
      relatedActionHandler,
      customActionHandler,
      templateCustomActionHandler,
    ]
  );
};

export const useCpaPageActionClickHandler = (
  data: IGenericComponentData,
  clearTableSelection?: () => void,
  reloadCurrentItems?: () => Promise<void>,
  setInfoMessages?: (messages: string[]) => void,
  setErrors?: (errors: string[]) => void
) => {
  return useCallback(
    (cpaPageAction: IAction) =>
      async (items?: IDataItem[]): Promise<void> => {
        if (!cpaPageAction.function) {
          return;
        }

        // Backend execution
        if (cpaPageAction.runOnBackend) {
          if (!data.page.dataEndpoint?.identifier || !data.page.identifier || !cpaPageAction.identifier) {
            return;
          }

          try {
            const response = await executeBackendAction(
              data.page.dataEndpoint.identifier,
              data.page.identifier,
              cpaPageAction.identifier,
              items?.map((item) => item.identifier!)
            );

            if (response.message) {
              setErrors?.([]);
              setInfoMessages?.([response.message]);
            }

            if (response.resetContent) {
              clearTableSelection?.();
              reloadCurrentItems?.();
            }
          } catch (e) {
            console.error(`Failed to execute backend action`, e.message);
            setErrors?.([e.message]);
            setInfoMessages?.([]);
          }

          return;
        }

        // Frontend execution
        const context: CpaPageActionExecutionContext = {
          event: 'Action',
          schema: data.schema || undefined,
          page: data.page,
          items: items,
          reloadCurrentItems: reloadCurrentItems || NO_ACTION_HANDLER,
          setInfoMessages: setInfoMessages || NO_ACTION_HANDLER,
          events: {
            // TODO: This should be made more generic in the future
            subscribeToBatchOperationStatus: (identifier: string) => {
              store.dispatch(wsSubscribeToBatchOperationStatus(identifier));
            },
          },
        };
        await executeFrontendAction(cpaPageAction as NonNullable<Schemas.CpaPage['actions']>[0], context);
      },
    [clearTableSelection, data.page, data.schema, reloadCurrentItems, setErrors, setInfoMessages]
  );
};

export const useCustomActions = (
  data: IGenericComponentData,
  selectedItems: IDataItem[],
  onCpaPageActionClick: (cpaPageAction: IAction) => (items?: IDataItem[]) => Promise<void>,
  customActions?: ICustomActionUnbound[]
) => {
  const powerUser = usePowerUser();
  const [customActionsBound, setCustomActionsBound] = useState<ICustomAction[]>([]);

  useEffect(() => {
    const getCustomActionsBound = async (): Promise<void> => {
      // Actions from CpaPage
      const actionsFromCpaPage: ICustomAction[] = [];
      for (const cpaPageAction of data.page.actions || []) {
        if (cpaPageAction.userExperiencePreference === userExperiencePreference.Expert && !powerUser) {
          continue;
        }
        if (cpaPageAction.hidden === true) {
          continue;
        }

        if (cpaPageAction.hidden !== false && cpaPageAction.hidden?.sourceCode) {
          const context: OnUiActionExecutionContext = {
            event: 'Action',
            schema: data.schema || undefined,
            page: data.page,
            items: selectedItems,
          };
          const showAction = await executeUiTrigger<OnUiActionExecutionContext>(context, { sourceCode: cpaPageAction.hidden.sourceCode });

          if (!showAction) continue;
        }

        switch (true) {
          case cpaPageAction.selection === ActionSelection.ONE && selectedItems.length === 1:
            break;
          case cpaPageAction.selection === ActionSelection.MANY && selectedItems.length >= 1:
            break;
          case cpaPageAction.selection === ActionSelection.NONE && selectedItems.length === 0:
            break;
          case cpaPageAction.selection === ActionSelection.ANY:
            break;
          default:
            continue;
        }

        actionsFromCpaPage.push({
          onClick: onCpaPageActionClick(cpaPageAction as IAction),
          button: {
            key: cpaPageAction.name,
            title: cpaPageAction.name,
            icon: cpaPageAction.icon,
          },
          group: cpaPageAction.group as string | undefined,
        });
      }

      const customActionsBound = [
        ...actionsFromCpaPage,
        ...((customActions
          ?.filter(({ button }) => (button?.condition ? button.condition(selectedItems) : true))
          .map((action) => ({
            ...action,
            button: {
              ...action.button,
              title: typeof action.button.title == 'function' ? action.button.title(selectedItems) : action.button.title,
              url: typeof action.button.url == 'function' ? action.button.url(selectedItems) : action.button.url,
            },
          })) || []) as ICustomAction[]),
      ];

      setCustomActionsBound(customActionsBound);
    };

    getCustomActionsBound().catch((error) => console.error(`Failed to bind custom actions. ${error}`));
  }, [customActions, data.page, data.schema, onCpaPageActionClick, powerUser, selectedItems]);

  return customActionsBound;
};
