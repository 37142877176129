import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalDialogType, IDataItem } from '@cpa/base-core/types';
import { DefaultButton, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton } from '@fluentui/react';
import { Schemas } from '@cp/base-types';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { push } from 'connected-react-router';
import { showDialog } from '@cpa/base-core/helpers';
import { hideDialog } from '@cpa/base-core/store/app/actions';

import { transformItemForAnotherEntity } from '../../../utils/copy';

export interface ICopyDialogRef {
  openDialog(item: IDataItem): void;

  closeDialog(): void;
}

const copyDialogInitialState = {
  item: null as IDataItem | null,
  selectedKey: undefined as string | undefined,
  error: undefined as string | undefined,
};

export const CopyDialog = React.forwardRef(function CopyDialog(props, ref: React.Ref<ICopyDialogRef>) {
  const appPages = useSelector((state: IGlobalState) => state.app.pages);
  const dispatch = useDispatch();

  const pages: (Schemas.CpaPage & { key: string; text: string })[] = useMemo(
    () =>
      appPages
        .filter((page) => page.allowCreate && page.dataUrl && page.path)
        .map((page) => {
          const matchedPage = appPages.find((p) => p.identifier === page.parentCpaPage?.identifier);
          const text = matchedPage?.name ? page.name + ' - ' + matchedPage.name : page.name;
          return { ...page, key: page.identifier!, text } as Schemas.CpaPage & { key: string; text: string };
        }),
    [appPages]
  );

  const [dialogState, setDialogState] = useState(copyDialogInitialState);
  const [t] = useTranslation();

  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: t('common.copyType'),
      subText: t('common.copyTypeSubtext'),
    }),
    [t]
  );

  const onPageDropdownChange = useCallback((event: React.FormEvent<HTMLDivElement>, op?: IDropdownOption) => {
    op && setDialogState((state) => ({ ...state, selectedKey: op.key.toString() }));
  }, []);

  const onPageDropdownFocus = useCallback(() => {
    setDialogState((state) => ({ ...state, error: undefined }));
  }, []);

  const onDismissDialog = useCallback(() => {
    setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    dispatch(hideDialog());
  }, [dispatch]);

  const onDialogSubmit = useCallback(async () => {
    const targetPage = pages.find((page) => page.identifier === dialogState.selectedKey);
    if (!targetPage || !targetPage.path) {
      setDialogState((state) => ({ ...state, error: t('errors.pages.unavailablePage') }));
      return;
    }

    try {
      const { item } = await transformItemForAnotherEntity(dialogState.item!, targetPage, t, true);
      setDialogState(copyDialogInitialState);
      dispatch(hideDialog());
      dispatch(push(targetPage.path + `?action=add&preset=${encodeURIComponent(JSON.stringify(item))}`));
    } catch (e) {
      setDialogState((state) => ({ ...state, error: e.message }));
    }
  }, [dialogState.item, dialogState.selectedKey, dispatch, pages, t]);

  useImperativeHandle(
    ref,
    () => ({
      openDialog(item: IDataItem): void {
        setDialogState((state) => ({ ...state, item }));
      },
      closeDialog(): void {
        onDismissDialog();
      },
    }),
    [onDismissDialog]
  );

  useEffect(() => {
    if (!dialogState.item) return;
    showDialog({
      type: GlobalDialogType.CUSTOM,
      dialogContentProps: dialogContentProps,
      onClose: onDismissDialog,
      dialogTypeOptions: {
        renderBody: () => {
          return (
            <>
              <Dropdown
                label={t('common.pages')}
                options={pages}
                selectedKey={dialogState.selectedKey || ''}
                onChange={onPageDropdownChange}
                errorMessage={dialogState.error}
                onFocus={onPageDropdownFocus}
              />
              <DialogFooter>
                <PrimaryButton onClick={onDialogSubmit} text={t('common.confirm')} disabled={!dialogState.selectedKey} />
                <DefaultButton onClick={onDismissDialog} text={t('common.cancel')} />
              </DialogFooter>
            </>
          );
        },
      },
    });
  }, [dialogContentProps, dialogState.error, dialogState.item, dialogState.selectedKey, onDialogSubmit, onDismissDialog, onPageDropdownChange, onPageDropdownFocus, pages, t]);

  return null;
});
