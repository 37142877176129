import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { generateCpShareLink } from '@cpa/base-core/helpers';
import { IDataItem } from '@cpa/base-core/types';
import { axiosDictionary, getEntitiesFromEndpoint } from '@cpa/base-core/api';
import { cloneDeepWithMetadata, DataServiceModules } from '@cp/base-utils';

import LoadingArea from '../../../../../components/LoadingArea/LoadingArea';
import { LayoutContext } from '../../../../../components/Layout/Layout';
import { buildItem, buildSchema } from '../../../../../templates/singleItem/SolutionSingleItemTemplate/utils';

import styles from './SingleItemCover.module.scss';
import SingleItemCoverCtaButtons from './SingleItemCoverCtaButtons/SingleItemCoverCtaButtons';

interface ISingleItemCoverProps {
  dataItem: Schemas.Solution & IDataItem;
  page: Schemas.CpaPage;
  schema: IJSONSchema;
  isFetching: boolean;
  extraContentHeight: number;
}

const SingleItemCover: React.FC<ISingleItemCoverProps> = ({ dataItem, page, isFetching, schema, extraContentHeight }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const layoutContext = useContext(LayoutContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const cpa = useSelector((store: IGlobalState) => store.app.cpa);
  const shareHandler = useCallback(async () => {
    if (!cpa?.identifier) {
      return;
    }
    await generateCpShareLink(cpa.identifier, page, dataItem);
  }, [cpa?.identifier, page, dataItem]);

  const itemData = useMemo(() => (dataItem ? cloneDeepWithMetadata(buildItem(dataItem)) : undefined), [dataItem]);
  const schemaData = useMemo(() => (schema && Object.keys(schema).length ? cloneDeepWithMetadata(buildSchema(schema)) : undefined), [schema]);

  type Unpacked<T> = T extends (infer U)[] ? U : T;

  type SolutionTypeDetails = Unpacked<Schemas.Solution['solutionTypeDetails']>;

  type Components = Extract<
    SolutionTypeDetails,
    {
      _type?: 'http://platform.cosmoconsult.com/ontology/Components';
    }
  >['components'];

  type Component = Extract<Components, {}>[0];

  const requestedSolutions = useRef<string[]>([]);

  const [cachedSolutions, addSolutionToCache] = useReducer(
    (solutions: Record<string, Schemas.Solution>, newSolution: Schemas.Solution) => ({
      ...solutions,
      [newSolution.identifier!]: newSolution,
    }),
    {}
  );

  const findSolution = useCallback(
    (identifier: string | undefined): Schemas.Solution | undefined => {
      if (!identifier) return;
      if (!requestedSolutions.current.includes(identifier)) {
        requestedSolutions.current.push(identifier);
        Promise.all(
          getEntitiesFromEndpoint(
            axiosDictionary.appDataService,
            `${DataServiceModules.DATA_STORE}/${encodeURIComponent(
              'http://platform.cosmoconsult.com/ontology/Solution'
            )}?$top=1&$filter=${encodeURIComponent(`identifier eq '${identifier}'`)}`
          )
        )
          .then((r) => r.flatMap((response) => response.entities))
          .then((solutions) => {
            for (const solution of solutions as Schemas.Solution[]) {
              if (!solution.identifier) continue;
              addSolutionToCache(solution);
            }
          });
      }
      return cachedSolutions[identifier];
    },
    [cachedSolutions, addSolutionToCache]
  );

  const componentsDetailsItems = useMemo(
    () => itemData?.components?.components?.map((item: Component) => findSolution(item.solution.identifier)) ?? [],
    [findSolution, itemData?.components?.components]
  );

  const hasProductPlatforms = useMemo(() => {
    if (!dataItem?.solutionTypeDetails?.length) {
      return false;
    }

    for (const solutionTypeDetail of dataItem.solutionTypeDetails) {
      const productPlatforms = (solutionTypeDetail as unknown as IDataItem).productPlatforms as IDataItem[];

      if (productPlatforms?.length > 0) {
        return true;
      }
    }

    return false;
  }, [dataItem]);

  const getScrollableContainer = useCallback(() => {
    return containerRef.current?.closest('[data-is-scrollable="true"]');
  }, []);

  const scrollDown = useCallback(() => {
    const scrollableContainer = getScrollableContainer();

    if (containerRef.current && scrollableContainer) {
      scrollableContainer.scrollTo({ top: containerRef.current.clientHeight + 84 + extraContentHeight, behavior: 'smooth' });
    }
  }, [extraContentHeight]);

  useEffect(() => {
    if (window.location.hash) {
      scrollDown();
    }

    return () => {};
  }, []);

  if (!isFetching && !dataItem) return null;

  return (
    <div
      className={classNames({
        [styles.cover]: true,
        [styles.coverDark]: darkMode,
        [styles.coverWithMesh]: hasProductPlatforms,
      })}
      data-is-single-item-cover="true"
      style={{
        minHeight: layoutContext?.isHorizontalMenu ? `calc(100vh - ${235 + extraContentHeight}px)` : `calc(100vh - ${189 + extraContentHeight}px)`,
      }}
      ref={containerRef}
    >
      {!!cpa?.headerLogo && (
        <div
          className={styles.backgroundImage}
          style={{
            position: 'absolute',
          }}
        >
          <img src={cpa.headerLogo} />
        </div>
      )}

      <div
        className={classNames({
          [styles.body]: true,
        })}
      >
        {!!dataItem && (
          <div className={styles.bodyIn}>
            {(!!dataItem.image || !!dataItem.imageOnLightBackground) && (
              <div className={styles.logo}>
                <img src={darkMode ? dataItem.image ?? dataItem.imageOnLightBackground : dataItem.imageOnLightBackground ?? dataItem.image} />
              </div>
            )}
            <div className={styles.header}>{dataItem.name}</div>
            <div className={styles.subHeader}>{dataItem.shortDescription}</div>

            <SingleItemCoverCtaButtons
              componentsDetailsItems={componentsDetailsItems}
              callToActions={dataItem.callToActions ?? []}
              onScrollDown={scrollDown}
              onShare={shareHandler}
              schemaData={schemaData}
              page={page}
            />

            <a className={styles.down} onClick={scrollDown}>
              <Icon iconName={'ChevronDown'} />
            </a>
          </div>
        )}

        {!dataItem && isFetching && <LoadingArea />}
      </div>
    </div>
  );
};

export default SingleItemCover;
