const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/JobLocation',
    'type': 'object',
    'links': [
        {
            'rel': 'collection',
            'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FJobLocation',
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
        {
            'rel': 'related',
            'href': "job-application?$filter=jobLocations%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Job Location',
    'properties': {
        'address': {
            'title': 'Address',
            'description': 'The mailing address.',
            '$id': 'http://schema.org/PostalAddress',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPostalAddress' },
                { 'href': 'http://schema.org/address', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'addressCountry': {
                    'title': 'Address Country',
                    'description': 'A country.',
                    '$id': 'http://schema.org/Country',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FCountry' },
                        { 'href': 'http://schema.org/addressCountry', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'identifier': {
                            'title': 'Identifier',
                            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'sortOrderForm': 30 },
                },
                'addressLocality': {
                    'title': 'Address Locality',
                    'description': 'The locality in which the street address is, and which is in the region. For example, Mountain View.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/addressLocality', 'rel': 'canonical' }],
                },
                'addressRegion': {
                    'title': 'Address Region',
                    'description': 'The region in which the locality is, and which is in the country. For example, California or another appropriate first-level [Administrative division](https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country) ',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/addressRegion', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 30 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const JobLocationSchema = Schema;
