import EventEmitter from 'events';

import { createQuery } from '@cp/base-odata';
import { IFilePropertyJsonPath, IJSONSchema, Schemas, TypeConstants } from '@cp/base-types';
import { adjustHexColorLightness, cloneDeepWithMetadata, DataServiceModules, getPathFromQuery, resolveSubjectUri, deepCompare } from '@cp/base-utils';
import { ICommandBarItemProps, ThemeContext } from '@fluentui/react';
import { useSetTimeout } from '@fluentui/react-hooks';
import { TFunction } from 'i18next';
import * as _ from 'lodash';
import React, { createContext, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useDidCache, useDidRecover } from 'react-router-cache-route';
import { Operation } from 'rfc6902';
import urlJoin from 'url-join';

import { axiosDictionary, getEndpoint, getSchema, patchEntityToEndpoint } from '../api';
import { Environment } from '../app/environment';
import { TableKeyPrefix } from '../constants';
import {
  buildGroupedButtons,
  buttonRenderer,
  createPatchAndUnsureStructure,
  executeBackendAction,
  executeFrontendAction,
  generateDefaultFormData,
  getAppBasePath,
  getFileExtension,
  getRelatedFromSchema,
  IMatchedRelatedLink,
  IMatchedRelatedLinkStructure,
  isTouchDevice,
  resolveItemDownloadUrls,
  splitBtnRenderer,
} from '../helpers';
import notification from '../helpers/toast';
import { IGlobalState, store } from '../store';
import { setUserSettingsEtag } from '../store/auth/actions';
import { changePageSetting } from '../store/settings/actions';
import { UserExperiencePreference } from '../store/settings/reducer';
import { BaseApi, IBase, ICustomAction, IDataItem, IGenericComponentData, IMenuItem, ITemplateCustomAction } from '../types';

export enum ActionSourceType {
  Table = 'table',
  SingleItem = 'singleItem',
}

export enum ActionType {
  Download = 'download',
  Delete = 'delete',
  Modify = 'modify',
  View = 'view',
  Copy = 'copy',
  CopyToType = 'copy_to_type',
  Add = 'add',
  Export = 'export',
  Related = 'related',
  Share = 'share',
  Meta = 'meta',
  Versions = 'versions',
  Localizations = 'localizations',
  CustomAction = 'custom_action',
  MergeInto = 'merge_into',
  Compare = 'compare',
  TemplateCustomAction = 'template_custom_action',
  RemoveComponent = 'remove_component',
  ChangeBaseLanguage = 'change_base_language',
  Close = 'close',
  Reply = 'reply',
  Forward = 'forward',
}

const ACTIONS_ORDER = [
  ActionType.Export,
  ActionType.Share,
  ActionType.Compare,
  ActionType.Meta,
  ActionType.Related,
  ActionType.CustomAction,
  ActionType.Copy,
  ActionType.Download,
  ActionType.Add,
  ActionType.Delete,
  ActionType.RemoveComponent,
  ActionType.Modify,
  ActionType.View,
  ActionType.Forward,
  ActionType.Reply,
];

export interface IHeading {
  element: Element;
  text: string;
  level: number;
  key: string;
  children: IHeading[];
}

export type IActionDescriptor =
  | { type: ActionType.Download; canOpen: boolean; url?: string }
  | { type: ActionType.Related; relatedStructure: IMatchedRelatedLinkStructure; url?: string }
  | { type: ActionType.TemplateCustomAction; templateCustomAction: ITemplateCustomAction }
  | { type: ActionType.Modify; forms: Schemas.CpaPage['forms']; url?: string }
  | { type: ActionType.Add; forms: Schemas.CpaPage['forms']; url?: string }
  | {
      type: Exclude<ActionType, ActionType.Download | ActionType.Related | ActionType.TemplateCustomAction | ActionType.Modify | ActionType.Add>;
      url?: string;
    };

interface ILink {
  key: string;
  path?: string;
  text: string;
  icon?: string;
  divider?: boolean;
  parentMenuItemKey?: IBase;
  sortOrderMenu?: number;
  externalUrl?: string;
  cpType?: string;
  actions?: Schemas.CpaPage['actions'];
  dataEndpointIdentifier?: string;
  pageIdentifier?: string;
}

type Unpacked<T> = T extends (infer U)[] ? U : T;
type CpaUserConfiguration = Unpacked<Schemas.UserProfileShadow['cpaUserConfiguration']>;
type CpaPageUserConfigurations = Extract<CpaUserConfiguration, { cpaPageUserConfigurations?: object }>['cpaPageUserConfigurations'];

const TOC_CHAR_LIMIT = 100;

export const EXPAND_LEVEL = 3;

const processTitle = (title: string): string => {
  const shortTittle = title.substring(0, TOC_CHAR_LIMIT);
  return encodeURIComponent(shortTittle.replace(/[^\w\s]/gi, ''));
};

function removeEmptyItems(items: IMenuItem[], allLinks: ILink[]): IMenuItem[] {
  return items.filter((item) => {
    if (!item.url && !item.links) {
      return false;
    }

    if (Array.isArray(item.links)) {
      item.links = removeEmptyItems(item.links, allLinks);
    }

    if (item.url && item.url.startsWith('/_navigation/') && !item.links?.length) {
      return false;
    }

    if (!item.links?.length) {
      delete item.links;
      delete item.expanded;
    }

    return true;
  });
}

function getImplicitPort(): string {
  if (window.location.port) {
    return window.location.port;
  }

  switch (window.location.protocol) {
    case 'http:':
      return '80';
    case 'https:':
      return '443';
    default:
      return '80';
  }
}

const generateTocKey = (title: string, index: number, tocItems: IHeading[]): string => {
  const cleanTitle = processTitle(title);
  const tocItemsSlice = tocItems.slice(0, index);
  const cleanTitleDuplicates = tocItemsSlice.filter((tocItem) => {
    const cleanTocTitle = processTitle(tocItem.key);
    return cleanTocTitle === cleanTitle;
  });
  if (cleanTitleDuplicates.length) {
    return `${cleanTitle.replaceAll(' ', '-')}-${cleanTitleDuplicates.length}`;
  }
  return cleanTitle.replaceAll(' ', '-');
};

export const useDrawerWidth = (
  options: { width?: string; isMaximized?: boolean } = {}
): {
  responsiveWidth: string;
  mobileOnly: boolean;
} => {
  const width = options.width ?? '50%';
  const isMaximized = options.isMaximized ?? false;
  const mobileOnly = useMediaQuery({ query: '(max-width: 500px)' });
  const laptopOnly = useMediaQuery({ query: '(max-width: 768px)' });
  const responsiveWidth = useMemo(() => (isMaximized || mobileOnly ? '100%' : laptopOnly ? '75%' : width), [isMaximized, mobileOnly, laptopOnly]);

  return { responsiveWidth, mobileOnly };
};

export const useAnimationOptions = (): [number, boolean, boolean] => {
  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });
  const disableAnimation = useSelector((store: IGlobalState) => store.settings.disableAnimation);
  return [400, disableAnimation, desktopOnly];
};

export const useAdvancedInterval = (callback: () => void, delay: number): [() => void, () => void] => {
  const savedCallback = useRef<() => void>(callback);
  const id = useRef<NodeJS.Timeout>();

  savedCallback.current = callback;

  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      id.current = setInterval(tick, delay);
    }
    return (): void => id.current && clearInterval(id.current);
  }, [delay]);

  const restart = useCallback(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (id.current) {
      clearInterval(id.current);
    }

    id.current = setInterval(tick, delay);
  }, [delay]);

  const clear = useCallback(() => {
    if (id.current) {
      clearInterval(id.current);
    }
  }, []);

  return [restart, clear];
};

export const useIsCached = (): boolean => {
  const [isCached, setIsCached] = useState(false);

  useDidCache(() => {
    setIsCached(true);
  });

  useDidRecover(() => {
    setIsCached(false);
  });

  return isCached;
};

export const useHeadings = (rootElement: HTMLElement | null, identifier?: string, extraDependency?: unknown): IHeading[] => {
  const [headings, setHeadings] = useState<IHeading[]>([]);
  const { setTimeout, clearTimeout } = useSetTimeout();
  useEffect(() => {
    const timoutId = setTimeout(() => {
      const elements = rootElement ? Array.from(rootElement.querySelectorAll('[data-heading], h1, h2, h3, h4, h5, h6')) : [];
      const elementsWithLevel = elements.map((element) => {
        const attributeValue = element.attributes.getNamedItem('data-heading')?.value;
        const headingSize = Number(element.tagName.substring(1));
        return { level: attributeValue ? 0 : headingSize, element };
      });

      let lastHeadingIndex = 0;

      const processedElements = elementsWithLevel
        .map((elementWithLevel, index) => {
          const attributeValue = elementWithLevel.element.attributes.getNamedItem('data-heading')?.value;
          if (attributeValue) {
            lastHeadingIndex = index;
          }
          const key =
            attributeValue || elementWithLevel.element.attributes.getNamedItem('data-heading-key')?.value || elementWithLevel.element.textContent;
          const text = elementWithLevel.element.textContent;
          const textContentTrimmed = text ? _.trim(text.trim(), ':') : null;
          if (!attributeValue && !textContentTrimmed) return null;
          if (attributeValue) {
            return {
              ...elementWithLevel,
              text: textContentTrimmed,
              key: key,
            };
          }
          let nextHeadingIndex = elementsWithLevel.findIndex((element, findIndex) => {
            return index < findIndex && element.level === 0;
          });
          if (nextHeadingIndex === -1) nextHeadingIndex = elementsWithLevel.length - 1;
          if (lastHeadingIndex === 0)
            return {
              ...elementWithLevel,
              text: textContentTrimmed,
              key: key,
            };
          const elementsPart = elementsWithLevel.slice(lastHeadingIndex + 1, nextHeadingIndex);
          const elementsPartLevels = [...new Set(elementsPart.map((element) => element.level).sort((a, b) => a - b))];
          const processedLevels = elementsPartLevels.reduce((acc, level, index) => {
            acc[level] = index + 1;
            return acc;
          }, {} as { [key: number]: number });
          return {
            element: elementWithLevel.element,
            level: processedLevels[elementWithLevel.level],
            text: textContentTrimmed,
            key: key,
          };
        })
        .filter(Boolean) as IHeading[];

      const result = processedElements.map((heading, index) => {
        const key = generateTocKey(heading.key, index, processedElements).toLowerCase();
        return {
          ...heading,
          key,
        };
      });
      setHeadings(
        result.reduce((acc: IHeading[], item) => {
          if (item.level < EXPAND_LEVEL) {
            acc.push({
              ...item,
              children: [],
            });
            return acc;
          } else {
            acc[acc.length - 1].children.push(item);
            return acc;
          }
        }, [])
      );
    }, 200);
    return () => {
      clearTimeout(timoutId);
    };
  }, [clearTimeout, rootElement, setTimeout, identifier, extraDependency]);
  return headings;
};

export const useScrollSpy = (
  headings: IHeading[],
  options: IntersectionObserverInit,
  scrollablePaneContext: { scrollEventEmitter: RefObject<EventEmitter> } | null
): string => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1000px)' });

  const headingsFlat = useMemo(() => {
    return headings
      .slice(1)
      .map((heading) => [heading, ...heading.children])
      .flat();
  }, [headings]);
  const elements = headingsFlat.map((heading) => heading.element);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('');
  const observer = useRef<IntersectionObserver>();
  const elementsVisibleState = useRef(elements.map((element, index) => index === 0));

  const handleBodyScroll = useCallback((event: { target: HTMLElement }): void => {
    setIsScrolledToBottom(Math.floor(event.target.scrollHeight - event.target.clientHeight) - Math.floor(event.target.scrollTop) < 5);
  }, []);

  useEffect(() => {
    if (!isMobileDevice) {
      scrollablePaneContext?.scrollEventEmitter.current?.on('onFullBodyScroll', handleBodyScroll);
    }

    return () => {
      if (!isMobileDevice) {
        scrollablePaneContext?.scrollEventEmitter.current?.off('onFullBodyScroll', handleBodyScroll);
      }
    };
  }, [handleBodyScroll, isMobileDevice, scrollablePaneContext?.scrollEventEmitter]);
  useEffect(() => {
    if (!isMobileDevice) {
      observer.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          const matchedTargetElementIndex = elements.findIndex((element) => element === entry.target);
          if (matchedTargetElementIndex === -1) continue;
          elementsVisibleState.current[matchedTargetElementIndex] = entry.isIntersecting;
        }
        const firstVisibleElementIndex = elementsVisibleState.current.findIndex((el) => el === true);
        if (firstVisibleElementIndex === -1) return;
        setIsScrolledToBottom(false);
        if (headingsFlat[firstVisibleElementIndex]) {
          setActiveKey(headingsFlat[firstVisibleElementIndex].key);
        }
        return;
      }, options);
      elements.forEach((el) => {
        if (el) {
          observer.current?.observe(el);
        }
      });
    }
    return () => {
      if (isMobileDevice) {
        observer.current?.disconnect();
      }
    };
  }, [elements, headingsFlat, isMobileDevice, options]);
  return isScrolledToBottom ? headingsFlat[headingsFlat.length - 1].key : activeKey;
};

export const useShowFilter = (pageIdentifier: string | undefined, hiddenInSettings: boolean = false): [boolean, (visible: boolean) => void] => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const updatePageSetting = useSyncedPageSettings();

  const setFilterHidden = useCallback(
    (visible: boolean) => {
      if (isMobileDevice) {
        setShowMobileFilter(!showMobileFilter);
      } else {
        updatePageSetting({ pageKey: pageIdentifier, setting: 'displayFilter', value: visible });
      }
    },
    [isMobileDevice, pageIdentifier, showMobileFilter, updatePageSetting]
  );
  return [isMobileDevice ? showMobileFilter : hiddenInSettings, setFilterHidden];
};

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
};

export const useChartColors = (): string[] => {
  const themeContext = useContext(ThemeContext);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  return useMemo(() => {
    if (!themeContext) return [];

    const secondary = darkMode ? themeContext.palette.neutralLighterAlt : themeContext.palette.black;
    const primary = themeContext.palette.themePrimary;

    return [
      adjustHexColorLightness(secondary, 0),
      adjustHexColorLightness(primary, 0),
      adjustHexColorLightness(secondary, 12),
      adjustHexColorLightness(primary, 8),
      adjustHexColorLightness(primary, -5),
      adjustHexColorLightness(secondary, 16),
      adjustHexColorLightness(primary, 16),
      adjustHexColorLightness(primary, -10),
      adjustHexColorLightness(secondary, 24),
      adjustHexColorLightness(primary, 24),
      adjustHexColorLightness(primary, -15),
      adjustHexColorLightness(secondary, 32),
      adjustHexColorLightness(primary, 32),
      adjustHexColorLightness(primary, -20),
      adjustHexColorLightness(primary, -25),
    ];
  }, [themeContext, darkMode]);
};

export interface IPowerUserContext {
  temporaryEnabled?: boolean;
}

export const PowerUserContext = createContext<null | IPowerUserContext>(null);

export const usePowerUser = (tableKeyPrefix?: TableKeyPrefix, cpTypeUrl?: string): boolean => {
  const userExperiencePreference = useSelector((state: IGlobalState) => state.settings.userExperiencePreference);
  const powerUserContext = useContext(PowerUserContext);

  return useMemo(() => {
    // Force simplified for messages
    if (cpTypeUrl === TypeConstants.CpMessage) return false;
    // Disable simplified ui for PromotedFilters
    if (tableKeyPrefix === TableKeyPrefix.SearchSuggestions) return true;
    return powerUserContext?.temporaryEnabled || userExperiencePreference !== UserExperiencePreference.Simple;
  }, [cpTypeUrl, powerUserContext?.temporaryEnabled, tableKeyPrefix, userExperiencePreference]);
};

const updateSettings = async (user: IGlobalState['auth']['user'], patch: Operation[], eTag: string | null, t: TFunction, silent?: boolean) => {
  if (!user || !patch.length) return;
  if (Environment.env.REACT_APP_USER_SETTINGS_REDUX_ONLY) {
    console.debug('Synchronization is disabled for tests');
    return;
  }
  const id = !silent ? notification.info(t('common.savingSettings'), Infinity) : null;
  const unloadHandler = (event: BeforeUnloadEvent): string | void => {
    event.preventDefault();
    event.returnValue = '';
    return '';
  };
  const patchWithEtag = [...patch, { op: 'add', path: '/_eTag', value: eTag }];
  window.addEventListener('beforeunload', unloadHandler);
  await patchEntityToEndpoint<IDataItem<unknown>>(
    axiosDictionary.appDataService,
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.UserProfileShadow)}`,
    { identifier: user.account.email, _eTag: eTag },
    patchWithEtag as unknown as IDataItem,
    undefined,
    {
      'content-type': 'application/json-patch+json',
    }
  )
    .then((item) => {
      if (item?.['_eTag']) {
        store.dispatch(setUserSettingsEtag({ eTag: item['_eTag'] }));
      }
      if (!silent) {
        notification.success(t('common.settingsSaved'));
      }
    })
    .catch(() => {
      notification.error(t('errors.connection.internalErrorTitle'));
    })
    .finally(() => {
      if (!silent) {
        notification.dismiss(id as string);
      }
      window.removeEventListener('beforeunload', unloadHandler);
    });
};

export const useSyncedCpaConfiguration = (): (() => Promise<void>) => {
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const userSettings = useSelector((state: IGlobalState) => state.auth.userSettings);
  const userSettingsEtag = useSelector((state: IGlobalState) => state.auth.userSettingsEtag);
  const settingsForThisAppOnly = useSelector((state: IGlobalState) => state.settings.settingsForThisAppOnly);
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);

  const [t] = useTranslation();

  const getUpdatedCpaSettings = useCallback(() => {
    if (user?.account.email) {
      // Using store.getState() instead of useSelector because of a timing issues
      const settings = store.getState().settings as IGlobalState['settings'];
      const updatedSettings = {
        darkMode: cpa?.configuration.disableDarkMode ? userSettings?.darkMode : settings.darkMode,
        userExperiencePreference: settings.userExperiencePreference,
        zebraRows: settings.zebraRows,
        highContrast: settings.highContrast,
        disableAnimation: settings.disableAnimation,
        useOnlyHorizontalMenu: settings.useOnlyHorizontalMenu,
        tableActionsPosition: settings.tableActionsPosition,
        surfaceLight: settings.surfaceLight,
        cpa: settingsForThisAppOnly ? { identifier: cpa?.identifier } : undefined,
        dataLanguage: settings.dataLanguage ?? undefined,
      };
      if (!settingsForThisAppOnly && userSettings?.cpaUserConfigurations?.find((cpaConfig) => cpaConfig?.cpa?.identifier === cpa?.identifier)) {
        // @ts-ignore
        const cpaConfigurations = userSettings?.cpaUserConfigurations.filter((cpaConfig) => cpaConfig?.cpa?.identifier !== cpa?.identifier);
        return { ...updatedSettings, cpaUserConfigurations: cpaConfigurations };
      }
      if (settingsForThisAppOnly) {
        const cpaConfigurations = cloneDeepWithMetadata(userSettings?.cpaUserConfigurations);
        if (cpaConfigurations) {
          const prevSettingsIndex = cpaConfigurations.findIndex((cpaConfig) => cpaConfig?.cpa?.identifier === cpa?.identifier);
          if (prevSettingsIndex !== -1) {
            const isUpdated = !_.isEqual(userSettings?.cpaUserConfigurations?.[prevSettingsIndex], updatedSettings);
            if (isUpdated) {
              cpaConfigurations[prevSettingsIndex] = updatedSettings;
              return {
                ...(userSettings || {}),
                cpaUserConfigurations: cpaConfigurations,
              };
            }
          } else {
            return {
              ...(userSettings || {}),
              cpaUserConfigurations: [...cpaConfigurations, updatedSettings],
            };
          }
        } else {
          return {
            ...(userSettings || {}),
            cpaUserConfigurations: [updatedSettings],
          };
        }
      } else {
        const isUpdated = !_.isEqual(updatedSettings, { cpa: undefined, ..._.omit(userSettings, 'cpaUserConfigurations') });
        if (isUpdated) {
          return { ...(userSettings || {}), ...updatedSettings };
        }
      }
    }
    return userSettings;
  }, [user?.account.email, userSettings, cpa?.configuration.disableDarkMode, cpa?.identifier, settingsForThisAppOnly]);

  return useCallback(async () => {
    const updatedSettings = getUpdatedCpaSettings();
    const patch = createPatchAndUnsureStructure(userSettings ? { cpaUserConfiguration: userSettings } : {}, {
      cpaUserConfiguration: updatedSettings,
    });

    await updateSettings(user, patch, userSettingsEtag, t);
  }, [getUpdatedCpaSettings, t, user, userSettings, userSettingsEtag]);
};

export interface IPageSetting {
  pageKey?: string;
  setting: string;
  value?: number | string | boolean | string[] | unknown | null;
}

export const useSyncedPageSettings = (
  waitForDrawerClose?: boolean,
  silent?: boolean
): (({ pageKey, setting, value }: IPageSetting) => Promise<void>) => {
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const userSettings = useSelector((state: IGlobalState) => state.auth.userSettings);
  const userSettingsEtag = useSelector((state: IGlobalState) => state.auth.userSettingsEtag);
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);
  const dispatch = useDispatch();
  const pageDrawerOpened = useSelector((state: IGlobalState) => state.settings.pagesDrawer.isOpened);
  const [settingsQueue, setSettingsQueue] = useState<IPageSetting[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const getUpdatedCpaPageSettings = useCallback(
    (prevCpaConfigurations: CpaPageUserConfigurations = [], { pageKey, setting, value }: IPageSetting) => {
      const existingPageConfigurationIndex = prevCpaConfigurations.findIndex(
        // @ts-ignore
        (cpaConfiguration) => cpaConfiguration?.cpa?.identifier === cpa?.identifier && cpaConfiguration?.cpaPage?.identifier === pageKey
      );
      // TODO: remove localstorage get after users are completely switched to userProfile
      const settingsFromLocalStorage = pageKey ? localStorage.getItem(pageKey) : null;
      const updatedSetting: { [key: string]: number | string | boolean | string[] | { identifier: string }[] | null | unknown | undefined } =
        settingsFromLocalStorage ? JSON.parse(settingsFromLocalStorage) : {};
      switch (setting) {
        case 'chartOptions': {
          updatedSetting['chartOptions'] = JSON.stringify(value);
          break;
        }
        case 'showOnDashboards': {
          updatedSetting['displayOnDashboards'] = ((value as string[]) || null)?.map((key) => ({ identifier: key })) || [];
          break;
        }
        default: {
          updatedSetting[setting] = value;
        }
      }
      if (existingPageConfigurationIndex !== -1) {
        prevCpaConfigurations[existingPageConfigurationIndex] = {
          ...prevCpaConfigurations[existingPageConfigurationIndex],
          ...updatedSetting,
        };
      } else {
        prevCpaConfigurations.push({
          // @ts-ignore
          cpa: {
            identifier: cpa?.identifier,
          },
          cpaPage: {
            identifier: pageKey,
          },
          ...updatedSetting,
        });
      }

      return prevCpaConfigurations;
    },
    [cpa?.identifier]
  );

  const updatePageSettings = useCallback(async () => {
    let prevCpaConfigurations = cloneDeepWithMetadata(userSettings)?.cpaPageUserConfigurations;
    for (const queueItem of settingsQueue) {
      prevCpaConfigurations = getUpdatedCpaPageSettings(prevCpaConfigurations, {
        pageKey: queueItem.pageKey,
        setting: queueItem.setting,
        value: queueItem.value,
      });
    }
    const patch = createPatchAndUnsureStructure(
      userSettings ? { cpaUserConfiguration: { cpaPageUserConfigurations: userSettings?.cpaPageUserConfigurations } } : {},
      { cpaUserConfiguration: { cpaPageUserConfigurations: prevCpaConfigurations } }
    );
    if (patch.length) {
      setLoading(true);
      await updateSettings(user, patch, userSettingsEtag, t, silent);
      setLoading(false);
    }
    setSettingsQueue([]);
  }, [userSettings, settingsQueue, getUpdatedCpaPageSettings, user, userSettingsEtag, t, silent]);

  useEffect(() => {
    if (waitForDrawerClose && !pageDrawerOpened && settingsQueue.length && !loading) {
      updatePageSettings();
    }
  }, [pageDrawerOpened, settingsQueue.length, updatePageSettings, waitForDrawerClose, loading]);

  return useCallback(
    async ({ pageKey, setting, value }: IPageSetting) => {
      if (!pageKey) return;
      dispatch(changePageSetting({ pageKey: pageKey, setting: setting, value: value }));
      if (waitForDrawerClose && pageDrawerOpened) {
        setSettingsQueue([...settingsQueue, { pageKey, setting, value }]);
      } else {
        const updatedCpaPageSettings = getUpdatedCpaPageSettings(cloneDeepWithMetadata(userSettings)?.cpaPageUserConfigurations, {
          pageKey,
          setting,
          value,
        });
        const patch = createPatchAndUnsureStructure(
          userSettings ? { cpaUserConfiguration: { cpaPageUserConfigurations: userSettings?.cpaPageUserConfigurations } } : {},
          { cpaUserConfiguration: { cpaPageUserConfigurations: updatedCpaPageSettings } }
        );
        await updateSettings(user, patch, userSettingsEtag, t, silent);
      }
    },
    [dispatch, waitForDrawerClose, pageDrawerOpened, settingsQueue, getUpdatedCpaPageSettings, userSettings, user, userSettingsEtag, t, silent]
  );
};

export const useCardWidth = (): number => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });

  return useMemo(() => {
    return isMobileDevice ? 10 : 20;
  }, [isMobileDevice]);
};

export const useMenuItems = (): [menuItems: IMenuItem[], linksForMenu: ILink[]] => {
  const [t] = useTranslation();
  const allAvailablePages = useSelector((state: IGlobalState) => state.app.pages);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });

  const linksForMenu: ILink[] = useMemo(
    () =>
      allAvailablePages
        .filter((page: Schemas.CpaPage) => {
          if (page.identifier === '__showMore') {
            return true;
          }

          if (isMobileDevice && !!page.showInMenu) {
            return true;
          }

          return !!page.showInMenu;
        })
        .map(
          (page): ILink => ({
            key: page.identifier!,
            text: page.name,
            icon: page.icon,
            path: page.path || `/_navigation/${page.identifier}`,
            divider: page.divider || false,
            parentMenuItemKey: page.parentCpaPage as IBase,
            sortOrderMenu: page.sortOrderMenu,
            externalUrl: page.externalUrl,
            cpType: page.cpTypeUrl,
            actions: page.actions,
            pageIdentifier: page.identifier,
            dataEndpointIdentifier: page.dataEndpoint?.identifier,
          })
        ),
    [allAvailablePages, isMobileDevice]
  );

  const linksToMenuItems = useCallback(
    (linksToConvert: ILink[]): IMenuItem[] => {
      const sortedLinks: ILink[] = [...linksToConvert].sort(
        ({ sortOrderMenu: sortOrderLeft = Infinity }, { sortOrderMenu: sortOrderRight = Infinity }) =>
          sortOrderLeft < sortOrderRight ? -1 : sortOrderLeft > sortOrderRight ? 1 : 0
      );

      const localLinks: IMenuItem[] = sortedLinks.map((link) => {
        const filteredActions = link.actions?.filter((action) => action?.showInMenu);
        const itemsWithoutGroup = filteredActions?.filter((action) => !action.group);
        const groupedCustomActions: Record<string, Schemas.CpaPage['actions']> = _.groupBy(
          filteredActions?.filter((action) => action.group) || [],
          (action) => action.group
        );

        const onActionClick = async (action: NonNullable<Schemas.CpaPage['actions']>[0]): Promise<void> => {
          if (action.runOnBackend) {
            if (!link.pageIdentifier || !link.dataEndpointIdentifier || !action.identifier) {
              return;
            }
            await executeBackendAction(link.dataEndpointIdentifier, link.pageIdentifier, action.identifier, []);
          } else {
            await executeFrontendAction(action, { event: 'Action' });
          }
        };

        const groupedActions = Object.keys(groupedCustomActions).map((groupKey, index) => {
          return {
            key: groupKey + index,
            name: groupKey,
            expanded: false,
            externalUrl: false,
            onClick: undefined,
            links:
              groupedCustomActions[groupKey]?.map((action, index) => {
                return {
                  key: `${action.identifier}-${index}`,
                  name: action.name,
                  url: '#',
                  icon: action.icon,
                  onClick: () => onActionClick(action),
                };
              }) || [],
          };
        });

        const actionsWithoutGroup =
          itemsWithoutGroup?.map((action, index) => {
            return {
              key: `${action.identifier}-${index}`,
              name: action.name,
              icon: action.icon,
              url: '#',
              external: false,
              onClick: () => onActionClick(action),
            };
          }) || [];

        const actionsLinks = [
          {
            key: `actions-${link.key}`,
            name: t('common.actions'),
            external: !!link.externalUrl,
            expanded: false,
            links: [...groupedActions, ...actionsWithoutGroup],
            icon: 'LightningBolt',
          },
        ];

        return {
          key: link.key,
          name: link.text.startsWith('_dictionary:') ? t(link.text.split(':')[1]) : link.text,
          url: link.externalUrl?.replace(/{CPA_CURRENT_PORT}/g, getImplicitPort()) || link.path,
          external: !!link.externalUrl,
          expanded: false,
          links: actionsLinks[0].links.length ? actionsLinks : [],
          icon: link.icon,
          divider: link.divider,
          cpType: link.cpType,
        };
      });
      for (const link of localLinks) {
        const childrenKeys = sortedLinks.filter((l) => !!l.parentMenuItemKey && l.parentMenuItemKey.identifier === link.key).map((l) => l.key);
        for (const key of childrenKeys) {
          const foundLink = localLinks.find((l) => l.key === key);
          if (foundLink) {
            link.links?.push(foundLink);
          }
        }
      }
      for (const link of localLinks) {
        if (!link.links?.length) {
          delete link.links;
          delete link.expanded;
        }
      }

      return removeEmptyItems(localLinks, sortedLinks).filter((l) => {
        const localLink = sortedLinks.find((link) => link.key === l.key);
        return !(localLink && !!localLink.parentMenuItemKey);
      });
    },
    [t]
  );

  const menuItems = useMemo(() => linksToMenuItems(linksForMenu), [linksForMenu, linksToMenuItems]);

  return [menuItems, linksForMenu];
};

export const useUxPreferenceFeature = (page: Schemas.CpaPage | undefined, feature: string) => {
  const powerUser = usePowerUser();

  return useMemo(() => {
    if (!page) return false;
    const currentUxPreference = powerUser ? UserExperiencePreference.Expert : UserExperiencePreference.Simple;
    const features = page?.uxPreferenceFeatureFlags;

    if (!features) return false;
    const featuresForPreference = features.filter((feature) => feature.userExperiencePreference === currentUxPreference);
    const allEnabledFeatures = _.flatten(featuresForPreference.map((feature) => (feature.featureFlags || []).map((flag) => flag.identifier)));
    return allEnabledFeatures.includes(feature);
  }, [feature, page, powerUser]);
};

export const useTableActions = (
  data: IGenericComponentData,
  selectedItems: IDataItem[],
  disableItemSharing: boolean | undefined,
  enableExport: boolean,
  downloadableFilePropertyJsonPaths: IFilePropertyJsonPath[],
  getCustomActionsForSelection: (selectedItems: IDataItem[], page: Schemas.CpaPage, schema: IJSONSchema | null, user?: string) => IActionDescriptor[],
  source: ActionSourceType
) => {
  const appPages = useSelector((state: IGlobalState) => state.app.pages);
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);
  const [formSchemas, setFormSchemas] = useState<Record<string, IJSONSchema>>({});
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);

  useEffect(() => {
    const loadSchemas = async () => {
      const forms = data.page.forms;
      const result: { [x: string]: IJSONSchema } = {};

      // handle if no forms
      if (!forms) {
        return;
      }

      const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);

      try {
        // iterate over forms
        for (const form of forms) {
          // load each schema and set to result
          const formSchema = await getSchema(
            urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(resolveSubjectUri(form.cpTypeUrl, prefixMap))}`)
          );
          result[form.cpTypeUrl] = formSchema;
        }
        setFormSchemas(result);
      } catch (e) {
        notification.error(e.message);
        console.error(e);
        return;
      }
    };
    loadSchemas();
  }, [data.page.forms, prefixMap]);

  return useMemo(() => {
    const actions: IActionDescriptor[] = [];
    const { page } = data;
    if (selectedItems.length === 1 && !disableItemSharing) {
      actions.push({ type: ActionType.Share });
    }
    if (
      selectedItems.length === 1 &&
      selectedItems[0]?.identifier &&
      data.schema &&
      typeof selectedItems[0]?.identifier === 'string' &&
      (data.page.cpTypeUrl || data.schema.$id) &&
      source === ActionSourceType.Table
    ) {
      const relatedStructure = getRelatedFromSchema(
        selectedItems[0]?.identifier as string,
        appPages,
        data.schema,
        data.page.cpTypeUrl || data.schema.$id!
      );
      if (Object.keys(relatedStructure).length > 0) {
        actions.push({ type: ActionType.Related, relatedStructure });
      }
    }

    const pageEndpoint = page.dataEndpoint?.identifier ? getEndpoint(page.dataEndpoint.identifier) : undefined;
    const displayVersions = !data.schema?.cp_disableVersions && page.allowModify && pageEndpoint?.dataType === BaseApi.DataService;
    const displayLocalizations = !data.schema?.cp_disableLocalization && page.allowModify && pageEndpoint?.dataType === BaseApi.DataService;
    const displayCloneOperations = !data.schema?.cp_disableClone && pageEndpoint?.dataType === BaseApi.DataService;

    if ((displayVersions || displayLocalizations) && selectedItems.length === 1) {
      actions.push({ type: ActionType.Meta });

      if (displayVersions) {
        actions.push({ type: ActionType.Versions });
      }

      if (displayLocalizations) {
        actions.push({ type: ActionType.Localizations });
      }
    }

    if (enableExport) {
      actions.push({ type: ActionType.Export });
    }

    if (selectedItems.length === 1 && source === ActionSourceType.Table) {
      if (page.allowCreate && page.allowDelete && displayCloneOperations) {
        actions.push({ type: ActionType.Copy });
      }

      if ((page.dataUrl || page.dynamicDataUrl) && pageEndpoint?.dataType === BaseApi.DataService) {
        if (page.allowModify && displayCloneOperations) {
          actions.push({ type: ActionType.MergeInto });
        }

        actions.push({ type: ActionType.Compare });
      }
    }

    if (page.allowCreate && selectedItems.length <= 1 && source === ActionSourceType.Table) {
      const url = page.path ? urlJoin(getAppBasePath(), page.path.replace('/:id', ''), '?action=add') : '';
      actions.push({ type: ActionType.Add, url, forms: page.forms });
    }

    if (selectedItems.length > 0 && source === ActionSourceType.Table) {
      if (page.allowDelete && selectedItems.every((selectedItem: IDataItem) => selectedItem && selectedItem.__component !== true)) {
        actions.push({ type: ActionType.Delete });
      }

      // Display Download/Open action based on selected items
      const downloadUrls = resolveItemDownloadUrls(selectedItems, downloadableFilePropertyJsonPaths);
      if (downloadUrls.length) {
        const canOpen = downloadUrls.every((downloadUrl: string) => {
          const [, canOpenResult] = getFileExtension(downloadUrl);
          return canOpenResult;
        });
        actions.push({ type: ActionType.Download, canOpen: canOpen });
      }
    }

    if (selectedItems.length === 1 && source === ActionSourceType.Table) {
      const url =
        page.path && selectedItems[0]?.identifier
          ? urlJoin(getAppBasePath(), page.path.replace('/:id', ''), encodeURIComponent(selectedItems[0].identifier))
          : '';
      if (page.allowModify) {
        // Initializing an Object holding the default form data for each form schema
        const defaultFormData: { [x: string]: IDataItem } = {};
        // Generate the default form data for each form schema
        Object.keys(formSchemas).forEach((formSchema) => {
          defaultFormData[formSchema] = generateDefaultFormData(formSchemas[formSchema], data.schema);
        });

        // Filter the matching forms for the selected item
        const filteredForms = page.forms?.filter((form) => {
          if (form.default) {
            return true;
          }
          // Getting the default form data of the current form
          const formData = defaultFormData[form.cpTypeUrl];
          // Compare the current form data with the selected item to check if the form is matching for the item
          return deepCompare(formData, selectedItems[0]);
        });

        actions.push({ type: ActionType.Modify, url: urlJoin(url, '?action=edit'), forms: filteredForms });
      }

      actions.push({ type: ActionType.View, url });
    }

    if (data.page.customRowTemplate?.identifier) {
      const customActions: IActionDescriptor[] = getCustomActionsForSelection(selectedItems, page, data.schema, user);
      actions.push(...customActions);
    }

    const contextMenuActions = actions.filter(({ type }) => {
      return type !== ActionType.Export && type !== ActionType.Add;
    });
    return { actions, contextMenuActions, formSchemas };
  }, [
    user,
    appPages,
    data,
    disableItemSharing,
    downloadableFilePropertyJsonPaths,
    enableExport,
    formSchemas,
    getCustomActionsForSelection,
    selectedItems,
    source,
  ]);
};

export const useCommandBarButtons = (
  availableActions: IActionDescriptor[],
  latestOnActionClick: React.MutableRefObject<(action: ActionType | string, payload?: unknown, event?: React.MouseEvent<HTMLDivElement>) => void>,
  onActionClick: (action: ActionType | string, payload?: unknown, event?: React.MouseEvent<HTMLDivElement>) => void,
  classnames: string = '',
  splitBtnClassnames: string = '',
  customActionsClassnames: string = '',
  customActions?: ICustomAction[]
) => {
  const [t] = useTranslation();
  const powerUser = usePowerUser();

  return useMemo(() => {
    const buttonTypesMap: { [key in ActionType]?: (ICommandBarItemProps | undefined)[] } = {};
    const usedKeys = new Set<string>();

    for (const action of availableActions) {
      const actionClickHandler = (e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, payload?: unknown): void => {
        e?.preventDefault();
        latestOnActionClick.current(action.type, payload);
      };
      const defaultProps = {
        className: classnames,
        onRender: buttonRenderer,
        onClick: actionClickHandler,
      };

      switch (action.type) {
        case ActionType.Download: {
          const { canOpen } = action;
          buttonTypesMap[ActionType.Download] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Download',
              key: 'open/download',
              iconProps: { iconName: canOpen ? 'OpenInNewTab' : 'Download' },
              text: canOpen ? t('common.open') : t('common.download'),
            },
          ];
          break;
        }
        case ActionType.Delete: {
          buttonTypesMap[ActionType.Delete] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Delete',
              key: 'delete',
              iconProps: { iconName: 'Delete' },
              text: t('common.delete'),
            },
          ];
          break;
        }
        case ActionType.Modify: {
          const { forms = [] } = action;
          const defaultForm = forms.find((form) => form.default);

          // We don't show default form in submenu
          const subForms = forms.filter((form) => !form.default);

          const mainEditButton: ICommandBarItemProps = {
            ..._.pick(defaultProps, 'className'),
            key: 'edit',
            iconProps: { iconName: 'Edit' },
            text: t('common.edit'),
            href: action.url,
            onClick: defaultForm ? (e): void => actionClickHandler(e, { form: defaultForm }) : defaultProps.onClick,
          };

          buttonTypesMap[ActionType.Modify] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Edit',
              id: `table-edit`,
              ...mainEditButton,
              ...(subForms.length > 0
                ? {
                    onClick: undefined,
                    menuProps: {
                      items: [
                        mainEditButton,
                        ...subForms
                          .filter((form) => !form.group)
                          .map(
                            (form): ICommandBarItemProps => ({
                              key: form.identifier,
                              text: form.name,
                              iconProps: {
                                iconName: form.icon,
                              },
                              onClick: (e): void => actionClickHandler(e, { form }),
                            })
                          ),
                        ...buildGroupedButtons(
                          subForms
                            .filter((form) => form.group)
                            .map((form) => ({
                              group: form.group,
                              button: {
                                key: form.identifier,
                                text: form.name,
                                iconProps: {
                                  iconName: form.icon,
                                },
                                onClick: (e): void => actionClickHandler(e, { form }),
                              },
                            })),
                          customActionsClassnames,
                          1
                        ),
                      ],
                    },
                  }
                : {}),
            },
          ];
          break;
        }
        case ActionType.View: {
          buttonTypesMap[ActionType.View] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-View',
              key: 'view',
              iconProps: { iconName: 'ViewOriginal' },
              text: t('common.view'),
              href: action.url,
            },
          ];
          break;
        }
        case ActionType.Copy: {
          if (!powerUser) break;
          const touchDevice = isTouchDevice();

          const menuItems = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Copy-Common',
              key: 'copy',
              text: t('common.copy'),
              onClick: (): void => latestOnActionClick.current(ActionType.Copy),
              onRender: undefined,
            },
            {
              ...defaultProps,
              ['data-testid']: 'Action-Copy-Type',
              key: 'copy_type',
              iconProps: touchDevice ? undefined : { iconName: 'Copy' },
              text: t('common.copyType'),
              onClick: (): void => latestOnActionClick.current(ActionType.CopyToType),
              onRender: undefined,
            },
          ];
          if (!touchDevice) {
            menuItems.shift();
          }

          if (availableActions.findIndex((a) => a.type === ActionType.MergeInto) !== -1) {
            menuItems.push({
              ...defaultProps,
              ['data-testid']: 'Action-Merge-Into',
              key: 'merge_into',
              iconProps: { iconName: 'BranchMerge' },
              text: t('common.mergeInto.title'),
              onClick: (): void => latestOnActionClick.current(ActionType.MergeInto),
              onRender: undefined,
            });
          }

          buttonTypesMap[ActionType.Copy] = [
            {
              ...defaultProps,
              key: 'copy',
              ['data-testid']: 'Action-Copy',
              iconProps: { iconName: 'Copy' },
              text: t('common.copy'),
              split: true,
              menuProps: { items: menuItems },
              onRender: (item, dismissMenu) => splitBtnRenderer(splitBtnClassnames, item, dismissMenu),
            },
          ];

          break;
        }
        case ActionType.Compare: {
          if (!powerUser) break;
          buttonTypesMap[ActionType.Compare] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Compare',
              key: 'compare',
              iconProps: { iconName: 'Compare' },
              text: t('common.compare'),
            },
          ];
          break;
        }
        case ActionType.Add: {
          const { forms = [] } = action;
          const defaultForm = forms.find((form) => form.default);

          // We don't show default form in submenu
          const subForms = forms.filter((form) => !form.default);

          const mainAddButton: ICommandBarItemProps = {
            ..._.pick(defaultProps, 'className'),
            key: 'add',
            iconProps: { iconName: 'CalculatorAddition' },
            text: t('common.add'),
            href: action.url,
            onClick: defaultForm ? (e): void => actionClickHandler(e, { form: defaultForm }) : defaultProps.onClick,
          };

          buttonTypesMap[ActionType.Add] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Add',
              id: `table-add`,
              ...mainAddButton,
              ...(subForms.length > 0
                ? {
                    onClick: undefined,
                    menuProps: {
                      items: [
                        mainAddButton,
                        ...subForms
                          .filter((form) => !form.group)
                          .map(
                            (form): ICommandBarItemProps => ({
                              key: form.identifier,
                              text: form.name,
                              iconProps: {
                                iconName: form.icon,
                              },
                              onClick: (e): void => actionClickHandler(e, { form }),
                            })
                          ),
                        ...buildGroupedButtons(
                          subForms
                            .filter((form) => form.group)
                            .map((form) => ({
                              group: form.group,
                              button: {
                                key: form.identifier,
                                text: form.name,
                                iconProps: {
                                  iconName: form.icon,
                                },
                                onClick: (e): void => actionClickHandler(e, { form }),
                              },
                            })),
                          customActionsClassnames,
                          1
                        ),
                      ],
                    },
                  }
                : {}),
            },
          ];
          break;
        }
        case ActionType.Export: {
          if (!powerUser) break;
          buttonTypesMap[ActionType.Export] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Export',
              key: 'export',
              iconProps: { iconName: 'Export' },
              text: t('common.export'),
            },
          ];
          break;
        }
        case ActionType.Meta: {
          const menuItems = [];

          if (availableActions.findIndex((a) => a.type === ActionType.Versions) !== -1) {
            menuItems.push({
              ...defaultProps,
              ['data-testid']: 'Action-Meta-Versions',
              key: 'versions',
              iconProps: { iconName: 'DependencyAdd' },
              text: t('common.versions'),
              onClick: (): void => latestOnActionClick.current(ActionType.Versions),
              onRender: undefined,
            });
          }

          if (availableActions.findIndex((a) => a.type === ActionType.Localizations) !== -1) {
            menuItems.push({
              ...defaultProps,
              ['data-testid']: 'Action-Meta-Localizations',
              key: 'localizations',
              iconProps: { iconName: 'Globe' },
              text: t('common.localizations'),
              onClick: (): void => latestOnActionClick.current(ActionType.Localizations),
              onRender: undefined,
            });

            menuItems.push({
              ...defaultProps,
              ['data-testid']: 'Action-ChangeBaseLanguage',
              key: 'changeBaseLanguage',
              iconProps: { iconName: 'globalstrategysinglecolor' },
              text: t('common.changeBaseLanguage'),
              onClick: (): void => latestOnActionClick.current(ActionType.ChangeBaseLanguage),
              onRender: undefined,
            });
          }

          buttonTypesMap[ActionType.Meta] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Meta',
              key: 'meta',
              text: t('common.meta'),
              menuProps: {
                items: menuItems,
              },
            },
          ];
          break;
        }
        case ActionType.Related: {
          if (!powerUser) break;
          const { relatedStructure } = action;

          buttonTypesMap[ActionType.Related] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Relations',
              key: 'relations',
              iconProps: { iconName: 'Relationship' },
              text: t('common.related'),
              menuProps: {
                items: _.sortBy(Object.values(relatedStructure), 'page.name').map(
                  ({ page, links }: { page: Schemas.CpaPage; links: IMatchedRelatedLink[] }): ICommandBarItemProps | undefined => {
                    if (!links.length) {
                      return undefined;
                    }

                    return {
                      ...defaultProps,
                      key: page.identifier!,
                      text: page.name,
                      iconProps: {
                        iconName: page.icon,
                      },
                      subMenuProps:
                        links.length > 1
                          ? {
                              items: links.map((link, index) => {
                                let linkName = page.name;
                                if (link.originalQuery) {
                                  try {
                                    const paths = getPathFromQuery(createQuery(link.originalQuery).query);
                                    linkName = paths.join();
                                  } catch (e) {
                                    console.error(e);
                                  }
                                }

                                return {
                                  key: link.originalQuery || index.toString(),
                                  text: linkName,
                                  iconProps: {
                                    iconName: 'Link',
                                  },
                                  onClick: (): void => latestOnActionClick.current(ActionType.Related, link),
                                  onRender: undefined,
                                };
                              }),
                            }
                          : undefined,
                      onRender: undefined,
                      onClick: links.length === 1 ? (): void => latestOnActionClick.current(ActionType.Related, links[0]) : undefined,
                    };
                  }
                ),
              },
              onClick: undefined,
            },
          ];
          break;
        }
        case ActionType.Share: {
          buttonTypesMap[ActionType.Share] = [
            {
              ...defaultProps,
              ['data-testid']: 'Action-Share',
              key: 'share',
              iconProps: { iconName: 'Share' },
              text: t('common.share'),
            },
          ];
          break;
        }
        case ActionType.TemplateCustomAction: {
          const { templateCustomAction } = action;
          const button = {
            ...defaultProps,
            key: templateCustomAction.key,
            iconProps: { iconName: templateCustomAction.icon },
            text: t(templateCustomAction.text),
            onClick: (): void => latestOnActionClick.current(ActionType.TemplateCustomAction, templateCustomAction),
          };
          const actionType = templateCustomAction.key as ActionType;
          if (!buttonTypesMap[actionType]) {
            buttonTypesMap[actionType] = [button];
          } else {
            buttonTypesMap[actionType]!.push(button);
          }
          break;
        }
        default:
          break;
      }
    }

    const itemsWithoutGroup = (customActions || []).filter((action) => !action.group);
    const customDescriptors = itemsWithoutGroup.map(({ button }) => {
      return {
        key: button.key,
        iconProps: { iconName: button.icon },
        text: button.title,
        href: button.url,
        split: false,
        onClick: (event: React.MouseEvent<HTMLDivElement>): void => latestOnActionClick.current(ActionType.CustomAction, button.key, event),
        className: customActionsClassnames,
        onRender: buttonRenderer,
      };
    });

    buttonTypesMap[ActionType.CustomAction] = [];
    for (const customDescriptor of customDescriptors) {
      const key = customDescriptor.key as ActionType;
      if (ACTIONS_ORDER.includes(key)) {
        buttonTypesMap[key] = [...(buttonTypesMap[key] || []), customDescriptor];
      } else {
        buttonTypesMap[ActionType.CustomAction] = [...(buttonTypesMap[ActionType.CustomAction] || []), customDescriptor];
      }
    }

    const customActionsWithGroup = (customActions || []).filter((action) => action.group);
    const customGroupedDescriptors = buildGroupedButtons(
      customActionsWithGroup.map((action) => ({
        button: {
          key: action.button.key,
          text: action.button.title,
          iconProps: {
            iconName: action.button.icon,
          },
          onClick: (): void => latestOnActionClick.current(ActionType.CustomAction, action.button.key),
        },
        group: action.group,
      })),
      customActionsClassnames
    );

    buttonTypesMap[ActionType.CustomAction].push(...(customGroupedDescriptors as ICommandBarItemProps[]));

    const sortedButtons = ACTIONS_ORDER.reduce((result, actionType) => {
      const typeButtons = buttonTypesMap[actionType];
      if (!typeButtons) return result;
      else {
        result.push(...typeButtons);
        return result;
      }
    }, [] as (ICommandBarItemProps | undefined)[]);

    return sortedButtons
      .reverse()
      .filter((action) => {
        if (!action || usedKeys.has(action.key)) {
          return false;
        }

        usedKeys.add(action.key);
        return true;
      })
      .reverse() as ICommandBarItemProps[];
  }, [availableActions, classnames, customActions, customActionsClassnames, latestOnActionClick, powerUser, splitBtnClassnames, t]);
};
