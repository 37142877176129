const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFeedback?$filter=cpaPage%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=showOnDashboards%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=singleItemComponents%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=derivedFrom%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=kpis%2FshowOnDashboards%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
    'cp_vectorizable': true,
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'cp_featureFlags': ['SemanticSearch'],
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa Page',
    'properties': {
        'dataEndpoint': {
            'title': 'Data Endpoint',
            'description': '<div>The data endpoint defines the source the data is loaded from. It can be any one of the configured endpoints. </div>\n<div>Currently <strong>API Gateway</strong> is used to access external systems, while <strong>Meta Service</strong> can be used for any data directly managed in COSMO Platform.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaDataEndpoint',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaDataEndpoint' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/dataEndpoint', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Data Endpoint' } },
            'cp_ui': { 'sortOrderForm': 98 },
        },
        'disableFilterHelper': {
            'title': 'Disable Filter Helper',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 119 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableFilterHelper', 'rel': 'canonical' }],
        },
        'helpLink': {
            'title': 'Help Link',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 106 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/helpLink', 'rel': 'canonical' }],
        },
        'icon': {
            'title': 'Icon Monochrom',
            'description': '<div>The friendly name of the symbol for this page. Use https://storybook.platform.cosmoconsult.com/?path=/story/icons--all-icons to find icons (incl. friendly name).</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 104 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/icon', 'rel': 'canonical' }],
        },
        'cpTypeUrl': {
            'title': 'Cp Type Url',
            'description': '<div>The URL that indicates which type this is assigned to</div>',
            'type': 'string',
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
            ],
            'cp_ui': { 'sortOrderForm': 96 },
            'cp_vectorizable': true,
        },
        'cpas': {
            'title': 'Cpas',
            'description': '<div>The apps to which this page is linked.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Cpa',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': true,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa' } },
                'required': ['identifier'],
                'cp_ignoreRelation': true,
            },
            'minItems': 1,
            'cp_ui': { 'sortOrderForm': 89 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/cpas', 'rel': 'canonical' }],
        },
        'actions': {
            'title': 'Actions',
            'description': '<div>Define custom actions that can be performed on item(s). </div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageAction',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageAction' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa Page Action',
                'properties': {
                    'icon': {
                        'title': 'Icon',
                        'description': '<div>Sets the icon for the action icon.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/icon', 'rel': 'canonical' }],
                    },
                    'function': {
                        'title': 'Function',
                        'description': '<div>The function to be executed when the action is triggered.</div>',
                        'anyOf': [
                            {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpFunction',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFunction' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': true,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'cp_featureFlags': ['SemanticSearch'],
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Function',
                                'properties': {
                                    'identifier': { 'type': 'string', 'title': 'Cp Function' },
                                    '_type': {
                                        'type': 'string',
                                        'const': 'http://platform.cosmoconsult.com/ontology/CpFunction',
                                        'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                    },
                                },
                            },
                            {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpInlineFunction' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'cp_featureFlags': ['SemanticSearch'],
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Inline Function',
                                'properties': {
                                    'sourceCode': {
                                        'title': 'Source Code',
                                        'description': '<div>The contents of the function to execute when the action is triggered.</div>',
                                        'type': 'string',
                                        'default': 'trigger.execute = function(executionContext, utils) {\n  // Here we write code that will be called on every execute\n};\n',
                                        'format': 'cp:code',
                                        'cp_ui': { 'sortOrderForm': 0 },
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sourceCode', 'rel': 'canonical' }],
                                    },
                                    '_type': {
                                        'type': 'string',
                                        'const': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                                        'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                    },
                                },
                                'required': ['sourceCode'],
                            },
                        ],
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/function', 'rel': 'canonical' }],
                    },
                    'showInMenu': {
                        'title': 'Show In Menu',
                        'description': '<div>If this option is activated, the page is displayed in the main menu (at the respective menu level) of the app(s) to which this page is linked.</div>',
                        'type': 'boolean',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/showInMenu', 'rel': 'canonical' }],
                    },
                    'hidden': {
                        'title': 'Hidden',
                        'anyOf': [
                            { 'type': 'boolean', 'title': 'Boolean' },
                            {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpInlineFunction' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'cp_featureFlags': ['SemanticSearch'],
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Inline Function',
                                'properties': {
                                    'sourceCode': {
                                        'title': 'Source Code',
                                        'type': 'string',
                                        'format': 'cp:code',
                                        'default': 'trigger.execute = function (executionContext, utils) { return true; };',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sourceCode', 'rel': 'canonical' }],
                                    },
                                    '_type': {
                                        'type': 'string',
                                        'const': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                                        'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                    },
                                },
                            },
                        ],
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/hidden', 'rel': 'canonical' }],
                    },
                    'runOnBackend': {
                        'title': 'Run On Backend',
                        'description': '<div>Select if the action should be run on the server-side instead of the client-side, i.e. the browser of the user. </div>',
                        'type': 'boolean',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/runOnBackend', 'rel': 'canonical' }],
                    },
                    'selection': {
                        'title': 'Selection',
                        'description': '<div>Define when the action is available:</div>\n<ul>\n<li>None: If no item is selected.</li>\n<li>One: If exactly one item is selected.</li>\n<li>Many: If more than one item is selected,</li>\n<li>Any: If one or more items are selected. </li>\n</ul>',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageActionSelection',
                        'type': 'string',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageActionSelection' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/selection', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'enum': [
                            'http://platform.cosmoconsult.com/ontology/Any',
                            'http://platform.cosmoconsult.com/ontology/Many',
                            'http://platform.cosmoconsult.com/ontology/none',
                            'http://platform.cosmoconsult.com/ontology/One',
                        ],
                        'enumNames': ['Any', 'Many', 'None', 'One'],
                    },
                    'group': {
                        'title': 'Group',
                        'type': 'string',
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/group', 'rel': 'canonical' }],
                    },
                    'userExperiencePreference': {
                        'title': 'User Experience Preference',
                        '$id': 'http://platform.cosmoconsult.com/ontology/UserExperiencePreference',
                        'type': 'string',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserExperiencePreference' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/userExperiencePreference', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'enum': [
                            'http://platform.cosmoconsult.com/ontology/Expert',
                            'http://platform.cosmoconsult.com/ontology/Simple',
                            'http://platform.cosmoconsult.com/ontology/Undefined',
                        ],
                        'enumNames': ['Expert', 'Simple', 'Undefined'],
                        'cp_enumIcons': ['CpSkillLevel4', null, null],
                        'cp_enumDescriptions': [
                            '<div style="text-align: center;">Subject matter expert who understands the underlying principles<br />Can perform task on a strategic or architectural level<br />Typically many years of practical experience</div>',
                            null,
                            null,
                        ],
                    },
                    'identifier': {
                        'title': 'Identifier',
                        'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': '<div>The name of the action. Should give a clue about the function of the action.</div>',
                        'type': 'string',
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
                'required': ['name', 'identifier', 'selection', 'function'],
            },
            'cp_ui': { 'sortOrderForm': 108 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/actions', 'rel': 'canonical' }],
        },
        'allowCreate': {
            'title': 'Allow create',
            'description': '<div>Enable the creation of new data objects via a plus button.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 100 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/allowCreate', 'rel': 'canonical' }],
        },
        'allowDelete': {
            'title': 'Allow delete',
            'description': '<div>Enable the deletion of existing data objects in the data view.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 101 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/allowDelete', 'rel': 'canonical' }],
        },
        'allowModify': {
            'title': 'Allow modify',
            'description': '<div>Enable editing of existing items in the data view.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 102 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/allowModify', 'rel': 'canonical' }],
        },
        'callToActions': {
            'title': 'Call To Actions',
            'description': '<div>Call to actions are displayed on the page and give the user a quick way to perform the given action. Could be something like: "Check your invoices"</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageCallToAction',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageCallToAction' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa Page Call To Action',
                'properties': {
                    'description': {
                        'title': 'Description',
                        'description': '<div>The displayed description of the call to action.</div>',
                        'type': 'string',
                        'format': 'cp:html',
                        'cp_ui': { 'sortOrderForm': 46 },
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
                    },
                    'url': {
                        'title': 'Url',
                        'description': '<div>The URL that should be opened when the user follows the call to action.</div>',
                        'type': 'string',
                        'format': 'cp:UrlOrPath',
                        'cp_ui': { 'sortOrderForm': 51 },
                        'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                    },
                },
                'required': ['description'],
            },
            'cp_ui': { 'sortOrderForm': 110 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/callToAction', 'rel': 'canonical' }],
        },
        'chart': {
            'title': 'Chart',
            'description': '<div>Display a chart of the selected type on the page. The data source and the chart type can be configured.</div>',
            'anyOf': [
                { '$id': 'http://platform.cosmoconsult.com/ontology/Undefined', 'type': 'null', 'title': 'Undefined' },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartArea',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartArea' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cpa Page Chart Area',
                    'properties': {
                        'axisProperty': {
                            'title': 'Axis Property',
                            'description': '<div>\n<div>The property used for the axis. Input the path to the property. </div>\n<div>For example: "dateCreated" or "amount.value"</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/axisProperty', 'rel': 'canonical' }],
                        },
                        'nameProperty': {
                            'title': 'Name Property',
                            'description': '<div>The property to be displayed in the legend of the chart. </div>\n<div>For example: "amount.currency"</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/nameProperty', 'rel': 'canonical' }],
                        },
                        'tooltipPreviewProperty': {
                            'title': 'Tooltip Preview Property',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tooltipPreviewProperty', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpaPageChartArea',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['axisProperty', 'nameProperty'],
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartHorizontal',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartHorizontal' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cpa Page Chart Horizontal',
                    'properties': {
                        'nameProperty': {
                            'title': 'Name Property',
                            'description': '<div>\n<div>The property to be displayed in the legend of the chart. </div>\n<div>For example: "amount.currency"</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/nameProperty', 'rel': 'canonical' }],
                        },
                        'selectionProperty': {
                            'title': 'Selection Property',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/selectionProperty', 'rel': 'canonical' }],
                        },
                        'tooltipPreviewProperty': {
                            'title': 'Tooltip Preview Property',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tooltipPreviewProperty', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_localizable': true,
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpaPageChartHorizontal',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['nameProperty', 'selectionProperty', 'name'],
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartPie',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartPie' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cpa Page Chart Pie',
                    'properties': {
                        'axisProperty': {
                            'title': 'Axis Property',
                            'description': '<div>\n<div>The property to be displayed in the legend of the chart. </div>\n<div>For example: "amount.currency"</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/axisProperty', 'rel': 'canonical' }],
                        },
                        'nameProperty': {
                            'title': 'Name Property',
                            'description': '<div>\n<div>The property to be displayed in the legend of the chart. </div>\n<div>For example: "amount.currency"</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/nameProperty', 'rel': 'canonical' }],
                        },
                        'operationType': {
                            'title': 'Operation Type',
                            'description': '<div>The count operation counts the number of values. The sum operation sums the values.</div>',
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartOperationType',
                            'type': 'string',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartOperationType' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/operationType', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'cp_featureFlags': ['SemanticSearch'],
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'enum': ['http://platform.cosmoconsult.com/ontology/CountOperation', 'http://platform.cosmoconsult.com/ontology/SumOperation'],
                            'enumNames': ['Count Operation', 'Sum Operation'],
                        },
                        'tooltipPreviewProperty': {
                            'title': 'Tooltip Preview Property',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tooltipPreviewProperty', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_localizable': true,
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpaPageChartPie',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['nameProperty', 'operationType'],
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartRadial',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartRadial' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cpa Page Chart Radial',
                    'properties': {
                        'axisProperty': {
                            'title': 'Axis Property',
                            'description': '<div>\n<div>\n<div>The property used for the chart. Input the path to the property. </div>\n<div>For example: "dateCreated" or "amount.value"</div>\n</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/axisProperty', 'rel': 'canonical' }],
                        },
                        'nameProperty': {
                            'title': 'Name Property',
                            'description': '<div>\n<div>The property to be displayed in the legend of the chart. </div>\n<div>For example: "amount.currency"</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/nameProperty', 'rel': 'canonical' }],
                        },
                        'operationType': {
                            'title': 'Operation Type',
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartOperationType',
                            'type': 'string',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartOperationType' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/operationType', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'cp_featureFlags': ['SemanticSearch'],
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'enum': ['http://platform.cosmoconsult.com/ontology/CountOperation', 'http://platform.cosmoconsult.com/ontology/SumOperation'],
                            'enumNames': ['Count Operation', 'Sum Operation'],
                        },
                        'tooltipPreviewProperty': {
                            'title': 'Tooltip Preview Property',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tooltipPreviewProperty', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_localizable': true,
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpaPageChartRadial',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['nameProperty', 'operationType'],
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartVertical',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartVertical' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cpa Page Chart Vertical',
                    'properties': {
                        'axisProperty': {
                            'title': 'Axis Property',
                            'description': '<div>\n<div>The property used for the y-axis (vertical). Input the path to the property. </div>\n<div>For example: "dateCreated" or "amount.value"</div>\n</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/axisProperty', 'rel': 'canonical' }],
                        },
                        'nameProperty': {
                            'title': 'Name Property',
                            'description': '<div>The property used in the legend of the chart. </div>\n<div>For example: "amount.currency"</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/nameProperty', 'rel': 'canonical' }],
                        },
                        'operationType': {
                            'title': 'Operation Type',
                            'description': '<div>The count operation counts the number of values. The sum operation sums the values.</div>',
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartOperationType',
                            'type': 'string',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartOperationType' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/operationType', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'cp_featureFlags': ['SemanticSearch'],
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'enum': ['http://platform.cosmoconsult.com/ontology/CountOperation', 'http://platform.cosmoconsult.com/ontology/SumOperation'],
                            'enumNames': ['Count Operation', 'Sum Operation'],
                        },
                        'dateProperty': {
                            'title': 'Date Property',
                            'description': '<div>The date property corresponds to the x-axis (horizontal). Input a property path here. </div>\n<div>Examples include: "amount.value" or "dateCreated"</div>',
                            'type': 'string',
                            'format': 'cp:property',
                            'cp_ui': { 'propertySelectorType': 'date,date-time' },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dateProperty', 'rel': 'canonical' }],
                        },
                        'multiplierSymbol': {
                            'title': 'Multiplier Symbol',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/multiplierSymbol', 'rel': 'canonical' }],
                        },
                        'tooltipPreviewProperty': {
                            'title': 'Tooltip Preview Property',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tooltipPreviewProperty', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpaPageChartVertical',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['axisProperty', 'dateProperty'],
                },
            ],
            'cp_ui': { 'sortOrderForm': 111 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/chart', 'rel': 'canonical' }],
        },
        'customCardTemplate': {
            'title': 'Custom Card Template',
            'description': '<div>Set the template to be used for cards on this page. </div>\n<div>Templates can be defined separately under General &gt; CPA &gt; Templates</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaCustomCardTemplate',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaCustomCardTemplate' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/customCardTemplate', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Custom Card Template' } },
            'cp_ui': { 'sortOrderForm': 112 },
        },
        'customRowTemplate': {
            'title': 'Custom Row Template',
            'description': '<div>\n<div>Set the template to be used for rows in the data table on this page. </div>\n<div>Templates can be defined separately under General &gt; CPA &gt; Templates</div>\n</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaCustomRowTemplate',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaCustomRowTemplate' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/customRowTemplate', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Custom Row Template' } },
            'cp_ui': { 'sortOrderForm': 114 },
        },
        'customSwitchText': {
            'title': 'Custom Switch Text',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 115 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/customSwitchText', 'rel': 'canonical' }],
        },
        'customTemplate': {
            'title': 'Custom Template',
            'description': '<div>Select a custom page template.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaCustomTemplate',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaCustomTemplate' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/customTemplate', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Custom Template' } },
            'cp_ui': { 'sortOrderForm': 116 },
        },
        'dataUrl': {
            'title': 'Data Url',
            'description': '<div>The URL path to the data. Variables can be used in {{variable}} notation. </div>\n<div>For example /customer/{{accountNo}}/invoice loads the data from the configured data endpoint.</div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 99 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dataUrl', 'rel': 'canonical' }],
        },
        'displayRelatedPageAsDrawer': {
            'title': 'Display Related Page As Drawer',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 117 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/displayRelatedPageAsDrawer', 'rel': 'canonical' }],
        },
        'divider': {
            'title': 'Divider',
            'description': '<div>If enabled, then a graphical divider between elements on the page is displayed.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/divider', 'rel': 'canonical' }],
        },
        'externalUrl': {
            'title': 'External Url',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 128 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/externalUrl', 'rel': 'canonical' }],
        },
        'fullWidthAsWidget': {
            'title': 'Full Width As Widget',
            'type': 'boolean',
            'default': false,
            'cp_ui': { 'sortOrderForm': 123 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/fullWidthAsWidget', 'rel': 'canonical' }],
        },
        'graphic': {
            'title': 'Graphic',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageGraphic',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageGraphic' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/graphic', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'align': {
                    'title': 'Align',
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageGraphicAlign',
                    'type': 'string',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageGraphicAlign' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/align', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'enum': [
                        'http://platform.cosmoconsult.com/ontology/BottomLeft',
                        'http://platform.cosmoconsult.com/ontology/BottomRight',
                        'http://platform.cosmoconsult.com/ontology/TopLeft',
                        'http://platform.cosmoconsult.com/ontology/TopRight',
                    ],
                    'enumNames': ['Bottom Left', 'Bottom Right', 'Top Left', 'Top Right'],
                },
                'opacity': {
                    'title': 'Opacity',
                    'type': 'number',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/opacity', 'rel': 'canonical' }],
                },
                'width': { 'title': 'Width', 'type': 'string', 'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/width', 'rel': 'canonical' }] },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_localizable': true,
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
                'url': {
                    'title': 'Url',
                    'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                },
            },
            'required': ['align'],
            'cp_ui': { 'sortOrderForm': 129 },
        },
        'groupPropertyJsonPath': {
            'title': 'Group Property Json Path',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 130 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/groupPropertyJsonPath', 'rel': 'canonical' }],
        },
        'initialFilter': {
            'title': 'Initial Filter',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 131 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initialFilter', 'rel': 'canonical' }],
        },
        'initialHideChartOnPage': {
            'title': 'Initial Hide Chart On Page',
            'description': '<div>If selected, then the chart on the page will be hidden initially.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 132 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initialHideChartOnPage', 'rel': 'canonical' }],
        },
        'initialOrderDirection': {
            'title': 'Initial Order Direction',
            '$id': 'http://platform.cosmoconsult.com/ontology/OrderDirection',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FOrderDirection' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/initialOrderDirection', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Ascending',
                'http://platform.cosmoconsult.com/ontology/Descending',
                'http://platform.cosmoconsult.com/ontology/Undefined',
            ],
            'enumNames': ['Ascending', 'Descending', 'Undefined'],
            'cp_ui': { 'sortOrderForm': 133 },
        },
        'initialOrderPropertyJsonPath': {
            'title': 'Initial Order Property Json Path',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 134 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initialOrderPropertyJsonPath', 'rel': 'canonical' }],
        },
        'isDashboard': {
            'title': 'Is Dashboard',
            'description': '<div>Defines if this page is a Dashboard</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 135 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isDashboard', 'rel': 'canonical' }],
        },
        'kpis': {
            'title': 'Kpis',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageKpi',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageKpi' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa Page Kpi',
                'properties': {
                    'pattern': {
                        'title': 'Pattern',
                        'description': '<div>The display pattern. The value of the KPI can be insterted with {{value}}</div>\n<div>For example: "This is a test {{value}}"</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 43 },
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/pattern', 'rel': 'canonical' }],
                    },
                    'cpTypeUrl': {
                        'title': 'Cp Type Url',
                        'description': '<div>The URL that indicates which type this is assigned to</div>',
                        'type': 'string',
                        'links': [
                            { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
                        ],
                        'cp_ui': { 'sortOrderForm': 40 },
                    },
                    'showOnDashboards': {
                        'title': 'Show On Dashboards',
                        'description': '<div>Configure on wich dashboards this page will appear</div>',
                        'type': 'array',
                        'items': {
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
                            'type': 'object',
                            'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' }],
                            'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'cp_featureFlags': ['SemanticSearch'],
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'title': 'Cpa Page',
                            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
                        },
                        'cp_ui': { 'sortOrderForm': 38 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/showOnDashboards', 'rel': 'canonical' }],
                    },
                    'valueProperty': {
                        'title': 'Value Property',
                        'description': '<div>The value of the item that the KPI is based upon.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 45 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/valueProperty', 'rel': 'canonical' }],
                    },
                    'filter': {
                        'title': 'Filter',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 44 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/filter', 'rel': 'canonical' }],
                    },
                    'kpiType': {
                        'title': 'Kpi Type',
                        'description': '<div>The type of the KPI can either be just the count of items or based on the sum of a property defined through the value property.</div>',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageKpiType',
                        'type': 'string',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageKpiType' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/kpiType', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'enum': [
                            'http://platform.cosmoconsult.com/ontology/CountOperation',
                            'http://platform.cosmoconsult.com/ontology/SumOperation',
                            'http://platform.cosmoconsult.com/ontology/Undefined',
                        ],
                        'enumNames': ['Count Operation', 'Sum Operation', 'Undefined'],
                        'cp_ui': { 'sortOrderForm': 42 },
                    },
                    'showOnPage': {
                        'title': 'Show On Page',
                        'type': 'boolean',
                        'cp_ui': { 'sortOrderForm': 37 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/showOnPage', 'rel': 'canonical' }],
                    },
                    'aggregationTemplate': {
                        'title': 'Aggregation Template',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpAggregationTemplate',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAggregationTemplate' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/aggregationTemplate', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Aggregation Template' } },
                        'cp_ui': { 'sortOrderForm': 41 },
                    },
                    'identifier': {
                        'title': 'Identifier',
                        'description': '<div>The identifier of the KPI.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 35 },
                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': '<div>The display name of the KPI.</div>',
                        'type': 'string',
                        'format': 'cp:html',
                        'cp_ui': { 'sortOrderForm': 36 },
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
                'required': ['name', 'identifier'],
            },
            'cp_ui': { 'sortOrderForm': 136 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/kpis', 'rel': 'canonical' }],
        },
        'maxNoOfCardsInSlider': {
            'title': 'Max No Of Cards In Slider',
            'description': '<div>Sets the maximal number of cards in a slider view. </div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 137 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/maxNoOfCardsInSlider', 'rel': 'canonical' }],
        },
        'parentCpaPage': {
            'title': 'Parent Cpa Page',
            'description': '<div>Defines the parent page for this page.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentCpaPage', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
            'cp_ui': { 'sortOrderForm': 90 },
            'cp_ignoreRelation': true,
        },
        'path': {
            'title': 'Path',
            'description': '<div>The relative path of the page with a leading slash.</div>\n<div>For example: "/invoices"</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 92 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/path', 'rel': 'canonical' }],
        },
        'promotedForRoles': {
            'title': 'Promoted For Roles',
            'type': 'array',
            'items': {
                'type': 'string',
                'links': [{ 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' }],
            },
            'cp_ui': { 'sortOrderForm': 139 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/promotedForRoles', 'rel': 'canonical' }],
        },
        'searchable': {
            'title': 'Searchable',
            'description': '<div>If enabled, it is possible to perform a search within the data source of the page from the global app search.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 107 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchable', 'rel': 'canonical' }],
        },
        'showOnDashboards': {
            'title': 'Show On Dashboards',
            'description': '<div>Configure on wich dashboards this page will appear</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' }],
                'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa Page',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
            },
            'cp_ui': { 'sortOrderForm': 95 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/showOnDashboards', 'rel': 'canonical' }],
        },
        'singleItemComponents': {
            'title': 'Single Item Components',
            'description': '<div>Additional single item components that can be displayed on the current page.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' }],
                'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa Page',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
            },
            'cp_ui': { 'sortOrderForm': 140 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/singleItemComponents', 'rel': 'canonical' }],
        },
        'singleItemTemplate': {
            'title': 'Single Item Template',
            'description': '<div>The template to be used for each single item.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaSingleItemTemplate',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaSingleItemTemplate' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/singleItemTemplate', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Single Item Template' } },
            'cp_ui': { 'sortOrderForm': 141 },
        },
        'sortOrderAsWidget': {
            'title': 'Sort Order As Widget',
            'description': '<div>The order value (placement) as a Widget on a Dashboard or a different Page.</div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 122 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sortOrderAsWidget', 'rel': 'canonical' }],
        },
        'sortOrderMenu': {
            'title': 'Sort Order Menu',
            'description': '<div>The order value (placement) in the menu for the respective menu level.</div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 121 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sortOrderMenu', 'rel': 'canonical' }],
        },
        'spanColumn': {
            'title': 'Span Column',
            'description': '<div>The column(s) to display first in table. It can either be a single property name, or a list of property names separated with a comma.</div>\n<div>For example: "name" or "name,identifier,height".</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 142 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/spanColumn', 'rel': 'canonical' }],
        },
        'staticContent': {
            'title': 'Static Content',
            'description': '<div>Additional static content for the page. It is displayed at the top of the page.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 143 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/staticContent', 'rel': 'canonical' }],
        },
        'fullPageDrawer': {
            'title': 'Full Page Drawer',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 118 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/fullPageDrawer', 'rel': 'canonical' }],
        },
        'promotedFilters': {
            'title': 'Promoted Filters',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPromotedFilter',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPromotedFilter' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa Promoted Filter',
                'properties': {
                    'propertyJsonPath': {
                        'title': 'Property Json Path',
                        'description': '<div>The name of the property (camelcase) in the Property Json</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/propertyJsonPath', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
                'required': ['propertyJsonPath'],
            },
            'cp_ui': { 'sortOrderForm': 138 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/promotedFilters', 'rel': 'canonical' }],
        },
        'topVisualImageLarge': {
            'title': 'Top Visual Image Large',
            'anyOf': [
                { 'type': 'string', 'format': 'uri', 'title': 'URL' },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/FileAttachment',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FFileAttachment' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'File Attachment',
                    'properties': {
                        'file': {
                            'title': 'File',
                            'type': 'string',
                            'format': 'data-url',
                            'cp_ui': { 'sortOrderForm': 29 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/file', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/FileAttachment',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
            ],
            'cp_ui': { 'sortOrderForm': 145 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/topVisualImageLarge', 'rel': 'canonical' }],
        },
        'topVisualImageSmall': {
            'title': 'Top Visual Image Small',
            'anyOf': [
                { 'type': 'string', 'format': 'uri', 'title': 'URL' },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/FileAttachment',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FFileAttachment' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'cp_featureFlags': ['SemanticSearch'],
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'File Attachment',
                    'properties': {
                        'file': {
                            'title': 'File',
                            'type': 'string',
                            'format': 'data-url',
                            'cp_ui': { 'sortOrderForm': 29 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/file', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/FileAttachment',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
            ],
            'cp_ui': { 'sortOrderForm': 146 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/topVisualImageSmall', 'rel': 'canonical' }],
        },
        'doubleClickAction': {
            'title': 'Double click action',
            '$id': 'http://platform.cosmoconsult.com/ontology/DoubleClickAction',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDoubleClickAction' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/doubleClickAction', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Edit',
                'http://platform.cosmoconsult.com/ontology/View',
                'http://platform.cosmoconsult.com/ontology/Undefined',
            ],
            'enumNames': ['Edit', 'View', 'Undefined'],
            'cp_ui': { 'sortOrderForm': 127 },
        },
        'widgetRedirectPath': {
            'title': 'Widget Redirect Path',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 125 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/widgetRedirectPath', 'rel': 'canonical' }],
        },
        'showInMenu': {
            'title': 'Show In Menu',
            'description': '<div>If this option is activated, the page is displayed in the main menu (at the respective menu level) of the app(s) to which this page is linked.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 93 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/showInMenu', 'rel': 'canonical' }],
        },
        'cardAction': {
            'title': 'Card Action',
            '$id': 'http://platform.cosmoconsult.com/ontology/CardAction',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCardAction' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cardAction', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Undefined',
                'http://platform.cosmoconsult.com/ontology/BrowseChildrenOrOpenItem',
                'http://platform.cosmoconsult.com/ontology/OpenItemOnly',
            ],
            'enumNames': ['Undefined', 'Browse Children Or Open Item', 'Open Item Only'],
            'cp_ui': { 'sortOrderForm': 113 },
        },
        'forms': {
            'title': 'Forms',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Form',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FForm' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Form',
                'properties': {
                    'icon': {
                        'title': 'Icon',
                        'description': '<div>The friendly name of the symbol for this page. Use https://storybook.platform.cosmoconsult.com/?path=/story/icons--all-icons to find icons (incl. friendly name).</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 33 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/icon', 'rel': 'canonical' }],
                    },
                    'cpTypeUrl': {
                        'title': 'Cp Type Url',
                        'description': '<div>The URL that indicates which type this is assigned to</div>',
                        'type': 'string',
                        'links': [
                            { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
                        ],
                        'cp_ui': { 'sortOrderForm': 32 },
                    },
                    'group': {
                        'title': 'Group',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 34 },
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/group', 'rel': 'canonical' }],
                    },
                    'default': {
                        'title': 'Default',
                        'type': 'boolean',
                        'cp_ui': { 'sortOrderForm': 35 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/default', 'rel': 'canonical' }],
                    },
                    'identifier': {
                        'title': 'Identifier',
                        'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 30 },
                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 31 },
                        'cp_localizable': true,
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
                'required': ['cpTypeUrl', 'identifier', 'name'],
            },
            'cp_ui': { 'sortOrderForm': 109 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/form', 'rel': 'canonical' }],
        },
        'transparentWidget': {
            'title': 'Transparent Widget',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 124 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/transparentWidget', 'rel': 'canonical' }],
        },
        'derivedFrom': {
            'title': 'Derived From',
            'description': "<div>Defines base page that will be used by 'InheritanceMerge' read treatment.<br />Can be useful for pages that inherit most of the properties.</div>",
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/derivedFrom', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['SemanticSearch'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
            'cp_ui': { 'sortOrderForm': 91 },
        },
        'uxPreferenceFeatureFlags': {
            'title': 'Ux Preference Feature Flags',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/UxPreferenceFeatureFlag',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUxPreferenceFeatureFlag' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'cp_featureFlags': ['SemanticSearch'],
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Ux Preference Feature Flag',
                'properties': {
                    'userExperiencePreference': {
                        'title': 'User Experience Preference',
                        '$id': 'http://platform.cosmoconsult.com/ontology/UserExperiencePreference',
                        'type': 'string',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserExperiencePreference' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/userExperiencePreference', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'enum': [
                            'http://platform.cosmoconsult.com/ontology/Expert',
                            'http://platform.cosmoconsult.com/ontology/Simple',
                            'http://platform.cosmoconsult.com/ontology/Undefined',
                        ],
                        'enumNames': ['Expert', 'Simple', 'Undefined'],
                        'cp_enumIcons': ['CpSkillLevel4', null, null],
                        'cp_enumDescriptions': [
                            '<div style="text-align: center;">Subject matter expert who understands the underlying principles<br />Can perform task on a strategic or architectural level<br />Typically many years of practical experience</div>',
                            null,
                            null,
                        ],
                    },
                    'featureFlags': {
                        'title': 'Feature Flags',
                        'type': 'array',
                        'items': {
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpaUxPreferenceFeatureFlag',
                            'type': 'object',
                            'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaUxPreferenceFeatureFlag' }],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'cp_featureFlags': ['SemanticSearch'],
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'title': 'Cpa Ux Preference Feature Flag',
                            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Ux Preference Feature Flag' } },
                        },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/featureFlag', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 148 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/uxPreferenceFeatureFlag', 'rel': 'canonical' }],
        },
        'iconFull': {
            'title': 'Dashboard Icon',
            'description': '<div>The friendly name of the dashboard icon. Use https://storybook.platform.cosmoconsult.com/?path=/story/icons--all-icons to find icons (incl. friendly name).</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 105 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/iconFull', 'rel': 'canonical' }],
        },
        'disableFacetFilters': {
            'title': 'Disable Facet Filters',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 120 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableFacetFilters', 'rel': 'canonical' }],
        },
        'breadcrumbHideTop': {
            'title': 'Breadcrumb Hide Top',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 144 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/breadcrumbHideTop', 'rel': 'canonical' }],
        },
        'itemNotFoundFallbackUrl': {
            'title': 'Item Not Found Fallback Url',
            'description': "<div>If provided and item not found, the following path will be used to redirect the user.</div>\n<div>Both relative and absolute path supported. Following placeholders supported:</div>\n<ul>\n<li>{{identifier}} - identifier of an item</li>\n<li>{{language}} - current page language, for example: 'en-US'</li>\n</ul>",
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 126 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/itemNotFoundFallbackUrl', 'rel': 'canonical' }],
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 97 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                                'cp_ui': { 'sortOrderForm': 45 },
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'cp_featureFlags': ['SemanticSearch'],
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'cp_ui': { 'sortOrderForm': 38 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'sortOrderForm': 147 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>A description of the page. If the page is dashboard, it will be used as a greeting message. Variables can be used in {{variable}} notation.</div>\n<div>Following variables are available: "<span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">given_name", "</span></span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">family_name", "</span></span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">name", "</span></span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">email", "</span></span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">cpa_name".</span></div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 103 },
            'cp_localizable': true,
            'cp_vectorizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The display name of the page.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 88 },
            'cp_localizable': true,
            'cp_vectorizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'cpas'],
};
export const CpaPageSchema = Schema;
