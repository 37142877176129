import { StorageKey, missingGanScreen } from '@cpa/base-core/constants';
import {
  ICardProps,
  IComponentWithOptions,
  IHtmlRendererProps,
  IRelatedContentProps,
  IScreenProps,
  ISingleItemOptions,
  ISingleItemTemplateProps,
  ITableRowOption,
  ITableRowProps,
  IUserAgreement,
} from '@cpa/base-core/types';
import { ElementType } from 'react';

import AttachmentReplacingHtmlRenderer from './components/Formatter/components/AttachmentReplacingHtmlRenderer/AttachmentReplacingHtmlRenderer';
import DefaultHtmlRenderer from './components/Formatter/components/DefaultHtmlRenderer/DefaultHtmlRenderer';
import Account from './screens/Account/Account';
import Contact from './screens/Contact/Contact';
import DocumentationSearch from './screens/DocumentationSearch/DocumentationSearch';
import GenericScreen, { IRelatedViewProps } from './screens/GenericScreen/GenericScreen';
import ItemReadonlyContent, { withReadonlyContentOptions } from './screens/ItemReadonlyContent/ItemReadonlyContent';
import LoopWorkItemHistory from './screens/LoopWorkItemHistory/LoopWorkItemHistory';
import Dashboard from './screens/RebrushedDashboard/RebrushedDashboard';
import DashboardWithoutAnimation from './screens/RebrushedDashboard/RebrushedDashboardWithoutAnimation';
import DashboardWithoutStats from './screens/RebrushedDashboard/RebrushedDashboardWithoutStats';
import DashboardWithoutTitle from './screens/RebrushedDashboard/RebrushedDashboardWithoutTitle';
import RelatedWork from './screens/RelatedWork/RelatedWork';
import SearchResults from './screens/SearchResults/SearchResults';
import Statement from './screens/Statement/Statement';
import StatementSmall from './screens/Statement/StatementSmall';
import StaticContent from './screens/StaticContent/StaticContent';
import Tags from './screens/Tags/Tags';
import ArticlesCard from './templates/card/ArticlesCard/ArticlesCard';
import CometAchievementCard from './templates/card/CometAchievementCard/CometAchievementCard';
import CometRewardCard from './templates/card/CometRewardCard/CometRewardCard';
import ContactPersonCard from './templates/card/ContactPersonCard/ContactPersonCard';
import DefaultCard from './templates/card/DefaultCard/DefaultCard';
import DocumentationCard from './templates/card/DocumentationCard/DocumentationCard';
import ImageItemCard from './templates/card/ImageItemCard/ImageItemCard';
import LoopWorkItemCard from './templates/card/LoopWorkItemCard/LoopWorkItemCard';
import ProfessionItemCard from './templates/card/ProfessionItemCard/ProfessionItemCard';
import PromotedItemCard from './templates/card/PromotedItemCard/PromotedItemCard';
import RebrushedImageItemCard, { withLightCard } from './templates/card/RebrushedImageItemCard/RebrushedImageItemCard';
import RebrushedImageItemCardWithoutExtLink from './templates/card/RebrushedImageItemCard/RebrushedImageItemCardWithoutExtLink';
import SolutionImageItemCard from './templates/card/SolutionImageItemCard/SolutionImageItemCard';
import Comment from './templates/row/Comment/Comment';
import SearchResult from './templates/row/SearchResult/SearchResult';
import SolutionExplorer from './templates/row/SolutionExplorer/SolutionExplorer';
import SolutionExplorerComponentsOnly from './templates/row/SolutionExplorer/SolutionExplorerComponentsOnly';
import SolutionExplorerComponentsPromoted from './templates/row/SolutionExplorer/SolutionExplorerComponentsPromoted';
import SolutionExplorerConfigurationCanvas from './templates/row/SolutionExplorer/SolutionExplorerConfigurationCanvas';
import TableRow from './templates/row/TableRow/TableRow';
import CometAchievementSingleItemTemplate from './templates/singleItem/CometAchievementSingleItemTemplate/CometAchievementSingleItemTemplate';
import CometRewardSingleItemTemplate from './templates/singleItem/CometRewardSingleItemTemplate/CometRewardSingleItemTemplate';
import DefaultSingleItemTemplate from './templates/singleItem/DefaultSingleItemTemplate/DefaultSingleItemTemplate';
import DocumentationSingleItemTemplate from './templates/singleItem/DocumentationSingleItemTemplate/DocumentationSingleItemTemplate';
import ProfessionSingleItemTemplate from './templates/singleItem/ProfessionSingleItemTemplate/ProfessionSingleItemTemplate';
import SolutionConfigurationCanvasSingleItemTemplate from './templates/singleItem/SolutionConfigurationCanvasSingleItemTemplate/SolutionConfigurationCanvasSingleItemTemplate';
import SolutionSingleItemTemplate from './templates/singleItem/SolutionSingleItemTemplate/SolutionSingleItemTemplate';
import WidgetsOnlySingleItemTemplate from './templates/singleItem/WidgetsOnlySingleItemTemplate/WidgetsOnlySingleItemTemplate';
import Message from './templates/row/Message/Message';

export type Screen = ElementType<(IScreenProps | IRelatedContentProps) & IRelatedViewProps> & IComponentWithOptions;
export type Card = ElementType<ICardProps> & IComponentWithOptions;
export type TableRow = ElementType<ITableRowProps> & IComponentWithOptions<ITableRowOption>;
export type SingleItem = ElementType<ISingleItemTemplateProps> & IComponentWithOptions<ISingleItemOptions>;
export type HtmlRenderer = ElementType<IHtmlRendererProps> & IComponentWithOptions;

export const getComponentByType = <T>(configuration: Record<string, T>, type: string): T => configuration[type] ?? configuration.Default;

// Screens
export const ScreenTypesConfiguration: Record<string, Screen> & { Default: Screen } = {
  Default: GenericScreen,
  [missingGanScreen]: Contact,
  Dashboard,
  DashboardWithoutStats,
  DashboardWithoutTitle,
  DashboardWithoutAnimation,
  SearchResults,
  Account,
  Tags,
  Rating: Statement,
  RatingSmall: StatementSmall,
  Voting: Statement,
  StaticContent,
  ItemReadonlyContent: ItemReadonlyContent,
  ItemReadonlyContentGrid: withReadonlyContentOptions(ItemReadonlyContent, { gridView: true }),
  DocumentationSearch,
  LoopWorkItemHistory,
  RelatedWork,
};

// Cards
export const CustomCardTemplates: Record<string, Card> & { Default: Card } = {
  Default: DefaultCard,
  Articles: ArticlesCard,
  CometAchievement: CometAchievementCard,
  CometReward: CometRewardCard,
  ContactPerson: ContactPersonCard,
  PromotedItem: PromotedItemCard,
  ImageItem: ImageItemCard,
  SolutionImageItem: SolutionImageItemCard,
  ProfessionItemCard: ProfessionItemCard,
  DocumentationCard: DocumentationCard,
  RebrushedImageItemCard: RebrushedImageItemCard,
  RebrushedLightItemCard: withLightCard(RebrushedImageItemCard),
  RebrushedImageItemCardWithoutExtLink: RebrushedImageItemCardWithoutExtLink,
  RebrushedLightImageItemCardWithoutExtLink: withLightCard(RebrushedImageItemCardWithoutExtLink),
  LoopWorkItemCard: LoopWorkItemCard,
};

// Rows
export const CustomRowTemplates: Record<string, TableRow> & { Default: TableRow } = {
  Default: TableRow,
  SearchResult: SearchResult,
  Comment: Comment,
  Message: Message,
  SolutionExplorer: SolutionExplorer,
  SolutionExplorerComponentsPromoted: SolutionExplorerComponentsPromoted,
  SolutionExplorerComponentsOnly: SolutionExplorerComponentsOnly,
  SolutionExplorerConfigurationCanvas: SolutionExplorerConfigurationCanvas,
};

// Single Item
export const SingleItemTemplates: Record<string, SingleItem> & { Default: SingleItem } = {
  Default: DefaultSingleItemTemplate,
  DefaultSingleItemTemplate: DefaultSingleItemTemplate,
  WidgetsOnlySingleItemTemplate: WidgetsOnlySingleItemTemplate,
  ProfessionSingleItemTemplate: ProfessionSingleItemTemplate,
  DocumentationSingleItemTemplate: DocumentationSingleItemTemplate,
  SolutionSingleItemTemplate: SolutionSingleItemTemplate,
  CometAchievementSingleItemTemplate: CometAchievementSingleItemTemplate,
  CometRewardSingleItemTemplate: CometRewardSingleItemTemplate,
  SolutionConfigurationCanvasSingleItemTemplate: SolutionConfigurationCanvasSingleItemTemplate,
  // User friendly alias
  canvas: SolutionConfigurationCanvasSingleItemTemplate,
};

// Html Renderer
export const HtmlRenderers: Record<string, HtmlRenderer> & { Default: HtmlRenderer } = {
  Default: DefaultHtmlRenderer,
  'cp:htmlAttachmentReplacing': AttachmentReplacingHtmlRenderer,
};

// User Agreements
export const UserAgreements: IUserAgreement[] = [
  { fieldName: StorageKey.HideCookie, title: 'cookie.fields.general', isChecked: true, disabled: true },
  { fieldName: StorageKey.AcceptGA, title: 'cookie.fields.ga' },
  { fieldName: StorageKey.AcceptGAWithUserId, title: 'cookie.fields.gaWithUserId' },
];
