import * as _ from 'lodash';
import N3 from 'n3';
import { parseOntologyUri } from '../ontology';
export function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const QuadMapper = {
    addEnumeration(iri) {
        return [...this.addSubclassOf(iri, RDFS.enumeration)];
    },
    addValueToEnumeration(enumIRI, value) {
        return [...this.addInstanceToClass(enumIRI, value)];
    },
    addSubclassOf(childClass, parentClass) {
        return [N3.DataFactory.quad(childClass, RDFS.subClassOf, parentClass)];
    },
    addSupersededBy(plural, singular) {
        return [N3.DataFactory.quad(plural, RDFS.supersededBy, singular)];
    },
    addObjectProperty(iri) {
        return [N3.DataFactory.quad(iri, RDFS.type, RDFS.objectProperty), N3.DataFactory.quad(iri, RDFS.subPropertyOf, RDFS.topObjectProperty)];
    },
    addDataProperty(iri) {
        return [N3.DataFactory.quad(iri, RDFS.type, RDFS.dataProperty)];
    },
    addAnnotationProperty(iri) {
        return [N3.DataFactory.quad(iri, RDFS.type, RDFS.annotationProperty)];
    },
    addClass(iri) {
        return [...this.addInstanceToClass(RDFS.classR, iri)];
    },
    addInstanceToClass(cls, iri) {
        return [N3.DataFactory.quad(iri, RDFS.type, cls)];
    },
    addDomainElement(prop, domainElement) {
        return [N3.DataFactory.quad(prop, RDFS.domain, domainElement)];
    },
    addRangeElement(prop, rangeElement) {
        return [N3.DataFactory.quad(prop, RDFS.range, rangeElement)];
    },
    addAnnotation(target, prop, value, context) {
        return [N3.DataFactory.quad(target, prop, value, context)];
    },
    addLabel(iri, name) {
        if (name !== undefined)
            return this.addAnnotation(iri, RDFS.Annotations.label, name);
        else {
            const defaultName = N3.DataFactory.literal(parseOntologyUri(iri.value).name);
            return this.addAnnotation(iri, RDFS.Annotations.label, defaultName);
        }
    },
};
export const NamedNodeHelper = {
    PropIri(name, prefix) {
        return N3.DataFactory.namedNode(this.constructIri(lowerCaseFirstLetter(name), prefix));
    },
    ClassIri(name, prefix) {
        return N3.DataFactory.namedNode(this.constructIri(capitalizeFirstLetter(name), prefix));
    },
    EnumIri(name, prefix) {
        return NamedNodeHelper.ClassIri(name, prefix);
    },
    EnumValueIri(name, prefix) {
        return N3.DataFactory.namedNode(this.constructIri(_.camelCase(name), prefix));
    },
    NamedNode(name, prefix) {
        return N3.DataFactory.namedNode(this.constructIri(name, prefix));
    },
    constructIri(name, prefix) {
        if (prefix !== undefined) {
            return `${prefix}${name}`;
        }
        else {
            return `${name}`;
        }
    },
};
export class RDFS {
    static isLiteral(term) {
        return N3.Util.isLiteral(term);
    }
    static isNamedNode(term) {
        return N3.Util.isNamedNode(term);
    }
    static isBlankNode(term) {
        return N3.Util.isBlankNode(term);
    }
    static isVariable(term) {
        return N3.Util.isVariable(term);
    }
    static isDefaultGraph(term) {
        return N3.Util.isDefaultGraph(term);
    }
    static jsonSchemaToDataType(schema) {
        let matchedDataType = null;
        let matchedDataTypeScore = 0;
        for (const [dataType, correspondingSchema] of _.sortBy(Object.entries(RDFS.dataTypesMapping), ([, schema]) => Object.keys(schema).length)) {
            const score = RDFS.compareSchemas(correspondingSchema, schema);
            if (score === 0) {
                continue;
            }
            if (matchedDataType === null || matchedDataTypeScore < score) {
                matchedDataType = dataType;
                matchedDataTypeScore = score;
            }
        }
        return matchedDataType;
    }
    static compareSchemas(schemaA, schemaB) {
        let score = 0;
        for (const [keyA, valueA] of Object.entries(schemaA)) {
            if (keyA in schemaB && valueA === schemaB[keyA]) {
                score++;
            }
        }
        return score;
    }
}
RDFS.rdf = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#';
RDFS.rdfs = 'http://www.w3.org/2000/01/rdf-schema#';
RDFS.owl = 'http://www.w3.org/2002/07/owl#';
RDFS.schema = 'http://schema.org/';
RDFS.cp = 'http://platform.cosmoconsult.com/ontology/';
RDFS.xsd = 'http://www.w3.org/2001/XMLSchema#';
RDFS.range = NamedNodeHelper.NamedNode('range', RDFS.rdfs);
RDFS.rangeIncludes = NamedNodeHelper.NamedNode('rangeIncludes', RDFS.schema);
RDFS.domainIncludes = NamedNodeHelper.NamedNode('domainIncludes', RDFS.schema);
RDFS.domain = NamedNodeHelper.NamedNode('domain', RDFS.rdfs);
RDFS.type = NamedNodeHelper.NamedNode('type', RDFS.rdf);
RDFS.subClassOf = NamedNodeHelper.NamedNode('subClassOf', RDFS.rdfs);
RDFS.supersededBy = NamedNodeHelper.NamedNode('supersededBy', RDFS.schema);
RDFS.topObjectProperty = NamedNodeHelper.NamedNode('topObjectProperty', RDFS.owl);
RDFS.subPropertyOf = NamedNodeHelper.NamedNode('subPropertyOf', RDFS.rdfs);
RDFS.annotationProperty = NamedNodeHelper.NamedNode('AnnotationProperty', RDFS.owl);
RDFS.Annotations = {
    label: NamedNodeHelper.NamedNode('label', RDFS.rdfs),
    comment: NamedNodeHelper.NamedNode('comment', RDFS.rdfs),
    disableIcon: NamedNodeHelper.NamedNode('disableIcon', RDFS.cp),
    Class: {
        parentPropertyJsonPath: NamedNodeHelper.NamedNode('parentPropertyJsonPath', RDFS.cp),
        helpLink: NamedNodeHelper.NamedNode('helpLink', RDFS.cp),
        aggregationPipeline: NamedNodeHelper.NamedNode('aggregationPipeline', RDFS.cp),
        promoteAggregationPipeline: NamedNodeHelper.NamedNode('promoteAggregationPipeline', RDFS.cp),
        useAggregationPipelinesInAggregationTemplates: NamedNodeHelper.NamedNode('useAggregationPipelinesInAggregationTemplates', RDFS.cp),
        aggergationPipelineSequence: NamedNodeHelper.NamedNode('aggergationPipelineSequence', RDFS.cp),
        disableTrash: NamedNodeHelper.NamedNode('disableTrash', RDFS.cp),
        disableLocalization: NamedNodeHelper.NamedNode('disableLocalization', RDFS.cp),
        disableVersions: NamedNodeHelper.NamedNode('disableVersions', RDFS.cp),
        disableClone: NamedNodeHelper.NamedNode('disableClone', RDFS.cp),
        disableFilterHelper: NamedNodeHelper.NamedNode('disableFilterHelper', RDFS.cp),
        disableLazyLoading: NamedNodeHelper.NamedNode('disableLazyLoading', RDFS.cp),
        allowAdditionalProperties: NamedNodeHelper.NamedNode('allowAdditionalProperties', RDFS.cp),
        staticRef: NamedNodeHelper.NamedNode('staticRef', RDFS.cp),
        dataEndpointPath: NamedNodeHelper.NamedNode('dataEndpointPath', RDFS.cp),
        dataEndpoint: NamedNodeHelper.NamedNode('dataEndpoint', RDFS.cp),
        promotedTypesUsingMainEntity: NamedNodeHelper.NamedNode('promotedTypesUsingMainEntity', RDFS.cp),
        promotedTypesUsingAbout: NamedNodeHelper.NamedNode('promotedTypesUsingAbout', RDFS.cp),
        parentIsNullFilterPropertyJsonPaths: NamedNodeHelper.NamedNode('parentIsNullFilterPropertyJsonPaths', RDFS.cp),
        enableLatestMajor: NamedNodeHelper.NamedNode('enableLatestMajor', RDFS.cp),
        autoUserItemAccessPermission: NamedNodeHelper.NamedNode('autoUserItemAccessPermission', RDFS.cp),
        vectorizable: NamedNodeHelper.NamedNode('vectorizable', RDFS.cp),
        featureFlags: NamedNodeHelper.NamedNode('featureFlags', RDFS.cp),
        liveUpdatesRelatedCpTypeUrl: NamedNodeHelper.NamedNode('liveUpdatesRelatedCpTypeUrl', RDFS.cp),
        skipRelationCheckIfClassIsRelated: NamedNodeHelper.NamedNode('skipRelationCheckIfClassIsRelated', RDFS.cp),
    },
    Property: {
        downloadable: NamedNodeHelper.NamedNode('downloadable', RDFS.cp),
        hiddenInForm: NamedNodeHelper.NamedNode('hiddenInForm', RDFS.cp),
        hiddenInReadonlyContent: NamedNodeHelper.NamedNode('hiddenInReadonlyContent', RDFS.cp),
        hiddenInTable: NamedNodeHelper.NamedNode('hiddenInTable', RDFS.cp),
        hiddenInFilter: NamedNodeHelper.NamedNode('hiddenInFilter', RDFS.cp),
        hiddenIfCreate: NamedNodeHelper.NamedNode('hiddenIfCreate', RDFS.cp),
        hiddenIfUpdate: NamedNodeHelper.NamedNode('hiddenIfUpdate', RDFS.cp),
        propertySelectorType: NamedNodeHelper.NamedNode('propertySelectorType', RDFS.cp),
        sortOrderForm: NamedNodeHelper.NamedNode('sortOrderForm', RDFS.cp),
        wizardGroup: NamedNodeHelper.NamedNode('wizardGroup', RDFS.cp),
        minItems: NamedNodeHelper.NamedNode('minItems', RDFS.cp),
        minLength: NamedNodeHelper.NamedNode('minLength', RDFS.cp),
        maxLength: NamedNodeHelper.NamedNode('maxLength', RDFS.cp),
        localizable: NamedNodeHelper.NamedNode('localizable', RDFS.cp),
        disableLookupAdvancedFilter: NamedNodeHelper.NamedNode('disableLookupAdvancedFilter', RDFS.cp),
        dialogLookup: NamedNodeHelper.NamedNode('dialogLookup', RDFS.cp),
        expand: NamedNodeHelper.NamedNode('expand', RDFS.cp),
        expertMode: NamedNodeHelper.NamedNode('expertMode', RDFS.cp),
        required: NamedNodeHelper.NamedNode('required', RDFS.cp),
        readOnly: NamedNodeHelper.NamedNode('readOnly', RDFS.cp),
        format: NamedNodeHelper.NamedNode('format', RDFS.cp),
        defaultValue: NamedNodeHelper.NamedNode('defaultValue', RDFS.cp),
        suggestionTypeUrl: NamedNodeHelper.NamedNode('suggestionTypeUrl', RDFS.cp),
        multiplicity: NamedNodeHelper.NamedNode('multiplicity', RDFS.cp),
        const: NamedNodeHelper.NamedNode('const', RDFS.cp),
        pattern: NamedNodeHelper.NamedNode('pattern', RDFS.cp),
        ignoreRelation: NamedNodeHelper.NamedNode('ignoreRelation', RDFS.cp),
        transform: NamedNodeHelper.NamedNode('transform', RDFS.cp),
        vectorizable: NamedNodeHelper.NamedNode('vectorizable', RDFS.cp),
        lookupFilter: NamedNodeHelper.NamedNode('lookupFilter', RDFS.cp),
    },
    Individual: {
        icon: NamedNodeHelper.NamedNode('icon', RDFS.cp),
        enumSortOrder: NamedNodeHelper.NamedNode('enumSortOrder', RDFS.cp),
    },
};
RDFS.classR = NamedNodeHelper.NamedNode('class', RDFS.rdf);
RDFS.property = NamedNodeHelper.NamedNode('Property', RDFS.rdf);
RDFS.objectProperty = NamedNodeHelper.NamedNode('ObjectProperty', RDFS.owl);
RDFS.dataProperty = NamedNodeHelper.NamedNode('DataProperty', RDFS.owl);
RDFS.enumeration = NamedNodeHelper.NamedNode('Enumeration', RDFS.schema);
RDFS.list = NamedNodeHelper.NamedNode('List', RDFS.rdf);
RDFS.thing = NamedNodeHelper.NamedNode('Thing', RDFS.schema);
RDFS.owlThing = NamedNodeHelper.NamedNode('Thing', RDFS.owl);
RDFS.DataTypes = {
    text: NamedNodeHelper.NamedNode('Text', RDFS.schema),
    number: NamedNodeHelper.NamedNode('Number', RDFS.schema),
    boolean: NamedNodeHelper.NamedNode('Boolean', RDFS.schema),
    date: NamedNodeHelper.NamedNode('Date', RDFS.schema),
    dateTime: NamedNodeHelper.NamedNode('DateTime', RDFS.schema),
    float: NamedNodeHelper.NamedNode('Float', RDFS.schema),
    integer: NamedNodeHelper.NamedNode('Integer', RDFS.schema),
    time: NamedNodeHelper.NamedNode('Time', RDFS.schema),
    url: NamedNodeHelper.NamedNode('URL', RDFS.schema),
    dataUrl: NamedNodeHelper.NamedNode('DataUrl', RDFS.cp),
    quantity: NamedNodeHelper.NamedNode('Quantity', RDFS.schema),
    occupationalCategory: NamedNodeHelper.NamedNode('occupationalCategory', RDFS.schema),
    repetitions: NamedNodeHelper.NamedNode('repetitions', RDFS.schema),
    startDate: NamedNodeHelper.NamedNode('startDate', RDFS.schema),
    endDate: NamedNodeHelper.NamedNode('endDate', RDFS.schema),
    undefined: NamedNodeHelper.NamedNode('Undefined', RDFS.cp),
};
RDFS.dataTypesMapping = {
    [RDFS.DataTypes.boolean.value]: {
        type: 'boolean',
    },
    [RDFS.DataTypes.date.value]: {
        type: 'string',
        format: 'date',
    },
    [RDFS.DataTypes.dateTime.value]: {
        type: 'string',
        format: 'date-time',
    },
    [RDFS.DataTypes.float.value]: {
        type: 'number',
        format: RDFS.DataTypes.float.value,
    },
    [RDFS.DataTypes.integer.value]: {
        type: 'integer',
    },
    [RDFS.DataTypes.number.value]: {
        type: 'number',
        format: 'date',
    },
    [RDFS.DataTypes.number.value]: {
        type: 'number',
    },
    [RDFS.DataTypes.text.value]: {
        type: 'string',
    },
    [RDFS.DataTypes.time.value]: {
        type: 'string',
        format: 'time',
    },
    [RDFS.DataTypes.url.value]: {
        type: 'string',
        format: 'uri',
    },
    [RDFS.DataTypes.dataUrl.value]: {
        type: 'string',
        format: 'data-url',
    },
    [RDFS.DataTypes.quantity.value]: {
        type: 'string',
    },
    [RDFS.DataTypes.occupationalCategory.value]: {
        type: 'string',
        format: 'http://www.onetcenter.org/taxonomy.html',
    },
    [RDFS.DataTypes.repetitions.value]: {
        type: 'integer',
        minimum: 1,
    },
    [RDFS.DataTypes.startDate.value]: {
        type: 'string',
        format: 'date-time',
    },
    [RDFS.DataTypes.endDate.value]: {
        type: 'string',
        format: 'date-time',
    },
    [RDFS.DataTypes.undefined.value]: {
        $id: RDFS.DataTypes.undefined.value,
        type: 'null',
    },
    [RDFS.owlThing.value]: {
        type: 'string',
        format: 'uri',
    },
};
