import { List, Text } from '@fluentui/react';
import { FieldTemplateProps } from '@rjsf/core';
import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { PathContext } from '@cpa/base-core/constants';

import styles from './FieldTemplate.module.scss';
import _ from 'lodash';

export const FieldTemplateContext = React.createContext<{ required: boolean } | null>(null);

const FieldTemplate = ({ required, id, children, rawErrors = [], rawHelp, classNames: clNames }: FieldTemplateProps): JSX.Element => {
  const dark = useSelector((state: IGlobalState) => state.settings.darkMode);
  const classes = useMemo(() => {
    return classNames(...clNames.replace('form-group', '').split(' '), styles.block, { [styles.blockDark]: dark });
  }, [dark, clNames]);
  const pathContext = useContext(PathContext);

  // We need to pass 'required' down to SelectWidget and other places where 'required' prop is missing
  // https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/core/src/components/fields/SchemaField.tsx#L285
  // Originally title was located here in FieldTemplate, but we moved it on deeper levels
  const fieldContextValue = useMemo(() => ({ required }), [required]);

  return (
    <div className={classes} data-testid={`DataItemField__${_.toPath(pathContext).join('.')}`}>
      <FieldTemplateContext.Provider value={fieldContextValue}>{children}</FieldTemplateContext.Provider>
      {rawErrors.length > 0 && <List items={rawErrors} />}
      {rawHelp && <Text id={id}>{rawHelp}</Text>}
    </div>
  );
};

export default FieldTemplate;
