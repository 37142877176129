import classNames from 'classnames';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { Icon } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { push } from 'connected-react-router';

import SingleItemCoverCtaButton from '../SingleItemCoverCtaButton/SingleItemCoverCtaButton';
import HoverTooltip from '../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './SingleItemCoverCtaButtons.module.scss';

interface SingleItemCoverCtaButtonsProps {
  componentsDetailsItems: (Schemas.Solution | undefined)[];
  callToActions: Schemas.Solution['callToActions'];
  schemaData: IJSONSchema | undefined;
  page: Schemas.CpaPage;
  onScrollDown?: () => void;
  onShare?: () => void;
}

const SingleItemCoverCtaButtons: React.FC<SingleItemCoverCtaButtonsProps> = ({
  callToActions,
  schemaData,
  page,
  onScrollDown,
  onShare,
  componentsDetailsItems,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const callToActionsCount = useMemo(() => callToActions?.length || 0, [callToActions]);

  const [areComponentsShown, { toggle: toggleComponentsShown }] = useBoolean(false);

  const getSolutionPath = useCallback(
    (componentDetailsItem: Schemas.Solution | undefined) => {
      return componentDetailsItem?.identifier ? page?.path?.replace(':id', encodeURIComponent(componentDetailsItem.identifier)) : undefined;
    },
    [page]
  );

  const goToComponentDetails = useCallback(
    (e: MouseEvent, componentDetailsItem: Schemas.Solution | undefined) => {
      e.preventDefault();
      e.stopPropagation();

      const pageUrl: string | undefined = getSolutionPath(componentDetailsItem);

      if (pageUrl) {
        dispatch(push(pageUrl));
      }
    },
    [dispatch, getSolutionPath]
  );

  return (
    <div className={classNames(styles.ctaButtons)}>
      {!!componentsDetailsItems.length && (
        <div className={styles.button}>
          <SingleItemCoverCtaButton
            description={areComponentsShown ? t('common.hideComponentsInCover') : t('common.showComponentsInCover')}
            customImageComponent={<Icon iconName={'webcomponents'} style={{ fontSize: 43 }} />}
            onClick={toggleComponentsShown}
            buttonIndex={0}
          >
            {schemaData?.components?.title}
            <div className={styles.chevronContainer}>
              {!areComponentsShown && (
                <Icon
                  className={classNames({
                    [styles.chevron]: true,
                    [styles.chevronDark]: darkMode,
                  })}
                  iconName={'ChevronRightMed'}
                  style={{ fontSize: 16 }}
                />
              )}
              {areComponentsShown && (
                <Icon
                  className={classNames({
                    [styles.chevron]: true,
                    [styles.chevronDark]: darkMode,
                  })}
                  iconName={'ChevronLeftMed'}
                  style={{ fontSize: 16 }}
                />
              )}
            </div>
          </SingleItemCoverCtaButton>
        </div>
      )}

      {areComponentsShown &&
        componentsDetailsItems?.map((componentDetailsItem, index: number) => (
          <div className={styles.button} key={index}>
            <SingleItemCoverCtaButton
              description={componentDetailsItem?.shortDescription}
              url={getSolutionPath(componentDetailsItem)}
              onClick={(e) => goToComponentDetails(e, componentDetailsItem!)}
              image={darkMode ? componentDetailsItem?.image : componentDetailsItem?.imageOnLightBackground}
              buttonIndex={index}
              isComponent={true}
            >
              {componentDetailsItem?.name}
            </SingleItemCoverCtaButton>
          </div>
        ))}
      {callToActions
        ?.filter((callToAction) => !!callToAction)
        .map((callToAction, index: number) => (
          <div className={styles.button} key={index}>
            <SingleItemCoverCtaButton
              description={callToAction.description}
              target={new URL(callToAction.url ?? window.location.href).origin === window.location.origin ? '_self' : '_blank'}
              url={callToAction.url}
              image={(callToAction as any).image}
              buttonIndex={index}
            >
              {callToAction.name}
            </SingleItemCoverCtaButton>
          </div>
        ))}

      <div className={classNames(styles.button, styles.showMoreWrapper)}>
        <SingleItemCoverCtaButton className={styles.showMore} onClick={onScrollDown} useFullWidth={true} buttonIndex={callToActionsCount + 1}>
          {t('common.showMore')}
        </SingleItemCoverCtaButton>
        <HoverTooltip content={t('common.share')}>
          <SingleItemCoverCtaButton onClick={onShare} useFullWidth={true} buttonIndex={callToActionsCount + 2}>
            <Icon iconName={'Share'} style={{ fontSize: 18 }} />
          </SingleItemCoverCtaButton>
        </HoverTooltip>
      </div>
    </div>
  );
};

export default SingleItemCoverCtaButtons;
