const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/BusinessUnit',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'businessunits', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
        {
            'rel': 'related',
            'href': "customer/{{accountNo}}/entitlement?$filter=crmBusinessUnit%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Business Unit',
    'properties': {
        'culture': {
            'title': 'Culture',
            'description': 'A culture based on RFC 4646',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/culture', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Culture' } },
            'cp_ui': { 'sortOrderForm': 103 },
        },
        'crmBusinessUnit': {
            'title': 'Crm Business Unit',
            '$id': 'http://platform.cosmoconsult.com/ontology/BusinessUnit',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'businessunits', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/crmBusinessUnit', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'readOnly': true,
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 118 },
        },
        'dmsSpPlaceholder': {
            'title': 'Dms Sp Placeholder',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 119 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dmsSpPlaceholder', 'rel': 'canonical' }],
        },
        'erpCompanyName': {
            'title': 'Erp Company Name',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 120 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/erpCompanyName', 'rel': 'canonical' }],
        },
        'financeContacts': {
            'title': 'Finance Contacts',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 112 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/financeContacts', 'rel': 'canonical' }],
        },
        'generalManagers': {
            'title': 'General Managers',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/GeneralManager',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FGeneralManager' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'General Manager',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 108 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/generalManagers', 'rel': 'canonical' }],
        },
        'hrContacts': {
            'title': 'Hr Contacts',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 111 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/hrContacts', 'rel': 'canonical' }],
        },
        'itContacts': {
            'title': 'It Contacts',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 113 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/itContacts', 'rel': 'canonical' }],
        },
        'operationsManagers': {
            'title': 'Operations Managers',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 110 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/operationsManagers', 'rel': 'canonical' }],
        },
        'privacyCoordinators': {
            'title': 'Privacy Coordinators',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 114 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/privacyCoordinators', 'rel': 'canonical' }],
        },
        'salesManagers': {
            'title': 'Sales Managers',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 109 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/salesManagers', 'rel': 'canonical' }],
        },
        'jointControllership': {
            'title': 'Joint Controllership',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 115 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/jointControllership', 'rel': 'canonical' }],
        },
        'mycosmoResponsibles': {
            'title': 'MyCOSMO Responsibles',
            'type': 'array',
            'items': {
                '$id': 'http://schema.org/Person',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Person',
                'description': 'A person (alive, dead, undead, or fictional).',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 108 },
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 109 },
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 115 },
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 138 },
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 117 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/mycosmoResponsible', 'rel': 'canonical' }],
        },
        'dpaSignees': {
            'title': 'DPA Signees',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/DpaSignee',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDpaSignee' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'DPA Signee',
                'properties': {
                    'email': {
                        'title': 'Email',
                        'description': 'Email address.',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 104 },
                        'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                    },
                    'familyName': {
                        'title': 'Family Name',
                        'description': '<div>Last name, surname, or family name.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 81 },
                        'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
                    },
                    'givenName': {
                        'title': 'Given Name',
                        'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 80 },
                        'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 79 },
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 116 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dpaSignee', 'rel': 'canonical' }],
        },
        'address': {
            'title': 'Address',
            'description': 'The mailing address.',
            '$id': 'http://schema.org/PostalAddress',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPostalAddress' },
                { 'href': 'http://schema.org/address', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'addressCountry': {
                    'title': 'Address Country',
                    'description': 'The country. For example, USA. You can also provide the two-letter [ISO 3166-1 alpha-2 country code](http://en.wikipedia.org/wiki/ISO_3166-1).',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/addressCountry', 'rel': 'canonical' }],
                },
                'addressLocality': {
                    'title': 'Address Locality',
                    'description': 'The locality in which the street address is, and which is in the region. For example, Mountain View.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/addressLocality', 'rel': 'canonical' }],
                },
                'postalCode': {
                    'title': 'Postal Code',
                    'description': 'The postal code. For example, 94043.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/postalCode', 'rel': 'canonical' }],
                },
                'streetAddress': {
                    'title': 'Street Address',
                    'description': 'The street address. For example, 1600 Amphitheatre Pkwy.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/streetAddress', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 102 },
        },
        'email': {
            'title': 'Email',
            'description': 'Email address.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 106 },
            'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
        },
        'legalName': {
            'title': 'Legal Name',
            'description': 'The official name of the organization, e.g. the registered company name.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 101 },
            'links': [{ 'href': 'http://schema.org/legalName', 'rel': 'canonical' }],
        },
        'taxID': {
            'title': 'TaxID',
            'description': 'The Tax / Fiscal ID of the organization or person, e.g. the TIN in the US or the CIF/NIF in Spain.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 105 },
            'links': [{ 'href': 'http://schema.org/taxID', 'rel': 'canonical' }],
        },
        'telephone': {
            'title': 'Telephone',
            'description': '<div>The telephone number at which a company or person can be reached during business hours.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 107 },
            'links': [{ 'href': 'http://schema.org/telephone', 'rel': 'canonical' }],
        },
        'vatID': {
            'title': 'VatID',
            'description': 'The Value-added Tax ID of the organization or person.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 104 },
            'links': [{ 'href': 'http://schema.org/vatID', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 99 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 121 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 100 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'identifier', 'culture'],
    'cp_restrictablePaths': ['dmsSpPlaceholder', 'dpaSignees', 'erpCompanyName', 'mycosmoResponsibles'],
    'cp_hasPermissions': true,
};
export const BusinessUnitSchema = Schema;
