import { getAppBasePath, isValidMenuItem } from '@cpa/base-core/helpers';
import { IDarkMode } from '@cpa/base-core/types';
import { IMenuItem } from '@cpa/base-core/types';
import {
  CommandBar,
  CommandBarButton,
  IButtonProps,
  ICommandBarItemProps,
  ISearchBoxStyleProps,
  ISearchBoxStyles,
  IStyleFunctionOrObject,
  SearchBox,
  ThemeContext,
} from '@fluentui/react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { CSSProperties, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedValue, useIsCached } from '@cpa/base-core/hooks';
import urlJoin from 'url-join';
import { useTranslation } from 'react-i18next';

import { applyFilter } from '../VerticalMenu/VerticalMenu';

import styles from './HorizontalMenu.module.scss';

export interface IHorizontalMenuProps extends IDarkMode {
  menuItems: IMenuItem[];
  activePath: string;
  style?: CSSProperties;
  isSearchVisible?: boolean;
}

function menuItemsToCommandBarItems(
  items: IMenuItem[],
  onItemClick?: (item: IMenuItem) => void,
  addIcons: boolean = false,
  level: number = 0
): ICommandBarItemProps[] {
  return items.filter(isValidMenuItem).map((item, i) => {
    const childLinks = item.links && item.links.length ? menuItemsToCommandBarItems(item.links, onItemClick, true, level + 1) : [];

    const isCalculatedLink = item.url && item.url.startsWith('/_navigation/');

    return {
      key: item.key,
      text: item.name,
      split: isCalculatedLink ? false : !!childLinks.length && !!item.url,
      iconProps: addIcons ? { iconName: item.icon } : undefined,
      href: item.url && !item.external ? urlJoin(getAppBasePath(), item.url) : item.url,
      onClick: (e: React.MouseEvent<HTMLElement>): void => {
        // e.defaultPrevented = false is a workaround to close sub-menu on click
        // https://github.com/microsoft/fluentui/issues/13339

        if (isCalculatedLink) {
          e.preventDefault();
          return;
        }

        if (item.onClick) {
          e?.preventDefault();
          e.defaultPrevented = false;
          item.onClick(e);
        } else if (onItemClick) {
          e?.preventDefault();
          e.defaultPrevented = false;
          onItemClick(item);
        }
      },
      subMenuProps: childLinks.length
        ? {
            items: childLinks,
          }
        : undefined,
      style: {
        borderBottom: item.divider && level > 0 && i < items.length - 1 ? '1px solid #9EA2A2' : undefined,
      },
    };
  });
}

const CustomButton: React.FunctionComponent<IButtonProps> = (props) => {
  return (
    <div className={styles.buttonWrapper}>
      <div className={styles.clickableArea}>
        {/* <div className={styles.hoverMarker} /> */}
        <CommandBarButton {...props} />
        <div className={styles.hoverLine} />
      </div>
      <span className={styles.divider} />
    </div>
  );
};

const commandBarStyles = { root: { marginBottom: 0, padding: 0, backgroundColor: 'transparent' } };

const HorizontalMenu: React.FC<IHorizontalMenuProps> = ({ menuItems, style, isSearchVisible, darkMode }) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isCached = useIsCached();

  const onItemClick = useCallback(
    (item: IMenuItem) => {
      if (item.external) {
        window.open(item.url, '_blank');
        return;
      }

      if (item.url) {
        dispatch(push(item.url));
      }
    },
    [dispatch]
  );

  const onSearchChange = useCallback((_: unknown, v: string | undefined) => {
    setSearchValue(v || '');
  }, []);

  const commandBarItems: ICommandBarItemProps[] = useMemo(() => {
    let filteredItems = menuItems;
    if (debouncedSearchValue) {
      filteredItems = applyFilter(menuItems, debouncedSearchValue);
    }
    return menuItemsToCommandBarItems(filteredItems, onItemClick);
  }, [debouncedSearchValue, menuItems, onItemClick]);

  const searchBoxStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = useMemo(
    () => ({
      root: {
        width: 200,
        backgroundColor: 'none',
        margin: '0 8px',
        '&::after': {
          border: 'unset',
        },
      },
      icon: {
        color: darkMode ? theme?.palette.black : undefined,
      },
      field: {
        color: darkMode ? theme?.palette.black : undefined,
      },
    }),
    [darkMode, theme]
  );

  if (isCached) return null;

  return (
    <div className={classNames(styles.wrapper, { [styles.dark]: darkMode })} style={style}>
      {isSearchVisible && (
        <div className={styles.searchWrapper}>
          <SearchBox placeholder={t('menu.filter')} styles={searchBoxStyles} value={searchValue} onChange={onSearchChange} />
          <div className={styles.divider} />
        </div>
      )}
      <CommandBar className={styles.commandBar} items={commandBarItems} styles={commandBarStyles} buttonAs={CustomButton} />
    </div>
  );
};

export default HorizontalMenu;
