export const TypeConstants = {
    Annotation: 'http://platform.cosmoconsult.com/ontology/Annotation',
    AnyThing: 'http://platform.cosmoconsult.com/ontology/AnyThing',
    Attachment: 'http://platform.cosmoconsult.com/ontology/Attachment',
    AttachmentCategory: 'http://platform.cosmoconsult.com/ontology/AttachmentCategory',
    BatchOperation: 'http://platform.cosmoconsult.com/ontology/BatchOperation',
    BillingDuration: 'http://platform.cosmoconsult.com/ontology/BillingDuration',
    BusinessUnit: 'http://platform.cosmoconsult.com/ontology/BusinessUnit',
    ChangeLog: 'http://platform.cosmoconsult.com/ontology/ChangeLog',
    CometAchievement: 'http://platform.cosmoconsult.com/ontology/CometAchievement',
    CometAssociation: 'http://platform.cosmoconsult.com/ontology/CometAssociation',
    CometTransaction: 'http://platform.cosmoconsult.com/ontology/CometTransaction',
    Comment: 'http://platform.cosmoconsult.com/ontology/Comment',
    CompanyUser: 'http://platform.cosmoconsult.com/ontology/CompanyUser',
    ComplexTicket: 'http://platform.cosmoconsult.com/ontology/ComplexTicket',
    Component: 'http://platform.cosmoconsult.com/ontology/Component',
    Contact: 'http://platform.cosmoconsult.com/ontology/Contact',
    ContactPerson: 'http://platform.cosmoconsult.com/ontology/ContactPerson',
    Country: 'http://platform.cosmoconsult.com/ontology/Country',
    CpAggregationPipelineSequencePosition: 'http://platform.cosmoconsult.com/ontology/CpAggregationPipelineSequencePosition',
    CpAggregationTemplate: 'http://platform.cosmoconsult.com/ontology/CpAggregationTemplate',
    CpAnnotation: 'http://platform.cosmoconsult.com/ontology/CpAnnotation',
    CpAnnotationProperty: 'http://platform.cosmoconsult.com/ontology/CpAnnotationProperty',
    CpBackendJob: 'http://platform.cosmoconsult.com/ontology/CpBackendJob',
    CpClass: 'http://platform.cosmoconsult.com/ontology/CpClass',
    CpCulture: 'http://platform.cosmoconsult.com/ontology/CpCulture',
    CpFeedback: 'http://platform.cosmoconsult.com/ontology/CpFeedback',
    CpFieldTrigger: 'http://platform.cosmoconsult.com/ontology/CpFieldTrigger',
    CpFunction: 'http://platform.cosmoconsult.com/ontology/CpFunction',
    CpHtmlTemplate: 'http://platform.cosmoconsult.com/ontology/CpHtmlTemplate',
    CpIndividual: 'http://platform.cosmoconsult.com/ontology/CpIndividual',
    CpJobDefinition: 'http://platform.cosmoconsult.com/ontology/CpJobDefinition',
    CpJobSchedule: 'http://platform.cosmoconsult.com/ontology/CpJobSchedule',
    CpJobStatusLog: 'http://platform.cosmoconsult.com/ontology/CpJobStatusLog',
    CpLanguage: 'http://platform.cosmoconsult.com/ontology/CpLanguage',
    CpLlmPromptTemplate: 'http://platform.cosmoconsult.com/ontology/CpLlmPromptTemplate',
    CpMember: 'http://platform.cosmoconsult.com/ontology/CpMember',
    CpMessage: 'http://platform.cosmoconsult.com/ontology/CpMessage',
    CpMessageState: 'http://platform.cosmoconsult.com/ontology/CpMessageState',
    CpNamespace: 'http://platform.cosmoconsult.com/ontology/CpNamespace',
    CpNoSeries: 'http://platform.cosmoconsult.com/ontology/CpNoSeries',
    CpPermissionRoleSuggestion: 'http://platform.cosmoconsult.com/ontology/CpPermissionRoleSuggestion',
    CpProperty: 'http://platform.cosmoconsult.com/ontology/CpProperty',
    CpRelationRequest: 'http://platform.cosmoconsult.com/ontology/CpRelationRequest',
    CpShareLink: 'http://platform.cosmoconsult.com/ontology/CpShareLink',
    CpSharedItem: 'http://platform.cosmoconsult.com/ontology/CpSharedItem',
    CpTrash: 'http://platform.cosmoconsult.com/ontology/CpTrash',
    CpType: 'http://platform.cosmoconsult.com/ontology/CpType',
    CpTypeHotProperty: 'http://platform.cosmoconsult.com/ontology/CpTypeHotProperty',
    CpTypeHotPropertyBlackList: 'http://platform.cosmoconsult.com/ontology/CpTypeHotPropertyBlackList',
    CpTypeMapping: 'http://platform.cosmoconsult.com/ontology/CpTypeMapping',
    CpTypePermission: 'http://platform.cosmoconsult.com/ontology/CpTypePermission',
    CpTypePropertyFormat: 'http://platform.cosmoconsult.com/ontology/CpTypePropertyFormat',
    CpTypeTrigger: 'http://platform.cosmoconsult.com/ontology/CpTypeTrigger',
    CpTypeVersion: 'http://platform.cosmoconsult.com/ontology/CpTypeVersion',
    CpValueMapping: 'http://platform.cosmoconsult.com/ontology/CpValueMapping',
    Cpa: 'http://platform.cosmoconsult.com/ontology/Cpa',
    CpaCustomCardTemplate: 'http://platform.cosmoconsult.com/ontology/CpaCustomCardTemplate',
    CpaCustomRowTemplate: 'http://platform.cosmoconsult.com/ontology/CpaCustomRowTemplate',
    CpaCustomTemplate: 'http://platform.cosmoconsult.com/ontology/CpaCustomTemplate',
    CpaDataEndpoint: 'http://platform.cosmoconsult.com/ontology/CpaDataEndpoint',
    CpaFooter: 'http://platform.cosmoconsult.com/ontology/CpaFooter',
    CpaLink: 'http://platform.cosmoconsult.com/ontology/CpaLink',
    CpaPage: 'http://platform.cosmoconsult.com/ontology/CpaPage',
    CpaPageChartEntity: 'http://platform.cosmoconsult.com/ontology/CpaPageChartEntity',
    CpaSavedFilter: 'http://platform.cosmoconsult.com/ontology/CpaSavedFilter',
    CpaSingleItemTemplate: 'http://platform.cosmoconsult.com/ontology/CpaSingleItemTemplate',
    CpaUxPreferenceFeatureFlag: 'http://platform.cosmoconsult.com/ontology/CpaUxPreferenceFeatureFlag',
    CrmAccount: 'http://platform.cosmoconsult.com/ontology/CrmAccount',
    CrmTicket: 'http://platform.cosmoconsult.com/ontology/CrmTicket',
    Currency: 'http://platform.cosmoconsult.com/ontology/Currency',
    Customer: 'http://platform.cosmoconsult.com/ontology/Customer',
    DataFeedItem: 'http://platform.cosmoconsult.com/ontology/DataFeedItem',
    Deliverable: 'http://platform.cosmoconsult.com/ontology/Deliverable',
    DevOpsOrganization: 'http://platform.cosmoconsult.com/ontology/DevOpsOrganization',
    Document: 'http://platform.cosmoconsult.com/ontology/Document',
    Entitlement: 'http://platform.cosmoconsult.com/ontology/Entitlement',
    FileAttachment: 'http://platform.cosmoconsult.com/ontology/FileAttachment',
    Idea: 'http://platform.cosmoconsult.com/ontology/Idea',
    IdeaAiCamp: 'http://platform.cosmoconsult.com/ontology/IdeaAiCamp',
    Industry: 'http://platform.cosmoconsult.com/ontology/Industry',
    JobLocation: 'http://platform.cosmoconsult.com/ontology/JobLocation',
    JobPosting: 'http://platform.cosmoconsult.com/ontology/JobPosting',
    JobRole: 'http://platform.cosmoconsult.com/ontology/JobRole',
    Language: 'http://platform.cosmoconsult.com/ontology/Language',
    License: 'http://platform.cosmoconsult.com/ontology/License',
    MachineTranslationCache: 'http://platform.cosmoconsult.com/ontology/MachineTranslationCache',
    ManagedMarketplaceProductType: 'http://platform.cosmoconsult.com/ontology/ManagedMarketplaceProductType',
    MarketplaceAccount: 'http://platform.cosmoconsult.com/ontology/MarketplaceAccount',
    MarketplaceCategory: 'http://platform.cosmoconsult.com/ontology/MarketplaceCategory',
    MarketplaceProductGroup: 'http://platform.cosmoconsult.com/ontology/MarketplaceProductGroup',
    MarketplaceProductType: 'http://platform.cosmoconsult.com/ontology/MarketplaceProductType',
    ProductPlatform: 'http://platform.cosmoconsult.com/ontology/ProductPlatform',
    Project: 'http://platform.cosmoconsult.com/ontology/Project',
    QuotePreparation: 'http://platform.cosmoconsult.com/ontology/QuotePreparation',
    Rating: 'http://platform.cosmoconsult.com/ontology/Rating',
    RecruitingChannel: 'http://platform.cosmoconsult.com/ontology/RecruitingChannel',
    SalesInvoice: 'http://platform.cosmoconsult.com/ontology/SalesInvoice',
    SalesOpportunity: 'http://platform.cosmoconsult.com/ontology/SalesOpportunity',
    SalesOrder: 'http://platform.cosmoconsult.com/ontology/SalesOrder',
    SalesQuote: 'http://platform.cosmoconsult.com/ontology/SalesQuote',
    SearchResult: 'http://platform.cosmoconsult.com/ontology/SearchResult',
    SoftwareApplication: 'http://schema.org/SoftwareApplication',
    Solution: 'http://platform.cosmoconsult.com/ontology/Solution',
    SolutionCalculationPhase: 'http://platform.cosmoconsult.com/ontology/SolutionCalculationPhase',
    SolutionType: 'http://platform.cosmoconsult.com/ontology/SolutionType',
    SolutionVariableKey: 'http://platform.cosmoconsult.com/ontology/SolutionVariableKey',
    Subscription: 'http://platform.cosmoconsult.com/ontology/Subscription',
    Tag: 'http://platform.cosmoconsult.com/ontology/Tag',
    TestPerson: 'http://platform.cosmoconsult.com/ontology/playwright/TestPerson',
    TestProduct: 'http://platform.cosmoconsult.com/ontology/playwright/TestProduct',
    Thing: 'http://schema.org/Thing',
    Ticket: 'http://platform.cosmoconsult.com/ontology/Ticket',
    TicketComment: 'http://platform.cosmoconsult.com/ontology/TicketComment',
    UnitOfMeasure: 'http://platform.cosmoconsult.com/ontology/UnitOfMeasure',
    User: 'http://platform.cosmoconsult.com/ontology/User',
    UserProfile: 'http://platform.cosmoconsult.com/ontology/UserProfile',
    UserProfileShadow: 'http://platform.cosmoconsult.com/ontology/UserProfileShadow',
    UserStory: 'http://platform.cosmoconsult.com/ontology/UserStory',
    UserStoryDevOpsOrganization: 'http://platform.cosmoconsult.com/ontology/UserStoryDevOpsOrganization',
    Vendor: 'http://platform.cosmoconsult.com/ontology/Vendor',
    Voting: 'http://platform.cosmoconsult.com/ontology/Voting',
    WebHook: 'http://platform.cosmoconsult.com/ontology/WebHook',
};
