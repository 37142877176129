const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/UnitOfMeasure',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUnitOfMeasure' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUnitOfMeasure?$filter=baseUnit%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Unit Of Measure',
    'description': 'Unit of measure using the UN/CEFACT Common Code',
    'properties': {
        'baseUnit': {
            'title': 'Base Unit',
            'description': 'Unit of measure using the UN/CEFACT Common Code',
            '$id': 'http://platform.cosmoconsult.com/ontology/UnitOfMeasure',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUnitOfMeasure' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/baseUnit', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Unit Of Measure' } },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'baseUnitConversionFactor': {
            'title': 'Base Unit Conversion Factor',
            'description': '<div>The factor by which to multiply to convert to base unit.</div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/baseUnitConversionFactor', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'alternateName': {
            'title': 'Alternate Name',
            'description': 'An alias for the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 34 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 32 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['baseUnitConversionFactor', 'name', 'baseUnit'],
};
export const UnitOfMeasureSchema = Schema;
