import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IJSONSchema, TypeConstants } from '@cp/base-types';
import { ObjectFieldTemplateProps, Registry } from '@rjsf/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { JsonObj } from '@cp/base-types';
import { ItemInfoContext, PathContext } from '@cpa/base-core/constants';
import { isRelationSchema } from '@cp/base-utils';

import ExpandButton from '../../../ExpandButton/ExpandButton';
import DescriptionField from '../DescriptionField/DescriptionField';
import { compareSchemaSortOrder } from '../../helpers/data';
import FieldTitleWithPreview from '../FieldTitleWithPreview/FieldTitleWithPreview';

import styles from './ObjectFieldTemplate.module.scss';
import RootDescription from './components/RootDescription/RootDescription';
import Gauge from './components/Chart/Gauge/Gauge';

export const ParentObjectContext = React.createContext<{
  parentSchema: IJSONSchema;
  parentValue: JsonObj;
} | null>(null);

const ObjectFieldTemplate = ({
  title,
  properties,
  required,
  uiSchema,
  idSchema,
  schema,
  formData,
  registry,
}: ObjectFieldTemplateProps & {
  registry: Registry & { rootSchema?: IJSONSchema };
}): JSX.Element | null => {
  const pathContextValue = useContext(PathContext);
  const itemInfoContext = useContext(ItemInfoContext);
  const expertMode = useSelector((state: IGlobalState) => state.settings.expertMode);

  const sortedProperties = useMemo(() => {
    return properties
      .filter((property) => {
        // Hidden in form
        if (property?.content?.props?.schema?.cp_ui?.hiddenInForm === true) {
          return false;
        }

        // Hidden in 'expert' form mode
        if (!expertMode && property?.content?.props?.schema?.expertMode === true) {
          return false;
        }

        // Hidden if create
        if (itemInfoContext?.type === 'add' && property?.content?.props?.schema?.cp_ui?.hiddenIfCreate === true) {
          return false;
        }

        // Hidden if update
        if (itemInfoContext?.type === 'edit' && property?.content?.props?.schema?.cp_ui?.hiddenIfUpdate === true) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        return compareSchemaSortOrder(a?.content?.props?.schema, b?.content?.props?.schema);
      });
  }, [properties, expertMode, itemInfoContext?.type]);

  const isRootEntity = idSchema.$id === 'root';

  const isRelatedSchema = useMemo(() => {
    return !isRootEntity && isRelationSchema(schema as IJSONSchema);
  }, [isRootEntity, schema]);

  const showExpandButton = !uiSchema.hideExpand && !isRelatedSchema && !isRootEntity;

  const [isExpanded, setIsExpanded] = useState(uiSchema.defaultExpanded ?? false);

  const toggleExpanded = useCallback(() => {
    uiSchema.onExpand?.(!isExpanded);
    setIsExpanded(!isExpanded);
  }, [isExpanded, uiSchema]);

  const parentObjectContextValue = useMemo(
    () => ({
      parentSchema: schema as IJSONSchema,
      parentValue: formData,
    }),
    [schema, formData]
  );

  if (!sortedProperties.length) {
    return null;
  }

  if (schema.format === 'chart:GaugeChart') {
    return <Gauge registry={registry} label={uiSchema['ui:title'] || title} schema={schema as IJSONSchema} formData={formData} />;
  }

  return (
    <>
      <div
        className={classNames({
          [styles.titleWrapper]: showExpandButton,
          [styles.multiline]: showExpandButton && isExpanded,
        })}
      >
        <div className={classNames(styles.labelWrapper, { [styles.arrayItem]: uiSchema.isArrayItem })}>
          {showExpandButton && <ExpandButton isExpanded={isExpanded} onClick={toggleExpanded} />}
          {(!uiSchema.hideTitle || isRelatedSchema) && (
            <FieldTitleWithPreview
              baseLabel={uiSchema['ui:title'] || title}
              schema={schema as IJSONSchema}
              formData={formData}
              registry={registry}
              fallbackPreview={schema.$id === TypeConstants.Component ? formData?.solution?.name : undefined}
              isArrayItem={uiSchema.isArrayItem}
              showExpandButton={showExpandButton}
              onClick={toggleExpanded}
              required={required}
              fieldId={idSchema.$id}
              disableTitlePreview={isRootEntity}
            />
          )}
        </div>
        {isRootEntity && schema.description ? (
          <RootDescription description={schema.description} />
        ) : (
          <DescriptionField description={schema.description} detailed={!showExpandButton ? true : isExpanded} hasDetails={showExpandButton} />
        )}
      </div>
      {(isExpanded || !showExpandButton) && (
        <ParentObjectContext.Provider value={parentObjectContextValue}>
          <div className="ms-Grid" dir="ltr" style={{ marginLeft: isRelatedSchema || !showExpandButton ? 0 : 32 }}>
            <div className={classNames('ms-Grid-row', { [styles.row]: idSchema.$id !== 'root' && isRelatedSchema })}>
              {sortedProperties.map((element) => (
                <PathContext.Provider value={pathContextValue + `['${element.name}']`} key={element.name}>
                  {element.content}
                </PathContext.Provider>
              ))}
            </div>
          </div>
        </ParentObjectContext.Provider>
      )}
    </>
  );
};

export default ObjectFieldTemplate;
