import { FieldTrigger, IJSONSchema, IPathMeta, IRelatedLink, JSONSchemaTypes, OnLookupExecutionContext, Schemas, TriggerType } from '@cp/base-types';
import { cloneDeepWithMetadata, getIntermediateAnyOfSchema, isVirtualPropertyKey } from '@cp/base-utils';
import {
  IContextualMenuItem,
  IContextualMenuStyleProps,
  IContextualMenuStyles,
  IStyleFunctionOrObject,
  MessageBarType,
  Theme,
} from '@fluentui/react';
import Form, { ErrorSchema, Registry } from '@rjsf/core';
import * as _ from 'lodash';

import { IDataItem, IDataUrlDetails, IFieldInSchema } from '../../types';
import { findFieldInSchema } from '../schema';

import { executeUiTriggers, resolveUiTriggersCode } from './triggers';

// ! Mutating
// Items with expanded data must be cleaned before passing to form
// otherwise we'll get 'invalid additional property' error
export const clearVirtualProperties = <T extends Record<string | number, unknown>>(obj: T): T => {
  if (obj && typeof obj === 'object') {
    const isExpanded: boolean = '__expanded' in obj && obj['__expanded'] === true;
    for (const key of Object.keys(obj)) {
      if (key === 'identifier') {
        continue;
      }

      if (isVirtualPropertyKey(key)) {
        _.unset(obj, key);
        continue;
      }

      const value = obj[key];
      if (typeof value === 'object' && value) {
        clearVirtualProperties(value as Record<string, unknown>);
      } else if (isExpanded) {
        _.unset(obj, key);
      }
    }
  }
  return obj;
};

export function generatePropertiesContextMenuStyles(theme?: Theme): IStyleFunctionOrObject<IContextualMenuStyleProps, IContextualMenuStyles> {
  return {
    subComponentStyles: {
      callout: {
        root: {
          boxShadow: `${theme?.palette.black} 0 0 3px 0`,
        },
      },
    },
  };
}

export const listPropertiesAsContextMenu = (
  schemaPaths: IPathMeta[],
  level: number = 1,
  onSelect: (property: string, type: JSONSchemaTypes) => void,
  isDisabled: (property: string) => boolean,
  theme?: Theme
): IContextualMenuItem[] => {
  const grouped = _.groupBy(schemaPaths, (schemaPath) => {
    if (!schemaPath.items[level]) {
      return;
    }
    return schemaPath.items[level].property || schemaPath.items[level].title;
  });
  return Object.entries(grouped)
    .map(([groupKey, group]): IContextualMenuItem => {
      const descriptor: IPathMeta = group[0];
      if (group.length === 1 && (descriptor.items.length <= level + 1 || descriptor.items.some((p) => p?.shortcutOption))) {
        const property = descriptor.items
          .map((p) => p.property!)
          .filter(Boolean)
          .join('.');
        return {
          key: groupKey,
          text: descriptor.items[level]?.title,
          onClick: (): void => onSelect(property, descriptor.type as JSONSchemaTypes),
          disabled: isDisabled(property),
        };
      } else {
        return {
          key: groupKey,
          text: descriptor.items[level]?.title,
          subMenuProps: {
            items: listPropertiesAsContextMenu(group, level + 1, onSelect, isDisabled, theme),
            styles: generatePropertiesContextMenuStyles(theme),
          },
        };
      }
    })
    .filter((descriptor) => !!descriptor.text && descriptor.text !== 'undefined')
    .sort((a, b) => a.text!.localeCompare(b.text!));
};

export const getFirstLevelProperties = (schema: IJSONSchema): string[] => {
  const properties = new Set<string>(Object.keys(schema.properties || {}));

  if (schema.dependencies && typeof schema.dependencies === 'object') {
    for (const dependencyValue of Object.values(schema.dependencies)) {
      if (dependencyValue && typeof dependencyValue === 'object') {
        const result: IFieldInSchema = { value: null };
        findFieldInSchema(dependencyValue, 'properties', result, true);
        if (!result.value || !Array.isArray(result.value) || !result.value.length) {
          continue;
        }
        result.value.forEach((item) => Object.keys(item).forEach((key) => properties.add(key)));
      }
    }
  }

  return Array.from(properties.values());
};

export const cleanUpInternalProperties = (item: IDataItem, schema: IJSONSchema): void => {
  const patternRegexps = Object.keys(schema.patternProperties ?? {}).map((p) => new RegExp(p));
  const firstLevelProperties: string[] = getFirstLevelProperties(schema);

  if (!item || typeof item !== 'object') {
    console.warn(`Invalid item to clean up internal properties`, item, schema);
    return;
  }

  for (const key of Object.keys(item)) {
    // We handle INTERNAL properties by allowing them with JSON Schema pattern properties.
    // We have to remove INTERNAL properties from UI in general.
    // But (as a rule):
    // 1. INTERNAL properties ('pattern properties') gets only removed from UI if they do not appear in 'properties'!
    //    Background: Possibility to get them up to UI. If so, property should be read-only.

    // About JSON Schema pattern properties:
    // http://json-schema.org/understanding-json-schema/reference/object.html#pattern-properties

    const keyIsRealProp = firstLevelProperties.includes(key);
    const keyIsPatternPropAndNotInSchema = patternRegexps.some((regexp) => regexp.test(key)) && !keyIsRealProp;

    if (keyIsPatternPropAndNotInSchema) {
      delete item[key];
    }
  }
};

export const cloneValueAndCleanUpInternalProperties = <T extends IDataItem | IDataItem[]>(value: T, schema: IJSONSchema): T => {
  let valueWithoutInternalProperties: T;
  if (Array.isArray(value)) {
    valueWithoutInternalProperties = value.map((arrayItem: IDataItem) => {
      const clonedArrayItem = cloneDeepWithMetadata(arrayItem);
      if (clonedArrayItem && typeof clonedArrayItem === 'object') {
        cleanUpInternalProperties(clonedArrayItem, schema);
      }
      return clonedArrayItem;
    }) as T;
  } else {
    valueWithoutInternalProperties = cloneDeepWithMetadata(value);
    cleanUpInternalProperties(valueWithoutInternalProperties as IDataItem, schema);
  }

  return valueWithoutInternalProperties;
};

export const getMatchingEnum = (schema: IJSONSchema, propertyKey?: string, propertyValue?: string): string | null => {
  if (!propertyValue) return null;
  const property = propertyKey ? schema.properties?.[propertyKey] : schema;
  const matchedEnumIndex = property?.enum?.findIndex((enumValue) => enumValue === propertyValue);
  if (matchedEnumIndex != null && matchedEnumIndex !== -1) {
    return (property as IJSONSchema)?.enumNames?.[matchedEnumIndex];
  } else return null;
};

interface IVirtualData {
  schema: IJSONSchema;
  items: IDataItem[];
  totalItems: number;
}

export const getDataUrlForLookup = async (
  lookupLink: IRelatedLink,
  parentSchema: IJSONSchema | undefined,
  fieldSchema: IJSONSchema,
  pathContextValue: string,
  registry: Registry & {
    rootSchema?: IJSONSchema;
    rootFormData?: IDataItem;
    formRef?: Form<unknown>;
  },
  id?: string,
  type?: 'add' | 'edit',
  page?: Schemas.CpaPage,
  showMessage?: (text: string, type?: MessageBarType) => void,
  setVirtualData?: (data: IVirtualData | undefined) => void
): Promise<IDataUrlDetails | null> => {
  const clonedLink = cloneDeepWithMetadata(lookupLink);

  const intermediateAnyOfSchema = parentSchema ? getIntermediateAnyOfSchema(pathContextValue, parentSchema, fieldSchema) : undefined;
  const triggers =
    parentSchema?.cp_fieldTriggers?.[FieldTrigger.OnLookup] ||
    fieldSchema?.cp_fieldTriggers?.[FieldTrigger.OnLookup] ||
    intermediateAnyOfSchema?.cp_fieldTriggers?.[FieldTrigger.OnLookup];
  const fieldName = id && (id.startsWith('root_') ? id.slice(5) : id).split('_').slice(-2, -1)[0];
  let disabledParenting: boolean = false;
  let disabledRequest: boolean = false;
  if (Array.isArray(triggers) && triggers.length) {
    await executeUiTriggers<OnLookupExecutionContext>(
      {
        event: FieldTrigger.OnLookup,
        showMessage: showMessage,
        lookupLink: clonedLink,
        fieldSchema: fieldSchema,
        fieldPath: pathContextValue,
        fieldName: fieldName,
        schema: registry?.rootSchema,
        page: page,
        rootFormData: registry?.rootFormData,
        isCreate: type === 'add',
        isUpdate: type === 'edit',
        disableParenting() {
          disabledParenting = true;
        },
        disableRequest() {
          disabledRequest = true;
        },
        setVirtualData(newVirtualData: IVirtualData) {
          disabledRequest = true;
          setVirtualData?.(newVirtualData);
        },
        get formData(): object {
          return _.cloneDeep((registry?.formRef?.state as { formData?: {} })?.formData || {});
        },
        setFormData: (formData: object) => {
          if (!registry?.formRef?.setState) {
            console.warn('Failed to change form data from trigger, missing setState', { registry });
            return;
          }
          registry.formRef.setState({
            formData,
          });
          registry.formRef.onChange(
            formData,
            (
              registry.formRef.state as {
                errorSchema: ErrorSchema;
              }
            ).errorSchema || {}
          );
        },
      },
      await resolveUiTriggersCode(triggers, TriggerType.Field)
    );
  }

  if (disabledRequest) {
    return null;
  }

  setVirtualData?.(undefined);
  const url = new URL(clonedLink.href, window.location.origin);
  return { url: url.pathname + url.search, disableParenting: disabledParenting };
};
