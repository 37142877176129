const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Document',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'documents', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Document',
    'properties': {
        'customer': {
            'title': 'Customer',
            'description': '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Customer',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'customer/all', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://schema.org/customer', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Customer' } },
            'cp_ui': { 'sortOrderForm': 141 },
        },
        'culture': {
            'title': 'Culture',
            'description': 'A culture based on RFC 4646',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/culture', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Culture' } },
            'cp_ui': { 'sortOrderForm': 143 },
        },
        'documentUrl': {
            'title': 'Document Url',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'downloadable': true, 'sortOrderForm': 148 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/documentUrl', 'rel': 'canonical' }],
        },
        'contentPreview': {
            'title': 'Content Preview',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 138 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/contentPreview', 'rel': 'canonical' }],
        },
        'documentType': {
            'title': 'Document Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/DocumentType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDocumentType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/documentType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Factsheet',
                'http://platform.cosmoconsult.com/ontology/Brochure',
                'http://platform.cosmoconsult.com/ontology/Whitepaper',
                'http://platform.cosmoconsult.com/ontology/Reference',
                'http://platform.cosmoconsult.com/ontology/Presentation',
                'http://platform.cosmoconsult.com/ontology/Battlecard',
                'http://platform.cosmoconsult.com/ontology/Video',
                'http://platform.cosmoconsult.com/ontology/Image',
                'http://platform.cosmoconsult.com/ontology/Blog',
                'http://platform.cosmoconsult.com/ontology/PublicRelation',
                'http://platform.cosmoconsult.com/ontology/Analysis',
                'http://platform.cosmoconsult.com/ontology/Survey',
                'http://platform.cosmoconsult.com/ontology/Checklist',
                'http://platform.cosmoconsult.com/ontology/COSMOverse',
                'http://platform.cosmoconsult.com/ontology/Story',
                'http://platform.cosmoconsult.com/ontology/COSMOCODE',
                'http://platform.cosmoconsult.com/ontology/Brand',
                'http://platform.cosmoconsult.com/ontology/Template',
                'http://platform.cosmoconsult.com/ontology/PoliciesAndTerms',
                'http://platform.cosmoconsult.com/ontology/SalesAndMarketing',
                'http://platform.cosmoconsult.com/ontology/BestPractice',
                'http://platform.cosmoconsult.com/ontology/ConfigurationAndData',
                'http://platform.cosmoconsult.com/ontology/Documentation',
                'http://platform.cosmoconsult.com/ontology/Application',
            ],
            'enumNames': [
                'Factsheet',
                'Brochure',
                'Whitepaper',
                'Reference',
                'Presentation',
                'Battlecard',
                'Video',
                'Image',
                'Blog',
                'Public Relation',
                'Analysis',
                'Survey',
                'Checklist',
                'COSM Overse',
                'Story',
                'COSMO CODE',
                'Brand',
                'Template',
                'Policies & Terms',
                'Sales & Marketing',
                'Best Practice',
                'Configuration & Data',
                'Documentation',
                'Application',
            ],
            'cp_ui': { 'sortOrderForm': 139 },
        },
        'dateModified': {
            'title': 'Date Modified',
            'description': "The date on which the CreativeWork was most recently modified or when the item's entry was modified within a DataFeed.",
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 145 },
            'links': [{ 'href': 'http://schema.org/dateModified', 'rel': 'canonical' }],
        },
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 144 },
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'version': {
            'title': 'Version',
            'description': 'The version of the CreativeWork embodied by a specified resource.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 142 },
            'links': [{ 'href': 'http://schema.org/version', 'rel': 'canonical' }],
        },
        'contentLocation': {
            'title': 'Content Location',
            'description': 'Entities that have a somewhat fixed, physical extension.',
            '$id': 'http://schema.org/Place',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPlace' },
                { 'href': 'http://schema.org/contentLocation', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'image': {
                    'title': 'Image',
                    'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
                    'type': 'string',
                    'format': 'uri',
                    'cp_ui': { 'sortOrderForm': 86 },
                    'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
                },
                'url': {
                    'title': 'Url',
                    'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                    'type': 'string',
                    'format': 'uri',
                    'cp_ui': { 'sortOrderForm': 56 },
                    'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 146 },
        },
        'about': {
            'title': 'About',
            'description': 'The most generic type of item.',
            '$id': 'http://schema.org/Thing',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FThing' },
                { 'href': 'http://schema.org/about', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 140 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 134 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'alternateName': {
            'title': 'Alternate Name',
            'description': 'An alias for the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 136 },
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 137 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 147 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 135 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const DocumentSchema = Schema;
