const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FVoting?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory?$filter=predecessors%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory?$filter=successors%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory?$filter=parentUserStory%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'User Story',
    'properties': {
        'priority': {
            'title': 'Priority',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/priority', 'rel': 'canonical' }],
        },
        'jobRoles': {
            'title': 'Job Roles',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/JobRole',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FJobRole' }],
                'cp_parentPropertyJsonPath': 'parentJobRole.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Job Role',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Job Role' } },
            },
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/jobRole', 'rel': 'canonical' }],
        },
        'workItemId': {
            'title': 'Work Item Id',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 49 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/loop/workItemId', 'rel': 'canonical' }],
        },
        'predecessors': {
            'title': 'Predecessors',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' }],
                'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'User Story',
                'properties': { 'identifier': { 'type': 'string', 'title': 'User Story' } },
            },
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/predecessor', 'rel': 'canonical' }],
        },
        'successors': {
            'title': 'Successors',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' }],
                'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'User Story',
                'properties': { 'identifier': { 'type': 'string', 'title': 'User Story' } },
            },
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/successor', 'rel': 'canonical' }],
        },
        'parentUserStory': {
            'title': 'Parent User Story',
            '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentUserStory', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'User Story' } },
            'cp_ui': { 'sortOrderForm': 37 },
        },
        'userStoryType': {
            'title': 'User Story Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/UserStoryType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStoryType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/userStoryType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': ['http://platform.cosmoconsult.com/ontology/Epic', 'http://platform.cosmoconsult.com/ontology/Feature'],
            'enumNames': ['Epic', 'Feature'],
            'cp_ui': { 'sortOrderForm': 38 },
        },
        'acceptanceCriteria': {
            'title': 'Acceptance Criteria',
            'type': 'array',
            'items': { 'type': 'string' },
            'cp_ui': { 'sortOrderForm': 45 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/acceptanceCriteria', 'rel': 'canonical' }],
        },
        'devOpsOrganization': {
            'title': 'Dev Ops Organization',
            '$id': 'http://platform.cosmoconsult.com/ontology/UserStoryDevOpsOrganization',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStoryDevOpsOrganization' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/devOpsOrganization', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'User Story Dev Ops Organization' } },
            'cp_ui': { 'sortOrderForm': 48 },
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CompanyUser?allData=true',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCompanyUser%3FallData%3Dtrue' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Company User',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Company User' } },
                'format': 'email',
                'transform': ['toLowerCase', 'trim'],
            },
            'cp_ui': { 'sortOrderForm': 46 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 51 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 52 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 53 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'sortOrderForm': 47 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const UserStorySchema = Schema;
