import * as _ from 'lodash';
import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatEntries, ODataPropsFilter, resolveSubjectUri } from '@cp/base-utils';
import { DataOperation, IJSONSchema, Schemas } from '@cp/base-types';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';

import { IGlobalState } from '../store';
import { IDataItem, LoadItemsFunction } from '../types';
import { axiosDictionary, executeAggregationTemplate } from '../api';

export const useDebouncedValue = <T>(value: T, delay: number): [T, Dispatch<T>] => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, setDebouncedValue];
};

export const useTempFlag = (delay: number): React.MutableRefObject<boolean> => {
  const ref = useRef<boolean>(false);

  const resetValue = useCallback(
    _.debounce(() => {
      ref.current = false;
    }, delay),
    []
  );

  return useMemo(
    () => ({
      get current() {
        return ref.current;
      },
      set current(value) {
        ref.current = value;
        if (value) {
          resetValue();
        }
      },
    }),
    [resetValue]
  );
};

export const useUrlForSubscription = (cpTypeUrl?: string): string | null => {
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);

  return useMemo(() => {
    if (!cpTypeUrl) {
      return null;
    }

    const resolvedSubjectUri = resolveSubjectUri(cpTypeUrl, prefixMap);

    try {
      const parsedUrl = new URL(resolvedSubjectUri);
      parsedUrl.searchParams.forEach((value, key) => {
        // Remove all query params except cp_collection and cp_view
        if (key === 'cp_collection') {
          return;
        }
        parsedUrl.searchParams.delete(key);
      });
      return parsedUrl.href;
    } catch (e) {}

    return resolvedSubjectUri;
  }, [cpTypeUrl, prefixMap]);
};

export function useItemModificationSubscription(
  cpTypeUrl: string | undefined,
  identifier: string | undefined,
  timestamp: number | undefined
): {
  refreshSuggestionVisible: boolean;
  setRefreshSuggestionVisible: Dispatch<boolean>;
} {
  const latestChanges = useSelector((state: IGlobalState) => state.websocket.latestChanges);
  const urlForSubscription: string | null = useUrlForSubscription(cpTypeUrl);
  const relatedToItemLatestChange = useMemo(() => {
    if (!urlForSubscription || !latestChanges[urlForSubscription] || !identifier) {
      return undefined;
    }

    const relatedTimestamps = latestChanges[urlForSubscription].changes
      .filter((change) => change.affectedItems.some((affectedItem) => affectedItem.identifier === identifier))
      .map(({ timestamp }) => timestamp);

    if (!relatedTimestamps.length) {
      return undefined;
    }

    return Math.max(...relatedTimestamps);
  }, [urlForSubscription, latestChanges, identifier]);

  const [refreshSuggestionVisible, setRefreshSuggestionVisible] = useState(false);
  useEffect(() => {
    if (!relatedToItemLatestChange || !timestamp) {
      return;
    }

    setRefreshSuggestionVisible(relatedToItemLatestChange - timestamp > 0);
  }, [timestamp, relatedToItemLatestChange]);

  useEffect(() => {
    // Reset message if identifier changes
    setRefreshSuggestionVisible(false);
  }, [identifier]);

  return { refreshSuggestionVisible, setRefreshSuggestionVisible };
}

export function useLiveAboutUpdates(
  subscriptionCpTypeUrl: string,
  identifier: string | null,
  cpTypeUrl?: string,
  onUpdate?: () => void,
  trackMainEntity?: boolean
): void {
  const latestFetchingTimestamp = useRef(Date.now() - 4000);
  const latestChanges = useSelector((state: IGlobalState) => state.websocket.latestChanges);
  const urlForSubscription: string | null = useUrlForSubscription(subscriptionCpTypeUrl);

  const relatedToItemLatestChange = useMemo(() => {
    if (!urlForSubscription || !latestChanges[urlForSubscription] || !cpTypeUrl) {
      return undefined;
    }

    const relatedTimestamps = latestChanges[urlForSubscription].changes
      .filter((change) =>
        change.affectedItems.some((affectedItem) => {
          const aboutProperty = affectedItem.about as IDataItem<unknown> | undefined;
          if (!aboutProperty) {
            return false;
          }
          const typeMatched = aboutProperty['_type'] === cpTypeUrl;
          const identifierMatched = identifier ? aboutProperty['identifier'] === identifier : true;
          return typeMatched && identifierMatched;
        })
      )
      .map(({ timestamp }) => timestamp);

    if (!relatedTimestamps.length) {
      return undefined;
    }

    return Math.max(...relatedTimestamps);
  }, [urlForSubscription, latestChanges, identifier, cpTypeUrl]);

  const mainEntityChange = useMemo(() => {
    if (!trackMainEntity || !cpTypeUrl || !latestChanges[cpTypeUrl]) {
      return undefined;
    }

    const relatedTimestamps = latestChanges[cpTypeUrl].changes.map(({ timestamp }) => timestamp);

    if (!relatedTimestamps.length) {
      return undefined;
    }

    return Math.max(...relatedTimestamps);
  }, [trackMainEntity, cpTypeUrl, latestChanges]);

  useEffect(() => {
    if (!(relatedToItemLatestChange || mainEntityChange) || !latestFetchingTimestamp) {
      return;
    }

    const maxUpdateTimestamp = Math.max(relatedToItemLatestChange || 0, mainEntityChange || 0);

    if (maxUpdateTimestamp - latestFetchingTimestamp.current > 0) {
      latestFetchingTimestamp.current = maxUpdateTimestamp;
      onUpdate?.();
    }
  }, [mainEntityChange, onUpdate, relatedToItemLatestChange]);
}

export function useAboutValue(
  subjectUri: string,
  itemIdentifier: string | null,
  aggregationTemplateIdentifier: string,
  responseValueKey: string,
  itemCpTypeUrl?: string,
  additionalVariables: { [key: string]: string | undefined } = {},
  disabled?: boolean,
  relatedCpTypeUrl?: string,
  trackMainEntity?: boolean
): [number | null, IDataItem | null, IDataItem[] | null] {
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const [value, setValue] = useState<number | null>(null);
  const [originalValue, setOriginalValue] = useState<IDataItem | null>(null);
  const [response, setResponse] = useState<IDataItem[] | null>(null);

  const loadData = useCallback(async () => {
    if (!disabled) {
      cancelToken.current?.cancel();
      cancelToken.current = createCancelToken();
      const response = await executeAggregationTemplate(
        axiosDictionary.appDataService,
        subjectUri,
        aggregationTemplateIdentifier,
        {
          identifier: itemIdentifier,
          subject: itemCpTypeUrl,
          ...additionalVariables,
        },
        undefined,
        cancelToken.current
      );
      setValue(response[0]?.[responseValueKey] as number);
      setOriginalValue(response[0]);
      setResponse(response);
    }
  }, [additionalVariables, aggregationTemplateIdentifier, disabled, itemCpTypeUrl, itemIdentifier, responseValueKey, subjectUri]);

  useEffect(() => {
    loadData();
  }, []);

  useLiveAboutUpdates(relatedCpTypeUrl || subjectUri, itemIdentifier, itemCpTypeUrl, () => loadData(), trackMainEntity);

  return [value, originalValue, response];
}

function useLiveUpdatesBase(
  loadItems: LoadItemsFunction | undefined,
  items: IDataItem[],
  itemModificationCallback: (
    change: { timestamp: number; affectedItems: IDataItem<unknown>[]; operation: DataOperation },
    status: { modified: boolean; newItems: IDataItem<unknown>[] },
    promises: Promise<void>[],
    loadData: (identifier: string) => Promise<IDataItem | undefined>
  ) => void,
  setItems: (items: IDataItem[]) => void,
  setTotalItems: Dispatch<SetStateAction<number>>,
  detachedFiltersMode: boolean,
  cpTypeForSubscription?: string,
  onUpdate?: () => void,
  odataFilter?: MutableRefObject<ODataPropsFilter | undefined>
) {
  // 8s - 3s (delay compensation) = 5s, we have this time difference in order to let react unmount/mount our component
  // in case if order of items is changed. We need to give GenericScreen time to load data.
  const latestFetchingTimestamp = useRef(Date.now() - 8000);
  const urlForSubscription: string | null = useUrlForSubscription(cpTypeForSubscription);
  const changes = useSelector((state: IGlobalState) => state.websocket.latestChanges);
  const [debouncedLatestChanges] = useDebouncedValue(changes, 100);
  const relatedLatestChanges = useMemo(
    () => (urlForSubscription ? debouncedLatestChanges[urlForSubscription] : null),
    [urlForSubscription, debouncedLatestChanges]
  );

  const loadData = async (identifier: string): Promise<IDataItem | undefined> => {
    if (!loadItems) return;
    const res = await loadItems(
      {
        filter: odataFilter?.current || {},
      },
      {
        detachedRequest: true,
        forceParentFilters: !detachedFiltersMode,
        interceptors: {
          beforeRequest: (endpointId, path, queryOptions, ...other) => {
            // Ignore filter from page level. We always fetch all children / components.
            const filterByItemIdentifier = formatEntries({
              identifier,
            });
            return [
              endpointId,
              path,
              {
                filter: detachedFiltersMode
                  ? filterByItemIdentifier
                  : {
                      and: [queryOptions?.filter || {}, filterByItemIdentifier],
                    },
                ...(queryOptions?.$expand ? { $expand: queryOptions.$expand } : {}),
              },
              ...other,
            ];
          },
        },
      }
    );
    return res.items[0];
  };

  useEffect(() => {
    if (!relatedLatestChanges || !latestFetchingTimestamp.current || relatedLatestChanges.latest - latestFetchingTimestamp.current <= 0) return;

    const latestChanges = relatedLatestChanges.changes.filter((change) => change.timestamp > latestFetchingTimestamp.current);
    if (!latestChanges) return;

    latestFetchingTimestamp.current = relatedLatestChanges.latest;

    const status: { modified: boolean; newItems: IDataItem<unknown>[] } = {
      modified: false,
      newItems: [...items],
    };
    const promises: Promise<void>[] = [];
    for (const change of latestChanges) {
      itemModificationCallback(change, status, promises, loadData);
    }
    onUpdate?.();

    Promise.all(promises).then(() => {
      if (status.modified) {
        setItems(status.newItems);
        setTotalItems((prevTotalItems) => {
          if (prevTotalItems === items.length) {
            return status.newItems.length;
          }
          return prevTotalItems;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedLatestChanges?.latest, relatedLatestChanges?.changes.length]);
}

export function useLiveUpdates(
  loadItems: LoadItemsFunction | undefined,
  items: IDataItem[],
  setItems: (items: IDataItem[]) => void,
  setTotalItems: Dispatch<SetStateAction<number>>,
  totalItems: number,
  page: Schemas.CpaPage,
  isItemRelatedToUpdate: (item: IDataItem, operation: DataOperation) => boolean,
  detachedFiltersMode: boolean,
  onUpdate?: () => void,
  odataFilter?: MutableRefObject<ODataPropsFilter | undefined>
): void {
  const itemModificationHandler = (
    change: { timestamp: number; affectedItems: IDataItem<unknown>[]; operation: DataOperation },
    status: { modified: boolean; newItems: IDataItem<unknown>[] },
    promises: Promise<void>[],
    loadData: (identifier: string) => Promise<IDataItem | undefined>
  ): void => {
    switch (change.operation) {
      case 'DELETE': {
        const removedIdentifiers = change.affectedItems.map((item) => item.identifier);
        const updatedItems = status.newItems.filter((item) => !removedIdentifiers.includes(item.identifier));
        if (status.newItems.length !== updatedItems.length) {
          status.newItems = updatedItems;
          status.modified = true;
        }
        // Not expanded
        if (items.length === 0) {
          let removedCount = 0;
          for (const affectedItem of change.affectedItems) {
            if (isItemRelatedToUpdate(affectedItem, change.operation)) {
              removedCount++;
            }
          }
          setTotalItems((totalItems) => Math.max(totalItems - removedCount, 0));
        }
        break;
      }
      case 'UPDATE': {
        let [prevVersion, newVersion] = change.affectedItems;
        if (!newVersion) {
          newVersion = prevVersion;
        }
        if (!newVersion.identifier) break;

        const prevVersionIndex = items.findIndex((item) => item.identifier === prevVersion.identifier);
        if (prevVersionIndex !== -1) {
          // Item exists in our data, we update it or delete
          if (!isItemRelatedToUpdate(newVersion, change.operation)) {
            status.newItems = items.filter((item) => item.identifier !== newVersion.identifier);
            if (status.newItems.length !== items.length) {
              status.modified = true;
            }
          } else {
            promises.push(
              loadData(newVersion.identifier)
                .then((newItem) => {
                  if (newItem) {
                    status.newItems[prevVersionIndex] = newItem;
                    status.modified = true;
                  } else {
                    status.newItems = items.filter((item) => item.identifier !== newVersion.identifier);
                    if (status.newItems.length !== items.length) {
                      status.modified = true;
                    }
                  }
                })
                .catch((reason) => {
                  console.error(`Failed to live update item ${newVersion.identifier}`, reason);
                })
            );
          }
        } else if (totalItems <= items.length && isItemRelatedToUpdate(newVersion, change.operation)) {
          promises.push(
            loadData(newVersion.identifier)
              .then((newItem) => {
                if (newItem && !items.some((item) => item.identifier === newVersion.identifier)) {
                  status.newItems.push(newItem);
                  status.modified = true;
                }
              })
              .catch((reason) => {
                console.error(`Failed to live update item ${newVersion.identifier}`, reason);
              })
          );
        } else if (
          isItemRelatedToUpdate(prevVersion, change.operation) &&
          !isItemRelatedToUpdate(newVersion, change.operation) &&
          items.length === 0
        ) {
          // Not expanded
          setTotalItems((totalItems) => Math.max(totalItems - 1, 0));
        }

        break;
      }
      case 'CREATE': {
        if (totalItems <= items.length) {
          const newItemIdentifier = change.affectedItems[0].identifier;
          if (!newItemIdentifier) break;
          if (isItemRelatedToUpdate && !isItemRelatedToUpdate(change.affectedItems[0], change.operation)) {
            break;
          }
          promises.push(
            loadData(newItemIdentifier)
              .then((newItem) => {
                if (newItem && !items.some((item) => item.identifier === newItem.identifier)) {
                  status.newItems.push(newItem);
                  status.modified = true;
                }
              })
              .catch((reason) => {
                console.error(`Failed to live update item ${newItemIdentifier}`, reason);
              })
          );
        }
        break;
      }
      default:
        break;
    }
  };
  useLiveUpdatesBase(loadItems, items, itemModificationHandler, setItems, setTotalItems, detachedFiltersMode, page.cpTypeUrl, onUpdate, odataFilter);
}

type UseAutoControlledOptions<Value> = {
  defaultValue: Value;
  value: Value;

  initialValue?: Value;
};

const isUndefined = (value: any) => typeof value === 'undefined';

/**
 * Returns a stateful value, and a function to update it. Mimics the `useState()` React Hook
 * signature.
 */
export const useAutoControlled = <Value>(options: UseAutoControlledOptions<Value>): [Value, React.Dispatch<React.SetStateAction<Value>>] => {
  const { defaultValue, initialValue = undefined, value } = options;
  const [state, setState] = React.useState<Value>(isUndefined(defaultValue) ? (initialValue as Value) : defaultValue);

  return [isUndefined(value) ? state : value, setState];
};

export function useRelatedLiveUpdates(
  schema: IJSONSchema | null,
  loadItems: LoadItemsFunction | undefined,
  items: IDataItem[],
  setItems: (items: IDataItem[]) => void,
  setTotalItems: Dispatch<SetStateAction<number>>,
  totalItems: number,
  isItemRelatedToUpdate: (item: IDataItem, operation: DataOperation) => boolean,
  detachedFiltersMode: boolean,
  onUpdate?: () => void,
  odataFilter?: MutableRefObject<ODataPropsFilter | undefined>
) {
  const itemModificationHandler = (
    change: { timestamp: number; affectedItems: IDataItem<unknown>[]; operation: DataOperation },
    status: { modified: boolean; newItems: IDataItem<unknown>[] },
    promises: Promise<void>[],
    loadData: (identifier: string) => Promise<IDataItem | undefined>
  ): void => {
    const relatedItemIdentifier = (change.affectedItems?.[0]?.about as { identifier: string; _type: string })?.identifier;
    if (!relatedItemIdentifier) return;
    const prevItem = items.find((item) => item.identifier === relatedItemIdentifier);

    if (!prevItem?.identifier) return;

    const prevVersionIndex = items.findIndex((item) => item.identifier === prevItem.identifier);
    if (prevVersionIndex !== -1) {
      // Item exists in our data, we update it or delete
      if (!isItemRelatedToUpdate(prevItem, 'UPDATE')) {
        status.newItems = items.filter((item) => item.identifier !== prevItem.identifier);
        if (status.newItems.length !== items.length) {
          status.modified = true;
        }
      } else {
        promises.push(
          loadData(prevItem.identifier)
            .then((newItem) => {
              if (newItem) {
                status.newItems[prevVersionIndex] = newItem;
                status.modified = true;
              } else {
                status.newItems = items.filter((item) => item.identifier !== prevItem.identifier);
                if (status.newItems.length !== items.length) {
                  status.modified = true;
                }
              }
            })
            .catch((reason) => {
              console.error(`Failed to live update item ${prevItem.identifier}`, reason);
            })
        );
      }
    } else if (totalItems <= items.length && isItemRelatedToUpdate(prevItem, 'UPDATE')) {
      promises.push(
        loadData(prevItem.identifier)
          .then((newItem) => {
            if (newItem && !items.some((item) => item.identifier === prevItem.identifier)) {
              status.newItems.push(newItem);
              status.modified = true;
            }
          })
          .catch((reason) => {
            console.error(`Failed to live update item ${prevItem.identifier}`, reason);
          })
      );
    } else if (isItemRelatedToUpdate(prevItem, 'UPDATE') && !isItemRelatedToUpdate(prevItem, 'UPDATE') && items.length === 0) {
      // Not expanded
      setTotalItems((totalItems) => Math.max(totalItems - 1, 0));
    }
  };
  useLiveUpdatesBase(
    loadItems,
    items,
    itemModificationHandler,
    setItems,
    setTotalItems,
    detachedFiltersMode,
    'cp:CpMessageState',
    onUpdate,
    odataFilter
  );
}
