import { IJSONSchema, Schemas, TypeConstants } from '@cp/base-types';
import urljoin from 'url-join';
import { IDataItem } from '@cpa/base-core/types';
import { ScrollablePane } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { axiosDictionary, getEndpoint, getSchema } from '@cpa/base-core/api';

import Drawer from '../../../../Drawer/Drawer';
import Form, { IFormChangeEvent } from '../../../../Form/Form';

import styles from './ChartSettingsDrawer.module.scss';

export interface IChartSettingsDrawerProps {
  chartOptions: Schemas.CpaPage['chart'];
  cpTypeUrl: string;
  onChange: (chartOptions: Schemas.CpaPage['chart']) => void;
  onClose: () => void;
}

export interface IChartSettingsFormData {
  chart: Schemas.CpaPage['chart'];
  cpTypeUrl: string;
}

export const ChartSettingsDrawer: React.FC<IChartSettingsDrawerProps> = ({ onChange, onClose, chartOptions, cpTypeUrl }) => {
  const [schema, setSchema] = useState<IJSONSchema | undefined>(undefined);
  const [formData, setFormData] = useState<IChartSettingsFormData>({
    chart: chartOptions,
    cpTypeUrl: cpTypeUrl,
  });

  useEffect(() => {
    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
    getSchema(urljoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(TypeConstants.CpaPage)}`)).then((schema) => {
      schema.properties = {
        chart: schema.properties!.chart,

        cpTypeUrl: {
          type: 'string',
          cp_ui: {
            hiddenInTable: true,
            hiddenInFilter: true,
            hiddenInForm: true,
            hiddenInReadonlyContent: true,
          },
        },
      };
      schema.title = undefined;
      schema.required = [];

      schema.properties.chart.cp_rjsfUiSchema = {
        defaultExpanded: true,
      };

      setSchema(schema);
    });
  }, [chartOptions]);

  const onChangeHandler = useCallback(
    (obj: IFormChangeEvent) => {
      setFormData(obj.formData);
    },
    [setFormData]
  );

  const onSubmitHandler = useCallback(() => {
    onChange(formData!.chart);
  }, [onChange, formData]);

  return (
    <Drawer isOpen={true} onClose={onClose}>
      <div className={styles.drawerRoot}>
        <div className={styles.body}>
          {!!schema && (
            <ScrollablePane>
              <Form
                schema={schema}
                formData={formData as unknown as IDataItem}
                disableStickySubmitButton={true}
                onSubmit={onSubmitHandler}
                onChange={onChangeHandler}
              />
            </ScrollablePane>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default ChartSettingsDrawer;
