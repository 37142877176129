import { DataItemProperties } from '@cp/base-types';
import * as _ from 'lodash';
export var SimplifySchemaMode;
(function (SimplifySchemaMode) {
    SimplifySchemaMode[SimplifySchemaMode["Whitelist"] = 0] = "Whitelist";
    SimplifySchemaMode[SimplifySchemaMode["Blacklist"] = 1] = "Blacklist";
})(SimplifySchemaMode || (SimplifySchemaMode = {}));
export function simplifySchema(schema, properties = [
    'type',
    'enum',
    'properties',
    'required',
    'items',
    'anyOf',
    'title',
    '$schema',
    '$id',
    'patternProperties',
    'additionalProperties',
], mode = SimplifySchemaMode.Whitelist) {
    if (typeof schema !== 'object') {
        return schema;
    }
    if (Array.isArray(schema)) {
        return schema.map((item) => simplifySchema(item, properties, mode));
    }
    const o = (mode === SimplifySchemaMode.Whitelist ? _.pick : _.omit)(schema, properties);
    if (o.properties) {
        o.properties = _.mapValues(o.properties, (property) => simplifySchema(property, properties, mode));
    }
    if (o.items) {
        o.items = simplifySchema(o.items, properties, mode);
    }
    if (o.anyOf) {
        o.anyOf = simplifySchema(o.anyOf, properties, mode);
    }
    return o;
}
export function makeSchemaPartial(schema) {
    const result = _.cloneDeep(schema);
    function internal(partialSchema) {
        delete partialSchema.required;
        delete partialSchema.default;
        if (partialSchema.properties) {
            for (const propName in partialSchema.properties) {
                if (partialSchema.properties.hasOwnProperty(propName)) {
                    const prop = partialSchema.properties[propName];
                    delete prop.default;
                    if (prop.type === 'array' && prop.items && prop.items.type) {
                        const types = Array.isArray(prop.items.type) ? [...prop.items.type] : [prop.items.type];
                        delete prop.items.type;
                        const objects = types.map((type) => makeSchemaPartial(Object.assign(Object.assign({}, prop.items), { type })));
                        prop.items = { anyOf: [...objects, { type: 'null' }] };
                    }
                    else if (prop.type === 'array' && prop.items && prop.items.anyOf) {
                        const objects = prop.items.anyOf.map((item) => makeSchemaPartial(item));
                        prop.items = { anyOf: [...objects, { type: 'null' }] };
                    }
                    else if (prop.anyOf) {
                        prop.anyOf = prop.anyOf.map((item) => makeSchemaPartial(item));
                    }
                    else if (prop.type === 'object' && prop.properties) {
                        partialSchema.properties[propName] = makeSchemaPartial(prop);
                    }
                }
            }
        }
        return partialSchema;
    }
    return internal(result);
}
export function extendSchemaToMainCollectionItem(inputSchema, localizations, noPatternProperties) {
    const schema = makeSchemaPartial(simplifySchema(inputSchema));
    return Object.assign(Object.assign({}, schema), { properties: Object.assign(Object.assign({}, schema.properties), { identifier: {
                type: 'string',
                title: 'Identifier',
                description: 'The identifier of the item',
            }, [DataItemProperties.E_TAG_KEY]: {
                type: 'string',
                title: 'ETag',
                description: 'The ETag of the item',
            }, [DataItemProperties.SEARCH_TEXT_KEY]: {
                type: 'string',
                title: 'Search text',
                description: 'The search text of the item',
            }, [DataItemProperties.BASE_LANGUAGE_KEY]: {
                type: 'string',
                title: 'Base language',
                description: 'The base language of the item',
            }, [DataItemProperties.NO_SERIES_KEY]: {
                type: 'object',
                title: 'No series',
                description: 'Number of series of the item',
            }, [DataItemProperties.META_DATA_KEY]: {
                type: 'object',
                title: 'Metadata',
                description: 'Metadata of the item',
                properties: {
                    createdByUser: {
                        type: 'string',
                        title: 'Created by user',
                    },
                    createdAt: {
                        type: 'string',
                        title: 'Created at',
                    },
                    modifiedByUser: {
                        type: 'string',
                        title: 'Modified by user',
                    },
                    modifiedAt: {
                        type: 'string',
                        title: 'Modified at',
                    },
                    localizations: getLocalizationsSchema(schema, localizations, noPatternProperties),
                    versions: {
                        type: 'array',
                        title: 'Versions',
                        description: 'Versions of the item',
                        items: extendSchemaToItemVersion(schema, localizations, noPatternProperties),
                    },
                },
            } }), additionalProperties: false, required: [
            DataItemProperties.BASE_LANGUAGE_KEY,
            DataItemProperties.META_DATA_KEY,
        ] });
}
function allowAdditionalProperties(schema) {
    return Object.assign(Object.assign({}, schema), { additionalProperties: true });
}
export function extendSchemaToItemVersion(schema, localizations, noPatternProperties) {
    const relaxedSchema = allowAdditionalProperties(schema);
    return {
        type: 'object',
        title: 'Version',
        description: 'Version of the item',
        properties: {
            number: {
                type: 'string',
                title: 'Number',
                description: 'Number of the version',
            },
            createdByUser: {
                type: 'string',
                title: 'Created by user',
            },
            createdAt: {
                type: 'string',
                title: 'Created at',
            },
            [DataItemProperties.BASE_LANGUAGE_KEY]: {
                type: 'string',
                title: 'Base language',
                description: 'The base language of the item',
            },
            item: Object.assign(Object.assign({}, schema), { type: 'object', title: 'Item', description: 'Item of the Version' }),
            localizations: getLocalizationsSchema(relaxedSchema, localizations, noPatternProperties),
            affectedLanguages: {
                type: 'array',
                title: 'Affected localizations',
                description: 'Affected localizations of the item',
                items: {
                    type: 'string',
                    title: 'Affected localization',
                    description: 'Affected localization of the item',
                },
            },
            cloned: {
                type: 'boolean',
                title: 'Cloned',
            },
        },
        required: ['number', 'createdByUser', 'createdAt', 'item', 'localizations', 'cloned'],
        additionalProperties: true,
    };
}
function getLocalizationsSchema(schema, localizations, noPatternProperties) {
    const partialSchema = makeSchemaPartial(schema);
    if (noPatternProperties) {
        return {
            type: 'object',
            title: 'Localizations',
            description: 'Localizations of the item',
            properties: localizations.reduce((acc, localization) => {
                acc[localization] = Object.assign(Object.assign({}, partialSchema), { type: 'object', title: localization, description: 'Localization of the item' });
                return acc;
            }, {}),
            additionalProperties: false,
        };
    }
    else {
        return {
            type: 'object',
            title: 'Localizations',
            description: 'Localizations of the item',
            patternProperties: {
                ['^(' + localizations.join('|') + ')$']: Object.assign(Object.assign({}, partialSchema), { type: 'object', title: 'Localization', description: 'Localization of the item' }),
            },
            additionalProperties: false,
        };
    }
}
