import {
  Operator,
  buildODataQuery,
  createQueryFilter,
  getAllSchemaPaths,
  getODataFilter,
  isDefinedAndNotEmpty,
  parseFilterMessage,
} from '@cp/base-utils';
import { EndpointContext, filterQueryKeyName } from '@cpa/base-core/constants';
import { KeyValidationOptions, dropRoutesCache, validateKeyFactory } from '@cpa/base-core/helpers';
import notification from '@cpa/base-core/helpers/toast';
import {
  PowerUserContext,
  useAnimationOptions,
  useDebouncedValue,
  useItemIdParam,
  useQuery,
  useShowFilter,
  useUrlForSubscription,
} from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { changePageSetting, openPageDrawer } from '@cpa/base-core/store/settings/actions';
import { UserExperiencePreference } from '@cpa/base-core/store/settings/reducer';
import { wsSubscribeToEntityModification } from '@cpa/base-core/store/websocket/actions';
import { IDataItem, IGenericComponentProps, IPageSetting, IScrollableContent } from '@cpa/base-core/types';
import { ThemeContext } from '@fluentui/react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';

import Animation from '../../components/Animation/Animation';
import EmptySpace from '../../components/EmptySpace/EmptySpace';
import ScrollingContent, { DataCallbackContext } from '../../components/ScrollingContent/ScrollingContent';
import ShimmerGrid from '../../components/ShimmerGrid/ShimmerGrid';
import Widget from '../../components/Widget/Widget';
import { IRelatedViewProps } from '../../screens/GenericScreen/GenericScreen';
import { SingleItemContext } from '../../screens/GenericScreen/components/SingleItem/context';
import FacetFiltersWrapper, { IFacetFiltersWrapperRef } from '../ScrollingContent/components/FacetFilterWrapper/FacetFiltersWrapper';
import { getFilterExpression, trimSerializedFilter } from '../ScrollingContent/components/Filter/components/FilterLayer/utils';
import { ISettingsItem, SettingsItemType } from '../Widget/components/Settings/Settings';

import styles from './GenericComponent.module.scss';
import ChartWrapper from './components/ChartWrapper/ChartWrapper';
import { PageGraphics } from './components/PageGraphics';

function generateFilter(filterOption: Operator, key: string, val: string): string {
  const filterExpression = getFilterExpression(filterOption, val);
  return `"${key}":${JSON.stringify(filterExpression)}`;
}

const tableId = 'table';

const GenericComponent: React.FC<IGenericComponentProps & IRelatedViewProps> = ({
  data,
  animationDelay = 0,
  onRefresh,
  resetFilterOnRefresh,
  isWidget = false,
  customContent,
  forceCustomContent,
  onAddRow,
  onEditRow,
  onDeleteRows,
  onCopyRow,
  loadItems,
  isODataSupportedByEndpoint,
  pageSize,
  tableRef: tableRefFromProps,
  hideSettings,
  hideMoreButton,
  withoutAnimation,
  hiddenInTable,
  preFillItems,
  initialAction,
  initialFilterValue,
  externalODataFilter,
  dataUrlMongoFilter,
  showFilterInWidget,
  scrollingContentProps,
  widgetSettings,
  disableFacetFilters,
}) => {
  const internalTableRef = useRef<IScrollableContent>(null);
  const tableRef = tableRefFromProps ?? internalTableRef;

  const pageSettings: IPageSetting | undefined = useSelector((state: IGlobalState) => state.settings.pages[data.page.identifier!]);
  const userExperiencePreference = useSelector((state: IGlobalState) => state.settings.userExperiencePreference);
  const powerUserEnabled = useMemo(() => {
    return userExperiencePreference !== UserExperiencePreference.Simple;
  }, [userExperiencePreference]);
  const queryFilter = useQuery();
  const location = useLocation();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const theme = useContext(ThemeContext);
  const [temporaryPowerUserEnabled, setTemporaryPowerUserEnabled] = useState(false);

  const [signalForFacetsToRefresh, setSignalForFacetsToRefresh] = useState(0);
  const [debouncedSignalForFacetsToRefresh] = useDebouncedValue(signalForFacetsToRefresh, 2000);

  const facetFilterWrapperRef = useRef<IFacetFiltersWrapperRef>(null);

  const handleFilterValueChange = useCallback(
    (filterValue: string) => {
      if (facetFilterWrapperRef?.current) {
        facetFilterWrapperRef.current.setFilterValue(filterValue);
      }
    },
    [facetFilterWrapperRef]
  );

  const handleFacetFilterChange = useCallback(
    (filterValue: string) => {
      if (tableRef?.current) {
        tableRef.current.setSearchText(filterValue);
      }
    },
    [tableRef]
  );

  useEffect(() => {
    if (!data.isFetching) setInitialDataLoaded(true);
  }, [data.isFetching]);

  const urlForSubscription: string | null = useUrlForSubscription(data.page.cpTypeUrl);
  const batchOperationChange = useSelector((state: IGlobalState) => state.websocket.latestBatchOperationStatus);

  // TODO: In the future it would be nice if this could be handled outside of the component in the business logic.
  useEffect(() => {
    notification.dismissAll();
    if (!batchOperationChange) {
      return;
    }

    if (batchOperationChange.status.state === 'RUNNING') {
      const timeLeft =
        ((new Date().getTime() - batchOperationChange.status.startTime) / batchOperationChange.status.processed) *
        (batchOperationChange.status.total - batchOperationChange.status.processed);
      const seconds = Math.floor(timeLeft / 1000) % 60;
      const minutes = Math.floor(timeLeft / 1000 / 60);
      const timeLeftString = Number.isInteger(seconds) && Number.isInteger(minutes) ? `${minutes}m ${seconds}s` : 'Calculating...';
      notification.info(
        `Batch operation ${batchOperationChange.identifier} is ${Math.floor(
          (batchOperationChange.status.processed / batchOperationChange.status.total) * 100
        )}% done. Processed ${batchOperationChange.status.processed}/${
          batchOperationChange.status.total
        } items. Estimated remaining time: ${timeLeftString}`,
        10000
      );
    }
  }, [batchOperationChange]);

  const singleItemContext = useContext(SingleItemContext);
  const [computedDuration, disableAnimation] = useAnimationOptions();
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });

  const [showFilter, setShowFilter] = useShowFilter(data.page.identifier, pageSettings?.displayFilter);

  const hideFilterBySetting: boolean = useMemo(() => !showFilter && !hideSettings, [hideSettings, showFilter]);
  const hideFilters = useMemo(() => isWidget && !showFilterInWidget, [isWidget, showFilterInWidget]);

  const onChartAreaClick = useCallback(
    (...params: string[]) => {
      const filter = _.chunk(params, 2).map(([property, value]) => generateFilter(Operator.CONTAINS, property, value));
      const filterJson = `{${filter.join(',')}}`;

      if (isWidget) {
        dropRoutesCache().then(() =>
          dispatch(
            push({
              search: `${filterQueryKeyName}=${encodeURIComponent(filterJson)}`,
              pathname: data.page.path,
            })
          )
        );
        return;
      }

      if (tableRef?.current) {
        tableRef.current.setSearchText(createQueryFilter(filterJson));
      }
    },
    [isWidget, tableRef, dispatch, data.page.path]
  );

  const hasEntered = useRef(false);
  const itemId = useItemIdParam();

  useEffect(() => {
    if (tableRef?.current && !hasEntered.current) {
      hasEntered.current = true;

      if (!isWidget && location.hash && location.hash === `#${tableId}`) {
        document.getElementById(tableId)?.scrollIntoView({
          behavior: 'smooth',
        });
      }

      const initialFilter = !hideFilters ? data.page.initialFilter : undefined;
      if (itemId && !isWidget && !singleItemContext?.isSingleItem) {
        // Identifier in browser param /:id
        tableRef.current.setSearchText(trimSerializedFilter(JSON.stringify({ $or: [{ identifier: itemId }, { urlSlug: itemId }] })));
      } else if (queryFilter[filterQueryKeyName] && !isWidget && !singleItemContext?.isSingleItem) {
        // Filter in browser query
        tableRef.current.setSearchText(createQueryFilter(queryFilter[filterQueryKeyName]));
      } else if (initialFilter) {
        // Fallback to initial filter if any
        tableRef.current.setSearchText(initialFilter);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef.current, queryFilter, itemId, data.page.initialFilter, location.hash]);

  const allHiddenInTableColumns = useMemo(
    () => [...(hiddenInTable ?? []), ...(isWidget ? pageSettings?.widgetDisabledColumns || [] : pageSettings?.disabledColumns || [])],
    [hiddenInTable, isWidget, pageSettings?.widgetDisabledColumns, pageSettings?.disabledColumns]
  );

  const validator = useMemo(
    () =>
      validateKeyFactory(data.schema ?? {}, hiddenInTable ?? [], [
        KeyValidationOptions.isHiddenFullPath,
        KeyValidationOptions.isMatchValidationPattern,
      ]),
    [data.schema, hiddenInTable]
  );

  const chart = useMemo(() => {
    if (!data.page.chart) {
      return null;
    }

    if (data.isFetching) {
      return (
        <div key={data.page.identifier + '-chart'} style={{ padding: '10px', zIndex: 1 }}>
          <ShimmerGrid darkMode={darkMode} gridArea={'"a"'} gridGap={0} height={250} />
        </div>
      );
    }

    return (
      <div key={data.page.identifier + '-chart'} style={{ zIndex: 1 }}>
        <ChartWrapper darkMode={darkMode} data={data} disableAnimation={disableAnimation} isWidget={isWidget} onAreaClick={onChartAreaClick} />
      </div>
    );
  }, [isWidget, data, darkMode, disableAnimation, onChartAreaClick]);

  const dataCallbackContext = useContext(DataCallbackContext);
  useEffect(() => {
    if (data.isFetching) {
      return;
    }
    dataCallbackContext?.onDataUpdated?.(data.page, data.items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.items, data.isFetching]);

  const refresh = useCallback(() => {
    if (!onRefresh) {
      return;
    }

    const filterValue = tableRef?.current?.filterValue;

    const composedFilter = [!!filterValue && parseFilterMessage(filterValue)]
      .map((parsedFilter) => getODataFilter(parsedFilter || {}))
      .filter(isDefinedAndNotEmpty);

    const refreshFilter = composedFilter.length > 1 ? { and: composedFilter } : composedFilter[0];
    setInitialDataLoaded(false);
    onRefresh(resetFilterOnRefresh ? undefined : refreshFilter).finally(() => {
      if (resetFilterOnRefresh) {
        return;
      }

      if (filterValue) {
        // TODO: Remove setTimeout
        // This issue appeared with React18 update. tableRef?.current is null without setTimeout because of delayed rendering.
        setTimeout(() => {
          tableRef?.current?.setSearchText(filterValue, true);
        }, 0);
      }
    });
  }, [onRefresh, tableRef, resetFilterOnRefresh]);

  // Handle data language change
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  useEffect(() => {
    if (!data.isFetching) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLanguage]);

  const wrapModifyFunction = useCallback(
    <T extends (itemData: IDataItem | IDataItem[], ...args: unknown[]) => Promise<IDataItem | undefined | void>>(func: T | undefined) =>
      async (...args: Parameters<T>): Promise<IDataItem | undefined | void> => {
        setSignalForFacetsToRefresh(Date.now());

        if (!func) {
          return;
        }

        const [itemData, ...rest] = args;
        const result = await func(itemData, ...rest);
        dataCallbackContext?.onDataModification?.(data.page, Array.isArray(itemData) ? itemData : [itemData]);
        return result;
      },
    [data.page, dataCallbackContext, setSignalForFacetsToRefresh]
  );

  const wrappedOnAddRow = useMemo(() => wrapModifyFunction(onAddRow), [wrapModifyFunction, onAddRow]);
  const wrappedOnEditRow = useMemo(() => wrapModifyFunction(onEditRow), [wrapModifyFunction, onEditRow]);
  const wrappedOnDeleteRows = useMemo(() => wrapModifyFunction(onDeleteRows), [wrapModifyFunction, onDeleteRows]);
  const wrappedOnCopyRow = useMemo(() => wrapModifyFunction(onCopyRow), [wrapModifyFunction, onCopyRow]);

  const getTable = useCallback(
    (chart?: JSX.Element | null): JSX.Element => {
      return (
        <div id={tableId} key={data.page.identifier + '-table'} style={{ padding: isWidget ? 10 : 0, zIndex: 1000 }}>
          <ScrollingContent
            tableKey={`${data.page.identifier}.${isWidget ? 'widget' : 'fullscreen'}`}
            isWidget={isWidget}
            pageSize={pageSize}
            hideFilter={hideFilterBySetting || hideFilters}
            ref={tableRef}
            chart={chart}
            onAdd={wrappedOnAddRow}
            onEdit={wrappedOnEditRow}
            onDelete={wrappedOnDeleteRows}
            onCopy={wrappedOnCopyRow}
            loadItems={loadItems}
            isODataSupportedByEndpoint={isODataSupportedByEndpoint}
            hiddenInTable={allHiddenInTableColumns}
            preFillItems={preFillItems}
            initialAction={initialAction}
            parentPropertyJsonPath={data.schema?.cp_parentPropertyJsonPath}
            data={data}
            initialFilterValue={initialFilterValue}
            onFilterValueChange={handleFilterValueChange}
            widgetCardView={pageSettings?.widgetDisplayAsCards}
            pageCardView={pageSettings?.displayAsCards}
            showItemLinksInTable={true}
            {...(scrollingContentProps || {})}
          />
        </div>
      );
    },
    [
      hideFilters,
      isWidget,
      pageSize,
      hideFilterBySetting,
      tableRef,
      wrappedOnAddRow,
      wrappedOnEditRow,
      wrappedOnDeleteRows,
      wrappedOnCopyRow,
      loadItems,
      isODataSupportedByEndpoint,
      allHiddenInTableColumns,
      preFillItems,
      initialAction,
      data,
      initialFilterValue,
      handleFilterValueChange,
      pageSettings?.widgetDisplayAsCards,
      pageSettings?.displayAsCards,
      scrollingContentProps,
    ]
  );

  const tableShimmer = useMemo(
    () => (
      <div key={data.page.identifier + '-tableShimmer'} style={{ padding: 10, zIndex: 1 }}>
        <ShimmerGrid darkMode={darkMode} gridArea={'"a" "b" "c" "d" "e"'} gridGap={10} height={250} />
      </div>
    ),
    [darkMode, data.page.identifier]
  );

  const chartShimmer = useMemo(
    () => (
      <div key={data.page.identifier + '-chartShimmer'} style={{ padding: 10, zIndex: 1 }}>
        <ShimmerGrid darkMode={darkMode} gridArea={'"a"'} gridGap={0} height={250} />
      </div>
    ),
    [data.page.identifier, darkMode]
  );

  const emptyWidget = useMemo(
    () => <EmptySpace key={data.page.identifier + '-emptyWidget'} darkMode={darkMode} iconName="Completed" text={t('common.noItems')} />,
    [data.page.identifier, t, darkMode]
  );

  const innerContent = useMemo(() => {
    if (isWidget) {
      // Displaying shimmer in widget
      if (((data.isFetching && data.items.length === 0) || !data.schema) && !initialDataLoaded) {
        return [data.page.chart ? chartShimmer : tableShimmer];
      }

      // If no items received
      if (!data.isFetching && data.items.length === 0) {
        // Render table to show actions to make possible to add
        return [data.page.allowCreate || !hideFilters ? getTable() : emptyWidget];
      }

      // If table/card view disabled in settings
      if (pageSettings && data.page.chart && !(pageSettings.widgetDisplayTable || pageSettings.widgetDisplayAsCards)) {
        return [getTable(chart)];
      } else {
        return [getTable()];
      }
    } else {
      const itemsToRender: (JSX.Element | null)[] = [];

      // If chart option enabled on page view
      if (data.page.chart && pageSettings?.displayChart) {
        if (!data.isFetching && data.items.length === 0) {
          if (!pageSettings.displayTable) {
            itemsToRender.push(emptyWidget);
          }
        } else {
          itemsToRender.push((data.isFetching || data.items.length === 0 || !data.schema) && !initialDataLoaded ? chartShimmer : chart);
        }
      }
      // If table/cards option enabled on page view
      if (!pageSettings || pageSettings.displayTable || pageSettings.displayAsCards) {
        itemsToRender.push(((data.isFetching && data.items.length === 0) || !data.schema) && !initialDataLoaded ? tableShimmer : getTable());
      }

      return itemsToRender.filter((el) => el !== null);
    }
  }, [initialDataLoaded, isWidget, chart, data, pageSettings, getTable, chartShimmer, tableShimmer, emptyWidget, hideFilters]);

  const displayCustomContent =
    (!!customContent || !!data.page.customTemplate) &&
    (forceCustomContent || (isWidget && pageSettings?.widgetCustomView) || (!isWidget && pageSettings?.customView));

  const widgetSettingsItems = useMemo(() => {
    const settingsItems: ISettingsItem[] = [];

    const helpLink = data.page.helpLink || data.schema?.cp_helpLink;
    if (helpLink) {
      settingsItems.push({
        type: SettingsItemType.HELP,
        url: helpLink,
        onClick: (e: React.MouseEvent<HTMLElement>): void => {
          e?.preventDefault();
          window.open(helpLink, '_blank');
        },
      });
    }

    if (isWidget && data.page.path && !hideMoreButton) {
      settingsItems.push({
        type: SettingsItemType.MORE,
        url: externalODataFilter ? undefined : data.page.path,
        onClick: (e: React.MouseEvent<HTMLElement>): void => {
          e?.preventDefault();
          if (!data.page.path) {
            return;
          }

          if (initialFilterValue) {
            dropRoutesCache().then(() => dispatch(push(`${data.page.path}?filter="${initialFilterValue}"`)));
          } else if (externalODataFilter) {
            dropRoutesCache().then(() =>
              dispatch(
                push(data.page.path!, {
                  externalDataQuery: {
                    $filter: new URLSearchParams(buildODataQuery({ filter: externalODataFilter })).get('$filter'),
                  },
                  originPage: singleItemContext?.rootPage,
                  originItem: singleItemContext?.rootItem,
                })
              )
            );
          } else {
            dispatch(push(data.page.path));
          }
        },
      });
    }

    if (!hideSettings) {
      if (!isWidget && (!pageSettings || pageSettings.displayTable) && !displayCustomContent) {
        if (powerUserEnabled || (!powerUserEnabled && isMobileDevice)) {
          // Filter switch
          settingsItems.push({
            type: SettingsItemType.FILTER,
            iconProps: { iconName: showFilter ? 'FilterSolid' : 'Filter' },
            onClick(): void {
              setShowFilter(!showFilter);
            },
          });
        }
      }

      if (powerUserEnabled) {
        settingsItems.push({
          type: SettingsItemType.SETTINGS,
          onClick(): void {
            if (!data.schema || data.isFetching) {
              return;
            }

            const columns = getAllSchemaPaths(data.schema ?? {}, { validator, nestingLevel: 1 }).map((column) => ({
              title: column.items
                .map((p) => p.title)
                .slice(1)
                .join(' '),
              fieldName: column.items.slice(1)[0]?.property,
            }));

            dispatch(openPageDrawer({ page: data.page, isWidget, columns }));
          },
        });
      }
      if (!powerUserEnabled && !isWidget) {
        settingsItems.push({
          type: SettingsItemType.POWER_USER,
          buttonStyles: {
            icon: {
              color: `${temporaryPowerUserEnabled ? theme?.palette.themePrimary : theme?.palette.black} !important`,
            },
          },
          onClick(): void {
            if (!temporaryPowerUserEnabled) {
              notification.success(t('common.tempPowerUserEnabled'));
            } else {
              notification.info(t('common.tempPowerUserDisabled'));
            }
            setTemporaryPowerUserEnabled(!temporaryPowerUserEnabled);
          },
        });
        if ('displayAsCards' in pageSettings) {
          settingsItems.push({
            type: SettingsItemType.MODE,
            iconProps: { iconName: pageSettings?.displayAsCards ? 'gridviewlarge' : 'gridviewmedium' },
            onClick(): void {
              dispatch(changePageSetting({ pageKey: data.page.identifier, setting: 'displayAsCards', value: !pageSettings?.displayAsCards }));
            },
          });
        }
      }
    }

    settingsItems.push({
      type: SettingsItemType.REFRESH,
      onClick: refresh,
      disabled: data.isFetching,
    });

    return settingsItems;
  }, [
    data.page,
    data.schema,
    data.isFetching,
    isWidget,
    hideMoreButton,
    hideSettings,
    refresh,
    externalODataFilter,
    initialFilterValue,
    dispatch,
    singleItemContext?.rootPage,
    singleItemContext?.rootItem,
    pageSettings,
    displayCustomContent,
    powerUserEnabled,
    isMobileDevice,
    showFilter,
    setShowFilter,
    validator,
    temporaryPowerUserEnabled,
    theme?.palette.themePrimary,
    theme?.palette.black,
    t,
  ]);

  const isLargeContainer =
    pageSettings && (pageSettings.displayTable || pageSettings.displayAsCards) && data.page.chart && pageSettings.displayChart && !isWidget;

  // Cp Type subscription
  useEffect(() => {
    if (displayCustomContent || !urlForSubscription) {
      return;
    }

    dispatch(wsSubscribeToEntityModification(urlForSubscription));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlForSubscription]);

  const genericComponentBody = (
    <div className={styles.block} style={{ padding: isWidget ? 10 : 0 }} id={data.page?.identifier}>
      <PowerUserContext.Provider value={{ temporaryEnabled: temporaryPowerUserEnabled }}>
        <EndpointContext.Provider value={data.page.dataEndpoint?.identifier || null}>
          <Animation
            duration={computedDuration}
            disable={withoutAnimation || disableAnimation}
            delay={animationDelay || 0}
            viewTriggerThreshold={0.00001}
          >
            <Widget
              page={data.page}
              schema={data.schema}
              isWidget={isWidget}
              subtitle={data.page.description}
              settingsItems={widgetSettingsItems}
              headerIconName={data.page.icon}
              widgetSettings={widgetSettings}
            >
              {displayCustomContent ? (
                customContent
              ) : (
                <div
                  className={classNames(styles.container, {
                    [styles.large]: isLargeContainer && (data.isFetching || data.items.length !== 0),
                  })}
                  style={{ padding: isWidget || isSmallScreen ? 5 : '10px 20px 20px 20px' }}
                >
                  {innerContent}
                  {!!data.page.graphic && !isWidget && <PageGraphics graphic={data.page.graphic} />}
                </div>
              )}
            </Widget>
          </Animation>
        </EndpointContext.Provider>
      </PowerUserContext.Provider>
    </div>
  );

  if (
    disableFacetFilters ||
    hideFilters ||
    data.page?.disableFacetFilters ||
    !isODataSupportedByEndpoint ||
    data.schema?.cp_handledByApiGateway === true
  ) {
    return genericComponentBody;
  } else {
    return (
      <FacetFiltersWrapper
        page={data.page}
        schema={data.schema}
        ref={facetFilterWrapperRef}
        onFilterChange={handleFacetFilterChange}
        isWidget={isWidget}
        tableRef={tableRef}
        dataUrlMongoFilter={dataUrlMongoFilter}
        signalForFacetsToRefresh={debouncedSignalForFacetsToRefresh}
      >
        {genericComponentBody}
      </FacetFiltersWrapper>
    );
  }
};

export default React.memo(GenericComponent);
