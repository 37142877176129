import { FieldTrigger, IJSONSchema, OnValidateFieldTriggerExecutionContext, TriggerType } from '@cp/base-types';
import { AjvError } from '@rjsf/core';
import Ajv, { ErrorObject, Options, SchemaObjCxt, ValidateFunction } from 'ajv';
import * as _ from 'lodash';
import {
  cloneDeepWithMetadata,
  CultureInfo,
  generateCommonAjv,
  generateErrorsInstanceToSchemaPathMap,
  getReadableSchemaPath,
  isRelatedError,
  resolveSchemaPath,
} from '@cp/base-utils';
import localize from 'ajv-i18n';

import { i18n } from '../../app/i18n';
import { IDataItem } from '../../types';
import { transformCodeSync } from '../swc';

import { executeUiTriggers, resolveUiTriggersCode } from './triggers';

const validationCache = new WeakMap<IJSONSchema, ValidateFunction>();

export const generateValidationAjv = (options: Partial<Options> = {}, transformCode: (src: string) => { code: string; map?: string }): Ajv => {
  const ajv = generateCommonAjv(options, transformCode);

  ajv.addKeyword({
    keyword: 'cp_fieldTriggers',
    async: false,
    modifying: true,
    compile: (
      keywordValue: { [key in FieldTrigger]: string[] },
      parentSchema,
      it: SchemaObjCxt & {
        root?: { schema?: { $id?: string } };
      }
    ) => {
      return async (data, dataCtx) => {
        let validationError: string | undefined;
        let customPropertyPath: string | undefined;

        const triggers = keywordValue?.[FieldTrigger.OnValidate];
        if (Array.isArray(triggers) && triggers.length && data) {
          await executeUiTriggers<OnValidateFieldTriggerExecutionContext>(
            {
              event: FieldTrigger.OnValidate,
              fieldValue: data,
              fieldPath: dataCtx?.instancePath,
              fieldName: _.toPath(dataCtx?.instancePath)?.pop(),
              fieldSchema: parentSchema,
              item: dataCtx?.rootData,
              schema: it?.root?.schema as IJSONSchema,
              failValidation: (text: string, propertyPath?: string) => {
                customPropertyPath = propertyPath;
                validationError = text;
              },
            },
            await resolveUiTriggersCode(triggers, TriggerType.Field)
          );
        }

        if (validationError) {
          throw new Ajv.ValidationError([
            {
              schemaPath: it.schemaPath.str,
              instancePath: customPropertyPath || dataCtx?.instancePath || '',
              keyword: 'cp_fieldTriggers',
              message: validationError,
              params: {
                keyword: 'cp_fieldTriggers',
              },
            },
          ]);
        }

        return true;
      };
    },
  });

  return ajv;
};

export const validationAjv = generateValidationAjv({}, transformCodeSync);

const transformAjvErrors = (errors: Partial<ErrorObject>[] = [], schema: IJSONSchema): AjvError[] => {
  if (errors === null) {
    return [];
  }

  const errorsMap = generateErrorsInstanceToSchemaPathMap(errors);

  const filteredErrors = errors
    .map((e: ErrorObject) => {
      const { instancePath, keyword, message, params, schemaPath } = e;
      const isRelated = isRelatedError(errorsMap, e);
      if (isRelated || keyword === 'anyOf') return null;
      const path = _.trim(schemaPath.replace('#', '').replace(keyword, ''), '/').replaceAll('/', '.');
      const resolvedPath = getReadableSchemaPath(schema, path.split('.'), instancePath.split('/'));
      const schemaObject = path ? _.get(schema, path) : schema;
      const resolvedAdditionalPath = params.missingProperty ? resolveSchemaPath(schemaObject as IJSONSchema, params.missingProperty) : undefined;
      const property = `${resolvedPath}`;
      const label = `${property} ${message} ${resolvedAdditionalPath?.title ? `('${resolvedAdditionalPath.title}')` : ''}`.trim();

      return {
        name: keyword,
        property,
        message: message as string,
        params,
        stack: label.startsWith('.') ? label.slice(1, label.length) : label,
        schemaPath,
      };
    })
    .filter(Boolean) as AjvError[];

  if (errors.length && !filteredErrors.length) {
    return errors.map((e: ErrorObject) => {
      const { instancePath, keyword, message, params, schemaPath } = e;
      const property = `${instancePath}`;
      const label = `${property} ${message}`.trim();

      return {
        name: keyword,
        property,
        message: message as string,
        params,
        stack: label.startsWith('.') ? label.slice(1, label.length) : label,
        schemaPath,
      };
    });
  }

  return filteredErrors;
};

const toErrorSchema = (errors: AjvError[]): {} => {
  if (!errors.length) {
    return {};
  }
  return errors.reduce((errorSchema, error) => {
    const { property, message } = error;
    const path = _.toPath(property);
    let parent: { [key: string]: unknown } = errorSchema;

    if (path.length > 0 && path[0] === '') {
      path.splice(0, 1);
    }

    for (const segment of path.slice(0)) {
      if (!(segment in parent)) {
        parent[segment] = {};
      }
      parent = parent[segment] as { [key: string]: unknown };
    }

    if (Array.isArray(parent.__errors)) {
      parent.__errors = parent.__errors.concat(message);
    } else {
      if (message) {
        parent.__errors = [message];
      }
    }
    return errorSchema;
  }, {});
};

const formatAvjErrors = (
  errors: Partial<ErrorObject>[],
  schema: IJSONSchema
): {
  errors: AjvError[];
  errorSchema: {};
} => {
  const language = i18n.language || 'en-US';
  const cultureInfo = new CultureInfo(language);
  const locale = cultureInfo.TwoLetterLanguageName;
  const errorsCopy = cloneDeepWithMetadata(errors);
  const localizeFunc = localize[locale as keyof typeof localize];
  localizeFunc?.(errorsCopy as ErrorObject[]);
  const readable = transformAjvErrors(errors, schema);

  const errorSchema = toErrorSchema(readable);

  return {
    errors: readable,
    errorSchema,
  };
};

const generateValidateFunction = (
  schema: IJSONSchema,
  ajv = validationAjv
): { validate?: ValidateFunction; errors?: AjvError[]; errorSchema?: {} } => {
  let validate;

  if (validationCache.has(schema)) {
    return { validate: validationCache.get(schema) };
  }

  try {
    validate = ajv.compile(schema);
  } catch (e) {
    if (e.message.includes('already exists') || e.message.includes('resolves to more than one schema')) {
      console.debug(`Clearing ajv cache because schemas were changed. ${e.message}`);
      ajv.removeSchema(/.*/);
      try {
        validate = ajv.compile(schema);
      } catch (e) {
        console.error(`Clearing ajv cache didn't help`, e);
        throw e;
      }
    } else {
      console.error(`Unhandled schema compilation error for schema ${schema.$id}`, e);
      return {
        errors: [
          {
            name: 'Schema compilation error',
            message: e.message,
            params: '',
            property: '',
            stack: e.message,
          },
        ],
        errorSchema: {},
      };
    }
  }

  validationCache.set(schema, validate);
  return { validate };
};

export const validateFormData = (
  formData: IDataItem[0],
  schema: IJSONSchema,
  ajv: Ajv = validationAjv
): {
  errors: AjvError[];
  errorSchema: {};
} => {
  const validationSchema = schema.cp_validationSchema ?? schema;

  const { validate, errors } = generateValidateFunction(validationSchema, ajv);

  if (errors) {
    return {
      errors: errors,
      errorSchema: {},
    };
  }
  if (!validate) {
    console.error(`Missing validate function for schema: ${JSON.stringify(validationSchema)}`);
    return {
      errors: [],
      errorSchema: {},
    };
  }
  try {
    // TODO: Fix on ApiGateway side
    if (validationSchema.type === 'string' && validationSchema.format === 'date-time' && typeof formData === 'string' && !formData.endsWith('Z')) {
      validate(formData + 'Z');
    } else {
      validate(formData);
    }
  } catch (e) {
    console.warn(e);
    if (e instanceof Ajv.ValidationError) {
      return formatAvjErrors(e.errors, validationSchema);
    }
  }

  return validate.errors
    ? formatAvjErrors(validate.errors, validationSchema)
    : {
        errors: [],
        errorSchema: {},
      };
};

export const isValid = (schema: IJSONSchema, data: IDataItem[0], ajv: Ajv = validationAjv): boolean => {
  try {
    return validateFormData(cloneDeepWithMetadata(data), schema, ajv).errors.length === 0;
  } catch (e) {
    return false;
  }
};
