import React, { useCallback, useMemo, useState } from 'react';
import { IDataItem, IRelatedMessage } from '@cpa/base-core/types';
import * as _ from 'lodash';
import { executeBackendAction } from '@cpa/base-core/helpers';
import { axiosDictionary } from '@cpa/base-core/api';
import { IColumn } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import MessageItem from './components/MessageItem/MessageItem';
import styles from './MessageContainer.module.scss';
import MessageInput from './components/MessageInput/MessageInput';

interface IMessageContainerProps {
  parentMessage: IDataItem;
  childItems: IDataItem[];
  isFetching: boolean;
  totalItems: number;
  pageIdentifier: string;
  lastRead?: string;
  columns: IColumn[];
}

const MessageContainer: React.FC<IMessageContainerProps> = ({
  columns,
  parentMessage,
  childItems,
  pageIdentifier,
  lastRead,
  totalItems,
  isFetching,
}) => {
  const [relatedMessage, setRelatedMessage] = useState<IRelatedMessage | null>(null);
  const [fetchingMessages, setFetchingMessages] = useState<number>(totalItems);
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);

  const onMessageReply = useCallback((relatedUserMessage: IRelatedMessage) => {
    setRelatedMessage(relatedUserMessage);
  }, []);

  const onMessageInView = useCallback(
    _.debounce(async (messageIdentifier: string) => {
      await executeBackendAction(axiosDictionary.appDataService, pageIdentifier, 'read-message', [messageIdentifier]);
    }, 1000),
    [childItems, pageIdentifier]
  );

  const handleContentLoaded = useCallback(() => {
    setFetchingMessages((prev) => (prev === 0 ? prev : prev - 1));
  }, []);

  const messages = useMemo(() => {
    const lastReadMessageIndex = childItems.findIndex((item) => item.identifier === lastRead);
    return childItems.map((message, index) => {
      const isOutgoingMessage = message.createdByUser === user;
      const isMessageRead = lastReadMessageIndex === -1 ? false : !lastRead ? false : index <= lastReadMessageIndex;
      return (
        <MessageItem
          key={index}
          message={message}
          onMessageReply={onMessageReply}
          onMessageInView={onMessageInView}
          isRead={isOutgoingMessage || isMessageRead}
          isFetching={isFetching}
          isAllMessagesLoaded={fetchingMessages === 0}
          onContentLoaded={handleContentLoaded}
        />
      );
    });
  }, [childItems, lastRead, user, onMessageReply, onMessageInView, isFetching, fetchingMessages, handleContentLoaded]);

  const unsetRelatedMessage = useCallback(() => {
    setRelatedMessage(null);
  }, []);

  const childrenWidth = useMemo(() => {
    const column = columns.find((col) => col.key === '__simpleCard');
    if (column) return column.calculatedWidth;
    return undefined;
  }, [columns]);

  return (
    <div className={styles.container} style={{ width: childrenWidth }}>
      <div>{messages}</div>
      <div>
        <MessageInput parentMessage={parentMessage} relatedMessage={relatedMessage} unsetRelatedMessage={unsetRelatedMessage} />
      </div>
    </div>
  );
};

export default MessageContainer;
