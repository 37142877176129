import { TypeConstants } from '@cp/base-types';
import { axiosDictionary } from '@cpa/base-core/api';
import { Environment } from '@cpa/base-core/app/environment';
import { FormContext, bootstrapDarkThemeHref, bootstrapLightThemeHref } from '@cpa/base-core/constants';
import { getAppBasePath } from '@cpa/base-core/helpers';
import { useLoadableData } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import LoadingArea from '../../../../../../../LoadingArea/LoadingArea';

import { filePick, imageUpload, pastePreProcess } from './handler';

import 'tinymce/icons/default/icons';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/codesample/plugin';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/emoticons/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/hr/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/print/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/template/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/themes/silver/theme';
import 'tinymce/tinymce';

// import 'tinymce/plugins/paste/plugin';
import styles from './TinyEditor.module.scss';
import { colorMap } from './colorMap';
import registerBootstrapPlugin from './plugins/bootstrap';
import registerInsertContentPlugin from './plugins/insertContent';
import registerPastePlugin from './plugins/paste'; // Forked because of bug

import { DataServiceModules } from '@cp/base-utils';

registerPastePlugin();
registerBootstrapPlugin();
registerInsertContentPlugin();

interface ITinyEditorProps {
  value: string;
  onChange: (content: string | boolean | number | object | null | undefined) => void;
  readOnly: boolean;
  bounds?: string;
  emptyValue?: boolean | number | string | object | null;
  onBlur?: () => void;
  placeholder?: string;
  customToolbarButtons?: string;
}

const TinyEditor: React.FC<ITinyEditorProps> = ({ value, onChange, readOnly, emptyValue, onBlur, placeholder, customToolbarButtons }) => {
  const name = useSelector((state: IGlobalState) => state.auth.user?.account?.name || '-');
  const email = useSelector((state: IGlobalState) => state.auth.user?.account.email || '-');
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const displaySourceCodeOption = useMemo(() => Environment.env.REACT_APP_EDITOR_SOURCE_CODE_OPTION === 'true', []);

  const formContext = useContext(FormContext);

  // Register/Unregister editor reference to form context
  const editorRef = useRef<Editor | null>(null);

  function onEditorRefChanges(editor: Editor): void {
    if (editorRef.current === null && editor !== null) {
      formContext.registerEditor(editor);
      editorRef.current = editor;
    }
  }

  useEffect(() => {
    return () => {
      if (editorRef?.current) {
        formContext.unregisterEditor(editorRef.current);
      }
    };
  }, [formContext]);

  const { loadItems, isFetching, items } = useLoadableData(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpHtmlTemplate)}`,
    axiosDictionary.appDataService
  );

  useEffect(() => {
    loadItems(
      {
        filter: {
          'sharedWithCpas/identifier': { eq: Environment.env.REACT_APP_CPA_NAME },
        },
      },
      { overwriteExistingData: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates = useMemo(() => {
    return items.map((template) => ({
      title: template.name,
      description: template.description,
      content: template.value,
    }));
  }, [items]);

  const toolbarButtons = useMemo(() => {
    let _toolbarButtons =
      'undo redo | removeformat | formatselect | bold italic underline strikethrough blockquote hr' +
      ' | forecolor backcolor | link image emoticons | fullscreen | alignleft aligncenter alignright' +
      ' | bullist numlist outdent indent table | codesample insertContent template';

    // Special treatment for source code & template option depending from .env of CPA
    if (displaySourceCodeOption) {
      if (_toolbarButtons.length > 0) {
        _toolbarButtons += ' | ';
      }
      _toolbarButtons += 'code';
    }

    return _toolbarButtons;
  }, [displaySourceCodeOption]);

  const extendedValidElements = useMemo(() => {
    let _extendedValidElements = '';

    // Special treatment for source code option depending from .env of CPA
    if (displaySourceCodeOption) {
      if (_extendedValidElements.length > 0) {
        _extendedValidElements += ',';
      }

      _extendedValidElements +=
        'style,link[href|rel],script[language|type|src],video[width|height|poster|autoplay|muted|loop|playsinline|class],a[*]';
    }

    return _extendedValidElements;
  }, [displaySourceCodeOption]);

  const customElements = useMemo(() => {
    let _customElements = '';

    // Special treatment for source code option depending from .env of CPA
    if (displaySourceCodeOption) {
      if (_customElements.length > 0) {
        _customElements += ',';
      }
      _customElements += 'style,link,~link';
    }

    return _customElements;
  }, [displaySourceCodeOption]);

  const onHtmlChange = useCallback(
    (html: string) => {
      if (!html || html === '<br data-mce-bogus="1">') {
        onChange(emptyValue);
        return;
      }
      onChange(html);
    },
    [emptyValue, onChange]
  );

  if (isFetching)
    return (
      <div style={{ height: '300px' }}>
        <LoadingArea />
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <Editor
        ref={onEditorRefChanges}
        // We use empty string instead of undefined
        // because TinyMCE starts working in stateful mode if undefined is passed
        value={value ?? ''}
        init={
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
          {
            height: 300,
            menubar: false,
            statusbar: true,
            branding: false,
            entity_encoding: 'raw',
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor emoticons',
              'searchreplace visualblocks code insertContent',
              'insertdatetime media table paste wordcount hr',
              'codesample fullscreen template bootstrap',
            ],
            default_link_target: '_blank',
            formats: {
              light: { block: 'div', attributes: { title: 'Light' }, styles: { fontWeight: '300' } },
            },
            toolbar: customToolbarButtons ?? toolbarButtons,
            toolbar_mode: 'sliding',
            // Due to SEO rules we avoid h1
            block_formats: 'Header 2=h2; Header 3=h3; Header 4=h4; Normal=div; Light=light; Note=samp;',
            extended_valid_elements: extendedValidElements,
            custom_elements: customElements,
            codesample_languages: [
              { text: 'Text', value: 'text' },
              { text: 'HTML/XML', value: 'markup' },
              { text: 'JavaScript', value: 'javascript' },
              { text: 'TypeScript', value: 'typescript' },
              { text: 'CSS', value: 'css' },
              { text: 'JSON', value: 'json' },
              { text: 'YAML', value: 'yaml' },
              { text: 'Python', value: 'python' },
              { text: 'Java', value: 'java' },
              { text: 'C', value: 'c' },
              { text: 'C#', value: 'csharp' },
              { text: 'C++', value: 'cpp' },
              { text: 'SQL', value: 'sql' },
              { text: 'Bash', value: 'bash' },
            ],
            convert_urls: false,
            valid_children: '+a[div],+a[span],+a[img]',
            relative_urls: false,
            template_cdate_classes: 'cdate creationdate',
            template_mdate_classes: 'mdate modifieddate',
            template_selected_content_classes: 'selcontent',
            template_cdate_format: '%m/%d/%Y : %H:%M:%S',
            template_mdate_format: '%m/%d/%Y : %H:%M:%S',
            template_replace_values: {
              user_name: name,
              user_email: email,
            },
            templates: templates,
            file_picker_types: 'image',
            color_map: colorMap,
            custom_colors: false,
            images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
            init_instance_callback: (editor: any) => {
              // TODO: Remove this workaround once the issue is resolved - https://github.com/tinymce/tinymce/issues/5746
              // https://stackoverflow.com/questions/34481959/tinymce-show-character-count-instead-of-word-count
              const button: HTMLElement | null = editor?.editorContainer?.querySelector('button.tox-statusbar__wordcount') || null;
              if (button) {
                button.click();
              }
            },
            images_upload_handler: imageUpload,
            file_picker_callback: Environment.env.REACT_APP_ALLOW_IMAGE_EMBEDDING ? filePick : undefined,
            forced_root_block: 'div',
            paste_data_images: true,
            /* No more base64 images - we'll use blob and img upload only
            images_dataimg_filter: (): boolean => {
              return false; // Blocks convert data image into blob and creates base64 instead
            },
            */
            paste_preprocess: pastePreProcess,
            // https://www.tiny.cloud/docs-3x/reference/Configuration3x/Configuration3x@valid_elements/
            paste_word_valid_elements:
              '-strong/b,-em/i,-u,-span,-p,-ol,-ul,-li,-h1,-h2,-h3,-h4,-h5,-h6,' +
              '-p/div,-a[href|name],sub,sup,strike,br,del,table[width],tr,' +
              'td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody,' +
              'img[alt|class|height|src|style|width]',
            paste_retain_style_properties: 'all',
            skin_url: darkMode ? `${getAppBasePath()}/vendor/tinymce/theme/cosmo-dark` : `${getAppBasePath()}/vendor/tinymce/theme/cosmo-light`,
            content_css: [
              ...[darkMode ? bootstrapDarkThemeHref : bootstrapLightThemeHref],
              ...[darkMode ? `${getAppBasePath()}/vendor/tinymce/content/dark.css` : `${getAppBasePath()}/vendor/tinymce/content/light.css`],
            ].join(','),
            placeholder: placeholder,
          }
        }
        outputFormat="html"
        disabled={readOnly}
        onEditorChange={onHtmlChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default React.memo(TinyEditor);
