import { DataItemProperties, IDataItem, IJSONSchema, Schemas, TypeConstants } from '@cp/base-types';
import { cloneDeepWithMetadata, DataServiceModules, resolveSubjectUri } from '@cp/base-utils';
import { axiosDictionary, getEntitiesFromEndpoint } from '@cpa/base-core/api';
import { getCpShareLink, showDialog } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { hideDialog } from '@cpa/base-core/store/app/actions';
import { GlobalDialogType, IComponentWithOptions, ISingleItemOptions, ISingleItemTemplateProps } from '@cpa/base-core/types';
import { DialogType, Icon, IconButton, Spinner } from '@fluentui/react';
import classNames from 'classnames';
import React, { NamedExoticComponent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import HoverTooltip from '../../../components/HoverTooltip/HoverTooltip';
import HtmlContent from '../../../components/HtmlContent/HtmlContent';
import { ScreenTypesConfiguration } from '../../../mapping';
import SingleItemCover from '../../../screens/GenericScreen/components/SingleItem/SingleItemCover/SingleItemCover';
import GenericScreen from '../../../screens/GenericScreen/GenericScreen';

import Drawer from './../../../components/Drawer/Drawer';
import ExpertCard from './components/ExpertsList/components/ExpertCard/ExpertCard';
import ExpertsList from './components/ExpertsList/ExpertsList';
import LazyIframe from './components/LazyIframe/LazyIframe';
import Localization from './components/Localization/Localization';
import Picker from './components/Picker/Picker';
import ProcessInformation from './components/ProcessInformation/ProcessInformation';
import QuestionsWidget from './components/QuestionsWidget/QuestionsWidget';
import SaleableList from './components/SaleableList/SaleableList';
import SubPage from './components/SubPage/SubPage';
import SuggestedSolutions from './components/SuggestedSolutions/SuggestedSolutions';
import TableOfContents from './components/TableOfContents/TableOfContents';
import Tags from './components/Tags/Tags';
import TargetWidget, { TargetWidgetSize } from './components/TargetWidget/TargetWidget';
import UspsList from './components/UspsList/UspsList';
import WelcomeDialog from './components/WelcomeDialog/WelcomeDialog';
import usePlatformColors from './hooks';
import dark from './styles/dark.module.scss';
import light from './styles/light.module.scss';
import { buildItem, buildSchema, isType } from './utils';

type Unpacked<T> = T extends (infer U)[] ? U : T;

type SolutionTypeDetails = Unpacked<Schemas.Solution['solutionTypeDetails']>;

export type Expert = Extract<SolutionTypeDetails, { _type?: 'http://platform.cosmoconsult.com/ontology/Expert' }>;

type Components = Extract<
  SolutionTypeDetails,
  {
    _type?: 'http://platform.cosmoconsult.com/ontology/Components';
  }
>['components'];

type Component = Extract<Components, {}>[0];

export type Quantity = { value?: number | { formula?: string }; unitCode?: string };

const SIDE_PAGES = [TypeConstants.Rating];

const isImageElement = (element: EventTarget): element is HTMLImageElement => (element as HTMLElement).tagName === 'IMG';

const getValidVideoCount = (values: string[]): number => {
  return values.filter((value) => {
    const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return !!regex.exec(value)?.[3];
  }).length;
};

enum ScopeType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

const SolutionSingleItemTemplate: React.FC<
  ISingleItemTemplateProps & {
    item: Schemas.Solution;
    disableSidePages?: boolean;
  }
> = ({ page, schema, item, subPages, subPageFilter, subPagePrefill, disableSidePages = false, isFetching, messagesHeight }) => {
  const dispatch = useDispatch();

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);
  const styles = useMemo<Record<string, string>>(() => (darkMode ? dark : light), [darkMode]);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1000px)' });
  const [t] = useTranslation();

  const itemData = useMemo(() => cloneDeepWithMetadata(buildItem(item)), [item]);
  const schemaData = useMemo(() => cloneDeepWithMetadata(buildSchema(schema)), [schema]);

  usePlatformColors(itemData.target?.productPlatforms?.map((platform) => platform.identifier) as string[], disableSidePages);

  const urlIconProps = useMemo(() => ({ iconName: 'NavigateExternalInline' }), []);
  const urlTooltip = useMemo(() => <HtmlContent html={schemaData.urlDescription} />, [schemaData.urlDescription]);

  const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>([]);
  const [selectedSolutionDomains, setSelectedSolutionDomains] = useState<string[]>([]);
  const [tocExpanded, setTocExpanded] = useState(!isMobileDevice);
  const [welcomeDialogVisible, setWelcomeDialogVisible] = useState<boolean>(false);
  const [forceTocUpdate, setForceTocUpdate] = useState<number>(0);

  const cpaIdentifier = useSelector((store: IGlobalState) => store.app.cpa?.identifier);

  const [cachedSolutions, addSolutionToCache] = useReducer(
    (solutions: Record<string, Schemas.Solution>, newSolution: Schemas.Solution) => ({
      ...solutions,
      [newSolution.identifier!]: newSolution,
    }),
    {}
  );

  // We need to render table of contents and welcome dialog only when solution content is on screen.
  const [containerRef, isContentOnScreen] = useInView({
    triggerOnce: true,
    threshold: 0,
    skip: isFetching,
    rootMargin: `-${85 + messagesHeight}px`,
  });

  const requestedSolutions = useRef<string[]>([]);
  const findSolution = useCallback(
    (identifier: string | undefined): Schemas.Solution | undefined => {
      if (!identifier) return;
      if (!requestedSolutions.current.includes(identifier)) {
        requestedSolutions.current.push(identifier);
        Promise.all(
          getEntitiesFromEndpoint(
            axiosDictionary.appDataService,
            `${DataServiceModules.DATA_STORE}/${encodeURIComponent(
              'http://platform.cosmoconsult.com/ontology/Solution'
            )}?$top=1&$filter=${encodeURIComponent(`identifier eq '${identifier}'`)}`
          )
        )
          .then((r) => r.flatMap((response) => response.entities))
          .then((solutions) => {
            for (const solution of solutions as Schemas.Solution[]) {
              if (!solution.identifier) continue;
              addSolutionToCache(solution);
            }
          });
      }
      return cachedSolutions[identifier];
    },
    [cachedSolutions, addSolutionToCache]
  );

  const solutionWrapperRef = useRef<HTMLElement>(null);

  const [billingDurations, setBillingDurations] = useState<Schemas.BillingDuration[]>([]);
  useEffect(() => {
    (async (): Promise<void> => {
      setBillingDurations(
        (
          await Promise.all(
            getEntitiesFromEndpoint(
              axiosDictionary.appDataService,
              `${DataServiceModules.DATA_STORE}/${encodeURIComponent('http://platform.cosmoconsult.com/ontology/BillingDuration')}`
            )
          )
        ).flatMap((response) => response.entities) as Schemas.BillingDuration[]
      );
    })();
  }, []);
  const findBillingDuration = useCallback(
    (identifier: string): string | undefined => billingDurations.find((billingDuration) => billingDuration.identifier == identifier)?.name,
    [billingDurations]
  );
  const formatBillingDuration = useCallback(
    (billingDuration: string) => {
      const billingDurationName = findBillingDuration(billingDuration);
      if (!billingDurationName) return;
      return billingDurationName;
    },
    [findBillingDuration]
  );

  const [unitCodes, setUnitCodes] = useState<Schemas.UnitOfMeasure[]>([]);
  useEffect(() => {
    (async (): Promise<void> => {
      setUnitCodes(
        (
          await Promise.all(
            getEntitiesFromEndpoint(
              axiosDictionary.appDataService,
              `${DataServiceModules.DATA_STORE}/${encodeURIComponent('http://platform.cosmoconsult.com/ontology/UnitOfMeasure')}`
            )
          )
        ).flatMap((response) => response.entities) as Schemas.UnitOfMeasure[]
      );
    })();
  }, []);
  const findUnitCode = useCallback(
    (identifier: string): string | undefined => unitCodes.find((unitCode) => unitCode.identifier == identifier)?.name,
    [unitCodes]
  );
  const formatQuantity = useCallback(
    (quantity: Quantity) => {
      const value = quantity?.value;
      if (!quantity || !value || !quantity.unitCode) return;
      const unitCode = findUnitCode(quantity.unitCode);
      if (!unitCode) return;
      return `${JSON.stringify(value)} ${unitCode}`;
    },
    [findUnitCode]
  );

  const [jobRoles, setJobRoles] = useState<Schemas.JobRole[]>([]);
  const jobRoleId = schemaData.solutionScopes.jobRoleId;
  useEffect(() => {
    (async (): Promise<void> => {
      if (!jobRoleId) return;
      const jobRoles = (
        await Promise.all(
          getEntitiesFromEndpoint(axiosDictionary.appDataService, `${DataServiceModules.DATA_STORE}/${encodeURIComponent(jobRoleId)}`)
        )
      ).flatMap((response) => response.entities);
      setJobRoles(jobRoles as Schemas.JobRole[]);
    })();
  }, [jobRoleId]);

  const [solutionDomains, setSolutionDomains] = useState<{ name: string; identifier: string }[]>([]);
  useEffect(() => {
    (async (): Promise<void> => {
      const solutionDomains = (
        await Promise.all(
          getEntitiesFromEndpoint(
            axiosDictionary.appDataService,
            `${DataServiceModules.DATA_STORE}/${encodeURIComponent('http://platform.cosmoconsult.com/ontology/SolutionDomain')}`
          )
        )
      ).flatMap((response) => response.entities);
      setSolutionDomains(solutionDomains as { name: string; identifier: string }[]);
    })();
  }, []);

  const solutionScopes = useMemo(() => {
    const matchedDomains: string[] = [];
    const externalDomains =
      itemData.solutionScopes?.solutionScopes?.map((scope, index) => {
        const external = scope.sdcs || [];
        return {
          solutionDomain: scope.solutionDomain?.identifier,
          sdcs: [
            ...external.map((sdc, sdcIndex) => ({
              ...sdc,
              scopeType: ScopeType.External,
              scopeIndex: index,
              sdcIndex,
            })),
          ],
        };
      }) || [];
    const internalDomains =
      itemData.solutionScopes?.internalSolutionScopes?.map((scope, index) => {
        const internal = scope.sdcs || [];
        return {
          solutionDomain: scope.solutionDomain?.identifier,
          sdcs: [
            ...internal.map((sdc, sdcIndex) => ({
              ...sdc,
              scopeType: ScopeType.Internal,
              scopeIndex: index,
              sdcIndex,
              // TODO: remove ignore after ontology fix
              // @ts-ignore
              alternateName: sdc.alternateName || undefined,
            })),
          ],
        };
      }) || [];
    for (const externalDomain of externalDomains) {
      const foundInternalDomain = internalDomains.find((internalDomain) => internalDomain?.solutionDomain === externalDomain?.solutionDomain);
      if (foundInternalDomain) {
        externalDomain.sdcs.push(...foundInternalDomain.sdcs);
        matchedDomains.push(foundInternalDomain.solutionDomain as string);
      }
    }
    const noMatchDomains = internalDomains.filter((domain) => !matchedDomains.includes(domain.solutionDomain as string));
    return [...externalDomains, ...noMatchDomains];
  }, [itemData.solutionScopes?.internalSolutionScopes, itemData.solutionScopes?.solutionScopes]);

  const renderedJobRoles = useMemo((): string[] => {
    const jobRolesIdentifiers: Set<string> = new Set();
    if (!solutionScopes) return [];
    const filteredScopes = solutionScopes.filter((scope) => scope.solutionDomain && selectedSolutionDomains.includes(scope.solutionDomain));
    for (const domain of filteredScopes) {
      for (const sdc of domain.sdcs) {
        if (!sdc.jobRole) continue;
        const sdcFilteredContentLength = Object.keys(cloneDeepWithMetadata(sdc)).filter((key) => key !== 'jobRole').length;
        if (!sdcFilteredContentLength || !sdc.jobRole.identifier) continue;
        jobRolesIdentifiers.add(sdc.jobRole.identifier);
      }
    }
    return Array.from(jobRolesIdentifiers) as string[];
  }, [selectedSolutionDomains, solutionScopes]);

  const componentsDetailsItems = useMemo(
    () => itemData.components?.components?.map((item: Component) => findSolution(item.solution.identifier)) ?? [],
    [findSolution, itemData.components?.components]
  );

  const featuredExpert: { email: string; role?: string } | undefined = useMemo(() => {
    const sellingExpert = itemData.experts.find((expert) => expert.expertType === 'http://platform.cosmoconsult.com/ontology/Selling');
    if (sellingExpert) {
      return {
        email: sellingExpert.email as string,
        role: schemaData.expertTypeTitles?.[sellingExpert.expertType],
      };
    }

    // Filter out owners of type 'CpPermissionRole'
    const userOwners = itemData.owners?.filter((owner) => typeof owner === 'string') as string[] | undefined;

    // Select first owner as featured expert
    const owner = userOwners?.[0];
    if (owner) {
      return {
        email: owner,
      };
    }
    return;
  }, [itemData.experts, itemData.owners, schemaData.expertTypeTitles]);

  const hideSidePages = isMobileDevice || disableSidePages;

  const handleHtmlContentClick = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    if (isImageElement(e.target)) {
      await showDialog({
        type: GlobalDialogType.CUSTOM,
        title: ' ',
        message: ' ',
        dialogContentProps: {
          type: DialogType.close,
        },
        closeOnClickOutside: true,
        dialogTypeOptions: {
          renderBody: () => {
            return <img className={styles.dialogImage} src={(e.target as unknown as { src: string }).src} />;
          },
        },
        width: '90%',
      });
    }
  };

  const onJobRoleChange = useCallback(
    (selectedRoles: string[]) => {
      const [firstRole] = renderedJobRoles;
      setSelectedJobRoles(renderedJobRoles.length === 1 ? [firstRole] : selectedRoles);
    },
    [renderedJobRoles]
  );

  const renderedSolutionDomains = useMemo(() => {
    const solutionDomainIdentifiers: Set<string> = new Set();
    if (!solutionScopes) return [];
    for (const scope of solutionScopes) {
      for (const sdc of scope.sdcs) {
        if (!sdc.jobRole) continue;
        const sdcFilteredContentLength = Object.keys(cloneDeepWithMetadata(sdc)).filter((key) => key !== 'jobRole').length;
        if (!sdcFilteredContentLength || !scope.solutionDomain) continue;
        solutionDomainIdentifiers.add(scope.solutionDomain);
      }
    }
    return Array.from(solutionDomainIdentifiers) as string[];
  }, [solutionScopes]);

  useEffect(() => {
    if (renderedSolutionDomains.length === 1) {
      setSelectedSolutionDomains([renderedSolutionDomains[0]]);
    }
  }, [renderedSolutionDomains]);

  useEffect(() => {
    if (renderedJobRoles.length === 1) {
      setSelectedJobRoles([renderedJobRoles[0]]);
    }
  }, [renderedJobRoles]);

  const onSolutionDomainChange = useCallback(
    (selectedDomains: string[]) => {
      if (renderedSolutionDomains.length == 1) {
        setSelectedSolutionDomains([renderedSolutionDomains[0]]);
      } else {
        setSelectedSolutionDomains(selectedDomains);
      }
    },
    [renderedSolutionDomains]
  );

  const solutionDomainSelectorRender = useCallback(() => {
    if (renderedSolutionDomains.length < 2) return null;
    return (
      <Picker
        title={schemaData.solutionScopes.solutionDomainTitle}
        className={classNames({ [styles.jobRolePicker]: !isMobileDevice && tocExpanded })}
        items={solutionDomains}
        renderedItems={renderedSolutionDomains}
        selectedItems={selectedSolutionDomains}
        onChange={onSolutionDomainChange}
        urlQueryKey={'solutionDomains'}
        localStorageKey={'trySolutionDomains'}
      />
    );
  }, [
    schemaData.solutionScopes.solutionDomainTitle,
    isMobileDevice,
    onSolutionDomainChange,
    renderedSolutionDomains,
    selectedSolutionDomains,
    solutionDomains,
    styles.jobRolePicker,
    tocExpanded,
  ]);

  const jobRoleSelectorRender = useCallback(() => {
    if (renderedJobRoles.length < 2) return null;
    return (
      <Picker
        title={schemaData.solutionScopes.jobRoleTitle}
        items={jobRoles as IDataItem[]}
        renderedItems={renderedJobRoles}
        onChange={onJobRoleChange}
        selectedItems={selectedJobRoles}
        className={classNames({ [styles.jobRolePicker]: !isMobileDevice && tocExpanded })}
        urlQueryKey={'jobRoles'}
        localStorageKey={'tryJobRoles'}
      />
    );
  }, [
    schemaData.solutionScopes.jobRoleTitle,
    renderedJobRoles,
    styles.jobRolePicker,
    isMobileDevice,
    tocExpanded,
    jobRoles,
    onJobRoleChange,
    selectedJobRoles,
  ]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlAction = url.searchParams.get('action');
    if (urlAction && urlAction.toUpperCase() === 'EDIT') return;
    const urlJobRoles = url.searchParams.get('jobRoles');
    const localStorageJobRoles = localStorage.getItem('tryJobRoles');
    const jobRolesFromStorage = localStorageJobRoles ? localStorageJobRoles.split(',').length : undefined;
    if ((renderedSolutionDomains.length === 1 && renderedJobRoles.length === 1) || !renderedJobRoles.length) {
      setWelcomeDialogVisible(false);
    } else if (!urlJobRoles && !jobRolesFromStorage && renderedSolutionDomains.length) {
      setWelcomeDialogVisible(true);
    }
  }, [renderedSolutionDomains.length, renderedJobRoles.length]);

  const handeTocItemClick = useCallback(() => {
    if (isMobileDevice) {
      setTocExpanded(false);
    }
  }, [isMobileDevice]);

  const tocHeaderRender = useCallback(() => {
    return (
      <div className={styles.drawerHeader}>
        {solutionDomainSelectorRender()}
        {jobRoleSelectorRender()}
      </div>
    );
  }, [jobRoleSelectorRender, solutionDomainSelectorRender, styles.drawerHeader]);

  const onForceTocUpdate = useCallback(() => {
    setForceTocUpdate((prev) => prev + 1);
  }, []);

  const tableOfContents = useMemo(() => {
    return (
      <div className={styles.tocWrapper}>
        {/*
          Show TOC when no job role found in schema (due to missing 'scopeOfSolutions' property,
          or if job roles could be resolved properly. )
        */}
        {(!jobRoleId && isContentOnScreen) || (jobRoleId && jobRoles.length && isContentOnScreen) ? (
          <TableOfContents
            isMobileDevice={isMobileDevice}
            solutionIdentifier={item.identifier}
            solutionWrapper={solutionWrapperRef.current}
            disableAnchors={disableSidePages}
            extraDependency={[selectedSolutionDomains, selectedJobRoles, forceTocUpdate].join(',')}
            renderHeader={isMobileDevice ? undefined : tocHeaderRender}
            onItemClick={handeTocItemClick}
            expanded={tocExpanded}
            onExpand={() => setTocExpanded(!tocExpanded)}
            renderCloseButton={!isMobileDevice}
            additionalSelectorsRendered={renderedSolutionDomains.length > 1 ? 1 : 0}
          />
        ) : (
          <Spinner style={{ marginTop: '50%' }} />
        )}
      </div>
    );
  }, [
    forceTocUpdate,
    renderedSolutionDomains.length,
    selectedSolutionDomains,
    styles.tocWrapper,
    jobRoleId,
    jobRoles.length,
    isContentOnScreen,
    item.identifier,
    disableSidePages,
    selectedJobRoles,
    tocHeaderRender,
    handeTocItemClick,
    tocExpanded,
    isMobileDevice,
  ]);

  const handleOpenToc = useCallback(() => {
    setTocExpanded(true);

    const singleItemCover = document.querySelector('[data-is-single-item-cover="true"]');

    if (singleItemCover) {
      const scrollableContainer = singleItemCover.closest('[data-is-scrollable="true"]');

      if (scrollableContainer) {
        scrollableContainer.scrollTo({ top: singleItemCover.clientHeight, behavior: 'smooth' });
      }
    }
  }, []);

  const onWelcomeDialogSubmit = useCallback(
    (domains: string[], jobRoles: string[]): void => {
      if (domains.length) {
        setSelectedSolutionDomains(domains);
      }
      setSelectedJobRoles(jobRoles);
      setWelcomeDialogVisible(false);
      dispatch(hideDialog());
    },
    [dispatch]
  );

  const onDialogDismiss = useCallback(() => {
    if (renderedSolutionDomains.length === 1) {
      setSelectedSolutionDomains(renderedSolutionDomains);
    }
    setSelectedJobRoles([]);
    const url = new URL(window.location.href);
    url.searchParams.delete('jobRoles');
    url.searchParams.delete('solutionDomains');
    localStorage.removeItem('tryJobRoles');
    localStorage.removeItem('trySolutionDomains');
    window.history.replaceState({}, '', url.toString());
    setWelcomeDialogVisible(false);
    dispatch(hideDialog());
  }, [dispatch, renderedSolutionDomains]);

  const handleDrawerClose = useCallback(() => {
    setTocExpanded(false);
  }, []);

  const solutionScopeIndex = useMemo(() => {
    const index = item.solutionTypeDetails?.findIndex(isType('http://platform.cosmoconsult.com/ontology/ScopeOfSolution'));
    return index && index === -1 ? undefined : index;
  }, [item.solutionTypeDetails]);

  const componentsIndex = useMemo(() => {
    const index = item.solutionTypeDetails?.findIndex(isType('http://platform.cosmoconsult.com/ontology/Components'));
    return index && index === -1 ? undefined : index;
  }, [item.solutionTypeDetails]);

  const questionsIndex = useMemo(() => {
    const index = item.solutionTypeDetails?.findIndex(isType('http://platform.cosmoconsult.com/ontology/QuestionsAndAnswers'));
    return index && index === -1 ? undefined : index;
  }, [item.solutionTypeDetails]);

  const saleableIndex = useMemo(() => {
    const index = item.solutionTypeDetails?.findIndex(isType('http://platform.cosmoconsult.com/ontology/Saleable'));
    return index && index === -1 ? undefined : index;
  }, [item.solutionTypeDetails]);

  const processInformationIndex = useMemo(() => {
    const index = item.solutionTypeDetails?.findIndex(isType('http://platform.cosmoconsult.com/ontology/ProcessInformation'));
    return index && index === -1 ? undefined : index;
  }, [item.solutionTypeDetails]);

  const targetIndex = useMemo(() => {
    const index = item.solutionTypeDetails?.findIndex(isType('http://platform.cosmoconsult.com/ontology/Target'));
    return index && index === -1 ? undefined : index;
  }, [item.solutionTypeDetails]);

  const commonLocalizationProps = useMemo(() => {
    return {
      page: page,
      rootSchema: schema,
      identifier: item.identifier as string,
      baseLanguage: item[DataItemProperties.BASE_LANGUAGE_KEY] as string,
    };
  }, [item, page, schema]);

  const itemImage = useMemo(() => {
    if (!darkMode && item.imageOnLightBackground) {
      return <img src={item.imageOnLightBackground as string} />;
    }
    if (item.image || item.imageOnLightBackground) return <img src={item.image || (item.imageOnLightBackground as string)} />;
    return null;
  }, [darkMode, item.image, item.imageOnLightBackground]);

  const commentPage = useMemo(() => {
    const comment = subPages.find((page) => page?.cpTypeUrl === TypeConstants.Comment);
    if (!comment) return null;
    return (
      <SubPage
        page={comment}
        item={item}
        schema={schema}
        prefill={subPagePrefill}
        filter={subPageFilter}
        classnames={classNames(styles.contentBlock, styles.noInnerWrapper)}
        handleHtmlContentClick={handleHtmlContentClick}
      />
    );
  }, [item, schema, styles.contentBlock, styles.noInnerWrapper, subPageFilter, subPagePrefill, subPages]);

  const getTeamsMessage = useCallback(
    async (personName: string) => {
      if (cpaIdentifier) {
        const shareLink = await getCpShareLink(cpaIdentifier, page, item);
        if (shareLink) {
          return `Hi ${personName}, I am contacting you because of following item: ${shareLink}`;
        } else {
          return null;
        }
      }
      return null;
    },
    [cpaIdentifier, item, page]
  );

  const questions = useMemo(() => {
    return itemData.questions?.questionsAndAnswers
      ?.map((qa) => {
        if (!qa?.questions?.length || !qa?.answer) return null;
        return {
          title: qa.questions[0],
          questions: qa.questions,
          answer: qa.answer,
        };
      })
      .filter(Boolean) as { title: string; questions: string[]; answer: string }[];
  }, [itemData.questions?.questionsAndAnswers]);

  return (
    <>
      <WelcomeDialog
        hidden={!welcomeDialogVisible || !isContentOnScreen}
        domains={solutionDomains}
        jobRoles={jobRoles as IDataItem[]}
        renderedDomains={renderedSolutionDomains}
        solutionScopes={solutionScopes}
        domainsTitle={schemaData.solutionScopes.solutionDomainTitle}
        jobRoleTitle={schemaData.solutionScopes.jobRoleTitle}
        onDismiss={onDialogDismiss}
        onSubmit={onWelcomeDialogSubmit}
      />
      <SingleItemCover dataItem={item} page={page} isFetching={false} schema={schema} extraContentHeight={messagesHeight} />
      {item.imageHeaderVisual && (
        <div
          style={{
            backgroundImage: `url(${CSS.escape(item.imageHeaderVisual)})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '300px',
          }}
        ></div>
      )}
      <div
        ref={containerRef}
        className={classNames({
          [styles.solutionWrapper]: true,
          [styles.mobile]: isMobileDevice,
          [styles.drawer]: disableSidePages,
          [styles.tocCollapsed]: !tocExpanded,
        })}
      >
        {!isMobileDevice ? (
          tableOfContents
        ) : (
          <Drawer onRenderHeader={tocHeaderRender} isOpen={tocExpanded} onClose={handleDrawerClose}>
            {tableOfContents}
          </Drawer>
        )}
        {isMobileDevice || (!isMobileDevice && !tocExpanded) ? (
          <div className={styles.tocLineWrapper}>
            <HoverTooltip content={t('common.openToc')}>
              <div className={styles.tocLine} onClick={handleOpenToc}>
                <Icon className={styles.tocIcon} iconName="ResponsesMenu" />
              </div>
            </HoverTooltip>
          </div>
        ) : null}
        <section className={styles.wrapper} ref={solutionWrapperRef}>
          <div className={styles.solutionHeader}>
            <div className={classNames({ [styles.solutionTitle]: true, [styles.titleMobile]: isMobileDevice })}>
              <div className={styles.row}>
                <div className={classNames(styles.itemFull, styles.header)}>
                  <div className={styles.title}>
                    {itemImage}
                    <Localization
                      {...commonLocalizationProps}
                      schema={schema.properties?.name as IJSONSchema | null}
                      dataPath={'[name]'}
                      top={14}
                      left={item.image ? -60 : undefined}
                    >
                      <h1>{item?.name}</h1>
                    </Localization>
                  </div>
                  {item?.url && (
                    <HoverTooltip content={urlTooltip}>
                      <IconButton iconProps={urlIconProps} href={item?.url} target="_blank" rel="noreferrer" />
                    </HoverTooltip>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <Tags tags={item.publicTags} path={page.path} />
              </div>
              <div className={styles.row}>
                {item?.shortDescription && (
                  <Localization
                    {...commonLocalizationProps}
                    schema={schema.properties?.shortDescription as IJSONSchema | null}
                    dataPath={'[shortDescription]'}
                    top={3}
                  >
                    <div className={styles.itemFull}>{item?.shortDescription}</div>
                  </Localization>
                )}
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.itemFull}>
              <div style={{ overflow: 'auto', overflowX: 'hidden' }} className={styles.row}>
                {!hideSidePages && (
                  <div className={styles.sidePagesFloating}>
                    <TargetWidget
                      size={TargetWidgetSize.Small}
                      platforms={itemData.target?.productPlatforms?.map((platform) => platform.identifier) as string[]}
                      supportedCountries={itemData.target?.supportedCountries?.map((supportedCountry) => supportedCountry.identifier) as string[]}
                      supportedLanguages={itemData.target?.supportedLanguages?.map((language) => language.identifier) as string[]}
                    />
                    {featuredExpert && (
                      <ExpertCard
                        email={featuredExpert.email}
                        role={featuredExpert.role}
                        topicAdditionalUsers={
                          itemData?.owners?.filter((owner) => typeof owner === 'string').filter((owner) => owner !== featuredExpert.email) as string[]
                        }
                        topicName={item.name}
                        topicMessage={getTeamsMessage}
                      />
                    )}
                    {subPages
                      .filter((subPage) => subPage.cpTypeUrl && SIDE_PAGES.includes(resolveSubjectUri(subPage.cpTypeUrl, prefixMap)))
                      .map((subPage: Schemas.CpaPage) => {
                        if (subPage.customTemplate?.identifier) {
                          const _Component = ScreenTypesConfiguration[subPage.customTemplate.identifier];
                          return (
                            <div key={subPage.identifier} className={classNames(styles.itemFull, styles.noInnerWrapper)}>
                              <_Component
                                item={item}
                                itemSchema={schema}
                                page={subPage}
                                externalODataFilter={subPageFilter}
                                isWidget={true}
                                externalPrefill={subPagePrefill}
                              />
                            </div>
                          );
                        }
                        return (
                          <div key={subPage?.identifier} className={classNames(styles.itemFull, styles.noInnerWrapper)}>
                            <GenericScreen
                              item={item}
                              itemSchema={schema}
                              page={subPage}
                              externalODataFilter={subPageFilter}
                              isWidget={true}
                              externalPrefill={subPagePrefill}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
                <div className={styles.contentBlock} onClick={handleHtmlContentClick}>
                  <Localization {...commonLocalizationProps} schema={schema.properties?.description as IJSONSchema} dataPath={'description'} top={20}>
                    <>
                      <h1 className={classNames({ [styles.schemaTitle]: true, [styles.titleHidden]: true })} data-heading={'description'}>
                        {schema.properties?.description?.title}
                      </h1>
                      <HtmlContent className={styles.htmlContent} html={item.description as string} />
                    </>
                  </Localization>
                </div>
                {hideSidePages && (
                  <div>
                    <TargetWidget
                      size={TargetWidgetSize.Small}
                      platforms={itemData.target?.productPlatforms?.map((platform) => platform.identifier) as string[]}
                      supportedCountries={itemData.target?.supportedCountries?.map((supportedCountry) => supportedCountry.identifier) as string[]}
                      supportedLanguages={itemData.target?.supportedLanguages?.map((language) => language.identifier) as string[]}
                    />
                    {featuredExpert && (
                      <ExpertCard
                        email={featuredExpert.email}
                        role={featuredExpert.role}
                        topicAdditionalUsers={
                          itemData?.owners?.filter((owner) => typeof owner === 'string').filter((owner) => owner !== featuredExpert.email) as string[]
                        }
                        topicName={item.name}
                        topicMessage={getTeamsMessage}
                      />
                    )}
                    {subPages
                      .filter((subPage) => subPage.cpTypeUrl && SIDE_PAGES.includes(resolveSubjectUri(subPage.cpTypeUrl, prefixMap)))
                      .map((subPage: Schemas.CpaPage) => {
                        if (subPage.customTemplate?.identifier) {
                          const _Component = ScreenTypesConfiguration[subPage.customTemplate.identifier];
                          return (
                            <div key={subPage.identifier} className={classNames(styles.itemFull, styles.noInnerWrapper)}>
                              <_Component
                                item={item}
                                itemSchema={schema}
                                page={subPage}
                                externalODataFilter={subPageFilter}
                                isWidget={true}
                                externalPrefill={subPagePrefill}
                              />
                            </div>
                          );
                        }
                        return (
                          <div key={subPage?.identifier} className={classNames(styles.itemFull, styles.noInnerWrapper)}>
                            <GenericScreen
                              item={item}
                              itemSchema={schema}
                              page={subPage}
                              externalODataFilter={subPageFilter}
                              isWidget={true}
                              externalPrefill={subPagePrefill}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
                {!!itemData.saleable?.offers?.length && schemaData.saleable?.properties?.offers ? (
                  <div>
                    <SaleableList
                      value={itemData.saleable.offers}
                      schema={schemaData.saleable.properties.offers}
                      formatQuantity={formatQuantity}
                      formatBillingDuration={formatBillingDuration}
                      renderHeader={() => (
                        <h1 className={styles.schemaTitle} data-heading={'offers'}>
                          {schemaData.saleable.properties.offers.title}
                        </h1>
                      )}
                      wrapperClassName={styles.contentBlock}
                      commonLocalizationProps={commonLocalizationProps}
                      dataIndex={saleableIndex}
                    />
                  </div>
                ) : null}
                {itemData.saleable?.solutionRecommendation ? (
                  <div className={styles.contentBlock}>
                    <SuggestedSolutions
                      renderHeader={() => (
                        <h1 className={styles.schemaTitle} data-heading={'solutionRecommendation'}>
                          {schemaData.saleable.properties.solutionRecommendation?.title}
                        </h1>
                      )}
                      page={page}
                      suggestedFilter={itemData.saleable?.solutionRecommendation?.suggestedSolutions}
                      suggestedLabel={schemaData.saleable.properties.solutionRecommendation?.properties?.suggestedSolutions?.title}
                      suggestedDescription={schemaData.saleable.properties.solutionRecommendation?.properties?.suggestedSolutions?.description}
                      crossSellFilter={itemData.saleable?.solutionRecommendation?.crossSellSolutions}
                      crossSellLabel={schemaData.saleable.properties.solutionRecommendation?.properties?.crossSellSolutions?.title}
                      crossSellDescription={schemaData.saleable.properties.solutionRecommendation?.properties?.crossSellSolutions?.description}
                      upsellFilter={itemData.saleable?.solutionRecommendation?.upsellSolutions}
                      upsellLabel={schemaData.saleable.properties.solutionRecommendation?.properties?.upsellSolutions?.title}
                      upsellDescription={schemaData.saleable.properties.solutionRecommendation?.properties?.upsellSolutions?.description}
                      forceTocUpdate={onForceTocUpdate}
                    />
                  </div>
                ) : null}
                {itemData.processInformation?.length && itemData.processInformation?.[0]?.businessProcessModelNotation?.bpmnDiagram ? (
                  <div className={styles.contentBlock} onClick={handleHtmlContentClick}>
                    <Localization
                      {...commonLocalizationProps}
                      schema={schemaData.processInformation.bpmnDiagram as IJSONSchema}
                      dataPath={`['solutionTypeDetails'][${processInformationIndex}]['businessProcessModelNotation']['bpmnDiagram']`}
                      top={15}
                    >
                      <ProcessInformation
                        title={schemaData.processInformation.title}
                        xml={itemData.processInformation?.[0]?.businessProcessModelNotation.bpmnDiagram as string}
                      ></ProcessInformation>
                    </Localization>
                  </div>
                ) : null}
                {solutionScopes?.length ? (
                  <div className={styles.contentBlock} onClick={handleHtmlContentClick}>
                    {solutionScopes
                      .filter((scope) => scope.solutionDomain && selectedSolutionDomains.includes(scope.solutionDomain))
                      .map((scope, scopeIndex) => {
                        if (!scope.solutionDomain) return null;
                        const foundDomain = solutionDomains.find((domain) => domain.identifier === scope?.solutionDomain);
                        const resolvedDomainName = foundDomain?.name || scope.solutionDomain;
                        const key = `${scope.solutionDomain}${scopeIndex}`;
                        return (
                          <div key={key}>
                            {scope.sdcs?.map((sdc, sdcIndex) => {
                              if (sdc.jobRole?.identifier && !selectedJobRoles.includes(sdc.jobRole.identifier)) return null;
                              const jobRole = jobRoles.find((role) => role.identifier === sdc.jobRole?.identifier);
                              const titleParts: string[] = [];
                              if (foundDomain?.identifier !== 'default') {
                                titleParts.push(resolvedDomainName);
                              }
                              if (jobRole && jobRole.identifier !== 'default') {
                                titleParts.push(jobRole.name);
                              }
                              titleParts.push(schemaData.solutionScopes.solutionScopeTitle);
                              const title = titleParts.join(' - ');
                              const headingId = `${foundDomain?.identifier} ${jobRole ? `- ${jobRole.identifier}` : ''} - solutionScopes`;
                              const sdcFilteredContentLength = Object.keys(cloneDeepWithMetadata(sdc)).filter((key) => key !== 'jobRole').length;
                              if (!sdcFilteredContentLength) return null;
                              return (
                                <div key={`${key}${sdcIndex}`}>
                                  <h1 className={styles.schemaTitle} data-heading={headingId}>
                                    {title}
                                  </h1>
                                  {sdc?.alternateName && (
                                    <div className={styles.itemFull}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.alternateName}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['alternateName']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'alternateName'}>
                                            {schemaData.solutionScopes?.alternateNameTitle}
                                          </h2>
                                          <span>{sdc.alternateName}</span>
                                        </>
                                      </Localization>
                                    </div>
                                  )}
                                  {sdc?.shortDescription && (
                                    <div className={styles.itemFull}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.shortDescription}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['shortDescription']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'shortDescription'}>
                                            {schemaData.solutionScopes?.shortDescriptionTitle}
                                          </h2>
                                          <span>{sdc.shortDescription}</span>
                                        </>
                                      </Localization>
                                    </div>
                                  )}
                                  {sdc?.description ? (
                                    <div className={styles.sdcBlock} onClick={handleHtmlContentClick}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.description}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['description']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'description'}>
                                            {schemaData.solutionScopes?.descriptionTitle}
                                          </h2>
                                          <HtmlContent className={styles.htmlContent} html={sdc.description} />
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc.challengesInfo?.challenges?.length ? (
                                    <div className={classNames(styles.sdcBlock, styles.contentWithImage)}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.challenges}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['challengesInfo']['challenges']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'challenges'}>
                                            {schemaData.solutionScopes?.challengesTitle}
                                          </h2>
                                          <div>
                                            {sdc.challengesInfo.image ? <img className={styles.image} src={sdc.challengesInfo.image} /> : null}
                                            {sdc.challengesInfo.challenges.length === 1 ? (
                                              <HtmlContent className={styles.htmlContent} html={sdc.challengesInfo.challenges[0]} />
                                            ) : (
                                              <ul style={{ paddingLeft: '15px' }}>
                                                {sdc.challengesInfo.challenges.map((text: string, index: number) => (
                                                  <li key={index}>
                                                    <HtmlContent className={styles.htmlContent} html={text} />
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </div>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc.descriptionOfSupport?.descriptions?.length ? (
                                    <div className={classNames(styles.sdcBlock, styles.contentWithImage)}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.supportDescription.properties.descriptions}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['descriptionOfSupport']['descriptions']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'support'}>
                                            {schemaData.solutionScopes?.supportDescriptionTitle}
                                          </h2>
                                          <div>
                                            {sdc.descriptionOfSupport.image ? (
                                              <img className={styles.image} src={sdc.descriptionOfSupport.image} />
                                            ) : null}
                                            {sdc.descriptionOfSupport.descriptions.length === 1 ? (
                                              <HtmlContent className={styles.htmlContent} html={sdc.descriptionOfSupport?.descriptions[0]} />
                                            ) : (
                                              <ul style={{ paddingLeft: '15px' }}>
                                                {sdc.descriptionOfSupport?.descriptions.map((text: string, index: number) => (
                                                  <li key={index}>
                                                    <HtmlContent className={styles.htmlContent} html={text} />
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </div>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc.intendedOutcomesInfo?.intendedOutcomes?.length ? (
                                    <div className={classNames(styles.sdcBlock, styles.contentWithImage)}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.intendedOutcomes}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['intendedOutcomesInfo'][intendedOutcomes]`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'intendedOutcomes'}>
                                            {schemaData.solutionScopes?.intendedOutcomesTitle}
                                          </h2>
                                          <div>
                                            {sdc.intendedOutcomesInfo.image ? (
                                              <img className={styles.image} src={sdc.intendedOutcomesInfo.image} />
                                            ) : null}
                                            {sdc.intendedOutcomesInfo.intendedOutcomes.length === 1 ? (
                                              <HtmlContent className={styles.htmlContent} html={sdc.intendedOutcomesInfo.intendedOutcomes[0]} />
                                            ) : (
                                              <ul style={{ paddingLeft: '15px' }}>
                                                {sdc.intendedOutcomesInfo.intendedOutcomes.map((text: string, index: number) => (
                                                  <li key={index}>
                                                    <HtmlContent className={styles.htmlContent} html={text} />
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </div>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc.featureInfo?.features?.length ? (
                                    <div className={classNames(styles.sdcBlock, styles.contentWithImage)}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.featureInfo}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['featureInfo']['features']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'features'}>
                                            {schemaData.solutionScopes?.featureInfoTitle}
                                          </h2>
                                          {sdc.featureInfo.image ? <img className={styles.image} src={sdc.featureInfo.image} /> : null}
                                          {sdc.featureInfo.features.length === 1 ? (
                                            <HtmlContent className={styles.htmlContent} html={sdc.featureInfo.features[0]} />
                                          ) : (
                                            <ul style={{ paddingLeft: '15px' }}>
                                              {sdc.featureInfo.features.map((text: string, index: number) => (
                                                <li key={index}>
                                                  <HtmlContent className={styles.htmlContent} html={text} />
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.usps?.length ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.usps}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['usps']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'usps'}>
                                            {schemaData.solutionScopes?.uspsTitle}
                                          </h2>
                                          <UspsList items={sdc.usps} />
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.customerVoices?.length ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.customerVoices}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['customerVoices']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'customerVoices'}>
                                            {schemaData.solutionScopes?.customerVoicesTitle}
                                          </h2>
                                          {sdc.customerVoices.map((customerVoice, index) => {
                                            return (
                                              <blockquote key={customerVoice + index} className={styles.quote}>
                                                <HtmlContent html={customerVoice}></HtmlContent>
                                              </blockquote>
                                            );
                                          })}
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc.solutionInfos?.length ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.solutionInfos}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['solutionInfos']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'solutionInfos'}>
                                            {schemaData.solutionScopes?.solutionInfosTitle}
                                          </h2>
                                          <ul>
                                            {sdc.solutionInfos.map((solutionInfo, index) => (
                                              <li key={index}>{solutionInfo}</li>
                                            ))}
                                          </ul>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc.assumptions?.length ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.assumptions}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['assumptions']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'assumptions'}>
                                            {schemaData.solutionScopes?.assumptionsTitle}
                                          </h2>
                                          {sdc.assumptions.length === 1 ? (
                                            <HtmlContent className={styles.htmlContent} html={sdc.assumptions[0]} />
                                          ) : (
                                            <ul style={{ paddingLeft: '15px' }}>
                                              {sdc.assumptions.map((text: string, index: number) => (
                                                <li key={index}>
                                                  <HtmlContent className={styles.htmlContent} html={text} />
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.successStories?.length ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.successStories}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['successStories']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'successStories'}>
                                            {schemaData.solutionScopes?.successStoriesTitle}
                                          </h2>
                                          <ul>
                                            {sdc.successStories.map((successStory, index) => (
                                              <li key={successStory + index}>
                                                <a href={successStory} target="_blank" rel="noreferrer">
                                                  {successStory}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.howToProceed ? (
                                    <div className={styles.sdcBlock} onClick={handleHtmlContentClick}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.howToProceed}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['howToProceed']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'howToProceed'}>
                                            {schemaData.solutionScopes?.howToProceedTitle}
                                          </h2>
                                          <HtmlContent className={styles.htmlContent} html={sdc.howToProceed} />
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.insights?.length && getValidVideoCount(sdc.insights) ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.insights}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['insights']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'insights'}>
                                            {schemaData.solutionScopes?.insightsTitle}
                                          </h2>
                                          <div className={styles.videoWrapper}>
                                            {sdc.insights.map((insight, index) => {
                                              return <LazyIframe key={index} url={insight} />;
                                            })}
                                          </div>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.solutionVideos?.length && getValidVideoCount(sdc.solutionVideos) ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.solutionVideos}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['solutionVideos']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'solutionVideos'}>
                                            {schemaData.solutionScopes?.solutionVideosTitle}
                                          </h2>
                                          <div className={styles.videoWrapper}>
                                            {sdc.solutionVideos.map((insight, index) => {
                                              return <LazyIframe key={index} url={insight} />;
                                            })}
                                          </div>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                  {sdc?.solutionWebinars?.length && getValidVideoCount(sdc.solutionWebinars) ? (
                                    <div className={styles.sdcBlock}>
                                      <Localization
                                        {...commonLocalizationProps}
                                        schema={schemaData.solutionScopes?.solutionWebinars}
                                        dataPath={`['solutionTypeDetails'][${solutionScopeIndex}][${
                                          sdc.scopeType === ScopeType.Internal ? 'internalSolutionScopes' : 'solutionScopes'
                                        }][${sdc.scopeIndex}]['sdcs'][${sdc.sdcIndex}]['solutionWebinars']`}
                                      >
                                        <>
                                          <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)} data-heading-key={'solutionWebinars'}>
                                            {schemaData.solutionScopes?.solutionWebinarsTitle}
                                          </h2>
                                          <div className={styles.videoWrapper}>
                                            {sdc.solutionWebinars.map((insight, index) => {
                                              return <LazyIframe key={index} url={insight} />;
                                            })}
                                          </div>
                                        </>
                                      </Localization>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                ) : null}
                {itemData.hints?.length ? (
                  <div className={styles.contentBlock}>
                    <h1 className={styles.schemaTitle} data-heading={'hints'}>
                      {schemaData.hintsTitle}
                    </h1>
                    <ol>
                      {itemData.hints.map(({ hint, solutionTypeDetailsIndex }, index: number) => (
                        <Localization
                          key={solutionTypeDetailsIndex}
                          {...commonLocalizationProps}
                          dataPath={`['solutionTypeDetails'][${solutionTypeDetailsIndex}]['internalHint']`}
                          schema={schemaData.hints.properties.internalHint}
                          top={4}
                          left={-32}
                        >
                          <li key={index}>{hint}</li>
                        </Localization>
                      ))}
                    </ol>
                  </div>
                ) : null}
                {componentsDetailsItems.length && componentsDetailsItems.filter(Boolean).length && schemaData.components ? (
                  <div className={styles.contentBlock} onClick={handleHtmlContentClick}>
                    <Localization
                      {...commonLocalizationProps}
                      schema={schemaData.components.properties?.components}
                      dataPath={`['solutionTypeDetails'][${componentsIndex}].components`}
                      top={14}
                    >
                      <>
                        <h1 className={styles.schemaTitle} data-heading={'components'}>
                          {schemaData.components?.title}
                        </h1>
                        <span>{t('common.solutionComponents')}</span>
                        <ul>
                          {componentsDetailsItems.map((component, index) => {
                            if (!component || !component?.identifier) return null;
                            const url = page.path?.replace(':id', encodeURIComponent(component.identifier));

                            let target = url?.startsWith('/') ? '_self' : '_blank';

                            try {
                              if (!url?.startsWith('/') && new URL(url ?? window.location.href).origin === window.location.origin) {
                                target = '_self';
                              }
                            } catch (e) {}

                            return (
                              <li key={index}>
                                <div>
                                  <a href={url} target={target} rel="noreferrer">
                                    {component?.name}
                                  </a>
                                </div>
                                {component?.shortDescription ? <span>{component.shortDescription}</span> : null}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    </Localization>
                  </div>
                ) : null}
                {itemData.target && schemaData.target ? (
                  <div className={styles.contentBlock}>
                    <Localization
                      {...commonLocalizationProps}
                      schema={schemaData.target}
                      dataPath={`['solutionTypeDetails'][${targetIndex}]`}
                      top={14}
                    >
                      <>
                        <h1 className={styles.schemaTitle} data-heading={'target'}>
                          {schemaData.target.title}
                        </h1>
                        <TargetWidget
                          size={TargetWidgetSize.Default}
                          platforms={itemData.target?.productPlatforms?.map((platform) => platform.identifier) as string[]}
                          supportedCountries={itemData.target?.supportedCountries?.map((supportedCountry) => supportedCountry.identifier) as string[]}
                          supportedLanguages={itemData.target?.supportedLanguages?.map((language) => language.identifier) as string[]}
                          technicalRequirements={itemData.target?.technicalRequirements}
                          technicalRequirementsTitle={schemaData.technicalRequirementsTitle}
                        />
                      </>
                    </Localization>
                  </div>
                ) : null}
                {itemData.questions && schemaData.questions ? (
                  <div className={styles.contentBlock}>
                    <Localization
                      {...commonLocalizationProps}
                      schema={schemaData.questions}
                      dataPath={`['solutionTypeDetails'][${questionsIndex}]`}
                      top={14}
                    >
                      <>
                        <h1 className={styles.schemaTitle} data-heading={'target'}>
                          {schemaData.questions.title}
                        </h1>
                        <QuestionsWidget questions={questions} />
                      </>
                    </Localization>
                  </div>
                ) : null}
              </div>
            </div>

            <div className={styles.itemFull}>
              <div className={styles.row}>
                {subPages
                  .filter(
                    (subPage) =>
                      subPage.cpTypeUrl &&
                      !SIDE_PAGES.includes(resolveSubjectUri(subPage.cpTypeUrl, prefixMap)) &&
                      subPage.cpTypeUrl !== TypeConstants.Comment
                  )
                  .map((subPage: Schemas.CpaPage, index) => {
                    return (
                      <SubPage
                        key={index}
                        page={subPage}
                        item={item}
                        schema={schema}
                        prefill={subPagePrefill}
                        filter={subPageFilter}
                        classnames={classNames(styles.contentBlock, styles.noInnerWrapper)}
                        handleHtmlContentClick={handleHtmlContentClick}
                      />
                    );
                  })}

                <ExpertsList
                  schemaData={schemaData}
                  experts={itemData.experts}
                  owners={itemData.owners?.filter((owner) => typeof owner === 'string') as string[]}
                />

                {commentPage}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const SolutionSingleItemTemplateMemoized: NamedExoticComponent & IComponentWithOptions<ISingleItemOptions> = React.memo(SolutionSingleItemTemplate);

SolutionSingleItemTemplateMemoized.options = {
  widget: {
    disableSubtitle: true,
  },
};

export default SolutionSingleItemTemplateMemoized;
