import { ODataPropsFilter, resolveSubjectUri } from '@cp/base-utils';
import { axiosDictionary, executeAggregationTemplate, postEntityToEndpoint, putEntityToEndpoint } from '@cpa/base-core/api';
import { fullPageSize } from '@cpa/base-core/constants';
import notification from '@cpa/base-core/helpers/toast';
import { useLoadableData } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem, IScreenProps } from '@cpa/base-core/types';
import { createCancelToken } from '@cpa/base-http';
import { MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IChangeEvent } from '@rjsf/core';
import { CancelTokenSource } from 'axios';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GenericComponent from '../../components/GenericComponent/GenericComponent';
import MessageBars from '../../components/MessageBars/MessageBars';
import { IRelatedViewProps } from '../GenericScreen/GenericScreen';
import { getParsedFilter } from '../GenericScreen/utils';

import styles from './Statement.module.scss';
import { IStatementItemProps, StatementItem } from './StatementItem';

export enum CustomAggregationTemplate {
  VOTING = 'VOTING',
  RATING = 'RATING',
}

const Statement: React.FC<IScreenProps & IRelatedViewProps & { isSmall: boolean }> = ({
  page,
  location,
  externalODataFilter,
  externalDataQuery,
  isWidget,
  isSmall,
}) => {
  const email = useSelector((state: IGlobalState) => state.auth?.user?.account?.email);
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);

  const personalODataFiler = useMemo<ODataPropsFilter>(() => {
    return { ...(externalODataFilter || {}), 'creator/email': email } as ODataPropsFilter;
  }, [email, externalODataFilter]);

  // This useLoadableData is used to load user's current rating/voting
  const { parsedODataFilter, loadItems, schema, totalItems, isFetching, errors, items, isODataSupportedByEndpoint } = useLoadableData(
    page.dataUrl || '/',
    page.dataEndpoint?.identifier || axiosDictionary.appDataService,
    undefined,
    undefined,
    location?.state?.externalDataQuery || externalDataQuery,
    personalODataFiler
  );

  const [t] = useTranslation();
  const customTemplate = useMemo(() => page.customTemplate?.identifier?.toUpperCase(), [page]) as CustomAggregationTemplate;

  const statementFilter = useMemo(() => {
    return _.merge({}, parsedODataFilter || {}, externalODataFilter || {});
  }, [externalODataFilter, parsedODataFilter]);

  const aggregationCancelToken = useRef<CancelTokenSource | null>(null);
  const [aggregationFetching, { setTrue: startAggregationFetching, setFalse: finishAggregationFetching }] = useBoolean(false);
  const [aggregatedData, setAggregatedData] = useState<IDataItem | undefined>();
  const loadAggregationValue = useCallback(async () => {
    const [parsedStatementFilter] = getParsedFilter(statementFilter);
    if (
      !page.cpTypeUrl ||
      !parsedStatementFilter ||
      !_.has(parsedStatementFilter, 'about._type') ||
      !_.has(parsedStatementFilter, 'about.identifier')
    ) {
      return;
    }

    try {
      aggregationCancelToken.current?.cancel();
      aggregationCancelToken.current = createCancelToken();
      startAggregationFetching();
      const response = await executeAggregationTemplate(
        page.dataEndpoint?.identifier || axiosDictionary.appDataService,
        page.cpTypeUrl,
        customTemplate.toLowerCase(),
        {
          subject: resolveSubjectUri(_.get(parsedStatementFilter, 'about._type') as string, prefixMap),
          identifier: _.get(parsedStatementFilter, 'about.identifier'),
        },
        [],
        aggregationCancelToken.current
      );

      setAggregatedData(response[0]);
    } catch (e) {
      notification.error(e.message);
    } finally {
      finishAggregationFetching();
    }
  }, [
    customTemplate,
    finishAggregationFetching,
    page.cpTypeUrl,
    page.dataEndpoint?.identifier,
    prefixMap,
    startAggregationFetching,
    statementFilter,
  ]);

  useEffect(() => {
    loadItems({}, { resetExistingData: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.dataUrl, page.dataEndpoint?.identifier]);

  useEffect(() => {
    loadAggregationValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statementFilter]);

  // Operations
  const onAddRate = useCallback(
    async (formData: IDataItem) => {
      if (!page.dataUrl || !page.dataEndpoint?.identifier || !schema) {
        return;
      }

      await postEntityToEndpoint(page.dataEndpoint?.identifier, page.dataUrl, formData, schema);
      await loadItems({}, { overwriteExistingData: true, silentFetching: true });

      await loadAggregationValue();
    },
    [page.dataUrl, page.dataEndpoint?.identifier, schema, loadItems, loadAggregationValue]
  );
  const onEditRate = useCallback(
    async (editedItem: IDataItem, initialItem: IDataItem) => {
      if (!page.dataUrl || !page.dataEndpoint?.identifier || !schema) {
        return;
      }

      await putEntityToEndpoint(page.dataEndpoint?.identifier, page.dataUrl, initialItem, editedItem, schema);
      await loadItems({}, { overwriteExistingData: true, silentFetching: true });

      await loadAggregationValue();
    },
    [loadAggregationValue, loadItems, page.dataEndpoint?.identifier, page.dataUrl, schema]
  );

  const targetItem = useMemo(() => {
    return items[0];
  }, [items]);

  const statementItemProps: IStatementItemProps = useMemo(
    () => ({
      isAggregationFetching: aggregationFetching,
      isButtonDataFetching: isFetching,
      filter: statementFilter,
      targetItem: targetItem,
      schema: isSmall
        ? {
            ...schema,
            title: '',
            properties: { value: { ...schema?.properties?.value!, title: '' } },
          }
        : schema,
      page: page,
      onAdd: onAddRate,
      onEdit: onEditRate,
      labels: {},
      shimmerHeight: 110,
    }),
    [isSmall, aggregationFetching, isFetching, statementFilter, targetItem, schema, page, onAddRate, onEditRate]
  );

  const statementComponent = useMemo(() => {
    const conditionalProps = {
      [CustomAggregationTemplate.VOTING]: {
        labels: {
          total: t('common.totalVotes'),
          active: t('common.activeVotes'),
          revoked: t('common.revokedVotes'),
          noData: '0',
        },
        submitOnFormChange: true,
        aggregatedData: aggregatedData,
        onFormSubmit: async ({ formData }: IChangeEvent): Promise<void> => {
          if (!formData || !Object.keys(formData).length) {
            return;
          }
          onAddRate?.(formData);
        },
      },
      [CustomAggregationTemplate.RATING]: {
        labels: {
          total: t('common.totalRatings'),
          noData: t('common.notApplicable'),
        },
        aggregatedData: aggregatedData,
      },
    }[customTemplate];

    return <StatementItem {...statementItemProps} {...conditionalProps} isSmall={isSmall} />;
  }, [t, aggregatedData, customTemplate, statementItemProps, isSmall, onAddRate]);

  const onPageRefresh = useCallback(
    (refreshFilter?: ODataPropsFilter) => {
      loadAggregationValue();
      return loadItems(isODataSupportedByEndpoint ? { filter: refreshFilter } : {}, {
        overwriteExistingData: true,
      });
    },
    [loadItems, isODataSupportedByEndpoint, loadAggregationValue]
  );

  const componentData = useMemo(() => {
    return { items: items, page, schema: schema, isFetching: isFetching, totalItems: totalItems };
  }, [isFetching, items, page, schema, totalItems]);

  return (
    <>
      <MessageBars messageBarType={MessageBarType.error} isMultiline={false} messages={errors} />
      <section className={styles.wrapper}>
        <GenericComponent
          isWidget={isWidget}
          animationDelay={0}
          onRefresh={onPageRefresh}
          customContent={statementComponent}
          forceCustomContent={true}
          data={componentData}
          pageSize={fullPageSize}
          hideSettings={true}
          withoutAnimation={true}
        />
      </section>
    </>
  );
};

export default Statement;
