import { IJSONSchema, OnBeforeUiUserMachineTranslateExecutionContext, Schemas, TypeTrigger } from '@cp/base-types';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { getEndpoint } from '@cpa/base-core/api';
import { dropRoutesCache, executeUiTriggers, filterSchema, getAppBasePath, resolveUiTriggersCode } from '@cpa/base-core/helpers';
import {
  ActionSourceType,
  ActionType,
  useActionHandler,
  useAnimationOptions,
  useCommandBarButtons,
  useCpaPageActionClickHandler,
  useCustomActions,
  useTableActions,
  useUrlForSubscription,
} from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { TableActionsPosition } from '@cpa/base-core/store/settings/reducer';
import { wsSubscribeToEntityModification } from '@cpa/base-core/store/websocket/actions';
import { BaseApi, ICustomActionUnbound, IDataItem, IGenericComponentData, IGenericComponentProps } from '@cpa/base-core/types';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import { useBoolean, usePrevious } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { push, replace } from 'connected-react-router';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation } from 'react-router';
import urlJoin from 'url-join';
import Mustache from 'mustache';

import Animation from '../../../../components/Animation/Animation';
import ItemInfo from '../../../../components/ItemInfo/ItemInfo';
import MessageBars from '../../../../components/MessageBars/MessageBars';
import { IWizardOptions } from '../../../../components/ScrollingContent/ScrollingContent';
import commandBarStyles from '../../../../components/ScrollingContent/components/Actions/Actions.module.scss';
import {
  ChangeBaseLanguageDialog,
  IChangeBaseLanguageDialogRef,
} from '../../../../components/ScrollingContent/components/Dialogs/ChangeBaseLanguage/ChangeBaseLanguageDialog';
import LocalizationsDrawer from '../../../../components/ScrollingContent/components/LocalizationsDrawer/LocalizationsDrawer';
import VersionsDrawer from '../../../../components/ScrollingContent/components/VersionsDrawer/VersionsDrawer';
import ShimmerGrid from '../../../../components/ShimmerGrid/ShimmerGrid';
import Widget from '../../../../components/Widget/Widget';
import { ISettingsItem, SettingsItemType } from '../../../../components/Widget/components/Settings/Settings';
import { SingleItemTemplates } from '../../../../mapping';
import { removeMajorCollectionQueryFromTypeUrl } from '../../utils';

import styles from './SingleItem.module.scss';
import { SingleItemContext } from './context';
import { ICheckDataItemLocalizedResult, checkIsLocalized } from './helpers/checkIsLocalized';
import { machineTranslateOnTheFly } from './helpers/machineTranslateOnTheFly';

interface ISingleItemProps extends Pick<IGenericComponentProps, 'onEditRow' | 'onDeleteRows' | 'loadItems' | 'initialAction' | 'onRefresh'> {
  data: IGenericComponentData;
  path: string;
  identifier: string;
  isODataSupportedByEndpoint: boolean;
  onCopyRow?: (item: IDataItem) => Promise<IDataItem | undefined>;
  cancelLoading?: (token: CancelTokenSource | null) => void;
  customActions?: ICustomActionUnbound[];
}

export function generateSingleItemQuery(identifier: string): { top: number; filter: unknown } {
  return {
    top: 2, // We need 2 in order to detect multiple matched items
    filter: { or: [{ identifier: encodeURIComponent(identifier) }, { urlSlug: encodeURIComponent(identifier) }] },
  };
}

let isActionExecuted = false;

const defaultSchema = {};
const singleItemShimmerStyle = { padding: 20 };

const SingleItem: React.FC<ISingleItemProps> = ({
  onRefresh,
  data,
  path,
  identifier,
  onEditRow,
  onCopyRow,
  onDeleteRows,
  loadItems,
  isODataSupportedByEndpoint,
  initialAction,
  cancelLoading,
  customActions,
}) => {
  const darkMode = useSelector((store: IGlobalState) => store.settings.darkMode);
  const tableActionsPosition = useSelector((state: IGlobalState) => state.settings.tableActionsPosition);
  const location = useLocation();
  const { page: originalPage, isFetching, schema: originalSchema } = data;
  const [errors, setErrors] = useState<string[]>([]);
  const [messagesHeight, setMessagesHeight] = useState<number>(0);
  const changeBaseLanguageDialogRef = useRef<IChangeBaseLanguageDialogRef>(null);

  const [contentOptions, setContentOptions] = useState<{
    activeItem: IDataItem | null;
    isDrawerOpened: boolean;
    isVersionsOpened: boolean;
    isLocalizationsOpened: boolean;
    drawerOptions: {
      readonly?: boolean;
      prefill?: IDataItem;
      type: 'add' | 'edit';
      wizardOptions?: IWizardOptions;
      customSchema?: IJSONSchema;
    };
    versionsOptions?: {
      comparedVersions: [string, string];
    };
  }>({
    activeItem: null,
    isDrawerOpened: false,
    isVersionsOpened: false,
    isLocalizationsOpened: false,
    drawerOptions: { type: 'add' },
  });

  const MatchedSingleItemTemplate = useMemo(() => {
    const query = new URLSearchParams(location.search || '');

    const singleItemTemplateIdentifier: string | undefined =
      (
        location.state as
          | {
              template?: string;
            }
          | undefined
      )?.template ||
      query.get('view') ||
      originalPage.singleItemTemplate?.identifier;

    return singleItemTemplateIdentifier ? SingleItemTemplates[singleItemTemplateIdentifier] : null;
  }, [location.state, location.search, originalPage.singleItemTemplate?.identifier]);

  const schema = useMemo(() => {
    const filteredSchema = filterSchema(originalSchema || {}, [], { filterVoidProperties: true });
    return MatchedSingleItemTemplate?.options?.schemaModifier?.(filteredSchema) || filteredSchema;
  }, [originalSchema, MatchedSingleItemTemplate]);

  const page = useMemo(() => {
    return MatchedSingleItemTemplate?.options?.pageModifier?.(originalPage) || originalPage;
  }, [originalPage, MatchedSingleItemTemplate]);

  const isSubtitleDisabled = MatchedSingleItemTemplate?.options?.widget?.disableSubtitle;

  // Handle data language change
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  useEffect(() => {
    if (!data.isFetching) {
      onRefresh?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLanguage]);

  const [, i18n] = useTranslation();

  const currentLanguage = useMemo(() => {
    let currentLanguageCode = i18n.language;
    if (dataLanguage && dataLanguage !== 'notSelected') {
      currentLanguageCode = dataLanguage;
    }

    return currentLanguageCode;
  }, [dataLanguage, i18n.language]);

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const loadCurrentItem = useCallback(
    (itemIdentifier: string) => {
      cancelLoading?.(cancelToken.current);

      cancelToken.current = createCancelToken();

      loadItems?.(undefined, {
        interceptors: {
          beforeRequest: (endpointId, path, queryOptions, ...other) => {
            // Ignore filter from page level. We always fetch item by identifier.
            return [
              endpointId,
              path,
              isODataSupportedByEndpoint && itemIdentifier
                ? {
                    ..._.pick(queryOptions, '$expand'),
                    ...generateSingleItemQuery(itemIdentifier),
                  }
                : undefined,
              ...other,
            ];
          },
        },
        resetExistingData: true,
        overwriteExistingData: true,
        silentFetching: false,
        cancelToken: cancelToken.current || undefined,
      });
    },
    [cancelLoading, isODataSupportedByEndpoint, loadItems]
  );

  const previousIdentifier = usePrevious(identifier);
  useEffect(() => {
    if (previousIdentifier && identifier && previousIdentifier !== identifier) {
      // Item identifier changed (can happen when navigating browser history between single items)
      loadCurrentItem(identifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  const matchedItems = useMemo<IDataItem[]>(() => {
    return data.items.filter((i) => i.identifier === identifier || i.urlSlug === identifier) || null;
  }, [data, identifier]);
  const item = matchedItems[0];

  const [localizedInfo, setLocalizedInfo] = useState<ICheckDataItemLocalizedResult | undefined>();

  const [isItemTranslating, { setTrue: enableSetItemTranslating, setFalse: disableSetItemTranslating }] = useBoolean(true);

  const [translatedItem, setTranslatedItem] = useState<IDataItem | undefined>();

  const selectedItems = useMemo(() => {
    return [translatedItem || item];
  }, [translatedItem, item]);

  const onCpaPageActionClick = useCpaPageActionClickHandler(data, undefined, undefined, undefined, setErrors);
  const customActionsBound = useCustomActions(data, selectedItems, onCpaPageActionClick, customActions);

  const { actions: availableActions, formSchemas } = useTableActions(data, selectedItems, false, true, [], () => [], ActionSourceType.SingleItem);

  const actionHandler = useActionHandler({
    data,
    selectedItems,
    dismissContextCallout: () => null,
    setErrors,
    setContentOptions,
    onEdit: onEditRow,
    onDelete: onDeleteRows,
    handleOnDelete: onDeleteRows,
    loadItems,
    changeBaseLanguageDialogRef,
    customActionsBound,
    reloadCurrentItems: async () => {
      onRefresh?.();
    },
    formSchemas,
  });
  const latestOnActionClick = useRef(actionHandler);
  latestOnActionClick.current = actionHandler;
  const buttons = useCommandBarButtons(
    availableActions,
    latestOnActionClick,
    actionHandler,
    classNames(
      darkMode ? commandBarStyles.controlDark : commandBarStyles.control,
      darkMode ? commandBarStyles.buttonsContainerDark : commandBarStyles.buttonsContainer
    ),
    classNames(commandBarStyles.splitControlWrapper, {
      [commandBarStyles.splitControlWrapperLeft]: !(tableActionsPosition === TableActionsPosition.Right),
    }),
    darkMode ? commandBarStyles.controlDark : commandBarStyles.control,
    customActionsBound
  );

  const refreshLocalizedInfo = useCallback(async () => {
    setLocalizedInfo(undefined);

    disableSetItemTranslating();
    setTranslatedItem(undefined);

    const localizedInfo = await checkIsLocalized({
      dataUrl: page.dataUrl!,
      dataEndpointIdentifier: page.dataEndpoint!.identifier!,
      identifier: item.identifier!,
      toLanguage: currentLanguage,
    });

    setLocalizedInfo(localizedInfo);
  }, [currentLanguage, item, page.dataUrl, page.dataEndpoint, disableSetItemTranslating]);

  useEffect(() => {
    const isTranslateOnTheFlyFeatureEnabled =
      page.dataEndpoint?.identifier && getEndpoint(page.dataEndpoint.identifier)?.dataType === BaseApi.DataService && !schema.cp_handledByApiGateway;

    if (isTranslateOnTheFlyFeatureEnabled && item && item._baseLanguage !== currentLanguage) {
      refreshLocalizedInfo();
    } else {
      setLocalizedInfo(undefined);
    }
  }, [item, currentLanguage, refreshLocalizedInfo, setLocalizedInfo, page.dataEndpoint, schema.cp_handledByApiGateway]);

  const onBeforeTranslateOnTheFly: (action: 'translate-on-the-fly' | 'show-original') => Promise<{ cancelTranslate: boolean }> = useCallback(
    async (action) => {
      let cancelTranslate = false;

      const triggers = data.schema?.cp_typeTriggers?.[TypeTrigger.OnBeforeUiUserMachineTranslate];
      if (Array.isArray(triggers) && triggers.length) {
        await executeUiTriggers<OnBeforeUiUserMachineTranslateExecutionContext>(
          {
            event: TypeTrigger.OnBeforeUiUserMachineTranslate,
            item: item,
            action: action,
            toLanguage: currentLanguage,
            schema: data.schema as IJSONSchema,
            page: data.page,
            doCancel: () => {
              cancelTranslate = true;
            },
          },
          await resolveUiTriggersCode(triggers)
        );
      }

      return { cancelTranslate };
    },
    [data.schema, data.page, item, currentLanguage]
  );

  const loadMachineTranslatedItem = useCallback(async () => {
    enableSetItemTranslating();
    setTranslatedItem(undefined);

    const { cancelTranslate } = await onBeforeTranslateOnTheFly('translate-on-the-fly');

    if (!cancelTranslate) {
      const translatedDataItem = await machineTranslateOnTheFly({
        dataUrl: page.dataUrl!,
        dataEndpointIdentifier: page.dataEndpoint!.identifier!,
        identifier: item.identifier!,
        toLanguage: currentLanguage,
        dataItem: item,
      });
      setTranslatedItem(translatedDataItem);
    }

    disableSetItemTranslating();
  }, [item, currentLanguage, page.dataUrl, page.dataEndpoint, onBeforeTranslateOnTheFly, disableSetItemTranslating, enableSetItemTranslating]);

  const resetMachineTranslatedItem = useCallback(async () => {
    const { cancelTranslate } = await onBeforeTranslateOnTheFly('show-original');

    if (cancelTranslate) {
      return;
    }

    disableSetItemTranslating();
    setTranslatedItem(undefined);
  }, [disableSetItemTranslating, onBeforeTranslateOnTheFly]);

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isDrawerOpened, { setTrue: setDrawerOpened, setFalse: setDrawerClosed }] = useBoolean(false);
  const [computedDuration, disableAnimation] = useAnimationOptions();

  const urlForSubscription: string | null = useUrlForSubscription(data.page.cpTypeUrl);
  // Cp Type subscription
  useEffect(() => {
    if (!urlForSubscription) {
      return;
    }

    dispatch(wsSubscribeToEntityModification(urlForSubscription));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlForSubscription]);

  useEffect(() => {
    if (isFetching || isActionExecuted) {
      return;
    }
    isActionExecuted = true;

    if (initialAction) {
      switch (initialAction.toUpperCase()) {
        case 'EDIT': {
          if (!item || !page.allowModify) {
            break;
          }
          setDrawerOpened();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, item]);

  const processedOnEdit = useCallback(
    async (value: IDataItem) => {
      if (!item) {
        return;
      }

      const newItem = await onEditRow?.({ ...value }, item);
      if (newItem?.identifier && newItem.identifier !== item.identifier) {
        // Redirect
        const newPath = urlJoin(path, newItem.identifier);
        dispatch(replace(newPath));
      }
    },
    [item, onEditRow, path, dispatch]
  );

  const deleteHandler = useCallback(async () => {
    setErrors([]);

    await actionHandler(ActionType.Delete);
    dispatch(push(path));
  }, [actionHandler, dispatch, path]);

  const handleOnSubmitAs = useCallback(
    async (item: IDataItem) => {
      if (onCopyRow && onEditRow) {
        // Copy item
        const copiedItem = await onCopyRow(item);
        if (!copiedItem?.identifier) {
          return;
        }

        // Align edited item with copied item
        const editedItem = cloneDeepWithMetadata(item);
        editedItem.identifier = copiedItem?.identifier;
        editedItem._eTag = copiedItem?._eTag;

        // Edit
        await onEditRow(editedItem, copiedItem);

        // Redirect
        const newPath = urlJoin(path, copiedItem.identifier);
        dispatch(push(newPath));
      }
    },
    [dispatch, onCopyRow, onEditRow, path]
  );

  const pageActions = useMemo(() => {
    const actions: ISettingsItem[] = [];

    if (!item) {
      return actions;
    }

    if (page.allowModify) {
      const url = page.path ? urlJoin(getAppBasePath(), page.path.replace('/:id', ''), encodeURIComponent(identifier), '?action=edit') : '';
      actions.push({
        type: SettingsItemType.EDIT,
        url: url,
        onClick: (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          setErrors([]);
          setDrawerOpened();
        },
      });
    }

    if (page.allowDelete) {
      actions.push({
        type: SettingsItemType.DELETE,
        onClick: () => {
          deleteHandler();
        },
      });
    }

    actions.push({
      type: SettingsItemType.SHARE,
      onClick: () => {
        actionHandler(ActionType.Share);
      },
    });

    const url = page.path ? urlJoin(getAppBasePath(), page.path.replace('/:id', '')) : '';

    actions.push({
      type: SettingsItemType.CLOSE,
      url: url,
      onClick: (e: React.MouseEvent<HTMLDivElement>) => {
        actionHandler(ActionType.Close, undefined, e);
      },
    });

    return actions;
  }, [item, page, identifier, setDrawerOpened, deleteHandler, actionHandler]);

  // NOTICE: singleItemComponents should consist CpaPages related for a certain app
  const subPages = useSelector((state: IGlobalState) => {
    const ids = page.singleItemComponents?.map((rel) => rel.identifier) || [];
    return ids.map((id) => state.app.pages.find((p) => p.identifier === id)).filter(Boolean) as Schemas.CpaPage[];
  });

  const { ref: resizeRef } = useResizeDetector({
    onResize: (width, height) => {
      setMessagesHeight(height || 0);
    },
  });

  const [subPageFilter, subPagePrefill] = useMemo(() => {
    // Strip 'cp_collection' query parameter if value is 'latestMajor'
    // Ensures that related data can be read for minor as well for major versions
    // Ensures that new items are not related to major collection
    const cpTypeUrl = removeMajorCollectionQueryFromTypeUrl(data.page.cpTypeUrl || schema?.$id || '');

    return [
      {
        'about/_type': encodeURIComponent(cpTypeUrl),
        'about/identifier': encodeURIComponent(item?.identifier as string),
      },
      {
        about: {
          _type: cpTypeUrl,
          identifier: item?.identifier as string,
        },
      },
    ];
  }, [data.page.cpTypeUrl, item, schema]);

  const itemInfoData = useMemo(() => {
    return { ...data, schema };
  }, [data, schema]);

  useEffect(() => {
    if (!isFetching && !item) {
      const notFoundPath = page.itemNotFoundFallbackUrl as string | undefined;
      if (!notFoundPath) return;
      const view = {
        identifier: identifier,
        language: i18n.language,
      };
      const redirectUrl = Mustache.render(notFoundPath, view);

      if (redirectUrl.startsWith('http')) {
        window.location.replace(redirectUrl);
      } else {
        dispatch(push(redirectUrl));
      }
    }
  }, [dispatch, i18n.language, identifier, isFetching, item, page.itemNotFoundFallbackUrl]);

  const warnings = useMemo(() => {
    return !isFetching && !item ? [t('common.itemNotFound')] : [];
  }, [isFetching, item, t]);

  const singleItemBody = useMemo((): JSX.Element | null => {
    if (!schema || isFetching) {
      return <ShimmerGrid gridArea={'"a"'} height={400} style={singleItemShimmerStyle} />;
    }

    if (!item || !MatchedSingleItemTemplate) {
      return null;
    }

    return (
      <MatchedSingleItemTemplate
        page={translatedItem ? { ...page, allowModify: false } : page}
        item={translatedItem ?? item}
        schema={schema || defaultSchema}
        subPages={subPages}
        subPageFilter={subPageFilter}
        subPagePrefill={subPagePrefill}
        isFetching={data.isFetching}
        messagesHeight={messagesHeight}
      />
    );
  }, [
    schema,
    isFetching,
    item,
    MatchedSingleItemTemplate,
    translatedItem,
    page,
    subPages,
    subPageFilter,
    subPagePrefill,
    data.isFetching,
    messagesHeight,
  ]);

  const singleItemContextValue = useMemo(
    () => ({
      isSingleItem: true,
      rootItem: item || undefined,
      rootPage: page,
    }),
    [item, page]
  );

  const handleViewMoreItems = useCallback(() => {
    if (!page.path) {
      return;
    }

    const newUrl =
      page.path.replace('/:id', '') + `?filter=${encodeURIComponent(JSON.stringify({ $or: [{ identifier }, { urlSlug: identifier }] }))}`;
    dropRoutesCache().then(() => dispatch(push(newUrl)));
  }, [dispatch, identifier, page.path]);

  const onVersionsDrawerClose = useCallback(() => {
    setContentOptions({
      activeItem: null,
      isDrawerOpened: false,
      isVersionsOpened: false,
      isLocalizationsOpened: false,
      drawerOptions: { type: 'add' },
    });
  }, []);

  const onLocalizationsDrawerClose = useCallback(() => {
    setContentOptions({
      activeItem: null,
      isDrawerOpened: false,
      isVersionsOpened: false,
      isLocalizationsOpened: false,
      drawerOptions: { type: 'add' },
    });
  }, []);

  const onVersionsDrawerUpdate = useCallback(() => {
    onVersionsDrawerClose();
    onRefresh?.();
  }, []);

  const onLocalizationsDrawerUpdate = useCallback(() => {
    onLocalizationsDrawerClose();
    onRefresh?.();
  }, []);

  return (
    <SingleItemContext.Provider value={singleItemContextValue}>
      {/*{MatchedSingleItemTemplate === SingleItemTemplates['SolutionSingleItemTemplate'] && (*/}
      {/*  <SingleItemCover dataItem={item as unknown as Schemas.Solution & IDataItem} page={page} isFetching={data.isFetching} schema={schema} />*/}
      {/*)}*/}

      <section className={classNames(styles.wrapper)}>
        <Animation duration={computedDuration} disable={disableAnimation}>
          <Widget
            title={!isFetching ? item?.name?.toString() : undefined}
            identifier={item?.identifier}
            page={!isFetching ? page : undefined}
            schema={!isFetching ? schema : undefined}
            subtitle={!isFetching && !isSubtitleDisabled ? item?.name?.toString() || page.description : undefined}
            headerIconName={page.icon}
            settingsItems={pageActions}
            additionalSettingsItems={buttons}
          >
            <div ref={resizeRef}>
              <MessageBars messages={errors} messageBarType={MessageBarType.error} />
              {!!warnings.length && (
                <div className={styles.warningsWrapper}>
                  <MessageBars messageBarType={MessageBarType.warning} messages={warnings} isClosable={false} />
                </div>
              )}
              {matchedItems.length > 1 && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  {t('common.multipleItems')} - <Link onClick={handleViewMoreItems}>{t('common.view')}</Link>
                </MessageBar>
              )}

              {!translatedItem && localizedInfo && !localizedInfo.isFullyLocalized && (
                <MessageBar messageBarType={MessageBarType.info}>
                  {localizedInfo.isPartiallyLocalized ? t('machineTranslation.notFullyTranslated') : t('machineTranslation.notTranslated')}
                  &nbsp;-&nbsp;
                  {!isItemTranslating && <Link onClick={loadMachineTranslatedItem}>{t('machineTranslation.showMachineTranslated')}</Link>}
                  {isItemTranslating && <span>{t('machineTranslation.translating')}</span>}
                </MessageBar>
              )}

              {translatedItem && (
                <MessageBar messageBarType={MessageBarType.info}>
                  {t('machineTranslation.translatedByMachine')}&nbsp;-&nbsp;
                  <Link onClick={resetMachineTranslatedItem}>{t('machineTranslation.showOriginal')}</Link>
                </MessageBar>
              )}
            </div>
            {singleItemBody}
          </Widget>
          {!!schema && (
            <ItemInfo
              isOpened={isDrawerOpened}
              onClose={setDrawerClosed}
              item={item}
              data={itemInfoData}
              readonly={false}
              onEdit={processedOnEdit}
              onSubmitAs={handleOnSubmitAs}
              type={'edit'}
            />
          )}
        </Animation>
      </section>
      {contentOptions.isVersionsOpened && data.schema && (
        <VersionsDrawer
          isOpen={true}
          pageSchema={data.schema}
          item={contentOptions.activeItem}
          onClose={onVersionsDrawerClose}
          page={data.page}
          onUpdate={onVersionsDrawerUpdate}
          comparedVersions={contentOptions.versionsOptions?.comparedVersions}
        />
      )}

      {contentOptions.isLocalizationsOpened && data.schema && (
        <LocalizationsDrawer
          isOpen={true}
          pageSchema={data.schema}
          item={contentOptions.activeItem}
          onClose={onLocalizationsDrawerClose}
          page={data.page}
          onUpdate={() => onLocalizationsDrawerUpdate()}
        />
      )}
      <ChangeBaseLanguageDialog ref={changeBaseLanguageDialogRef} page={data.page} />
    </SingleItemContext.Provider>
  );
};

export default SingleItem;
