import React, { useContext, useMemo } from 'react';
import { IDataItem } from '@cpa/base-core/types';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ParentMessageContext } from '@cpa/base-core/constants';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './MessageTitle.module.scss';

interface IMessageTitleProps {
  item: IDataItem;
  srcFieldName: string | undefined;

  // Used only to display inside badge
  srcFieldValue: string;
}

const MessageTitle: React.FC<IMessageTitleProps> = ({ item, srcFieldName, srcFieldValue }) => {
  const parentMessageContextValue = useContext(ParentMessageContext);
  const [t] = useTranslation();
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);

  const isRead = useMemo(() => {
    const parentMessageIdentifier = (item?.parentMessage as { identifier: string })?.identifier;
    if (parentMessageIdentifier) {
      // if (item.createdByUser === user) return true;
      if (!item.createdAt || !parentMessageContextValue) return false;
      return parentMessageContextValue >= item.createdAt;
    } else {
      if (!item['__lastRead']) return false;
      if (!item['__latestMessage']) return true;
      return item['__lastRead'] === item['__latestMessage'];
    }
  }, [item, parentMessageContextValue]);
  const directionIcon = useMemo(() => {
    return <Icon className={styles.icon} iconName={item.createdByUser !== user ? 'ArrowDownRight8' : 'ArrowUpRightMirrored8'} />;
  }, [item.createdByUser, user]);

  return (
    <div className={styles.wrapper}>
      {directionIcon}
      <HoverTooltip content={t(isRead ? 'Read' : 'common.unread')}>
        <div className={classNames(styles.unreadMark, { [styles.hidden]: isRead })}></div>
      </HoverTooltip>
      <span>{srcFieldValue}</span>
    </div>
  );
};

export default MessageTitle;
