import { isCancelError } from '@cpa/base-http';
import axios, { CancelToken } from 'axios';
import { Schemas, TypeConstants } from '@cp/base-types';
import { buildODataQuery, DataServiceModules } from '@cp/base-utils';
import * as _ from 'lodash';

import { Environment } from '../app/environment';
import { IDataEndpoint, ILanguage } from '../types';

import { getLanguage, rootDataService } from './axios';
import { IDataStoreArrayDto, IDataStoreItemDto } from './entities';
import { globalItemCache } from './cache';

export interface IPagesResponse {
  pages: Schemas.CpaPage[];
}

export interface IFooterLinksResponse {
  footerLinks: Schemas.CpaFooter[];
}

export interface ILanguagesResponse {
  languages: ILanguage[];
}

export interface ICpaResponse {
  cpa: Schemas.Cpa;
}

export interface IDataEndpointsResponse {
  endpoints: IDataEndpoint[];
}

export interface ICpNamespacesResponse {
  namespaces: Schemas.CpNamespace[];
}

export interface IFileUploadDetails {
  uploadUrl: string;
  downloadUrl: string;
  expiresOn: string;
}

export interface IUserProfileResponse {
  userSettings: Schemas.UserProfileShadow['cpaUserConfiguration'] | null;
  eTag: string | null;
}

/*
  Data Service - Root Calls
 */
export const getPages = async (language: string): Promise<IPagesResponse> => {
  const { value: pages } = await rootDataService.get<IDataStoreArrayDto<Schemas.CpaPage>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaPage)}`,
    {
      params: {
        filter: { 'cpas/identifier': { eq: Environment.env.REACT_APP_CPA_NAME } },
      },
      paramsSerializer: buildODataQuery,
      headers: {
        'x-cp-read-treatments': 'PagesByPermissions,PromotedForRoles,FullCpTypeUrl,InheritanceMerge',
        'Accept-Language': language,
      },
    }
  );
  return { pages };
};

export const getFooterLinks = async (language: string): Promise<IFooterLinksResponse> => {
  const { value: footerLinks } = await rootDataService.get<IDataStoreItemDto<Schemas.CpaFooter[]>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaFooter)}`,
    {
      params: {
        filter: { 'cpas/identifier': { eq: Environment.env.REACT_APP_CPA_NAME } },
      },
      paramsSerializer: buildODataQuery,
      headers: {
        'Accept-Language': language,
      },
    }
  );
  return { footerLinks };
};

export const getLanguages = async (): Promise<ILanguagesResponse> => {
  const { value: languages } = await rootDataService.get<IDataStoreItemDto<ILanguage[]>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpCulture)}`
  );
  return { languages };
};

export const getCpNamespaces = async (): Promise<ICpNamespacesResponse> => {
  const { value: namespaces } = await rootDataService.get<IDataStoreItemDto<Schemas.CpNamespace[]>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpNamespace)}`
  );
  return { namespaces };
};

const putUserProfile = async (email: string, userProfile: Schemas.UserProfileShadow = {}) => {
  const language = getLanguage();
  return await rootDataService.put<IDataStoreItemDto<Schemas.UserProfileShadow>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.UserProfileShadow)}/${encodeURIComponent(email)}`,
    {
      identifier: email,
    },
    {
      headers: { 'If-Match': '*', 'content-language': language },
    }
  );
};

export const getUserSettings = async (email?: string): Promise<IUserProfileResponse> => {
  if (email) {
    try {
      const { value: userProfile } = await rootDataService.get<IDataStoreItemDto<Schemas.UserProfile>>(
        `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.UserProfileShadow)}/${encodeURIComponent(email)}`
      );
      return { userSettings: userProfile?.cpaUserConfiguration, eTag: userProfile?._eTag as string };
    } catch (e) {
      console.error('Failed to fetch user profile');
      // create user profile if doesn't exists
      const profile = await putUserProfile(email);
      return { userSettings: null, eTag: (profile?.value?.['_eTag'] as string) || null };
    }
  }
  return { userSettings: null, eTag: null };
};

export const getCpa = async (): Promise<ICpaResponse> => {
  if (Environment.env.REACT_APP_PRELOAD_CPA) {
    console.info('Using preload from env for Cpa');
    return {
      cpa: JSON.parse(Environment.env.REACT_APP_PRELOAD_CPA),
    };
  }

  const { value: cpa } = await rootDataService.get<IDataStoreItemDto<Schemas.Cpa>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.Cpa)}/${Environment.env.REACT_APP_CPA_NAME}`
  );
  return { cpa };
};

export const getDataEndpoints = async (): Promise<IDataEndpointsResponse> => {
  const { value: endpoints } = await rootDataService.get<IDataStoreItemDto<IDataEndpoint[]>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaDataEndpoint)}`
  );
  return { endpoints };
};

export const getCpTypeTrigger = async (identifier: string): Promise<Schemas.CpTypeTrigger> => {
  return globalItemCache.get<Schemas.CpTypeTrigger>(TypeConstants.CpTypeTrigger, identifier);
};

export const getCpFieldTrigger = async (identifier: string): Promise<Schemas.CpFieldTrigger> => {
  return globalItemCache.get<Schemas.CpFieldTrigger>(TypeConstants.CpFieldTrigger, identifier);
};

export const getCpFunction = async (identifier: string): Promise<Schemas.CpFunction> => {
  return globalItemCache.get<Schemas.CpFunction>(TypeConstants.CpFunction, identifier);
};

export const generateFileUploadUrl = async (filename: string, cancelToken?: CancelToken): Promise<IFileUploadDetails> => {
  return await rootDataService.get<IFileUploadDetails>('file-upload', {
    params: {
      filename,
    },
    cancelToken: cancelToken,
  });
};

export const uploadFileToAzureStorage = async (
  uploadUrl: string,
  file: File | Blob,
  onUploadProgress?: (progress: number) => unknown,
  cancelToken?: CancelToken
): Promise<void> => {
  try {
    await axios.put(uploadUrl, file, {
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'content-type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent && typeof progressEvent.loaded === 'number' && typeof progressEvent.total === 'number') {
          onUploadProgress?.((progressEvent.loaded / progressEvent.total) * 100);
        }
      },
      cancelToken: cancelToken,
    });
  } catch (e) {
    if (isCancelError(e)) {
      throw e;
    }
    console.error(`Failed to upload file to Azure Storage`, e.message, e.response?.data);
    throw new Error('Server error. Please try again later.');
  }
};

export async function ensureCpShareLink(identifier: string, shareLink: Schemas.CpShareLink, cancelToken?: CancelToken): Promise<Schemas.CpShareLink> {
  const { value: existingShareLink } = await rootDataService.get<IDataStoreItemDto<Schemas.CpShareLink>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpShareLink)}/${encodeURIComponent(identifier)}`,
    {
      cancelToken,
      validateStatus: () => true,
    }
  );

  // Remember
  shareLink._imageOriginal = shareLink.image;

  if (
    shareLink.image &&
    shareLink.image.toLowerCase().indexOf('svg') > -1 &&
    (!existingShareLink || existingShareLink._imageOriginal !== shareLink.image)
  ) {
    const { data: imgData } = await axios.get(shareLink.image, {
      cancelToken,
    });
    const { downloadUrl } = await rootDataService.post(`/img-opt-upload`, imgData, {
      cancelToken,
      headers: {
        'Content-Type': 'application/octet-stream',
        'target-format': 'png',
        filename: shareLink.identifier,
      },
    });
    shareLink.image = downloadUrl;
  } else {
    shareLink.image = existingShareLink?.image;
  }

  delete shareLink._imageOriginal;

  if (existingShareLink) {
    let isDataChanged = false;

    const existingShareLinkData: Schemas.CpShareLink = {
      identifier: existingShareLink.identifier,
      name: existingShareLink.name,
      description: existingShareLink.description,
      url: existingShareLink.url,
      image: existingShareLink.image,
    };

    if (_.difference(Object.keys(existingShareLinkData), Object.keys(shareLink)).length) {
      isDataChanged = true;
    }

    for (const key of Object.keys(existingShareLinkData)) {
      if (existingShareLinkData[key] !== shareLink[key]) {
        isDataChanged = true;
      }
    }

    if (!isDataChanged) {
      return existingShareLink;
    }
  }

  const { value: newShareLink } = await rootDataService.put<IDataStoreItemDto<Schemas.CpShareLink>>(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpShareLink)}/${encodeURIComponent(identifier)}`,
    { ...shareLink, ...(existingShareLink ? { '_eTag': existingShareLink['_eTag'] } : {}) },
    {
      cancelToken,
    }
  );

  return newShareLink;
}
