import * as _ from 'lodash';
import { IJSONSchema, IPrefixMap, Schemas } from '@cp/base-types';
import { cloneDeepWithMetadata, RDFS, resolveSchemaPath, shortenSubjectUri } from '@cp/base-utils';
import { BasicDataNode } from 'rc-tree';
import React from 'react';
import { Icon, Spinner } from '@fluentui/react';
import { i18n } from '@cpa/base-core/app';

export const SORT_ORDER_ANNOTATION_KEY = 'cp:sortOrderForm';

export interface Node extends BasicDataNode {
  key: string;
  title: JSX.Element | string;
  children?: Node[];
  selectedChildrenCount: number;
  name: string;
  data?: {
    ref?: string;
    schemaPath?: string;
  };
}

const spinnerStyles = { root: { height: '14px', width: '14px' }, circle: { height: '14px', width: '14px' } };

// TODO: use this function to reduce assignments loops
// const getMatchingAssignment = (
//   assignments: Schemas.CpType['assignments'],
//   key: string
// ): Extract<Schemas.CpType['assignments'], {}>[0] | undefined => {
//   if (!assignments) return;
//   return assignments.find((assignment) => assignment.propertyJsonPath === key);
// };

const isAffectedNode = (path: string | undefined, nodeKey: string): boolean => {
  // If nodeKey is not a leaf node key, we should update children also
  if (!path) return true;
  if (nodeKey.length > path.length) {
    // Trim nodeKey to length of path and compare
    const slicedNodeKey = nodeKey.slice(0, path.length);
    if (slicedNodeKey === path) return true;
  }
  return path === nodeKey;
};

const generatePropertyPathFromArray = (pathArray: string[], prefixMap: IPrefixMap): string => {
  return pathArray.reduce((result, pathComponent, index) => {
    if (!pathComponent) return result;
    if (index === 0) return result + pathComponent;
    if (isType(pathComponent, prefixMap)) {
      return result + `["${pathComponent}"]`;
    }
    return result + `.${pathComponent}`;
  }, '');
};

const getUnusedNodes = (
  assignments: Schemas.CpType['assignments'],
  parentKey: string,
  renderedPaths: string[],
  level: number,
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    isClickable?: boolean,
    removeOnly?: boolean
  ) => JSX.Element,
  prefixMap: IPrefixMap
): Node[] => {
  if (!assignments) return [];
  const filteredAssignments = parentKey
    ? assignments.filter(
        (assignment) => assignment.propertyJsonPath && getPathAtLevel(assignment.propertyJsonPath, Math.max(0, level - 1)) === parentKey
      )
    : assignments;
  const slicedPaths = filteredAssignments
    .map((assignment) => {
      const path = _.toPath(assignment.propertyJsonPath);
      if (path.length < level + 1) return;
      return {
        fullPath: assignment.propertyJsonPath,
        trimmedPath: _.trim(
          path.slice(0, level + 1).reduce((acc, value, index) => (index % 2 === 0 ? (acc += value) : (acc += `["${value}"].`)), ''),
          '.'
        ),
      };
    })
    .filter(Boolean) as { fullPath: string; trimmedPath: string }[];
  const unusedPaths = slicedPaths.filter((path) => !renderedPaths.includes(path.trimmedPath));
  return unusedPaths.map((unusedPath) => {
    const pathFromLevel = generatePropertyPathFromArray(
      _.toPath(unusedPath.fullPath)
        .slice(level)
        .map((pathComponent) => shortenSubjectUri(pathComponent, prefixMap)),
      prefixMap
    );
    const rawTitle = `${pathFromLevel} (${i18n.t('common.unknown')})`;
    const title = nodeTitleRender(assignments, rawTitle, 0, unusedPath.fullPath, false, true);
    const removedNode: Node = {
      key: unusedPath.fullPath,
      title: title,
      name: rawTitle,
      checkable: false,
      selectedChildrenCount: 0,
      isLeaf: true,
      icon: <Icon iconName={'Unknown'} />,
      disabled: true,
      children: [],
    };
    return removedNode;
  });
};

export const getLevelFromPath = (path: string): number => {
  return _.toPath(path).length - 1;
};

export const getPathAtLevel = (path: string, level: number): string => {
  return _.trim(
    _.toPath(path)
      .slice(0, level + 1)
      .reduce((acc, value, index) => (index % 2 === 0 ? (acc += value) : (acc += `["${value}"].`)), ''),
    '.'
  );
};

export const findUnknownParentNodeKey = (tree: Node[], path: string, level = 0): string => {
  if (!path) return '';
  const pathAtLevel = getPathAtLevel(path, level);
  const matchedNode = tree.find((node) => node.key === pathAtLevel);
  if (!matchedNode) {
    console.error(`Unable to find node with key ${path} at level ${level}`);
    return '';
  }
  const matchedChildren = matchedNode.children?.find((children) => children.key === path);
  if (!matchedChildren) {
    return findUnknownParentNodeKey(matchedNode.children || [], path, level + 1);
  } else {
    return matchedNode.key;
  }
};

export const removeNodeByPath = (tree: Node[], path: string, level: number = 0): Node[] => {
  const pathAtLevel = getPathAtLevel(path, level);
  const filteredTree = tree.filter((node) => node.key === pathAtLevel);
  const matchedNode = filteredTree.find((node) => node.key === pathAtLevel);
  if (!matchedNode) {
    console.error(`Unable to find node with key ${path} at level ${level}`);
    return filteredTree;
  }
  // Find key in children
  const matchedChildren = matchedNode.children?.find((children) => children.key === path);
  if (!matchedChildren) {
    matchedNode.children = removeNodeByPath(matchedNode.children || [], path, level + 1);
    return filteredTree;
  } else {
    matchedNode.children = matchedNode.children?.filter((children) => children.key !== path);
    return filteredTree;
  }
};

const getSelectionCount = (assignments: Schemas.CpType['assignments'], key: string, level: number): number => {
  if (!assignments) return 0;
  const path = getPathAtLevel(key, level);
  // Filter assignments for better performance
  const filteredAssignments = assignments.filter((assignment) => assignment.propertyJsonPath?.startsWith(path));
  return filteredAssignments.reduce((acc, assignment) => {
    const pathAtLevel = getPathAtLevel(assignment.propertyJsonPath || '', level);
    if (path === pathAtLevel && assignment.deactivated !== true) acc += 1;
    return acc;
  }, 0);
};

const generatePropertyPath = (property: string, schema: IJSONSchema, prefix?: string, isArray: boolean = false): string => {
  const parsedType = RDFS.jsonSchemaToDataType(schema.items ? schema.items : schema);
  return `${prefix ? prefix + '' : ''}["${parsedType}${isArray ? '[]' : ''}"]`;
};

const generatePropertyTitle = (schema: IJSONSchema, prefixMap: IPrefixMap, isArray: boolean = false): string => {
  const parsedType = RDFS.jsonSchemaToDataType(schema);
  return `${isArray ? i18n.t('common.listOf') : ''}${getDisplayNameForNodeInTreeView(parsedType!, prefixMap)}`;
};

const compareTreeNodes = (nodeA: Node, nodeB: Node, assignments: Schemas.CpType['assignments'], isSomeNodeSelected: boolean): number => {
  if (!assignments) return 0;
  const aFieldIndex = assignments.find((assignment) => assignment.propertyJsonPath === nodeA.key);
  const bFieldIndex = assignments.find((assignment) => assignment.propertyJsonPath === nodeB.key);
  const aSortOrderAnnotation = aFieldIndex?.annotations?.find(
    (annotation) => annotation.annotationPropertyType?.identifier === SORT_ORDER_ANNOTATION_KEY
  )?.value;
  const bSortOrderAnnotation = bFieldIndex?.annotations?.find(
    (annotation) => annotation.annotationPropertyType?.identifier === SORT_ORDER_ANNOTATION_KEY
  )?.value;
  if (aFieldIndex?.deactivated && aSortOrderAnnotation && !bFieldIndex?.deactivated) return 1;
  if (!aFieldIndex?.deactivated && aSortOrderAnnotation && bFieldIndex?.deactivated) return -1;
  if (aSortOrderAnnotation && !bSortOrderAnnotation) return -1;
  if (!aSortOrderAnnotation && bSortOrderAnnotation) return 1;
  if (aSortOrderAnnotation && bSortOrderAnnotation) return +aSortOrderAnnotation - +bSortOrderAnnotation;
  return 0;
};

export const extendSchema = (schema: IJSONSchema, loadedSchema: IJSONSchema, schemaPath: string): IJSONSchema => {
  const schemaCopy = cloneDeepWithMetadata(schema);
  const oldSchema = _.get(schemaCopy, `properties.${schemaPath}`);
  const updatedSchema = _.merge(loadedSchema, oldSchema);
  _.set(schemaCopy, `properties.${schemaPath}`, updatedSchema);
  return schemaCopy;
};

const processNormalField = (
  schema: IJSONSchema,
  key: string,
  prefixMap: IPrefixMap | null,
  assignments: Schemas.CpType['assignments'],
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    isClickable?: boolean
  ) => JSX.Element,
  level: number,
  parentNode?: Node,
  affectedPath?: string
): Node[] => {
  const resultChildren: Node[] = [];
  const pathAtLevel = affectedPath ? getPathAtLevel(affectedPath, level) : undefined;
  switch (true) {
    case !!schema.enum: {
      const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${schema.$id}"]`;
      if (!isAffectedNode(pathAtLevel, nodeKey)) {
        break;
      }
      const shortTitle = schema.$id && prefixMap ? shortenSubjectUri(schema.$id, prefixMap) : schema.title;
      const selectionCount = getSelectionCount(assignments, nodeKey, level);
      const node = {
        key: nodeKey,
        title: nodeTitleRender(assignments, shortTitle || '', selectionCount, nodeKey),
        name: shortTitle || '',
        icon: <Icon iconName={'Dictionary'} />,
        selectedChildrenCount: 0,
        children: schema.enum!.map((entry) => ({
          key: `${nodeKey}${entry}`,
          title: getDisplayNameForNodeInTreeView(entry as string, prefixMap!) as string,
          name: getDisplayNameForNodeInTreeView(entry as string, prefixMap!) as string,
          checkable: false,
          isLeaf: true,
          selectedChildrenCount: 0,
          label: '',
          icon: <Icon iconName={'Dictionary'} />,
        })),
      };
      resultChildren.push(node);
      break;
    }
    case !!schema.properties: {
      const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${schema.$id}"]`;
      if (!isAffectedNode(pathAtLevel, nodeKey)) {
        break;
      }
      const shortTitle = schema.$id && prefixMap ? shortenSubjectUri(schema.$id, prefixMap) : schema.title;
      const selectionCount = getSelectionCount(assignments, nodeKey, level);
      const node = {
        key: nodeKey,
        title: nodeTitleRender(assignments, shortTitle || '', selectionCount, nodeKey),
        name: shortTitle || '',
        selectedChildrenCount: selectionCount,
        icon: (props: { loading: boolean }) => {
          if (props.loading) {
            return <Spinner styles={spinnerStyles} />;
          }
          return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
        },
        data: {
          schemaPath: `${parentNode?.data?.schemaPath ? parentNode.data.schemaPath + `.properties.` : ''}${key}`,
        },
      };
      resultChildren.push({
        ...node,
        children: parseSchema(schema, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath),
      });
      break;
    }
    case !!schema.items?.properties: {
      const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${schema.items!.$id}[]"]`;
      if (!isAffectedNode(pathAtLevel, nodeKey)) {
        break;
      }
      const shortTitle = schema.items!.$id && prefixMap ? shortenSubjectUri(schema.items!.$id, prefixMap) : schema.title;
      const selectionCount = getSelectionCount(assignments, nodeKey, level);
      const node = {
        key: nodeKey,
        selectedChildrenCount: selectionCount,
        name: shortTitle || '',
        title: nodeTitleRender(
          assignments,
          `${i18n.t('common.listOf')} ${getDisplayNameForNodeInTreeView(shortTitle!, prefixMap!)}`,
          selectionCount,
          nodeKey
        ),
        icon: (props: { loading: boolean }) => {
          if (props.loading) {
            return <Spinner styles={spinnerStyles} />;
          }
          return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
        },
        data: {
          schemaPath: `${parentNode?.data?.schemaPath ? parentNode.data.schemaPath + `.properties.` : ''}${key}.items`,
        },
      };
      resultChildren.push({
        ...node,
        children: parseSchema(schema.items!, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath),
      });
      break;
    }
    case !!schema.$ref: {
      const subjectUri = new URL(schema.$ref!).searchParams.get('subjectUri');
      const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${subjectUri}"]`;
      if (!isAffectedNode(pathAtLevel, nodeKey)) {
        break;
      }
      const selectionCount = getSelectionCount(assignments, nodeKey, level);
      const rawTitle = `${getDisplayNameForNodeInTreeView(subjectUri!, prefixMap!)}`;
      resultChildren.push({
        key: nodeKey,
        title: nodeTitleRender(assignments, rawTitle, selectionCount, nodeKey),
        name: rawTitle,
        isLeaf: false,
        selectedChildrenCount: selectionCount,
        data: {
          ref: schema.$ref!,
          schemaPath: `${parentNode?.data?.schemaPath ? parentNode.data.schemaPath + '.properties.' : ''}${key}`,
        },
        icon: (props: { loading: boolean }) => {
          if (props.loading) {
            return <Spinner styles={spinnerStyles} />;
          }
          return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
        },
      });
      break;
    }
    case schema.type === 'array': {
      if (schema.items?.$ref) {
        const subjectUri = new URL(schema.items.$ref).searchParams.get('subjectUri');
        const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${subjectUri}[]"]`;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          break;
        }
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        const rawTitle = `${i18n.t('common.listOf')} ${getDisplayNameForNodeInTreeView(subjectUri!, prefixMap!)}`;
        resultChildren.push({
          key: nodeKey,
          title: nodeTitleRender(assignments, rawTitle, selectionCount, nodeKey),
          name: rawTitle,
          selectedChildrenCount: selectionCount,
          isLeaf: false,
          data: {
            ref: schema.items.$ref,
            schemaPath: `${parentNode?.data?.schemaPath ? parentNode.data.schemaPath + '.properties.' : ''}${key}.items`,
          },
          icon: (props: { loading: boolean }) => {
            if (props.loading) {
              return <Spinner styles={spinnerStyles} />;
            }
            return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
          },
        });
      } else {
        const nodeKey = generatePropertyPath(key, schema, parentNode?.key, true);
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          break;
        }
        const title = generatePropertyTitle(schema.items!, prefixMap!, true);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        resultChildren.push({
          key: nodeKey,
          title: nodeTitleRender(assignments, title, selectionCount, nodeKey, false),
          name: title,
          selectedChildrenCount: selectionCount,
          data: parentNode?.data,
          isLeaf: true,
          icon: <Icon iconName={'InsertTextBox'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}}></Icon>,
        });
      }
      break;
    }
    default: {
      const nodeKey = generatePropertyPath(key, schema, parentNode?.key);
      if (!isAffectedNode(pathAtLevel, nodeKey)) {
        break;
      }
      const title = generatePropertyTitle(schema, prefixMap!);
      const selectionCount = getSelectionCount(assignments, nodeKey, level);
      resultChildren.push({
        key: nodeKey,
        title: nodeTitleRender(assignments, title, selectionCount, nodeKey, false),
        name: title,
        selectedChildrenCount: selectionCount,
        data: parentNode?.data,
        isLeaf: true,
        icon: <Icon iconName={'InsertTextBox'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}}></Icon>,
      });
      break;
    }
  }
  if (!affectedPath) {
    const unusedNodes = getUnusedNodes(assignments, parentNode?.key || '', [resultChildren[0].key], level, nodeTitleRender, prefixMap!);
    resultChildren.unshift(...unusedNodes);
  }
  return resultChildren;
};

const processAnyOfField = (
  schema: IJSONSchema,
  key: string,
  prefixMap: IPrefixMap | null,
  assignments: Schemas.CpType['assignments'],
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    isClickable?: boolean,
    removeOnly?: boolean
  ) => JSX.Element,
  level: number,
  parentNode?: Node,
  affectedPath?: string
): Node[] => {
  const renderedKeys: string[] = [];
  const pathAtLevel = affectedPath ? getPathAtLevel(affectedPath, level) : undefined;
  const nodes = schema.map((item: IJSONSchema, index: number) => {
    switch (true) {
      case !!item.properties: {
        const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${item.$id}"]`;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        const shortTitle = item.$id && prefixMap ? shortenSubjectUri(item.$id, prefixMap) : item.title;
        const node = {
          key: nodeKey,
          title: nodeTitleRender(assignments, shortTitle || '', selectionCount, nodeKey),
          name: shortTitle || '',
          selectedChildrenCount: selectionCount,
          icon: (props: { loading: boolean }) => {
            if (props.loading) {
              return <Spinner styles={spinnerStyles} />;
            }
            return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
          },
          data: {
            schemaPath: `${parentNode && parentNode.data ? parentNode.data.schemaPath + '.properties.' : ''}${key}.anyOf[${index}]`,
          },
        };
        return {
          ...node,
          children: parseSchema(item, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath),
        };
      }
      case !!item.items?.properties: {
        const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${item.items!.$id}[]"]`;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        const shortTitle = item.items!.$id && prefixMap ? shortenSubjectUri(item.items!.$id, prefixMap) : item.items!.title;
        const rawTitle = `${i18n.t('common.listOf')} ${shortTitle || ''}`;
        const node = {
          key: nodeKey,
          title: nodeTitleRender(assignments, rawTitle, selectionCount, nodeKey),
          name: rawTitle,
          selectedChildrenCount: selectionCount,
          isLeaf: false,
          icon: (props: { loading: boolean }) => {
            if (props.loading) {
              return <Spinner styles={spinnerStyles} />;
            }
            return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
          },
          data: {
            schemaPath: `${parentNode && parentNode.data ? parentNode.data.schemaPath + '.properties.' : ''}${key}.anyOf[${index}].items`,
          },
        };
        return {
          ...node,
          children: parseSchema(item.items!, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath),
        };
      }
      // If it is a single item with a ref, we display title and save a link, which will be used to lazy-load schema for this property
      case !!item.$ref: {
        const subjectUri = new URL(item.$ref!).searchParams.get('subjectUri');
        // get property name from url
        const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${subjectUri}"]`;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        return {
          key: nodeKey,
          title: nodeTitleRender(assignments, `${getDisplayNameForNodeInTreeView(subjectUri!, prefixMap!)}`, selectionCount, nodeKey),
          isLeaf: false,
          selectedChildrenCount: selectionCount,
          data: {
            ref: item.$ref!,
            schemaPath: `${parentNode && parentNode.data ? parentNode.data.schemaPath + '.properties.' : ''}${key}.anyOf[${index}]`,
          },
          icon: (props: { loading: boolean }) => {
            if (props.loading) {
              return <Spinner styles={spinnerStyles} />;
            }
            return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
          },
        };
      }
      // If it is an array, we display title with 'List of'
      case item.type === 'array': {
        if (item.items?.$ref) {
          const subjectUri = new URL(item.items.$ref).searchParams.get('subjectUri');
          const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${subjectUri}[]"]`;
          if (!isAffectedNode(pathAtLevel, nodeKey)) {
            return;
          }
          renderedKeys.push(nodeKey);
          const selectionCount = getSelectionCount(assignments, nodeKey, level);
          return {
            key: nodeKey,
            title: nodeTitleRender(
              assignments,
              `${i18n.t('common.listOf')} ${getDisplayNameForNodeInTreeView(subjectUri!, prefixMap!)}`,
              selectionCount,
              nodeKey
            ),
            selectedChildrenCount: selectionCount,
            isLeaf: false,
            data: {
              ref: item.items.$ref,
              schemaPath: `${parentNode?.data?.schemaPath ? parentNode.data.schemaPath + '.properties.' : ''}${key}.anyOf[${index}].items`,
            },
            icon: (props: { loading: boolean }) => {
              if (props.loading) {
                return <Spinner styles={spinnerStyles} />;
              }
              return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
            },
          };
        } else {
          const nodeKey = generatePropertyPath(key, item.items!, parentNode?.key, true);
          if (!isAffectedNode(pathAtLevel, nodeKey)) {
            return;
          }
          renderedKeys.push(nodeKey);
          const title = generatePropertyTitle(item.items!, prefixMap!, true);
          const selectionCount = getSelectionCount(assignments, nodeKey, level);
          return {
            key: nodeKey,
            title: nodeTitleRender(assignments, title, selectionCount, nodeKey, false),
            selectedChildrenCount: selectionCount,
            data: parentNode?.data,
            isLeaf: true,
            icon: <Icon iconName={'InsertTextBox'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}}></Icon>,
          };
        }
      }
      default: {
        const nodeKey = generatePropertyPath(key, item, parentNode?.key);
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const title = generatePropertyTitle(item, prefixMap!);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        return {
          key: nodeKey,
          title: nodeTitleRender(assignments, title, selectionCount, nodeKey, false),
          selectedChildrenCount: selectionCount,
          data: parentNode?.data,
          isLeaf: true,
          icon: <Icon iconName={'InsertTextBox'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}}></Icon>,
        };
      }
    }
  });
  if (!affectedPath) {
    const unusedNodes = getUnusedNodes(assignments, parentNode?.key || '', renderedKeys, level, nodeTitleRender, prefixMap!);
    nodes.unshift(...unusedNodes);
  }
  return nodes;
};

const processAnyOfArray = (
  schema: IJSONSchema,
  key: string,
  prefixMap: IPrefixMap | null,
  assignments: Schemas.CpType['assignments'],
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    isClickable?: boolean,
    removeOnly?: boolean
  ) => JSX.Element,
  level: number,
  parentNode?: Node,
  affectedPath?: string
): Node[] => {
  const renderedKeys: string[] = [];
  const pathAtLevel = affectedPath ? getPathAtLevel(affectedPath, level) : undefined;
  const nodes = schema.map((item: IJSONSchema, index: number) => {
    switch (true) {
      case !!item.properties: {
        const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${item.$id}[]"]`;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        const shortTitle = item.$id && prefixMap ? shortenSubjectUri(item.$id, prefixMap) : item.title;
        const rawTitle = `${i18n.t('common.listOf')}${shortTitle}`;
        const node = {
          key: nodeKey,
          title: nodeTitleRender(assignments, rawTitle, selectionCount, nodeKey),
          name: rawTitle,
          selectedChildrenCount: selectionCount,
          icon: (props: { loading: boolean }) => {
            if (props.loading) {
              return <Spinner styles={spinnerStyles} />;
            }
            return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
          },
          data: {
            schemaPath: `${parentNode && parentNode.data ? `${parentNode.data.schemaPath}.properties.` : ''}${key}.items.anyOf[${index}]`,
          },
        };
        return {
          ...node,
          children: parseSchema(item, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath),
        };
      }
      // If it is a single item with a ref, we display title and save a link, which will be used to lazy-load schema for this property
      case !!item.$ref: {
        const subjectUri = new URL(item.$ref!).searchParams.get('subjectUri');
        // get property name from url
        const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${subjectUri}[]"]`;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        return {
          key: nodeKey,
          title: nodeTitleRender(
            assignments,
            `${i18n.t('common.listOf')} ${getDisplayNameForNodeInTreeView(subjectUri!, prefixMap!)}`,
            selectionCount,
            nodeKey
          ),
          selectedChildrenCount: selectionCount,
          isLeaf: false,
          data: {
            ref: item.$ref!,
            schemaPath: `${parentNode && parentNode.data ? `${parentNode.data.schemaPath}.properties.` : ''}${key}.items.anyOf[${index}]`,
          },
          icon: (props: { loading: boolean }) => {
            if (props.loading) {
              return <Spinner styles={spinnerStyles} />;
            }
            return <Icon iconName={'CircleRing'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />;
          },
        };
      }
      default: {
        const nodeKey = generatePropertyPath(key, item, parentNode?.key, true);
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        renderedKeys.push(nodeKey);
        const title = generatePropertyTitle(item, prefixMap!, true);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        return {
          key: nodeKey,
          title: nodeTitleRender(assignments, title, selectionCount, nodeKey, false),
          selectedChildrenCount: selectionCount,
          data: parentNode?.data,
          isLeaf: true,
          icon: <Icon iconName={'InsertTextBox'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}}></Icon>,
        };
      }
    }
  });
  if (!affectedPath) {
    const unusedNodes = getUnusedNodes(assignments, parentNode?.key || '', renderedKeys, level, nodeTitleRender, prefixMap!);
    nodes.unshift(...unusedNodes);
  }
  return nodes;
};

const processAnyOfEnum = (
  schema: IJSONSchema,
  key: string,
  prefixMap: IPrefixMap | null,
  assignments: Schemas.CpType['assignments'],
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    isClickable?: boolean
  ) => JSX.Element,
  level: number,
  parentNode?: Node
): Node[] => {
  const nodeKey = `${parentNode?.key ? parentNode.key + '' : ''}["${schema.items?.$id}[]"]`;
  const shortTitle = schema.items?.$id && prefixMap ? shortenSubjectUri(schema.items?.$id, prefixMap) : schema.title;
  const selectionCount = getSelectionCount(assignments, nodeKey, level);
  const rawTitle = `${i18n.t('common.listOf')} ${shortTitle || ''}`;
  const nodes = [
    {
      key: nodeKey,
      title: nodeTitleRender(assignments, rawTitle, selectionCount, nodeKey),
      name: rawTitle,
      icon: <Icon iconName={'Dictionary'} />,
      selectedChildrenCount: 0,
      children: schema.items?.anyOf?.map((item) => ({
        key: `${nodeKey}${item?.enum?.[0]}`,
        title: getDisplayNameForNodeInTreeView(item?.enum?.[0] as string, prefixMap!) as string,
        name: getDisplayNameForNodeInTreeView(item?.enum?.[0] as string, prefixMap!) as string,
        checkable: false,
        isLeaf: true,
        selectedChildrenCount: 0,
        label: '',
        icon: <Icon iconName={'Dictionary'} />,
      })),
    },
  ];
  const unusedNodes = getUnusedNodes(assignments, parentNode?.key || '', [nodes[0].key], level, nodeTitleRender, prefixMap!);
  nodes.unshift(...(unusedNodes as any));
  return nodes;
};

export const parseSchema = (
  schema: IJSONSchema,
  prefixMap: IPrefixMap | null,
  assignments: Schemas.CpType['assignments'],
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    isClickable?: boolean,
    removeOnly?: boolean
  ) => JSX.Element,
  level: number = 0,
  parentNode?: Node,
  affectedPath?: string,
  updateRoot?: boolean
): Node[] => {
  if (!assignments || !schema) return [];
  if (updateRoot && level !== 0) return [];
  if (schema.properties) {
    const renderedAssignments: string[] = [];
    const pathAtLevel = affectedPath ? getPathAtLevel(affectedPath, level) : undefined;
    const tree = Object.entries(schema.properties as IJSONSchema)
      .map(([key, value]) => {
        const anyOfField = value.anyOf ? value.anyOf : value.items?.anyOf ? value.items.anyOf : null;
        const nodeKey = `${parentNode?.key ? parentNode.key + '.' : ''}${key}`;
        const rawTitle: string = value.title || key;
        if (!isAffectedNode(pathAtLevel, nodeKey)) {
          return;
        }
        // const matchedAssignment = getMatchingAssignment(assignments, nodeKey);
        const selectionCount = getSelectionCount(assignments, nodeKey, level);
        const title = nodeTitleRender(assignments, rawTitle, selectionCount, nodeKey);
        renderedAssignments.push(nodeKey);
        const node = {
          key: nodeKey,
          title: title,
          name: rawTitle,
          checkable: false,
          selectedChildrenCount: selectionCount,
          isLeaf: false,
          icon: <Icon iconName={'FieldFilled'} styles={selectionCount ? { root: { color: '#b39c4d' } } : {}} />,
          data: parentNode?.data,
        };

        // Special case with enum
        if (anyOfField && value.items?.anyOf && value.type === 'array' && value.uniqueItems && anyOfField.every((item: IJSONSchema) => item.enum)) {
          const children = processAnyOfEnum(value, key, prefixMap, assignments, nodeTitleRender, level + 1, node);
          const isContainsActivatedAndSelectedNode = children.some((node) => node.selectedChildrenCount);
          return {
            ...node,
            children: children.sort((a, b) => compareTreeNodes(a, b, assignments, isContainsActivatedAndSelectedNode)),
          };
        }

        const children = !updateRoot
          ? anyOfField
            ? value.items?.anyOf && value.type === 'array'
              ? processAnyOfArray(anyOfField, key, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath)
              : processAnyOfField(anyOfField, key, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath)
            : processNormalField(value, key, prefixMap, assignments, nodeTitleRender, level + 1, node, affectedPath)
          : [];

        const isContainsActivatedAndSelectedNode = children.some((node) => node?.selectedChildrenCount);

        return {
          ...node,
          children: children.sort((a, b) => compareTreeNodes(a, b, assignments, isContainsActivatedAndSelectedNode)),
        };
      })
      .filter(Boolean) as Node[];

    const isContainsActivatedAndSelectedNode = tree.some((node) => node.selectedChildrenCount);
    const sortedTree = tree.sort((a, b) => compareTreeNodes(a as Node, b as Node, assignments, isContainsActivatedAndSelectedNode));
    if (!affectedPath) {
      const unusedNodes = getUnusedNodes(assignments, parentNode?.key || '', renderedAssignments, level, nodeTitleRender, prefixMap!);
      sortedTree.unshift(...unusedNodes);
    }
    return sortedTree as Node[];
  }
  return [];
};

export const updateTree = (
  destination: Node[],
  source: Node[],
  assignments: Schemas.CpType['assignments'],
  nodeTitleRender: (
    assignments: Schemas.CpType['assignments'],
    title: string,
    selectionCount: number,
    key: string,
    removeOnly?: boolean
  ) => JSX.Element,
  prefixMap: IPrefixMap | null,
  resetUnusedNodes: boolean = false,
  sort: boolean = false,
  updateRoot: boolean = false,
  parentNodeKey?: string,
  level: number = 0
): Node[] => {
  if (updateRoot) {
    for (const sourceNode of source) {
      const matchedNodeIndex = destination.findIndex(
        (destinationNode) => destinationNode?.key && sourceNode?.key && destinationNode.key === sourceNode.key
      );
      if (matchedNodeIndex === -1) continue;
      destination[matchedNodeIndex] = { ...sourceNode, children: destination[matchedNodeIndex].children };
    }
    const filteredUpdatedNodes = destination.filter((node) => !node.disabled);
    const unusedNodes: Node[] = getUnusedNodes(assignments, '', filteredUpdatedNodes.map((node) => node.key) || [], 0, nodeTitleRender, prefixMap!);
    return [...unusedNodes, ...filteredUpdatedNodes];
  }
  for (const sourceNode of source) {
    const matchedNodeIndex = destination.findIndex(
      (destinationNode) => destinationNode?.key && sourceNode?.key && destinationNode.key === sourceNode.key
    );
    if (matchedNodeIndex === -1) continue;
    const node = destination[matchedNodeIndex];
    if (node.children?.length && sourceNode.children) {
      const updatedChildren = updateTree(
        node.children,
        sourceNode.children,
        assignments,
        nodeTitleRender,
        prefixMap,
        resetUnusedNodes,
        sort,
        updateRoot,
        node.key,
        level + 1
      );
      const filteredUpdatedChildren = updatedChildren.filter((child) => !child.disabled);
      const unusedNodes: Node[] = getUnusedNodes(
        assignments,
        sourceNode.key || '',
        filteredUpdatedChildren.map((childNode) => childNode.key) || [],
        level + 1,
        nodeTitleRender,
        prefixMap!
      );
      destination[matchedNodeIndex] = { ...sourceNode, children: [...unusedNodes, ...filteredUpdatedChildren] };
    } else {
      const unusedNodes = sourceNode.data?.ref
        ? []
        : getUnusedNodes(
            assignments,
            sourceNode.key || '',
            sourceNode.children?.map((childNode) => childNode.key) || [],
            level + 1,
            nodeTitleRender,
            prefixMap!
          );
      destination[matchedNodeIndex] = { ...sourceNode, children: [...unusedNodes, ...(sourceNode.children || [])] };
    }
  }
  if (sort) {
    const isContainsActivatedAndSelectedNode = destination.some((node) => node.selectedChildrenCount);
    const sortedNodes = destination.sort((a, b) => compareTreeNodes(a as Node, b as Node, assignments, isContainsActivatedAndSelectedNode));
    const filteredUpdatedNodes = sortedNodes.filter((node) => !node.disabled);
    const unusedNodes: Node[] = getUnusedNodes(assignments, '', filteredUpdatedNodes.map((node) => node.key) || [], 0, nodeTitleRender, prefixMap!);
    return [...unusedNodes, ...filteredUpdatedNodes];
  }
  return destination;
};

const getNodeByPosition = (tree: Node[], position: string, removeLeading?: boolean, removeTrailing?: boolean): Node | Node[] | undefined => {
  const positions = position.split('-');
  if (removeLeading) {
    positions.shift();
  }
  if (removeTrailing) {
    positions.pop();
  }
  if (!positions.length) return tree;
  const targetNodePath = positions.reduce((acc, position, index) => {
    if (index === 0) acc += `[${position}]`;
    else acc += `.children[${position}]`;
    return acc;
  }, '');
  return _.get(tree, targetNodePath);
};

/**
 * Gets the name from the annotations and if not found from the identifier.
 * @param obj
 */
export const getDisplayNameForNodeInTreeView = (
  fullPropertyName: string | undefined,
  prefixMap: {
    [key: string]: string;
  }
): string => {
  if (!fullPropertyName) return '';

  return _.reduce(
    Object.entries(prefixMap),
    (s, [short, long]) => {
      return s.replace(long, `${short}:`);
    },
    fullPropertyName
  );
};

export const isPropertyNode = (node: Node): boolean => {
  const path = _.toPath(node.key);
  return !!(path.length % 2);
};

export const isSameLevelNodes = (nodeA: Node, nodeB: Node): boolean => {
  // Remove last path element
  const nodeAPath = _.toPath(nodeA.key).slice(0, -1);
  const nodeBPath = _.toPath(nodeB.key).slice(0, -1);
  return nodeAPath.join('') === nodeBPath.join('');
};

export const isParentNode = (child: Node, parent: Node): boolean => {
  const childPath = _.toPath(child.key).slice(0, -1);
  const parentPath = _.toPath(parent.key);
  return childPath.join('') === parentPath.join('');
};

export const createFieldIndex = (propertyJsonPath: string, deactivated: boolean = true): Extract<Schemas.CpType['assignments'], {}>[0] => {
  return {
    annotations: [],
    propertyJsonPath: propertyJsonPath,
    deactivated: deactivated,
  };
};

export const getUpdatedTreeOrder = (
  assignments: Schemas.CpType['assignments'],
  selectedClass: Schemas.CpClass,
  tree: Node[],
  dragNode: Node & { pos: string },
  dropNode: Node & { pos: string },
  dropPosition: string
): Schemas.CpType['assignments'] | undefined => {
  if (!assignments) return;
  const assignmentsCopy = cloneDeepWithMetadata(assignments);
  const treeCopy = cloneDeepWithMetadata(tree);
  const posFrom = dragNode.pos;
  let targetArray = getNodeByPosition(treeCopy, posFrom, true, true);
  if (targetArray && !Array.isArray(targetArray)) {
    targetArray = targetArray.children;
  }
  const filteredTargetArray = targetArray?.filter((node) => node.disabled !== true);
  const disabledItemsCount = targetArray && filteredTargetArray ? targetArray.length - filteredTargetArray.length : 0;
  const dragItemIndex = _.last(posFrom.split('-'));
  const dropItemIndex = dropPosition;
  if (!dragItemIndex || !dropItemIndex || !filteredTargetArray || !Array.isArray(filteredTargetArray)) return;
  const correctedDragIndex = +dragItemIndex - disabledItemsCount;
  let correctedDropIndex = +dropItemIndex - disabledItemsCount;
  if (correctedDropIndex > correctedDragIndex) {
    correctedDropIndex -= 1;
  }
  const dragItem = filteredTargetArray.splice(correctedDragIndex, 1);
  const updatedArray = [...filteredTargetArray.slice(0, correctedDropIndex), ...dragItem, ...filteredTargetArray.slice(correctedDropIndex)];
  updatedArray.forEach((node, index) => {
    let assignment = assignmentsCopy.find((assignment) => assignment.propertyJsonPath === node.key);
    if (!assignment) {
      assignment = createFieldIndex(node.key);
      assignmentsCopy.push(assignment);
    }
    if (node.selectedChildrenCount && dragNode.selectedChildrenCount) {
      assignment.deactivated = false;
    }
    if (!assignment!.annotations) {
      assignment!.annotations = [];
    }
    const sortOrderAnnotation = assignment?.annotations?.find(
      (annotation) => annotation.annotationPropertyType?.identifier === SORT_ORDER_ANNOTATION_KEY
    );
    if (!sortOrderAnnotation) {
      assignment!.annotations.push({
        annotationPropertyType: { identifier: SORT_ORDER_ANNOTATION_KEY },
        value: `${index + updatedArray.length}`,
      });
    } else {
      sortOrderAnnotation.value = `${index + updatedArray.length}`;
    }
  });
  return assignmentsCopy;
};

const isType = (selector: string, prefixMap: IPrefixMap): boolean => {
  const shortSelector = getDisplayNameForNodeInTreeView(selector, prefixMap);
  return shortSelector.includes(':');
};

export const matchPropertyTypes = (
  assignments: Schemas.CpType['assignments'],
  schema: IJSONSchema,
  prefixMap: IPrefixMap
): Schemas.CpType['assignments'] => {
  if (!assignments) return assignments;
  if (!schema.properties) return assignments;
  for (const assignment of assignments) {
    // Split path to [property, type] format
    let pathUpdated = false;
    const pathChunks = _.chunk(_.toPath(assignment.propertyJsonPath), 2);
    const accumulatedPath: string[] = [];
    for (const pathChunk of pathChunks) {
      accumulatedPath.push(pathChunk[0]);
      if (pathChunk[1] && !isType(pathChunk[1], prefixMap)) {
        // Get type and transform
        const propertySchema = resolveSchemaPath(schema.properties, accumulatedPath.join('.'), true);
        if (!propertySchema) break;
        const ref = propertySchema.items?.$ref || propertySchema.$ref;
        if (ref) {
          const subjectUri = new URL(ref).searchParams.get('subjectUri');
          pathChunk[2] = pathChunk[1];
          pathChunk[1] = `${subjectUri!}${propertySchema.type === 'array' ? '[]' : ''}`;
          pathUpdated = true;
          break;
        }
      }
    }
    if (pathUpdated) {
      assignment.propertyJsonPath = generatePropertyPathFromArray(pathChunks.flat(), prefixMap);
    }
  }
  return assignments;
};
