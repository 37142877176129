import { EventEmitter } from 'events';

import { IGlobalState } from '@cpa/base-core/store';
import { GlobalDialogType, IBase, ILanguage } from '@cpa/base-core/types';
import { DefaultButton, DialogFooter, Icon, PrimaryButton, Layer } from '@fluentui/react';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { applySettings, closePageDrawer, closeSettings, setCookieAgreement } from '@cpa/base-core/store/settings/actions';
import { StorageKey } from '@cpa/base-core/constants';
import { gaStatus, getSafeString, instantApmTransaction, showDialog } from '@cpa/base-core/helpers';
import { Toaster } from 'react-hot-toast';
import { Environment } from '@cpa/base-core/app/environment';
import { Schemas } from '@cp/base-types';
import { hideDialog, setCurrentTourBubble } from '@cpa/base-core/store/app/actions';
import { useBoolean } from '@fluentui/react-hooks';
import { match } from 'react-router';
import { useMenuItems, usePowerUser, useSyncedCpaConfiguration, useSyncedPageSettings } from '@cpa/base-core/hooks';

import Drawer from '../../components/Drawer/Drawer';

import Cookie from './components/Cookie/Cookie';
import Bot from './components/Bot/Bot';
import Header, { IHeaderButton } from './components/Header/Header';
import HorizontalMenu from './components/HorizontalMenu/HorizontalMenu';
import LanguageSelect from './components/LanguageSelect/LanguageSelect';
import VerticalMenu from './components/VerticalMenu/VerticalMenu';
import GenericComponentSettings from './components/GenericComponentSettings/GenericComponentSettings';
import LoadingLine from './components/LoadingLine/LoadingLine';
import AppSettings from './components/AppSettings/AppSettings';
import styles from './Layout.module.scss';
import ActionBubbles, { IActionBubble } from './components/ActionBubbles/ActionBubbles';
import Feedback from './components/ActionBubbles/components/Feedback/Feedback';
import steps, { completeTour, isTourCompleted } from './components/Tour/tourSteps';

interface ILink {
  key: string;
  path?: string;
  text: string;
  icon?: string;
  divider?: boolean;
  parentMenuItemKey?: IBase;
  sortOrderMenu?: number;
  externalUrl?: string;
  cpType?: string;
  actions?: Schemas.CpaPage['actions'];
  dataEndpointIdentifier?: string;
  pageIdentifier?: string;
}

export const HIDE_SIGNIN_STORAGE_KEY = 'hideSignInHint';

export interface ILayoutContent {
  page: Schemas.CpaPage;
  routeMatch: match;
  hasForcedLanguage?: boolean;
}

export const PageContext = React.createContext<{
  isSignInHintCancelled: boolean;
  setIsSignInHintCancelled: (isSignInHintCancelled: boolean) => void;
} | null>(null);

export const LayoutContext = React.createContext<{
  isHorizontalMenu: boolean;
  setLayoutContent: (layoutContent: ILayoutContent) => void;
  hideBubbles: boolean;
} | null>(null);

export const TourContext = React.createContext<{
  tourEventEmitter: React.RefObject<EventEmitter>;
  skipTour: () => void;
} | null>(null);

export interface ILayoutProps {
  children: React.ReactNode;
}

const generateTourEventEmitter = (): EventEmitter => {
  const eventEmitter = new EventEmitter();
  eventEmitter.setMaxListeners(0);
  return eventEmitter;
};

const toasterLayerStyles = { root: { zIndex: 10000100 } };

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  const [layoutContent, setLayoutContent] = useState<ILayoutContent>();

  const [t] = useTranslation();
  const [isMenuOpen, { toggle: toggleMenu, setFalse: closeMenu }] = useBoolean(false);
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IGlobalState) => state.settings.isOpened);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const allLocales = useSelector((state: IGlobalState) => state.app.allLocales);
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });
  const horizontalMenuEnabled = useSelector((state: IGlobalState) => state.settings.useOnlyHorizontalMenu);
  const redirectTarget = useSelector((state: IGlobalState) => state.app.pages[0]);
  const [isSignInHintCancelled, setIsSignInHintCancelled] = useState(localStorage.getItem(HIDE_SIGNIN_STORAGE_KEY) === 'true');
  const [showGreetings, setShowGreetings] = useState(localStorage.getItem(StorageKey.HideGreetings) !== 'true');
  const [hideBubbles, setHideBubbles] = useState(() => {
    return isTourCompleted();
  });
  const powerUser = usePowerUser();

  const onSettingsUpdate = useSyncedCpaConfiguration();
  const updatePageSetting = useSyncedPageSettings(true);

  const renderHorizontalMenu = !isMobileDevice && horizontalMenuEnabled;

  const layoutRef = useRef<HTMLDivElement>(null);

  const tourEventEmitter = useRef<EventEmitter>(generateTourEventEmitter());

  const closeSettingsPanel = useCallback(async () => {
    dispatch(closeSettings());
    await onSettingsUpdate();
  }, [dispatch, onSettingsUpdate]);

  const handleTitleClick = useCallback(() => {
    const path = redirectTarget?.path || '/dashboard';
    dispatch(push(path));
  }, [dispatch, redirectTarget]);

  const [menuItems, linksForMenu] = useMenuItems();

  const isSearchVisibleForMenu = useMemo(() => {
    // display search if more than 5 items with path exist
    return linksForMenu.filter((l) => !!l.path).length > 5;
  }, [linksForMenu]);

  const gaAllowed = useSelector((state: IGlobalState) => state.settings.acceptGA);

  useEffect(() => {
    if (layoutContent?.page) {
      if (gaStatus.initialized) {
        ReactGA.set({ title: layoutContent.page.identifier });
        if (layoutContent.page.path) {
          ReactGA.pageview(layoutContent.page.path, undefined, layoutContent.page.identifier);
        }
      }

      if (layoutContent.page.path) {
        instantApmTransaction(layoutContent.page.path, 'route-change', {
          pageName: layoutContent.page.name,
          pageIdentifier: layoutContent.page.identifier,
        });
      }

      document.title = (getSafeString(cpa?.configuration.name, true) || `Unknown CPA`) + ` - ${layoutContent.page.name}`;
    }
  }, [layoutContent, cpa, gaAllowed]);

  const confirmGreetings = useCallback(() => {
    localStorage.setItem(StorageKey.HideGreetings, 'true');
    setShowGreetings(false);
    dispatch(hideDialog());
  }, [dispatch]);

  const skipTour = useCallback(() => {
    confirmGreetings();
    for (const step of steps) {
      localStorage.setItem(`hide${step.name}Bubble`, 'true');
    }
    dispatch(setCurrentTourBubble({ bubble: null }));
    completeTour();
    setHideBubbles(true);
  }, [confirmGreetings, dispatch]);

  const takeATour = useCallback(() => {
    confirmGreetings();
    for (const step of steps) {
      localStorage.setItem(`hide${step.name}Bubble`, 'false');
    }
    setHideBubbles(false);
    tourEventEmitter.current.emit('forceBubbleCheck');
  }, [confirmGreetings]);

  const currentLocation = useSelector((state: IGlobalState) => state.router.location);

  const buttons = useMemo<IHeaderButton[]>(() => {
    const baseButtons: IHeaderButton[] = [];

    return baseButtons;
  }, []);

  const hideCookie = useSelector((state: IGlobalState) => state.settings.hideCookie as boolean);

  const onCookieAccept = useCallback(
    (acceptedFields: Record<string, boolean>) => {
      dispatch(setCookieAgreement(acceptedFields));
    },
    [dispatch]
  );

  const isSettingDrawerOpened = useSelector((state: IGlobalState) => state.settings.pagesDrawer.isOpened);
  const activePage = useSelector((state: IGlobalState) => state.settings.pagesDrawer.activePage);

  const onSettingsClose = useCallback(async () => {
    dispatch(closePageDrawer());
  }, [dispatch]);

  const greetingsText = useMemo<string>(
    () => t('common.greetings', { name: user?.account?.name ? `, ${user.account.name}` : '' }).toString(),
    [user, t]
  );

  const dataLanguages: ILanguage[] = useMemo(() => {
    return [
      {
        identifier: 'notSelected',
        appLocale: 'notSelected',
        name: t('common.notSelected'),
        alternateName: t('common.notSelected'),
      },
      ...allLocales,
    ];
  }, [t, allLocales]);

  const onDataLanguageChange = useCallback(
    (closePopup: () => void) =>
      async (code: string): Promise<void> => {
        dispatch(applySettings({ settings: { dataLanguage: code === 'notSelected' ? null : code } }));
        closePopup();
        await onSettingsUpdate();
      },
    [dispatch, onSettingsUpdate]
  );

  const actionBubbles: IActionBubble[] = useMemo(() => {
    const actions: IActionBubble[] = [];

    if (layoutContent?.page && user) {
      actions.unshift({
        key: 'feedback',
        tooltip: t('feedback.bubble.title'),
        icon: 'Emoji2',
        onRender: (bubble: IActionBubble, index: number, visible: boolean, onClose: () => void): JSX.Element | null => {
          return <Feedback thankYouText={t('feedback.thankYouText')} visible={visible} currentPage={layoutContent.page} onClose={onClose} />;
        },
      });
    }

    if (user && !Environment.env.REACT_APP_HIDE_COSMA_ACTION_BUBBLE) {
      actions.unshift({
        key: 'cosma',
        tooltip: 'COSMA',
        icon: 'Feedback',
        layerClassName: styles.botLayer,
        onRender: (bubble, index, visible): JSX.Element | null => {
          return <Bot title="COSMA" url="https://cosma.cosmoconsult.com/embed" visible={visible} />;
        },
      });
    }

    if (Environment.env.REACT_APP_DISPLAY_DATA_LANGUAGE_ACTION_BUBBLE && powerUser) {
      const isDataLanguageSelected = dataLanguage && dataLanguage !== 'notSelected';

      actions.unshift({
        key: 'data-language',
        tooltip: t('header.controls.settings.dataLanguage'),
        icon: 'LocaleLanguage',
        iconStyle: { marginLeft: 2 },
        buttonStyle: { backgroundColor: isDataLanguageSelected ? '#B39C4D' : undefined },
        onRenderButton: (bubble, index, iconComponentProps) => {
          return (
            <div className={styles.dataLanguageButtonWrapper}>
              {!!isDataLanguageSelected && <div className={styles.notificationCircle} />}
              {iconComponentProps({
                onRenderIcon: (buttonProps) => {
                  if (isDataLanguageSelected) {
                    const matchedLanguage = dataLanguages.find(({ identifier }) => identifier === dataLanguage);
                    if (matchedLanguage && matchedLanguage.icon) {
                      return (
                        <ReactCountryFlag
                          style={{
                            width: '24px',
                            height: '16px',
                            backgroundSize: 'contain',
                          }}
                          countryCode={matchedLanguage.icon}
                          svg={true}
                        />
                      );
                    }
                  }

                  return <Icon {...(buttonProps?.iconProps || {})} />;
                },
              })}
            </div>
          );
        },
        onRender: (bubble, index, visible: boolean, closePopup): JSX.Element | null => {
          return (
            <div style={{ padding: 10 }}>
              <LanguageSelect
                useLocalizedName={true}
                darkMode={darkMode}
                onLanguageClick={onDataLanguageChange(closePopup)}
                languages={dataLanguages}
                title={t('header.controls.settings.dataLanguage')}
                currentLanguage={dataLanguage || 'notSelected'}
                className={styles.dataLanguageSelect}
              />
            </div>
          );
        },
      });
    }

    return actions;
  }, [t, user, layoutContent, dataLanguage, dataLanguages, darkMode, onDataLanguageChange]);

  const horizontalMenuStyle = useMemo(
    () => ({
      marginTop: 101,
      padding: !renderHorizontalMenu ? '0 64px' : '0 54px',
    }),
    [renderHorizontalMenu]
  );

  const tourContext = useMemo(() => {
    return {
      tourEventEmitter: tourEventEmitter,
      skipTour: skipTour,
    };
  }, [skipTour]);

  const layoutContext = useMemo(() => {
    return {
      isHorizontalMenu: renderHorizontalMenu,
      setLayoutContent,
      hideBubbles,
    };
  }, [hideBubbles, renderHorizontalMenu]);

  const pageContext = useMemo(() => {
    return {
      isSignInHintCancelled: isSignInHintCancelled,
      setIsSignInHintCancelled,
    };
  }, [isSignInHintCancelled]);

  const settingsToggle = useMemo(
    () =>
      isMobileDevice
        ? {
            useOnlyHorizontalMenu: false,
          }
        : undefined,
    [isMobileDevice]
  );

  const greetingsDialogProps = useMemo(
    () => ({
      isModeless: false,
      className: darkMode ? styles.greetingsDark : styles.greetings,
    }),
    [darkMode]
  );

  const toasterContainerStyle = useMemo(() => {
    const headerHeight = 101;
    const offset = 10;

    return { top: `${renderHorizontalMenu ? headerHeight + offset + 44 : headerHeight + offset}px`, right: 25 };
  }, [renderHorizontalMenu]);

  useEffect(() => {
    if (showGreetings) {
      showDialog({
        type: GlobalDialogType.CUSTOM,
        title: t('common.greetingsTitle'),
        dialogContentProps: greetingsDialogProps,
        dialogTypeOptions: {
          renderBody: () => {
            return (
              <>
                {greetingsText.split('\n').map((line, index, list) => (
                  <p key={index} style={{ margin: 0, paddingBottom: index === list.length - 1 ? 0 : 10 }}>
                    {line}
                  </p>
                ))}
                <DialogFooter>
                  <DefaultButton text={t('common.gotIt')} onClick={skipTour} />
                  <PrimaryButton
                    text={t('common.takeATour')}
                    onClick={takeATour}
                    className={darkMode ? styles.greetingsBtnDark : styles.greetingsBtn}
                  />
                </DialogFooter>
              </>
            );
          },
        },
        width: 'calc(max(30%, 400px))',
      });
    }
  }, [darkMode, greetingsDialogProps, greetingsText, showGreetings, skipTour, t, takeATour]);

  return (
    <PageContext.Provider value={pageContext}>
      <div className={darkMode ? styles.layoutDark : styles.layout} ref={layoutRef}>
        <Header
          isMenuOpen={isMenuOpen}
          toggleMainMenu={toggleMenu}
          logo={cpa?.headerLogo}
          logoPath={redirectTarget?.path || '/dashboard'}
          onTitleClick={handleTitleClick}
          buttons={buttons}
          appTitle={getSafeString(cpa?.configuration.name) || `Unknown CPA`}
          hideMenuButton={renderHorizontalMenu}
          page={layoutContent?.page}
          hasForcedLanguage={layoutContent?.hasForcedLanguage}
          routeMatch={layoutContent?.routeMatch}
        />
        {renderHorizontalMenu && (
          <HorizontalMenu
            style={horizontalMenuStyle}
            darkMode={darkMode}
            menuItems={menuItems}
            activePath={currentLocation.pathname}
            isSearchVisible={isSearchVisibleForMenu}
          />
        )}
        <LoadingLine top={renderHorizontalMenu ? 147 : 101} />
        {!renderHorizontalMenu && (
          <VerticalMenu
            isOpen={isMenuOpen}
            onDismiss={closeMenu}
            onLinkClick={closeMenu}
            menuItems={menuItems}
            activePath={currentLocation.pathname}
            isSearchVisible={isSearchVisibleForMenu}
          />
        )}
        {/* Possible performance issues */}
        <LayoutContext.Provider value={layoutContext}>
          <TourContext.Provider value={tourContext}>
            <div>
              <Layer styles={toasterLayerStyles}>
                <Toaster position="top-right" containerStyle={toasterContainerStyle} containerClassName={styles.toastContainer} />
              </Layer>
            </div>
            {children}
            <Drawer
              width="calc(max(30%, 400px))"
              isOpen={isSettingDrawerOpened}
              onClose={onSettingsClose}
              title={activePage?.name || ''}
              headerClassName={styles.settingsDrawerHeader}
              className={styles.settingsDrawerContent}
            >
              {!!activePage && <GenericComponentSettings page={activePage} updatePageSetting={updatePageSetting} />}
            </Drawer>
            <ActionBubbles bubbles={actionBubbles} />
          </TourContext.Provider>
        </LayoutContext.Provider>
        <Drawer
          isOpen={isOpened}
          width="calc(max(30%, 400px))"
          title={t('header.controls.settings.title')}
          onClose={closeSettingsPanel}
          headerClassName={styles.settingsDrawerHeader}
          className={styles.settingsDrawerContent}
        >
          <AppSettings forcedToggleState={settingsToggle} onClose={closeSettingsPanel} onTakeATourClick={takeATour} />
        </Drawer>

        {hideCookie || (
          <Cookie
            title={t('cookie.title')}
            text={t('cookie.text')}
            btnAcceptAllText={t('cookie.acceptAllBtn')}
            btnAcceptSelectedText={t('cookie.acceptSelectedBtn')}
            onAccept={onCookieAccept}
          />
        )}
      </div>
    </PageContext.Provider>
  );
};

export default Layout;
