const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/playwright/TestProduct',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Fplaywright%2FTestProduct' }],
    'cp_aggregationPipeline': [
        '[{"$set": {"dateCreated":"$_cp:dataServiceMetadata.createdAt"} }]',
        '[{"$set":{"width.value":{"$ifNull":[{"$multiply":["$height.value",2]},0]}}}]',
        '[]',
        '[]',
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': true,
    'cp_featureFlags': ['PayloadPropertyContext'],
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Test Product 1 2 3 4',
    'description': '<div>Test Product <strong>description</strong></div>',
    'properties': {
        'schema:sku': {
            'title': 'Sku 1 (from schema)',
            'description': 'The Stock Keeping Unit (SKU), i.e. a merchant-specific identifier for a product or service, or the product to which the offer refers.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 98 },
            'links': [{ 'href': 'http://schema.org/sku', 'rel': 'canonical' }],
        },
        'approved': {
            'title': 'Approved',
            'type': 'boolean',
            'default': true,
            'cp_ui': { 'sortOrderForm': 95 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/approved', 'rel': 'canonical' }],
        },
        'serialNo': {
            'title': 'Serial No',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 96 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/serialNo', 'rel': 'canonical' }],
        },
        '__level': {
            'title': 'Level',
            '$id': 'http://platform.cosmoconsult.com/ontology/AnyThing',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAnyThing' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/__level', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['PayloadPropertyContext'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': true,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'format': 'chart:GaugeChart',
            'cp_ui': { 'hiddenInFilter': true },
        },
        'color': {
            'title': 'Color',
            'description': 'The color of the product.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 78 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/color', 'rel': 'canonical' }],
        },
        'height': {
            'title': 'Height',
            'description': ' A point value or interval for product characteristics and other purposes.',
            '$id': 'http://schema.org/QuantitativeValue',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FQuantitativeValue' },
                { 'href': 'http://schema.org/height', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['PayloadPropertyContext'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 84 },
        },
        'isFamilyFriendly': {
            'title': 'Is Family Friendly',
            'description': 'Indicates whether this content is family friendly.',
            'type': 'boolean',
            'const': true,
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 79 },
            'links': [{ 'href': 'http://schema.org/isFamilyFriendly', 'rel': 'canonical' }],
        },
        'pattern': {
            'title': 'Pattern',
            'description': "A pattern that something has, for example 'polka dot', 'striped', 'Canadian flag'. Values are typically expressed as text, although links to controlled value schemes are also supported.",
            'type': 'string',
            'format': 'cp:filter',
            'cp_ui': { 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://schema.org/pattern', 'rel': 'canonical' }],
        },
        'releaseDate': {
            'title': 'Release Date',
            'description': 'The release date of a product or product model. This can be used to distinguish the exact variant of a product.',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 88, 'wizardGroup': 0 },
            'links': [{ 'href': 'http://schema.org/releaseDate', 'rel': 'canonical' }],
        },
        'width': {
            'title': 'Width',
            'description': ' A point value or interval for product characteristics and other purposes.',
            '$id': 'http://schema.org/QuantitativeValue',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FQuantitativeValue' },
                { 'href': 'http://schema.org/width', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['PayloadPropertyContext'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'readOnly': true,
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 89 },
        },
        'urlSlug': {
            'title': 'Url Slug',
            'description': '<div>https://solution-store.cosmoconsult.com/solutions/<span style="background-color: #ebe6dc; color: #464f57;"><strong>this-is-a-url-slug</strong></span>/<br />A URL slug or website slug is the last part of the URL address that is used to uniquely identify the item or page.</div>',
            'type': 'string',
            'pattern': '^[a-z0-9-_]*$',
            'cp_ui': { 'sortOrderForm': 97 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/urlSlug', 'rel': 'canonical' }],
        },
        'stampText': {
            'title': 'Stamp Text',
            'type': 'string',
            'format': 'cp:code',
            'cp_ui': { 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/stampText', 'rel': 'canonical' }],
        },
        'company': {
            'title': 'CompanY',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 80 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/company', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 90 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 92 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 93 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'blockLocalization': {
            'title': 'Block Localization',
            'description': '<div>Allows to block the whole localization feature for this item.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 81 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/blockLocalization', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 85 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Product name1234 🛀🏾',
            'description': 'It is optional',
            'type': 'string',
            'format': 'cp:html',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'sortOrderForm': 86, 'wizardGroup': 1 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
        'cp:sku': {
            'title': 'Sku 2 (from cp)',
            '$id': 'http://platform.cosmoconsult.com/ontology/Sku',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSku' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/sku', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'cp_featureFlags': ['PayloadPropertyContext'],
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 99 },
        },
    },
    'required': ['isFamilyFriendly', 'company'],
    'cp_restrictablePaths': ['color'],
    'cp_hasPermissions': true,
};
export const TestProductSchema = Schema;
