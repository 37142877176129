import React, { CSSProperties } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { IScreenProps } from '@cpa/base-core/types';

import { IRelatedViewProps } from '../GenericScreen/GenericScreen';

const iframeStyle: CSSProperties = { minWidth: '100%', border: 'none', minHeight: '75vh' };

const DocumentationSearch: React.FC<IScreenProps & IRelatedViewProps> = ({ page, initialFilterValue }) => {
  if (!initialFilterValue || !page.dataUrl) {
    return null;
  }

  return (
    <IframeResizer
      scrolling={true}
      heightCalculationMethod={'documentElementScroll'}
      bodyBackground={'transparent'}
      bodyMargin={0}
      bodyPadding={0}
      src={`${page.dataUrl}?q=${encodeURIComponent(initialFilterValue)}`}
      style={iframeStyle}
    />
  );
};

export default DocumentationSearch;
