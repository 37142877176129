import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { axiosDictionary, postEntityToEndpoint } from '@cpa/base-core/api';
import { TypeConstants } from '@cp/base-types';
import notification from '@cpa/base-core/helpers/toast';
import classNames from 'classnames';
import { Icon, PrimaryButton, TextField } from '@fluentui/react';
import { IDataItem, IRelatedMessage } from '@cpa/base-core/types';
import { getSafeString } from '@cpa/base-core/helpers';

import TinyEditor from '../../../../../../../components/Form/components/TextWidget/components/Html/components/TinyEditor/TinyEditor';
import HoverTooltip from '../../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './MessageInput.module.scss';

interface IMessageInputProps {
  parentMessage: IDataItem;
  relatedMessage: IRelatedMessage | null;
  unsetRelatedMessage: () => void;
}

const MessageInput: React.FC<IMessageInputProps> = ({ parentMessage, relatedMessage, unsetRelatedMessage }) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState<string | boolean | number | object | null | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const toggleExpanded = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (expanded) {
        setInputValue((prevValue) => getSafeString(prevValue as string, true));
      }
      setExpanded(!expanded);
    },
    [expanded]
  );

  const handleInputChange = useCallback((content: string | boolean | number | object | null | undefined) => {
    setInputValue(content);
  }, []);

  const handlePost = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setLoading(true);
      // Post item
      await postEntityToEndpoint(axiosDictionary.appDataService, `data-store/${encodeURIComponent(TypeConstants.CpMessage)}`, {
        name: `Re: ${parentMessage.name}`,
        description: inputValue as string,
        accessPermissions: parentMessage.accessPermissions,
        parentMessage: { identifier: parentMessage.identifier },
        ...(relatedMessage ? { relatedTo: { identifier: relatedMessage.identifier } } : {}),
      })
        .then(() => {
          setInputValue('');
          setExpanded(false);
          unsetRelatedMessage();
        })
        .catch((e) => {
          notification.error('Failed to post comment');
          console.error('Failed to post comment', e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [parentMessage.name, parentMessage.accessPermissions, parentMessage.identifier, inputValue, relatedMessage, unsetRelatedMessage]
  );

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent) => {
      if (loading) return;
      if (event.shiftKey && event.key === 'Enter') {
        setExpanded(true);
      } else if (event.key === 'Enter') {
        await handlePost(event as unknown as React.MouseEvent<HTMLButtonElement>);
      }
    },
    [handlePost, loading]
  );

  const renderSuffix = useMemo(() => {
    return () => (
      <div className={styles.suffix}>
        <HoverTooltip content={'Format'}>
          <Icon iconName={'fabrictexthighlight'} onClick={toggleExpanded} />
        </HoverTooltip>
        <HoverTooltip content={'Send'}>
          <Icon iconName={'send'} onClick={handlePost} />
        </HoverTooltip>
      </div>
    );
  }, [handlePost, toggleExpanded]);

  if (!expanded) {
    return (
      <div style={{ marginTop: 10 }}>
        {relatedMessage ? (
          <div className={styles.relatedWrapper}>
            <span>{`Replying to a message from ${relatedMessage.userName}`}</span>
            <Icon iconName={'close'} onClick={unsetRelatedMessage} />
          </div>
        ) : null}
        <TextField
          disabled={loading}
          className={classNames(styles.box, { [styles.light]: !darkMode, [styles.withRelated]: relatedMessage })}
          borderless
          value={inputValue as string}
          onChange={(e, value) => handleInputChange(value)}
          placeholder={'Reply'}
          onRenderSuffix={renderSuffix}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.editor}>
        {relatedMessage ? (
          <div className={styles.relatedWrapper}>
            <span>{`Replying to a message from ${relatedMessage.userName}`}</span>
            <Icon iconName={'close'} onClick={unsetRelatedMessage} />
          </div>
        ) : null}
        <TinyEditor
          value={inputValue as string}
          onChange={handleInputChange}
          emptyValue={''}
          readOnly={loading}
          customToolbarButtons={
            'undo redo | removeformat | formatselect | bold italic underline blockquote hr' +
            ' | link emoticons ' +
            ' | bullist numlist outdent indent'
          }
        />
        <div className={styles.buttons}>
          <div className={styles.collapse} onClick={toggleExpanded}>
            {t('common.cancel')}
          </div>
          <PrimaryButton disabled={loading} onClick={handlePost}>
            Send
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
