const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/UserProfileShadow',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserProfileShadow' }],
    'cp_autoUserItemAccessPermission': true,
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'description': '<div>Complete your user profile and benefit from a personalized user experience. Detailed information enables us to offer you customized solutions for your concerns.</div>',
    'title': 'User Profile Shadow',
    'properties': {
        'jobRoles': {
            'title': 'Job Roles',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/JobRole',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FJobRole' }],
                'cp_parentPropertyJsonPath': 'parentJobRole.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Job Role',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Job Role' } },
            },
            'cp_ui': { 'sortOrderForm': 100 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/jobRole', 'rel': 'canonical' }],
        },
        'company': {
            'title': 'Company',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 96 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/company', 'rel': 'canonical' }],
        },
        'industries': {
            'title': 'Industries',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Industry',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIndustry' }],
                'cp_parentPropertyJsonPath': 'parent.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Industry',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Industry' } },
            },
            'cp_ui': { 'sortOrderForm': 99 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/industry', 'rel': 'canonical' }],
        },
        'businessRelationTypes': {
            'title': 'Business Relation Types',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/BusinessRelationType',
                'type': 'string',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessRelationType' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Business Relation',
                'anyOf': [
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Customer'],
                        'enumNames': ['Customer'],
                        'title': 'Customer',
                        'cp_enumIcons': ['CpCustomer'],
                        'description': '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
                        'cp_enumDescriptions': [
                            '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
                        ],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Prospect'],
                        'enumNames': ['Prospect'],
                        'title': 'Prospect',
                        'cp_enumIcons': ['ReminderPerson'],
                        'description': '<div>Natural or legal person who is interested in the products and/or services of COSMO CONSULT.</div>',
                        'cp_enumDescriptions': ['<div>Natural or legal person who is interested in the products and/or services of COSMO CONSULT.</div>'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Supplier'],
                        'enumNames': ['Supplier'],
                        'title': 'Supplier',
                        'cp_enumIcons': ['Teamwork'],
                        'description': '<div>Supplier of COSMO CONSULT as a natural person, legal entity or as a natural person in the external relationship for a legal entity.</div>',
                        'cp_enumDescriptions': [
                            '<div>Supplier of COSMO CONSULT as a natural person, legal entity or as a natural person in the external relationship for a legal entity.</div>',
                        ],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Partner'],
                        'enumNames': ['Partner'],
                        'title': 'Partner',
                        'cp_enumIcons': ['ContactHeart'],
                        'description': '<div>Natural or legal person who has a partner contract with COSMO CONSULT.</div>',
                        'cp_enumDescriptions': ['<div>Natural or legal person who has a partner contract with COSMO CONSULT.</div>'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Press'],
                        'enumNames': ['Press'],
                        'title': 'Press',
                        'cp_enumIcons': ['UserOptional'],
                        'description': '<div>Acting for or as press and/or media services.</div>',
                        'cp_enumDescriptions': ['<div>Acting for or as press and/or media services.</div>'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Reseller'],
                        'enumNames': ['Reseller'],
                        'title': 'Reseller',
                        'cp_enumIcons': ['TeamFavorite'],
                        'description': '<div>\n<div>Reseller of the products and/or services of COSMO CONSULT.</div>\n</div>',
                        'cp_enumDescriptions': ['<div>\n<div>Reseller of the products and/or services of COSMO CONSULT.</div>\n</div>'],
                    },
                ],
            },
            'uniqueItems': true,
            'cp_ui': { 'sortOrderForm': 98 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/businessRelationType', 'rel': 'canonical' }],
        },
        'userInterests': {
            'title': 'User Interests',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/UserInterest',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserInterest' }],
                'cp_handledByApiGateway': true,
                'cp_disableTrash': true,
                'cp_disableLocalization': true,
                'cp_disableVersions': true,
                'cp_disableClone': true,
                'cp_disableLazyLoading': true,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'User Interest',
                'properties': { 'identifier': { 'type': 'string', 'title': 'User Interest' } },
            },
            'cp_ui': { 'sortOrderForm': 101 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/userInterest', 'rel': 'canonical' }],
        },
        'cpaUserConfiguration': {
            'title': 'Cpa User Configuration',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaUserConfiguration',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaUserConfiguration' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpaUserConfiguration', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'userExperiencePreference': {
                    'title': 'User Experience Preference',
                    '$id': 'http://platform.cosmoconsult.com/ontology/UserExperiencePreference',
                    'type': 'string',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserExperiencePreference' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/userExperiencePreference', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'enum': [
                        'http://platform.cosmoconsult.com/ontology/Expert',
                        'http://platform.cosmoconsult.com/ontology/Simple',
                        'http://platform.cosmoconsult.com/ontology/Undefined',
                    ],
                    'enumNames': ['Expert', 'Simple', 'Undefined'],
                    'cp_enumIcons': ['CpSkillLevel4', null, null],
                    'cp_enumDescriptions': [
                        '<div style="text-align: center;">Subject matter expert who understands the underlying principles<br />Can perform task on a strategic or architectural level<br />Typically many years of practical experience</div>',
                        null,
                        null,
                    ],
                },
                'cpaUserConfigurations': {
                    'title': 'Cpa User Configurations',
                    'type': 'array',
                    'items': {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpaUserConfiguration',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaUserConfiguration' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cpa User Configuration',
                        'properties': {
                            'cpa': {
                                'title': 'Cpa',
                                '$id': 'http://platform.cosmoconsult.com/ontology/Cpa',
                                'type': 'object',
                                'links': [
                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/cpa', 'rel': 'canonical' },
                                ],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': true,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa' } },
                                'cp_ignoreRelation': true,
                            },
                            'userExperiencePreference': {
                                'title': 'User Experience Preference',
                                '$id': 'http://platform.cosmoconsult.com/ontology/UserExperiencePreference',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserExperiencePreference' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/userExperiencePreference', 'rel': 'canonical' },
                                ],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'enum': [
                                    'http://platform.cosmoconsult.com/ontology/Expert',
                                    'http://platform.cosmoconsult.com/ontology/Simple',
                                    'http://platform.cosmoconsult.com/ontology/Undefined',
                                ],
                                'enumNames': ['Expert', 'Simple', 'Undefined'],
                                'cp_enumIcons': ['CpSkillLevel4', null, null],
                                'cp_enumDescriptions': [
                                    '<div style="text-align: center;">Subject matter expert who understands the underlying principles<br />Can perform task on a strategic or architectural level<br />Typically many years of practical experience</div>',
                                    null,
                                    null,
                                ],
                            },
                            'darkMode': {
                                'title': 'Dark Mode',
                                'type': 'boolean',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/darkMode', 'rel': 'canonical' }],
                            },
                            'highContrast': {
                                'title': 'High Contrast',
                                'type': 'boolean',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/highContrast', 'rel': 'canonical' }],
                            },
                            'zebraRows': {
                                'title': 'Zebra Rows',
                                'type': 'boolean',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/zebraRows', 'rel': 'canonical' }],
                            },
                            'disableAnimation': {
                                'title': 'Disable Animation',
                                'type': 'boolean',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableAnimation', 'rel': 'canonical' }],
                            },
                            'useOnlyHorizontalMenu': {
                                'title': 'Use Only Horizontal Menu',
                                'type': 'boolean',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/useOnlyHorizontalMenu', 'rel': 'canonical' }],
                            },
                            'tableActionsPosition': {
                                'title': 'Table Actions Position',
                                '$id': 'http://platform.cosmoconsult.com/ontology/TableActionsPosition',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTableActionsPosition' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/tableActionsPosition', 'rel': 'canonical' },
                                ],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'enum': [
                                    'http://platform.cosmoconsult.com/ontology/Left',
                                    'http://platform.cosmoconsult.com/ontology/Right',
                                    'http://platform.cosmoconsult.com/ontology/Undefined',
                                ],
                                'enumNames': ['Left', 'Right', 'Undefined'],
                            },
                            'dataLanguage': {
                                'title': 'Data Language',
                                'type': 'string',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dataLanguage', 'rel': 'canonical' }],
                            },
                            'surfaceLight': {
                                'title': 'Surface Light',
                                'type': 'boolean',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/surfaceLight', 'rel': 'canonical' }],
                            },
                        },
                    },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/cpaUserConfiguration', 'rel': 'canonical' }],
                },
                'darkMode': {
                    'title': 'Dark Mode',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/darkMode', 'rel': 'canonical' }],
                },
                'highContrast': {
                    'title': 'High Contrast',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/highContrast', 'rel': 'canonical' }],
                },
                'zebraRows': {
                    'title': 'Zebra Rows',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/zebraRows', 'rel': 'canonical' }],
                },
                'disableAnimation': {
                    'title': 'Disable Animation',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableAnimation', 'rel': 'canonical' }],
                },
                'useOnlyHorizontalMenu': {
                    'title': 'Use Only Horizontal Menu',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/useOnlyHorizontalMenu', 'rel': 'canonical' }],
                },
                'tableActionsPosition': {
                    'title': 'Table Actions Position',
                    '$id': 'http://platform.cosmoconsult.com/ontology/TableActionsPosition',
                    'type': 'string',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTableActionsPosition' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/tableActionsPosition', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'enum': [
                        'http://platform.cosmoconsult.com/ontology/Left',
                        'http://platform.cosmoconsult.com/ontology/Right',
                        'http://platform.cosmoconsult.com/ontology/Undefined',
                    ],
                    'enumNames': ['Left', 'Right', 'Undefined'],
                },
                'cpaPageUserConfigurations': {
                    'title': 'Cpa Page User Configurations',
                    'type': 'array',
                    'items': {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageUserConfiguration',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageUserConfiguration' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cpa Page User Configuration',
                        'properties': {
                            'cpa': {
                                'title': 'Cpa',
                                '$id': 'http://platform.cosmoconsult.com/ontology/Cpa',
                                'type': 'object',
                                'links': [
                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/cpa', 'rel': 'canonical' },
                                ],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': true,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa' } },
                                'cp_ui': { 'sortOrderForm': 16 },
                                'cp_ignoreRelation': true,
                            },
                            'cpaPage': {
                                'title': 'Cpa Page',
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
                                'type': 'object',
                                'links': [
                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/cpaPage', 'rel': 'canonical' },
                                ],
                                'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
                                'cp_ui': { 'sortOrderForm': 17 },
                                'cp_ignoreRelation': true,
                            },
                            'displayOnDashboards': {
                                'title': 'Display On Dashboards',
                                'type': 'array',
                                'items': {
                                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
                                    'type': 'object',
                                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' }],
                                    'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
                                    'cp_handledByApiGateway': false,
                                    'cp_disableTrash': false,
                                    'cp_disableLocalization': false,
                                    'cp_disableVersions': false,
                                    'cp_disableClone': false,
                                    'cp_disableLazyLoading': false,
                                    'patternProperties': { '^[_@].*$': {} },
                                    'additionalProperties': false,
                                    'title': 'Cpa Page',
                                    'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
                                    'cp_ignoreRelation': true,
                                },
                                'cp_ui': { 'sortOrderForm': 25 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/displayOnDashboard', 'rel': 'canonical' }],
                            },
                            'displayAsCards': {
                                'title': 'Display As Cards',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 22 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/displayAsCards', 'rel': 'canonical' }],
                            },
                            'disabledColumns': {
                                'title': 'Disabled Columns',
                                'type': 'array',
                                'items': { 'type': 'string' },
                                'cp_ui': { 'sortOrderForm': 21 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disabledColumn', 'rel': 'canonical' }],
                            },
                            'displayFilter': {
                                'title': 'Display Filter',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 24 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/displayFilter', 'rel': 'canonical' }],
                            },
                            'displayChart': {
                                'title': 'Display Chart',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 23 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/displayChart', 'rel': 'canonical' }],
                            },
                            'displayTable': {
                                'title': 'Display Table',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 26 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/displayTable', 'rel': 'canonical' }],
                            },
                            'facetFiltersWidth': {
                                'title': 'Facet Filters Width',
                                'type': 'number',
                                'cp_ui': { 'sortOrderForm': 27 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/facetFiltersWidth', 'rel': 'canonical' }],
                            },
                            'customView': {
                                'title': 'Custom View',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 20 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/customView', 'rel': 'canonical' }],
                            },
                            'widgetCustomView': {
                                'title': 'Widget Custom View',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 28 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/widgetCustomView', 'rel': 'canonical' }],
                            },
                            'widgetDisplayTable': {
                                'title': 'Widget Display Table',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 31 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/widgetDisplayTable', 'rel': 'canonical' }],
                            },
                            'widgetDisabledColumns': {
                                'title': 'Widget Disabled Columns',
                                'type': 'array',
                                'items': { 'type': 'string' },
                                'cp_ui': { 'sortOrderForm': 29 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/widgetDisabledColumn', 'rel': 'canonical' }],
                            },
                            'widgetDisplayAsCards': {
                                'title': 'Widget Display As Cards',
                                'type': 'boolean',
                                'cp_ui': { 'sortOrderForm': 30 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/widgetDisplayAsCards', 'rel': 'canonical' }],
                            },
                            'amountOfColumns': {
                                'title': 'Amount Of Columns',
                                'type': 'number',
                                'cp_ui': { 'sortOrderForm': 18 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/amountOfColumns', 'rel': 'canonical' }],
                            },
                            'chartOptions': {
                                'title': 'Chart Options',
                                'type': 'string',
                                'cp_ui': { 'sortOrderForm': 19 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/chartOptions', 'rel': 'canonical' }],
                            },
                        },
                    },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/cpaPageUserConfiguration', 'rel': 'canonical' }],
                },
                'dataLanguage': {
                    'title': 'Data Language',
                    'type': 'string',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dataLanguage', 'rel': 'canonical' }],
                },
                'surfaceLight': {
                    'title': 'Surface Light',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/surfaceLight', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 102 },
        },
        'address': {
            'title': 'Address',
            'description': 'The mailing address.',
            '$id': 'http://schema.org/PostalAddress',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPostalAddress' },
                { 'href': 'http://schema.org/address', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'addressCountry': {
                    'title': 'Address Country',
                    'description': 'The country. For example, USA. You can also provide the two-letter [ISO 3166-1 alpha-2 country code](http://en.wikipedia.org/wiki/ISO_3166-1).',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 45 },
                    'links': [{ 'href': 'http://schema.org/addressCountry', 'rel': 'canonical' }],
                },
                'addressLocality': {
                    'title': 'Address Locality',
                    'description': 'The locality in which the street address is, and which is in the region. For example, Mountain View.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 43 },
                    'links': [{ 'href': 'http://schema.org/addressLocality', 'rel': 'canonical' }],
                },
                'postalCode': {
                    'title': 'Postal Code',
                    'description': 'The postal code. For example, 94043.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 42 },
                    'links': [{ 'href': 'http://schema.org/postalCode', 'rel': 'canonical' }],
                },
                'streetAddress': {
                    'title': 'Street Address',
                    'description': 'The street address. For example, 1600 Amphitheatre Pkwy.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 41 },
                    'links': [{ 'href': 'http://schema.org/streetAddress', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 97 },
        },
        'familyName': {
            'title': 'Family Name',
            'description': '<div>Last name, surname, or family name.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
        },
        'gender': {
            'title': 'Gender',
            'description': 'An enumeration of genders.',
            '$id': 'http://schema.org/GenderType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FGenderType' },
                { 'href': 'http://schema.org/gender', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': ['http://schema.org/Female', 'http://schema.org/Male', 'http://platform.cosmoconsult.com/ontology/Diverse'],
            'enumNames': ['Female', 'Male', 'Diverse'],
            'cp_enumIcons': ['CpGenderFemale', 'CpGenderMale', 'CpGenderThird'],
            'cp_enumDescriptions': [
                'The female gender.',
                'The male gender.',
                '<div>Also known as <strong>third gender</strong>.</div>\n<div>A category of people who do not identify as male or female, but rather as neither, both, or a combination of male and female genders.</div>',
            ],
            'cp_ui': { 'sortOrderForm': 92 },
        },
        'givenName': {
            'title': 'Given Name',
            'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 93 },
            'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
        },
        'telephone': {
            'title': 'Telephone',
            'description': '<div>The telephone number at which a company or person can be reached during business hours.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 95 },
            'links': [{ 'href': 'http://schema.org/telephone', 'rel': 'canonical' }],
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 103 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                                'cp_ui': { 'sortOrderForm': 46 },
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'cp_ui': { 'sortOrderForm': 38 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'sortOrderForm': 108 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
    },
    'cp_hasPermissions': true,
};
export const UserProfileShadowSchema = Schema;
