import { IJSONSchema, Schemas, TypeConstants } from '@cp/base-types';
import { simpleCardKey } from '@cpa/base-core/constants';
import { checkIfRelatedLinkExist, generateCpShareLink, prepareSchema } from '@cpa/base-core/helpers';
import { useCardWidth, useUxPreferenceFeature } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { IDetailsColumnFieldProps, Icon } from '@fluentui/react';
import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Email from '../../../../../components/Formatter/components/Email/Email';
import HoverTooltip from '../../../../../components/HoverTooltip/HoverTooltip';
import { TableContext } from '../../../../../components/ScrollingContent/ScrollingContent';
import { FormatSource, formatValue } from '../../../../../index';

import styles from './SimpleCard.module.scss';
import Comments from './components/Comments/Comments';
import Rating from './components/Rating/Rating';
import Voting from './components/Voting/Voting';

interface ISimpleCardProps {
  item: any;
  page?: Schemas.CpaPage;
  level?: number;
  isSelected?: boolean;
  cpTypeUrl?: string;
  width?: number;
}

const SimpleCard: React.FC<ISimpleCardProps> = ({ item, level, page, cpTypeUrl, width }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const tableContext = useContext(TableContext);

  const isCommentsEnabled = useUxPreferenceFeature(page, 'comment');
  const isRatingEnabled = useUxPreferenceFeature(page, 'rate');
  const isVotingEnabled = useUxPreferenceFeature(page, 'vote');
  const isImageEnabled = useUxPreferenceFeature(page, 'image');
  const isVersionBadgeEnabled = useUxPreferenceFeature(page, 'versionBadge');

  const [t, i18n] = useTranslation();

  const isCommentsExists = useMemo(() => {
    if (!isCommentsEnabled) return false;
    const schema = tableContext?.schema;
    if (!schema) return false;
    return checkIfRelatedLinkExist(TypeConstants.Comment, schema);
  }, [isCommentsEnabled, tableContext?.schema]);

  const isRatingExists = useMemo(() => {
    if (!isRatingEnabled) return false;
    const schema = tableContext?.schema;
    if (!schema) return false;
    return checkIfRelatedLinkExist(TypeConstants.Rating, schema);
  }, [isRatingEnabled, tableContext?.schema]);

  const isVotingExists = useMemo(() => {
    if (!isVotingEnabled) return false;
    const schema = tableContext?.schema;
    if (!schema) return false;
    return checkIfRelatedLinkExist(TypeConstants.Voting, schema);
  }, [isVotingEnabled, tableContext?.schema]);

  const imageExists = useMemo(() => !!item.image, [item.image]);

  const formatProperty = useCallback(
    (property: string) => {
      const schema = tableContext?.schema;
      if (!schema) return null;
      const currentLanguageCode = i18n.language;
      const value = item[property];
      const schemaForFormatter = prepareSchema(schema?.properties?.[property] as IJSONSchema, value);
      const formatted = formatValue(item[property], FormatSource.SimpleCard, {
        schema: schemaForFormatter || null,
        locale: currentLanguageCode,
        darkMode: darkMode,
        t: t,
        itemKey: property,
        rootSchema: schema,
        originalItem: item?.__originalItem || item,
        page: page,
      });
      return formatted;
    },
    [darkMode, i18n.language, item, page, t, tableContext?.schema]
  );

  const cpaIdentifier = useSelector((store: IGlobalState) => store.app.cpa?.identifier);
  const handleShareClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (!cpaIdentifier || !page) {
        return;
      }
      await generateCpShareLink(cpaIdentifier, page, item);
    },
    [cpaIdentifier, item, page]
  );

  const title = useMemo(() => {
    if (item.name) {
      return formatProperty('name');
    }
    return item.identifier;
  }, [formatProperty, item]);

  const user = useMemo(() => {
    const owners = (item as Schemas.Thing).owners;
    return owners?.length ? (owners.filter((owner) => typeof owner === 'string') as string[]) : item.createdByUser;
  }, [item]);

  const cardMargin = useCardWidth();

  const cardWidth = useMemo(() => {
    return (width || 0) - (level || 0) * cardMargin + 20;
  }, [cardMargin, level, width]);

  return (
    <div>
      <div
        className={classNames(styles.simpleCard, {
          [styles.small]: tableContext?.isWidget,
          [styles.withoutVersionBadge]: !isVersionBadgeEnabled,
        })}
        style={{ width: cardWidth }}
      >
        {/* TODO: decide about solution colors */}
        {/*{colorsStyle ? <div className={styles.colors} style={{ background: colorsStyle }}></div> : null}*/}
        <div className={classNames(styles.content)}>
          <div className={styles.textWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.descriptionContainer}>
              {item.shortDescription ? <div className={styles.shortDescription}>{formatProperty('shortDescription')}</div> : null}
              {imageExists && isImageEnabled ? (
                <div className={styles.imageContainer}>
                  <img src={item.image} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {tableContext?.isWidget ? null : (
          <div className={styles.footer}>
            <div className={styles.left}>
              {item['createdByUser'] ? (
                <div className={styles.createdByUser}>
                  <Icon iconName={'contact'} />
                  <span className={styles.emailContainer}>
                    <Email email={user} source={FormatSource.Table}></Email>
                  </span>
                </div>
              ) : null}
            </div>
            <div className={styles.right}>
              {!tableContext?.disableItemSharing ? (
                <div className={styles.share} onClick={handleShareClick}>
                  <HoverTooltip content={t('common.share')}>
                    <Icon iconName={'Share'} />
                  </HoverTooltip>
                </div>
              ) : null}
              {isVotingExists ? <Voting identifier={item.identifier} cpTypeUrl={cpTypeUrl} isVotingExists={isVotingExists} /> : null}
              {isRatingExists ? <Rating identifier={item.identifier} cpTypeUrl={cpTypeUrl} isRatingExists={isRatingExists} /> : null}
            </div>
          </div>
        )}
      </div>
      {isCommentsExists ? <Comments identifier={item.identifier} cpTypeUrl={cpTypeUrl} width={cardWidth} /> : null}
    </div>
  );
};

export const simplifiedRowRenderer = (
  props: IDetailsColumnFieldProps | undefined,
  defaultRenderer: ((props: IDetailsColumnFieldProps) => JSX.Element | null) | undefined,
  level: number,
  page: Schemas.CpaPage
): JSX.Element | null => {
  if (props?.column.key !== simpleCardKey) return defaultRenderer && props ? defaultRenderer(props) : null;
  return (
    <SimpleCard
      key={props.itemIndex}
      page={page}
      level={level}
      item={props.item}
      isSelected={props.isSelected}
      cpTypeUrl={page.cpTypeUrl}
      width={props.column.calculatedWidth}
    />
  );
};
