const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpTypeTrigger',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypeTrigger' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Type Trigger',
    'properties': {
        'function': {
            'title': 'Function',
            'description': '<div>\n<div>The Javascript function be executed when the trigger becomes active. </div>\n<div>Can be either an inline function or a shared function.</div>\n</div>',
            'anyOf': [
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpFunction',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFunction' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': true,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Function',
                    'properties': {
                        'identifier': { 'type': 'string', 'title': 'Cp Function' },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpFunction',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpInlineFunction' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Inline Function',
                    'properties': {
                        'sourceCode': {
                            'title': 'Source Code',
                            'type': 'string',
                            'default': 'trigger.execute = function(executionContext, utils) {\n  // Here we write code that will be called on every execute\n};\n',
                            'format': 'cp:code',
                            'cp_ui': { 'sortOrderForm': 0 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sourceCode', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['sourceCode'],
                },
            ],
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/function', 'rel': 'canonical' }],
        },
        'cpTypeUrl': {
            'title': 'Cp Type Url',
            'description': '<div>The URL of the type the trigger is listening for. For example if the trigger listens for <em>on create</em> events of type <em>comments </em>it will be triggered every time a new <em>comment</em> is created.</div>',
            'type': 'string',
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
            ],
        },
        'typeTriggerType': {
            'title': 'Type Trigger Type',
            'description': '<div>The type of the trigger defines when the trigger is executed. </div>\n<div>Depending on the trigger type, different objects are available in the execution context passed to the trigger function. </div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpTypeTriggerType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypeTriggerType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/typeTriggerType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/OnAfterCreate',
                'http://platform.cosmoconsult.com/ontology/OnAfterDelete',
                'http://platform.cosmoconsult.com/ontology/OnAfterFormChange',
                'http://platform.cosmoconsult.com/ontology/OnAfterFormClose',
                'http://platform.cosmoconsult.com/ontology/OnAfterFormSubmit',
                'http://platform.cosmoconsult.com/ontology/OnAfterPublishMajorVersion',
                'http://platform.cosmoconsult.com/ontology/OnAfterRead',
                'http://platform.cosmoconsult.com/ontology/OnAfterUiItemRead',
                'http://platform.cosmoconsult.com/ontology/OnAfterUpdate',
                'http://platform.cosmoconsult.com/ontology/OnBeforeCreate',
                'http://platform.cosmoconsult.com/ontology/OnBeforeDelete',
                'http://platform.cosmoconsult.com/ontology/OnBeforeFormInit',
                'http://platform.cosmoconsult.com/ontology/OnBeforeFormSubmit',
                'http://platform.cosmoconsult.com/ontology/OnBeforeFormClose',
                'http://platform.cosmoconsult.com/ontology/OnBeforePublishMajorVersion',
                'http://platform.cosmoconsult.com/ontology/OnBeforeRead',
                'http://platform.cosmoconsult.com/ontology/OnBeforeUiCreate',
                'http://platform.cosmoconsult.com/ontology/OnBeforeUiDelete',
                'http://platform.cosmoconsult.com/ontology/OnBeforeUiDrop',
                'http://platform.cosmoconsult.com/ontology/OnBeforeUiUpdate',
                'http://platform.cosmoconsult.com/ontology/OnBeforeUpdate',
                'http://platform.cosmoconsult.com/ontology/OnBeforeUiUserMachineTranslate',
                'http://platform.cosmoconsult.com/ontology/OnBeforeVectorize',
            ],
            'enumNames': [
                'On After Create',
                'On After Delete',
                'On After Form Change',
                'On After Form Close',
                'On After Form Submit',
                'On After Publish Major Version',
                'On After Read',
                'On After Ui Item Read',
                'On After Update',
                'On Before Create',
                'On Before Delete',
                'On Before Form Init',
                'On Before Form Submit',
                'On Before Form Close',
                'On Before Publish Major Version',
                'On Before Read',
                'On Before Ui Create',
                'On Before Ui Delete',
                'On Before Ui Drop',
                'On Before Ui Update',
                'On Before Update',
                'On Before Ui User Machine Translate',
                'On Before Vectorize',
            ],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The display name of the Type Trigger.</div>',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'cpTypeUrl', 'typeTriggerType', 'function'],
};
export const CpTypeTriggerSchema = Schema;
